import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';
import RenderFeature from 'ol/render/Feature';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import ImageStyle from 'ol/style/Image';
import { StyleFunction } from 'ol/style/Style';
import {
  ASSET_ID,
  ASSET_LABEL,
  LAST_SENSED_ISO8601,
  LOCALISED_MAX_DISTANCE_M,
  LOCAL_BEACONS,
  PIN_ELEMENT_SCALE,
  SENSED_ASSETS,
  UNKNOWN,
} from '../../../util/constants';
import { ReportBeacon } from '../Messages';
import { Color } from 'ol/color';
import { ZIndexes } from '../../../util/ZIndexes';
import CircleStyle from 'ol/style/Circle';
import { ACTIVE_GREEN, PURPLE, RED, WHITE } from '../../../Style/GeoMobyBaseTheme';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { LayerParts } from '../types';

/* Icons */
export const iconFromElement = (
  el: HTMLImageElement,
  w: number,
  h: number,
  ax: number,
  ay: number,
  options?: {
    scale?: number;
    opcaity?: number;
    color?: Color | string;
  },
): Icon => {
  return new Icon({
    img: el,
    imgSize: [w, h],
    anchor: [ax, ay],
    scale: options?.scale === undefined ? 1 : options.scale,
    opacity: options?.opcaity === undefined ? 1 : options.opcaity,
    color: options?.color,
  });
};

const pinFromElement = (id: string) =>
  iconFromElement(document.getElementById(id) as HTMLImageElement, 512, 512, 0.5, 1, {
    scale: PIN_ELEMENT_SCALE,
  });
const walkingBlueIcon = pinFromElement('walkingBlue');
const walkingPurpleIcon = pinFromElement('walkingPurple');
const walkingRedIcon = pinFromElement('walkingRed');
const walkingGreenIcon = pinFromElement('walkingGreen');
const walkingOrangeIcon = pinFromElement('walkingOrange');
const locationOnBlueIcon = pinFromElement('locationOnBlue');
const locationOnPurpleIcon = pinFromElement('locationOnPurple');
const locationOnRedIcon = pinFromElement('locationOnRed');
const gpsTrackerIcon = (selected: boolean, beacons?: unknown[]): ImageStyle => {
  return selected ? locationOnRedIcon : beacons ? locationOnPurpleIcon : locationOnBlueIcon;
};
const assetIcon = (
  selected: boolean,
  wfCheck: boolean | undefined,
  beacons?: unknown[],
): ImageStyle => {
  if (wfCheck !== undefined) {
    return wfCheck ? walkingGreenIcon : walkingOrangeIcon;
  }
  return selected ? walkingRedIcon : beacons ? walkingPurpleIcon : walkingBlueIcon;
};

/* Count */
export const countStyle = (
  count: number,
  options?: {
    radius?: number;
    offset_x?: number;
    offset_y?: number;
    text_offset_x?: number;
    text_offset_y?: number;
    active?: boolean;
  },
): Style =>
  new Style({
    image: new CircleStyle({
      displacement: [12.5, 13],
      radius: count === 0 ? 6 : 10,
      fill: new Fill({
        color: options?.active ? ACTIVE_GREEN : RED,
      }),
    }),
    text: new Text({
      text: count === 0 ? '' : count > 99 ? '99+' : count.toString(),
      fill: new Fill({
        color: WHITE,
      }),
      font: `10px Open Sans, Montserrat, Arial, sans-serif`,
      stroke: new Stroke({
        color: WHITE,
        width: 1,
      }),
      offsetX: 13,
      offsetY: -13,
      scale: 0.9,
    }),
    zIndex: ZIndexes.COUNTER,
  });
export const transmissionArea = (radiusM: number) =>
  new Style({
    renderer: (coords, { context, resolution }) => {
      const [x, y] = coords;
      if (typeof x === 'number' && typeof y === 'number') {
        // Draw the radius for picking up local beacons
        const radius = radiusM / resolution;

        const gradient = context.createRadialGradient(x, y, 0, x, y, radius * 1.4);
        gradient.addColorStop(0, `${PURPLE}00`);
        gradient.addColorStop(0.6, `${PURPLE}44`);
        gradient.addColorStop(1, PURPLE);

        context.beginPath();
        context.arc(x, y, radius, 0, 2 * Math.PI, true);
        context.fillStyle = gradient;
        context.fill();

        context.arc(x, y, radius, 0, 2 * Math.PI, true);
        context.lineWidth = 0;
        context.strokeStyle = '#883DE800';
        context.stroke();
      } else {
        throw new Error('Unexpected coordinate parameter for Beacon renderer');
      }
    },
    zIndex: 1,
  });

/* Asset */
const styleFromAssetId = (
  assetId: { [key: string]: string },
  selected: boolean,
  wfCheck: boolean | undefined,
  localBeacons?: ReportBeacon[],
): Style[] => {
  const image = assetId?.gpsTrackerId
    ? gpsTrackerIcon(selected, localBeacons)
    : assetIcon(selected, wfCheck, localBeacons);
  const styles = [
    new Style({
      image,
      zIndex: ZIndexes.MARKER,
    }),
  ];

  if (localBeacons !== undefined) {
    if (localBeacons.length > 0) {
      styles.push(countStyle(localBeacons.length, { active: true }));
    }
    styles.push(transmissionArea(LOCALISED_MAX_DISTANCE_M));
  }
  return styles;
};
const getAssetCacheKey = (
  assetLabel: string,
  selected: boolean,
  wfCheck: boolean | undefined,
  nearby?: unknown,
): string => {
  if (wfCheck !== undefined) return '' + assetLabel + '_wf_' + (wfCheck ? 'ok' : 'notok');
  return assetLabel + '_' + (selected ? 'selected' : 'not_selected') + JSON.stringify(nearby);
};
export const assetStyle = (cache: Map<string, Style | Style[]>): StyleFunction => {
  return (asset: Feature<Geometry> | RenderFeature, p1) => {
    const assetId = asset.get(ASSET_ID);
    const assetLabel = asset.get(ASSET_LABEL) ?? UNKNOWN;
    const selected = asset.get('selected') ?? false;
    const wfCheck = asset.get('wfCheck') ?? undefined;
    const localBeacons = asset.get(LOCAL_BEACONS);
    const key = getAssetCacheKey(assetLabel, selected, wfCheck, localBeacons);
    if (cache.has(key)) {
      const cachedStyle = cache.get(key);
      if (cachedStyle !== undefined) return cachedStyle;
    }
    const style = styleFromAssetId(assetId, selected, wfCheck, localBeacons);
    cache.set(key, style);
    return style;
  };
};
export const initialiseAssetOverlay = (): LayerParts => {
  const source = new VectorSource();
  return {
    src: source,
    lyr: new VectorLayer({
      source,
      style: assetStyle(new Map<string, Style>()),
      zIndex: ZIndexes.MARKER,
    }),
  };
};
