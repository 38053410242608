export enum ZIndexes {
  MAP = 0,
  MAP_BANNER = 1,
  MAP_TOOLBAR = 2,
  GEOFENCE = 3,
  GEOFENCE_FOREGROUND = 4,
  LINE = 4,
  MARKER = 5,
  COUNTER = 6,
  NOTIFICATION_TOAST = 7,
}
