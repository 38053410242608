import { GridRowData } from '@material-ui/data-grid';
import { Delete } from '@mui/icons-material';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import { Dispatch, SetStateAction, useState } from 'react';
import { UNKNOWN_LAYER } from '../../../../../util/constants';
import { GeomobyPropertiesValues } from '../../../../../util/enums';
import { BACKGROUND_OFFSET, lineLimitStyle, PRIMARY } from '../../../../../Style/GeoMobyBaseTheme';
import { GeomobyOverride } from '../../../types';
import { AlertStylePropertyValues } from '../../../values';
import { Map as olMap } from 'ol';
import { Property } from '../../../../ProjectConfig/types';
import { PropertyType } from '../../../../ProjectConfig/enums';

export const PropertiesTable = ({
  rows,
}: {
  rows: { index: number; property: string; value: string }[];
}) => (
  <TableContainer component={Paper}>
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell style={{ fontSize: '15px' }}>Property</TableCell>
          <TableCell style={{ fontSize: '15px' }} align="right">
            Value
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.length === 0 && (
          <TableRow>
            <TableCell>No properties found</TableCell>
          </TableRow>
        )}
        {rows
          .sort((a, b) => a.property.localeCompare(b.property))
          .map(row => (
            <TableRow
              key={row.property}
              sx={{ fontSize: '15px', '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <Tooltip title={row.property}>
                <TableCell component="th" scope="row">
                  {row.property}
                </TableCell>
              </Tooltip>
              <Tooltip title={row.value}>
                <TableCell align="right" style={{ ...lineLimitStyle, overflowWrap: 'anywhere' }}>
                  {row.value}
                </TableCell>
              </Tooltip>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export const GeomobyProperties = ({
  olmap,
  selectedGeofence,
  displayGeomobyProperties,
  setDisplayGeomobyProperties,
  allProperties,
  saveAllChanges,
  updateGeomobyProperties,
}: {
  olmap: olMap;
  selectedGeofence: GridRowData | undefined;
  displayGeomobyProperties: { index: number; property: string; value: string }[];
  setDisplayGeomobyProperties: Dispatch<
    SetStateAction<{ index: number; property: string; value: string }[]>
  >;
  allProperties: Property[];
  saveAllChanges: () => Promise<void>;
  updateGeomobyProperties: (
    olmap: olMap,
    geomobyProperties: Record<string, string>,
  ) => Promise<Record<string, string> | undefined>;
}) => {
  const onChangeFields = (updatedProperty: { index: number; property: string; value: string }) => {
    return [
      ...displayGeomobyProperties.filter(displayed => displayed.index !== updatedProperty.index),
      updatedProperty,
    ];
  };

  const onChangeValue = (
    value: string,
    prop: { index: number; property: string; value: string },
  ) => {
    const updatedProperties: Record<string, string> = {};
    const updatedDisplayGeomobyProperties = onChangeFields({
      index: prop.index,
      property: prop.property,
      value: value,
    });
    setDisplayGeomobyProperties(updatedDisplayGeomobyProperties);
  };

  const onChangeProperty = async (
    property: string,
    prop: { index: number; property: string; value: string },
  ) => {
    if (!olmap) return;
    const updatedProperties: Record<string, string> = {};
    let value = prop.value;
    const foundProperty = allProperties.find(p => p.label === property);
    if (foundProperty?.type === PropertyType.List.toLowerCase()) {
      value =
        foundProperty.type === PropertyType.List.toLowerCase() && foundProperty.items
          ? foundProperty.items.find(i => i === prop.value) ?? foundProperty.items?.[0]
          : prop.value ?? '';
    }

    const updatedDisplayGeomobyProperties = onChangeFields({
      index: prop.index,
      property,
      value,
    });
    updatedDisplayGeomobyProperties.map(prop => {
      updatedProperties[prop.property] = prop.value;
    });
    await updateGeomobyProperties(olmap, updatedProperties);
    setDisplayGeomobyProperties(updatedDisplayGeomobyProperties);
  };

  const onBlurValue = async (
    value: string,
    prop: { index: number; property: string; value: string },
  ) => {
    const updatedProperties: Record<string, string> = {};
    const updatedDisplayGeomobyProperties = onChangeFields({
      index: prop.index,
      property: prop.property,
      value: value,
    });
    updatedDisplayGeomobyProperties.map(prop => {
      updatedProperties[prop.property] = prop.value;
    });
    await updateGeomobyProperties(olmap, updatedProperties);
    setDisplayGeomobyProperties(updatedDisplayGeomobyProperties);
  };

  const onKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      saveAllChanges();
    }
  };

  return (
    <>
      <Grid
        container
        style={{
          marginTop: '10px',
        }}
      >
        <Tooltip title={'Geofence Properties'}>
          <Typography style={{ color: PRIMARY }}>Geofence Properties</Typography>
        </Tooltip>

        <TableContainer
          component={Paper}
          style={{
            maxHeight: '500px',
          }}
        >
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow
                key={'header-row'}
                style={{
                  height: '55px',
                }}
              >
                <TableCell
                  key={'Property'}
                  style={{ fontSize: '15px', backgroundColor: BACKGROUND_OFFSET }}
                  align="left"
                >
                  Property
                </TableCell>
                <TableCell
                  key={'Value'}
                  style={{ fontSize: '15px', backgroundColor: BACKGROUND_OFFSET }}
                  align="left"
                >
                  Value
                </TableCell>
                <TableCell
                  key={''}
                  style={{ fontSize: '15px', backgroundColor: BACKGROUND_OFFSET }}
                  align="left"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayGeomobyProperties
                .sort((a, b) => {
                  return String(a.index).localeCompare(String(b.index));
                })
                .map(property => {
                  return (
                    <TableRow
                      key={`${property.index}-row`}
                      sx={{
                        height: '55px',
                        fontSize: '15px',
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      {/* Property */}
                      <TableCell
                        key={`${property.index}-cell`}
                        style={{
                          padding: '0px 0px 0px 5px',
                          width: '40%',
                          maxWidth: '190px',
                          fontSize: '15px',
                        }}
                      >
                        <FormControl
                          key={`${property.index}-form`}
                          sx={{
                            width: '100%',
                            marginTop: '8px',
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor:
                                !allProperties.find(p => p.label === property.property) &&
                                property.property
                                  ? 'red !important'
                                  : 'inherit',
                            },
                          }}
                        >
                          <Select
                            fullWidth
                            key={`${property.index}-list-value-cell`}
                            id={'prop-property-' + property.index}
                            name={'prop' + property.index}
                            value={property.property}
                            style={{
                              width: '100%',
                            }}
                            onChange={async (e: { target: { value: string } }) => {
                              onChangeProperty(e.target.value, property);
                            }}
                          >
                            {(allProperties.find(p => p.label === property.property)
                              ? allProperties.filter(
                                  p =>
                                    !displayGeomobyProperties.find(
                                      d =>
                                        d.property === p.label && d.property !== property.property,
                                    ),
                                )
                              : [{ id: -1, label: property.property }, ...allProperties]
                                  .filter(
                                    p =>
                                      !displayGeomobyProperties.find(d => d.property === p.label),
                                  )
                                  .sort((a, b) => a.label.localeCompare(b.label))
                            ).map(propName => (
                              <MenuItem
                                key={propName.id ?? propName.label}
                                value={propName.label}
                                disabled={!allProperties.find(p => p.label === propName.label)}
                              >
                                <Tooltip title={propName.label}>
                                  <Typography
                                    style={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      width: 'calc(100% - 50px)',
                                    }}
                                  >
                                    {propName.label}
                                  </Typography>
                                </Tooltip>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>

                      <TableCell
                        key={`${property.index}-number-value-cell`}
                        style={{
                          padding: '0px 5px 0px 5px',
                          width: '40%',
                          fontSize: '15px',
                        }}
                      >
                        <FormControl
                          style={{
                            width: '100%',
                          }}
                        >
                          {/* Number Value */}
                          {allProperties.find(p => p.label === property.property)?.type ===
                            PropertyType.Number.toLowerCase() && (
                            <TextField
                              id={`${property.index}-number-value-cell`}
                              label=""
                              key={`${property.index}-number-value-cell`}
                              type={'number'}
                              name={`${property.index}-number-value-cell`}
                              sx={{
                                marginTop: '-11px',
                                width: '100%',
                                height: '41px',
                                '& input': {
                                  height: '25px',
                                },
                              }}
                              value={property.value}
                              onChange={(e: { target: { value: string } }) => {
                                onChangeValue(e.target.value, property);
                              }}
                              onBlur={(e: { target: { value: string } }) => {
                                onBlurValue(e.target.value, property);
                              }}
                              onKeyPress={(event: { key: string }) => {
                                onKeyPress(event);
                              }}
                              placeholder=""
                            />
                          )}

                          {/* String Value */}
                          {allProperties.find(p => p.label === property.property)?.type ===
                            PropertyType.String.toLowerCase() && (
                            <TextField
                              id={`${property.index}-string-value-cell`}
                              label=""
                              key={`${property.index}-string-value-cell`}
                              type={'string'}
                              name={`${property.index}-string-value-cell`}
                              sx={{
                                marginTop: '6px',
                                width: '100%',
                                height: '58px',
                                '& input': {
                                  height: '25px',
                                },
                              }}
                              value={property.value}
                              onChange={(e: { target: { value: string } }) => {
                                onChangeValue(e.target.value, property);
                              }}
                              onBlur={(e: { target: { value: string } }) => {
                                onBlurValue(e.target.value, property);
                              }}
                              onKeyPress={(event: { key: string }) => {
                                onKeyPress(event);
                              }}
                              placeholder=""
                            />
                          )}

                          {/* List Value */}
                          {allProperties.find(p => p.label === property.property)?.type ===
                            PropertyType.List.toLowerCase() && (
                            <Select
                              fullWidth
                              id={`${property.index}-string-list-cell`}
                              key={`${property.index}-string-list-cell`}
                              name={`${property.index}-string-list-cell`}
                              sx={{
                                marginTop: '6px',
                                width: '100%',
                                height: '58px',
                                '& input': {
                                  height: '25px',
                                },
                              }}
                              value={
                                property.value ??
                                allProperties.find(p => p.label === property.property)
                                  ?.items?.[0] ??
                                ''
                              }
                              onChange={async (e: { target: { value: string } }) => {
                                onBlurValue(e.target.value, property);
                              }}
                            >
                              {allProperties
                                .find(p => p.label === property.property)
                                ?.items?.map(item => (
                                  <MenuItem key={item} value={item}>
                                    <Tooltip title={item}>
                                      <Typography
                                        style={{
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          width: 'calc(100% - 50px)',
                                        }}
                                      >
                                        {item}
                                      </Typography>
                                    </Tooltip>
                                  </MenuItem>
                                ))}
                            </Select>
                          )}
                        </FormControl>
                      </TableCell>

                      {/* Delete */}
                      <TableCell
                        key={`${property.index}-delete`}
                        style={{
                          padding: '0px 5px 0px 0px',
                          width: '10%',
                          fontSize: '15px',
                        }}
                      >
                        <IconButton
                          color="primary"
                          onClick={async () => {
                            const updatedProperties: Record<string, string> = {};
                            const updatedDisplayGeomobyProperties = displayGeomobyProperties.filter(
                              displayed => displayed.index !== property.index,
                            );
                            updatedDisplayGeomobyProperties.map(prop => {
                              updatedProperties[prop.property] = prop.value;
                            });
                            await updateGeomobyProperties(olmap, updatedProperties);
                            setDisplayGeomobyProperties(updatedDisplayGeomobyProperties);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        {displayGeomobyProperties.find(
          prop => !allProperties.find(p => p.label === prop.property) && !!prop.property,
        ) && (
          <Typography
            style={{
              marginTop: '10px',
              color: 'red',
            }}
          >
            {'Properties outlined red are INVALID'}
          </Typography>
        )}

        <Button
          variant="outlined"
          style={{
            marginTop: '10px',
            width: '100%',
          }}
          disabled={
            selectedGeofence?.layerId === UNKNOWN_LAYER ||
            selectedGeofence?.previousLayer === UNKNOWN_LAYER
          }
          onClick={() => {
            if (displayGeomobyProperties.find(p => p.property === '')) return;
            setDisplayGeomobyProperties(
              onChangeFields({
                index: displayGeomobyProperties.length,
                property: '',
                value: '',
              }),
            );
          }}
        >
          Add
        </Button>
      </Grid>
    </>
  );
};
