import { Asset, SelectedAsset } from '../../../types';
import { SearchListProps } from '../../Props';
import { useCallback, useMemo } from 'react';
import { SidebarAssetList } from '../SidebarAssetList';

export const TrackerList = ({
  activeAssets,
  availableGpsTrackers,
  onChosen,
}: {
  activeAssets: SelectedAsset[];
  availableGpsTrackers: Asset[];
  onChosen: (tracker: Asset, activeTracker: SelectedAsset | undefined) => void;
}) => {
  const isActive = useCallback(
    (tracker: Asset) =>
      !!activeAssets.find(
        active => active.id?.gpsTrackerId !== undefined && active.id?.gpsTrackerId === tracker.id,
      ),
    [activeAssets],
  );
  const trackerLabel = useCallback((tracker: Asset) => tracker.label, []);
  const trackerId = useCallback((tracker: Asset) => tracker.id, []);
  const onClick = (tracker: Asset) => {
    const activeTracker = activeAssets.find(
      active => active.id?.gpsTrackerId !== undefined && active.id?.gpsTrackerId === tracker.id,
    );
    onChosen(tracker, activeTracker);
  };

  return (
    <SidebarAssetList
      assets={availableGpsTrackers}
      assetIsActive={isActive}
      assetLabel={trackerLabel}
      assetId={trackerId}
      onClick={onClick}
    />
  );
};
