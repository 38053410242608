import { GridRowData } from '@material-ui/data-grid';
import { Delete, Edit, ChevronLeft, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import axios from 'axios';
import { useAtomValue } from 'jotai';
import { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useState } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../../../../store/auth';
import { AUTHN_URL } from '../../../../../store/url';
import { CID, PID } from '../../../../../store/user';
import { BACKGROUND_OFFSET, lineLimitStyle, PRIMARY } from '../../../../../Style/GeoMobyBaseTheme';
import { UNKNOWN_LAYER } from '../../../../../util/constants';
import { GeomobyPropertiesValues } from '../../../../../util/enums';
import InputContainer from '../../../../Global/InputContainer';
import { Device, GeomobyOverride } from '../../../types';
import { Map as olMap } from 'ol';
import { Property } from '../../../../ProjectConfig/types';
import { PropertyType } from '../../../../ProjectConfig/enums';

export const OverridesTable = ({ rows }: { rows: GeomobyOverride[] }) => {
  const [selectedOverride, setSelectedOverride] = useState<GeomobyOverride | undefined>();
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontSize: '15px' }}>Property</TableCell>
            <TableCell style={{ fontSize: '15px' }} align="left">
              Value
            </TableCell>
            <TableCell style={{ fontSize: '15px' }} align="right">
              Devices
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 && (
            <TableRow>
              <TableCell>No properties found</TableCell>
            </TableRow>
          )}

          {[...new Map(rows.map(row => [row['property'] && row['value'], row])).values()]
            .sort((a, b) => a.property.localeCompare(b.property))
            .map((row, index) => (
              <Fragment key={`${row.property}-${row.value}-${index}`}>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                  <Tooltip title={row.property}>
                    <TableCell>{row.property}</TableCell>
                  </Tooltip>
                  <Tooltip title={row.value}>
                    <TableCell
                      align="left"
                      style={{ ...lineLimitStyle, overflowWrap: 'anywhere', borderBottom: 'unset' }}
                    >
                      {row.value}
                    </TableCell>
                  </Tooltip>
                  <TableCell align="right" style={{ borderBottom: 'unset' }}>
                    {row.deviceIds.length}
                    <>
                      <IconButton
                        style={{
                          height: '27px',
                          width: '27px',
                        }}
                        onClick={() => {
                          setSelectedOverride(
                            row.property === selectedOverride?.property &&
                              row.value === selectedOverride?.value
                              ? undefined
                              : row,
                          );
                        }}
                        onBlur={() => {
                          if (
                            row.property === selectedOverride?.property &&
                            row.value === selectedOverride?.value
                          )
                            setSelectedOverride(undefined);
                        }}
                      >
                        {row.property === selectedOverride?.property &&
                        row.value === selectedOverride?.value ? (
                          <ExpandMore />
                        ) : (
                          <ChevronLeft />
                        )}
                      </IconButton>
                    </>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse
                      in={
                        row.property === selectedOverride?.property &&
                        row.value === selectedOverride?.value
                      }
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box>
                        <Table size="small" aria-label="devices">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ paddingLeft: '10px' }}>Device</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.deviceIds
                              .sort((a, b) => a.localeCompare(b))
                              .map(device => (
                                <TableRow key={`${row.property}-${row.value}-${device}`}>
                                  <TableCell component="th" scope="row">
                                    {device}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const GeomobyOverrides = ({
  olmap,
  selectedGeofence,
  displayGeomobyOverrides,
  setDisplayGeomobyOverrides,
  allProperties,
  saveAllChanges,
  updateGeomobyOverrides,
}: {
  olmap: olMap;
  selectedGeofence: GridRowData | undefined;
  displayGeomobyOverrides: (GeomobyOverride & { index: number })[];
  setDisplayGeomobyOverrides: Dispatch<SetStateAction<(GeomobyOverride & { index: number })[]>>;
  allProperties: Property[];
  saveAllChanges: () => Promise<void>;
  updateGeomobyOverrides: (
    olmap: olMap,
    geomobyOverrides: GeomobyOverride[],
  ) => Promise<GeomobyOverride[] | undefined>;
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const theme = useTheme();
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const httpBaseUrl = useAtomValue(AUTHN_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);

  const [availableDevices, setAvailableDevices] = useState<{ id: string; label: string }[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);

  const getDevices = useCallback(async () => {
    const { devices, count } = (
      await axios.get<{ devices: Device[]; count: number }>(
        `${httpBaseUrl}/open/device/paginate/${cid}/${pid}/1?perPage=500`,
        authedConfig,
      )
    ).data;

    setAvailableDevices(
      devices.map(device => {
        return {
          id: device.label,
          label: device.label,
        };
      }),
    );
  }, [authedConfig, httpBaseUrl, cid, pid]);

  useEffect(() => {
    getDevices();
    return () => {
      setAvailableDevices([]);
    };
  }, [getDevices]);

  const onChangeFields = (updatedOverride: GeomobyOverride & { index: number }) => {
    return [
      ...displayGeomobyOverrides.filter(displayed => displayed.index !== updatedOverride.index),
      updatedOverride,
    ].sort((a, b) => {
      return String(a.index).localeCompare(String(b.index));
    });
  };

  const onChangeValue = (value: string, override: GeomobyOverride & { index: number }) => {
    const updatedDisplayGeomobyOverrides = onChangeFields({
      ...override,
      value,
    });
    setDisplayGeomobyOverrides(updatedDisplayGeomobyOverrides);
  };

  const onChangeProperty = async (
    property: string,
    override: GeomobyOverride & { index: number },
  ) => {
    let value = override.value;
    const foundProperty = allProperties.find(p => p.label === property);
    if (foundProperty?.type === PropertyType.List.toLowerCase()) {
      value =
        foundProperty.type === PropertyType.List.toLowerCase() && foundProperty.items
          ? foundProperty.items.find(i => i === override.value) ?? foundProperty.items?.[0]
          : override.value ?? '';
    }

    const updatedDisplayGeomobyOverrides = onChangeFields({
      ...override,
      value,
      property,
    });
    await updateGeomobyOverrides(
      olmap,
      updatedDisplayGeomobyOverrides.map(override => {
        return {
          index: override.index,
          property: override.property,
          value: override.value,
          deviceIds: override.deviceIds,
        };
      }),
    );
    setDisplayGeomobyOverrides(updatedDisplayGeomobyOverrides);
  };

  const onBlurValue = async (value: string, override: GeomobyOverride & { index: number }) => {
    const updatedDisplayGeomobyOverrides = onChangeFields({
      ...override,
      value: value,
    });
    setDisplayGeomobyOverrides(updatedDisplayGeomobyOverrides);
    await updateGeomobyOverrides(
      olmap,
      updatedDisplayGeomobyOverrides.map(override => {
        return {
          index: override.index,
          property: override.property,
          value: override.value,
          deviceIds: override.deviceIds,
        };
      }),
    );
  };

  const onKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      saveAllChanges();
    }
  };

  return (
    <>
      <Grid
        container
        style={{
          marginTop: '30px',
        }}
      >
        <Tooltip title={'Override Rules'}>
          <Typography style={{ color: PRIMARY }}>Override Rules</Typography>
        </Tooltip>

        <TableContainer
          component={Paper}
          style={{
            maxHeight: '500px',
          }}
        >
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow
                key={'header-row'}
                style={{
                  height: '55px',
                }}
              >
                <TableCell
                  key={'Property'}
                  style={{ fontSize: '15px', backgroundColor: BACKGROUND_OFFSET }}
                  align="left"
                >
                  Property
                </TableCell>
                <TableCell
                  key={'Value'}
                  style={{ fontSize: '15px', backgroundColor: BACKGROUND_OFFSET }}
                  align="left"
                >
                  Value
                </TableCell>
                <TableCell
                  key={''}
                  style={{ fontSize: '15px', backgroundColor: BACKGROUND_OFFSET }}
                  align="left"
                ></TableCell>
              </TableRow>
            </TableHead>
            {displayGeomobyOverrides
              .sort((a, b) => {
                return String(a.index).localeCompare(String(b.index));
              })
              .map(override => {
                return (
                  <TableBody key={`${override.index}-body`}>
                    <TableRow
                      key={`${override.index}-row`}
                      sx={{
                        height: '55px',
                        fontSize: '15px',
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      {/* Property */}
                      <TableCell
                        key={`${override.index}-cell`}
                        colSpan={1}
                        style={{
                          padding: '0px 0px 0px 5px',
                          width: '40%',
                          maxWidth: '190px',
                          fontSize: '15px',
                        }}
                      >
                        <FormControl
                          key={`${override.index}-form`}
                          sx={{
                            width: '100%',
                            marginTop: '8px',
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor:
                                !allProperties.find(p => p.label === override.property) &&
                                override.property
                                  ? 'red !important'
                                  : 'inherit',
                            },
                          }}
                        >
                          <Select
                            fullWidth
                            key={`${override.index}-list-value-cell`}
                            id={'prop-property-' + override.index}
                            name={'prop' + override.index}
                            value={override.property}
                            style={{
                              width: '100%',
                            }}
                            onChange={async (e: { target: { value: string } }) => {
                              onChangeProperty(e.target.value, override);
                            }}
                          >
                            {(allProperties.find(p => p.label === override.property)
                              ? allProperties
                              : [{ id: -1, label: override.property }, ...allProperties].sort(
                                  (a, b) => a.label.localeCompare(b.label),
                                )
                            ).map(propName => (
                              <MenuItem
                                key={propName.id ?? propName.label}
                                value={propName.label}
                                disabled={!allProperties.find(p => p.label === propName.label)}
                              >
                                <Tooltip title={propName.label}>
                                  <Typography
                                    style={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      width: 'calc(100% - 50px)',
                                    }}
                                  >
                                    {propName.label}
                                  </Typography>
                                </Tooltip>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>

                      <TableCell
                        key={`${override.index}-number-value-cell`}
                        colSpan={1}
                        style={{
                          padding: '0px 5px 0px 5px',
                          width: '40%',
                          fontSize: '15px',
                        }}
                      >
                        <FormControl
                          style={{
                            width: '100%',
                          }}
                        >
                          {/* Number Value */}
                          {allProperties.find(p => p.label === override.property)?.type ===
                            PropertyType.Number.toLowerCase() && (
                            <TextField
                              id={`${override.index}-number-value-cell`}
                              label=""
                              key={`${override.index}-number-value-cell`}
                              type={'number'}
                              name={`${override.index}-number-value-cell`}
                              sx={{
                                marginTop: '-11px',
                                width: '100%',
                                height: '41px',
                                '& input': {
                                  height: '25px',
                                },
                              }}
                              value={override.value}
                              onChange={(e: { target: { value: string } }) => {
                                onChangeValue(e.target.value, override);
                              }}
                              onBlur={(e: { target: { value: string } }) => {
                                onBlurValue(e.target.value, override);
                              }}
                              onKeyPress={(event: { key: string }) => {
                                onKeyPress(event);
                              }}
                              placeholder=""
                            />
                          )}

                          {/* String Value */}
                          {allProperties.find(p => p.label === override.property)?.type ===
                            PropertyType.String.toLowerCase() && (
                            <TextField
                              id={`${override.index}-string-value-cell`}
                              label=""
                              key={`${override.index}-string-value-cell`}
                              type={'string'}
                              name={`${override.index}-string-value-cell`}
                              sx={{
                                marginTop: '6px',
                                width: '100%',
                                height: '58px',
                                '& input': {
                                  height: '25px',
                                },
                              }}
                              value={override.value}
                              onChange={(e: { target: { value: string } }) => {
                                onChangeValue(e.target.value, override);
                              }}
                              onBlur={(e: { target: { value: string } }) => {
                                onBlurValue(e.target.value, override);
                              }}
                              onKeyPress={(event: { key: string }) => {
                                onKeyPress(event);
                              }}
                              placeholder=""
                            />
                          )}

                          {/* List Value */}
                          {allProperties.find(p => p.label === override.property)?.type ===
                            PropertyType.List.toLowerCase() && (
                            <Select
                              fullWidth
                              id={`${override.index}-string-list-cell`}
                              key={`${override.index}-string-list-cell`}
                              name={`${override.index}-string-list-cell`}
                              sx={{
                                marginTop: '6px',
                                width: '100%',
                                height: '58px',
                                '& input': {
                                  height: '25px',
                                },
                              }}
                              value={
                                override.value ??
                                allProperties.find(p => p.label === override.property)
                                  ?.items?.[0] ??
                                ''
                              }
                              onChange={async (e: { target: { value: string } }) => {
                                onBlurValue(e.target.value, override);
                              }}
                            >
                              {allProperties
                                .find(p => p.label === override.property)
                                ?.items?.map(item => (
                                  <MenuItem key={item} value={item}>
                                    <Tooltip title={item}>
                                      <Typography
                                        style={{
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          width: 'calc(100% - 50px)',
                                        }}
                                      >
                                        {item}
                                      </Typography>
                                    </Tooltip>
                                  </MenuItem>
                                ))}
                            </Select>
                          )}
                        </FormControl>
                      </TableCell>

                      {/* Delete */}
                      <TableCell
                        key={`${override.index}-delete`}
                        colSpan={1}
                        style={{
                          padding: '0px 5px 0px 0px',
                          width: '10%',
                          fontSize: '15px',
                        }}
                      >
                        <IconButton
                          color="primary"
                          onClick={async () => {
                            const updatedDisplayGeomobyOverrides = displayGeomobyOverrides.filter(
                              displayed => displayed.index !== override.index,
                            );
                            await updateGeomobyOverrides(
                              olmap,
                              updatedDisplayGeomobyOverrides.map(override => {
                                return {
                                  index: override.index,
                                  property: override.property,
                                  value: override.value,
                                  deviceIds: override.deviceIds,
                                };
                              }),
                            );
                            setDisplayGeomobyOverrides(updatedDisplayGeomobyOverrides);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>

                    {/* Device IDs */}
                    <TableRow
                      key={`${override.index}-devices-row`}
                      sx={{
                        width: '100%',
                        height: '55px',
                        fontSize: '15px',
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell
                        colSpan={3}
                        key={`${override.index}-devices-cell`}
                        style={{
                          padding: '20px 0px 20px 0px',
                          width: '100%',
                          fontSize: '15px',
                        }}
                      >
                        <FormControl
                          style={{
                            width: '100%',
                          }}
                        >
                          <InputLabel id="select-devices-label">Devices</InputLabel>
                          <Select
                            labelId="select-devices-label"
                            id="select-devices"
                            multiple
                            value={override.deviceIds}
                            onChange={async (event: SelectChangeEvent<typeof selectedDevices>) => {
                              const {
                                target: { value },
                              } = event;
                              override.deviceIds =
                                typeof value === 'string' ? value.split(',') : value;

                              const updatedDisplayGeomobyOverrides = [
                                ...displayGeomobyOverrides.filter(
                                  displayed => displayed.index !== override.index,
                                ),
                                {
                                  ...override,
                                  deviceIds: Array.from(event.target.value),
                                },
                              ].sort((a, b) => {
                                return String(a.index).localeCompare(String(b.index));
                              });
                              await updateGeomobyOverrides(
                                olmap,
                                updatedDisplayGeomobyOverrides.map(o => {
                                  return {
                                    index: o.index,
                                    property: o.property,
                                    value: o.value,
                                    deviceIds: o.deviceIds,
                                  };
                                }),
                              );
                              setDisplayGeomobyOverrides(updatedDisplayGeomobyOverrides);
                            }}
                            input={<OutlinedInput id="select-device" label="Device" />}
                            style={{
                              width: '100%',
                            }}
                            renderValue={selected => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {override.deviceIds.map((id, index) => (
                                  <Chip key={`${index}-${id}`} label={id} />
                                ))}
                              </Box>
                            )}
                            MenuProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                width: 250,
                              },
                            }}
                          >
                            {availableDevices?.map((device, index) => (
                              <MenuItem
                                key={`${override.index}-${device.id}-${index}`}
                                value={device.id}
                                style={{
                                  fontWeight: override.deviceIds.find(id => id === device.id)
                                    ? theme.typography.fontWeightMedium
                                    : theme.typography.fontWeightRegular,
                                }}
                              >
                                <Typography
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: 'calc(100% - 50px)',
                                  }}
                                >
                                  {device.label}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                );
              })}
          </Table>
        </TableContainer>

        <Button
          variant="outlined"
          style={{
            width: '100%',
          }}
          disabled={
            selectedGeofence?.layerId === UNKNOWN_LAYER ||
            selectedGeofence?.previousLayer === UNKNOWN_LAYER
          }
          onClick={() => {
            if (displayGeomobyOverrides.find(p => p.property === '')) return;
            setDisplayGeomobyOverrides(
              onChangeFields({
                index: displayGeomobyOverrides.length,
                property: '',
                value: '',
                deviceIds: [],
              }),
            );
          }}
        >
          Add
        </Button>
      </Grid>
    </>
  );
};
