export enum AdminCategories {
  CreateNewProject = 'Create New Project',
  CreateAppInvitation = 'Create App Invitation',
  SendEmailInvitation = 'Send Email Invitation',
  ActiveEmailInvitations = 'Active Email Invitations',
  Users = 'Users',
}

export enum InviteColumns {
  ID = 'ID',
  Email = 'Email',
  Accesss = 'Access',
  CreatedAt = 'Created At',
}

export enum UserColumns {
  ID = 'ID',
  Label = 'Label',
  Accesss = 'Access',
  CreatedAt = 'Created At',
}
