import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { Invite } from '../Components/SuperAdmin/types';
import { AUTHED_REQUEST_CONFIG } from '../store/auth';
import { AUTHN_URL } from '../store/url';
import { useAsyncTask } from '../util/AsyncTask';
import { AccessItem } from './useInviteUser';

export const useAllInvites = () => {
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const httpBaseUrl = useAtomValue(AUTHN_URL);

  const login = useCallback(
    async () => (await axios.get<Invite[]>(`${httpBaseUrl}/open/email-invite`, authedConfig)).data,
    [httpBaseUrl, authedConfig],
  );

  return useAsyncTask<Invite[], typeof login>(login);
};
