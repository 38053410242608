import { MapSourceType } from '../Components/Map/types';

export const M_TO_FT = 3.28084;
export const M_TO_KM = 0.001;
export const M_TO_MI = 0.000621371;

export const initialLatitude = -31.953512;
export const initialLongitude = 115.857048;
export const initialExtentInDegrees = 0.512;
export const initialZoomHeight = 11;

export const ACTIVE_MICROFENCE = 'ACTIVE_MICROFENCE';
export const ALL = 'ALL';
export const ALL_LAYERS = 'all-layers';
export const ALL_GEOFENCES = 'All Geofences';
export const ALL_GEOFENCE_TYPES = 'All Geofence Types';
export const ALL_GEOFENCE_ZONES = 'All Geofence Zones';
export const ALL_GROUPS = 'All Groups';
export const ASSET_LABEL = 'ASSET_LABEL';
export const ASSET_ID = 'ASSET_ID';
export const CLUSTER_MAX_ZOOM = 13;
export const CLUSTER_DISTANCE = 200;
export const CLUSTER_FONT_FAMILY = '"Open Sans","Montserrat","Arial",sans-serif,bold;';
export const DEFAULT_BUFFER_METERS = 250;
export const EBT_DISPLAY_LIMIT = 5;
export const FENCE_VIEWING_HEIGHT = 12;
export const FRESH = 'fresh';
export const FRESH_LAYER = 'fresh-layer';
export const GOOGLE_FLAG_FOR_MAP_TYPE: Record<MapSourceType, string> = {
  'Road map': 'm',
  Satellite: 's',
  'Satellite & Roads': 'y',
  'Terrain & Roads': 'p',
  'Road without Building': 'r',
  'Light map': 'lm',
  Nearmap: 's',
};
export const LAST_SENSED_ISO8601 = 'LAST_SENSED_ISO8601';
export const LOCALISED_MAX_DISTANCE_M = 15;
export const LOCALISED_SUFFIX = '[localised]';
export const LOCAL_BEACONS = 'LOCAL_BEACONS';
export const LOCATION_HISTORY = 'LOCATION_HISTORY';
export const MAP_SOURCE_TYPES = [
  'Road map',
  'Satellite',
  'Satellite & Roads',
  'Terrain & Roads',
  'Road without Building',
  'Light map',
  'Nearmap',
] as const;
export const MICROFENCE_LAYER_ID = '__microfences__';
export const MICROFENCE_LAYER_LABEL = 'MicroFences';
export const MICROFENCE_PROP_LABELS = {
  boundaryRssi: 'Boundary RSSI',
  timeoutSeconds: 'Timeout (seconds)',
};
export const MICROFENCE_DEFAULT_PROPS = {
  [MICROFENCE_PROP_LABELS.boundaryRssi]: -90,
  [MICROFENCE_PROP_LABELS.timeoutSeconds]: 15,
};
export const PIN_ELEMENT_SCALE = 0.07;
export const POINT = '__point__';
export const SENSED_ASSETS = 'SENSED_ASSETS';
export const SENSED_EXITED_ASSETS_IDS = 'SENSED_EXITED_ASSETS';
export const TRACKED_DEVICE = 'Tracked device';
export const TRACKING_BOUNDS = 'tracking_bounds';
export const UNKNOWN = 'Unknown';
export const UNKNOWN_LAYER = 'unknown-layer';
export const ZOOM_THRESHOLD = 21.5;
