import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { AssetState, CMContainer } from '../../Components/Map/Messages';
import { NEW_REPLAY_MESSAGES, REPLAY_LATEST_EVENTS, REPLAY_NOW } from '../../store/replayEvents';
import { isDefined } from '../../util/isDefined';
import { LiveMapUpdate, updateFullState } from './LiveMapActions';
import {
  LiveMapState,
  LiveStreamData,
  reduceIncomingMessagesToMapState,
  toMapUpdateOrUndefined,
} from './useGeomobyLiveStream';
import { REPLAY_NOTIFICATIONS } from '../../store/notifications';
import { uniqBy } from 'lodash';

const INITIAL_STATE: LiveMapState = {
  assets: [],
  selectedAsset: undefined,
  notifications: [],
};

export function useGeomobyReplayStream(): LiveStreamData {
  const startDate = useMemo(() => new Date(), []);
  const [state, dispatchState] = useReducer(reduceIncomingMessagesToMapState, INITIAL_STATE);
  const [lastUpdates, setLastUpdates] = useState<LiveMapUpdate[] | undefined>();
  const [queue, setQueue] = useAtom(NEW_REPLAY_MESSAGES);

  const lastEvents = useAtomValue(REPLAY_LATEST_EVENTS);
  const setNotifications = useSetAtom(REPLAY_NOTIFICATIONS);
  const now = useAtomValue(REPLAY_NOW);
  const lastNow = useRef(now);
  const lastNegative = useRef(Symbol());
  lastNegative.current = now < lastNow.current ? Symbol() : lastNegative.current;
  const lastNegativeCurrent = lastNegative.current;
  lastNow.current = now;

  useEffect(() => {
    const messages = lastEvents.flatMap(e => toMapUpdateOrUndefined(e)).filter(isDefined);
    const actualInitialState = messages.reduce<LiveMapState>(
      (liveMapState, liveMapUpdate) =>
        reduceIncomingMessagesToMapState(liveMapState, liveMapUpdate),
      INITIAL_STATE,
    );
    const updateEvent = updateFullState(actualInitialState);
    dispatchState(updateEvent);
    setLastUpdates([updateEvent]);
  }, [lastEvents, lastNegativeCurrent]);

  useEffect(() => {
    let messages: CMContainer[] = [];
    if (queue.length > 0) {
      setQueue(queue => {
        messages = queue;
        return [];
      });
    }

    const updates = messages
      .flatMap(message => toMapUpdateOrUndefined(message))
      .filter((update): update is LiveMapUpdate => !!update);

    updates.forEach(update => {
      dispatchState(update);
    });
    setLastUpdates(updates);
  }, [queue, setQueue]);

  useEffect(() => {
    setNotifications(notifications =>
      uniqBy([...notifications, ...state.notifications], 'notificationId'),
    );
  }, [state.notifications, setNotifications]);

  return {
    state,
    lastUpdates,
    startDate,
  };
}
