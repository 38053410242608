import { NearMe } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { StrongFeatureHolder } from '../../../../../hooks/geomoby/useLiveMapLoader';
import {
  EntityType,
  MicrofenceEntity,
  MicrofenceZone,
  SearchTypeIDs,
  SearchTypeValue,
} from '../../../../../util/enums';
import { PropertiesTable } from '../../../Editor/Sidebar/Geofence/GeomobyProperties';
import {
  AssetActivityStatus,
  displayAssetHeading,
  AssetIdOrUnknown,
  getMicroFenceIcon,
} from '../../../Helpers';
import { MicrofenceData } from '../../../Messages';
import { MicrofenceZoneTypes } from '../../../values';
import {
  ACTIVE_MICROFENCE,
  LAST_SENSED_ISO8601,
  MICROFENCE_LAYER_LABEL,
} from '../../../../../util/constants';
import { SearchListProps } from '../../Props';
import { Point } from 'ol/geom';
import { PRIMARY, WHITE } from '../../../../../Style/GeoMobyBaseTheme';

export const SelectedMicrofence = (
  props: SearchListProps & { availableMicrofences: StrongFeatureHolder<Point, MicrofenceData>[] },
) => {
  return (
    <>
      {props.selectedMicrofence && (
        <Paper
          variant="outlined"
          style={{
            padding: '10px',
            height: 'fit-content',
          }}
        >
          <Box color="primary.main" style={{ marginTop: '10px' }}>
            {getMicroFenceIcon(props.selectedMicrofence.type)}
          </Box>

          <Grid
            container
            direction="row"
            style={{
              width: '100%',
              marginBottom: '10px',
              marginTop: '-37px',
              justifyContent: 'flex-end',
            }}
          >
            <Tooltip title={'NAVIGATE TO'}>
              <IconButton
                color="primary"
                onClick={() => {
                  props.setNavigateTo(props.selectedMicrofence?.id);
                }}
              >
                <NearMe />
              </IconButton>
            </Tooltip>
          </Grid>

          {displayAssetHeading(
            props.selectedMicrofence.name,
            props.selectedMicrofence.name,
            EntityType.Microfence,
          )}
          <Grid
            container
            direction="column"
            style={{
              marginBottom: '10px',
              width: 'fit-content',
            }}
          >
            <Button
              style={{
                marginLeft: '-7px',
                color: WHITE,
              }}
              onClick={() => {
                props.setDeselectFence(true);
                props.setSearchType({
                  id: SearchTypeIDs.Microfences,
                  value: SearchTypeValue.Microfences,
                });
                props.setSelectedMicrofence(undefined);
                props.setSelectedAsset(undefined);
                props.setRefreshSearch(true);
              }}
            >
              {MICROFENCE_LAYER_LABEL}
            </Button>
          </Grid>

          <Grid container direction={'row'} justifyContent={'center'}>
            {props.selectedMicrofence.type === MicrofenceEntity.Beacon && (
              <>
                <Grid container direction={'row'}>
                  <Tooltip title={'UUID'}>
                    <Typography
                      style={{
                        color: PRIMARY,
                        paddingRight: '5px',
                        width: 'fit-content',
                      }}
                    >
                      {`UUID: `}
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title={
                      (
                        props.selectedMicrofence.assetId as {
                          uuid: string;
                          major: string;
                          minor: string;
                        }
                      ).uuid
                    }
                  >
                    <Typography>
                      {
                        (
                          props.selectedMicrofence.assetId as {
                            uuid: string;
                            major: string;
                            minor: string;
                          }
                        ).uuid
                      }
                    </Typography>
                  </Tooltip>
                </Grid>
              </>
            )}
            {(props.selectedMicrofence.type === MicrofenceEntity.Gateway ||
              props.selectedMicrofence.type === MicrofenceEntity.Smartplug) && (
              <>
                {AssetIdOrUnknown(
                  (props.selectedMicrofence.assetId as { gatewayId: string }).gatewayId ||
                    (props.selectedMicrofence.assetId as { smartplugId: string }).smartplugId,
                  EntityType.Gateway,
                )}
              </>
            )}
            {props.selectedMicrofence.type === MicrofenceEntity.Device && (
              <>
                {AssetIdOrUnknown(
                  (props.selectedMicrofence.assetId as { deviceId: string }).deviceId,
                  EntityType.Device,
                )}
              </>
            )}

            {props.selectedMicrofence.type === MicrofenceEntity.Beacon && (
              <Grid item container direction={'row'} xs={12} fontSize="90%">
                <Tooltip title={'Major'}>
                  <Typography style={{ color: PRIMARY, paddingRight: '5px' }}>Major:</Typography>
                </Tooltip>
                <Tooltip
                  title={
                    (
                      props.selectedMicrofence.assetId as {
                        uuid: string;
                        major: string;
                        minor: string;
                      }
                    ).major
                  }
                >
                  <Typography>
                    {
                      (
                        props.selectedMicrofence.assetId as {
                          uuid: string;
                          major: string;
                          minor: string;
                        }
                      ).major
                    }
                  </Typography>
                </Tooltip>
              </Grid>
            )}

            {props.selectedMicrofence.type === MicrofenceEntity.Beacon && (
              <Grid item container direction={'row'} xs={12} fontSize="90%">
                <Tooltip title={'Minor'}>
                  <Typography style={{ color: PRIMARY, paddingRight: '5px' }}>Minor:</Typography>
                </Tooltip>
                <Tooltip
                  title={
                    (
                      props.selectedMicrofence.assetId as {
                        uuid: string;
                        major: string;
                        minor: string;
                      }
                    ).minor
                  }
                >
                  <Typography>
                    {
                      (
                        props.selectedMicrofence.assetId as {
                          uuid: string;
                          major: string;
                          minor: string;
                        }
                      ).minor
                    }
                  </Typography>
                </Tooltip>
              </Grid>
            )}
          </Grid>

          <Grid item container direction={'row'} xs={12} fontSize="90%">
            <Tooltip title={'Zone'}>
              <Typography style={{ color: PRIMARY, paddingRight: '5px' }}>Zone:</Typography>
            </Tooltip>
            <Tooltip
              title={
                MicrofenceZoneTypes.find(
                  z => z.id === (props.selectedMicrofence?.zone as MicrofenceZone),
                )?.value ?? 'No Zone'
              }
            >
              <Typography>
                {MicrofenceZoneTypes.find(
                  z => z.id === (props.selectedMicrofence?.zone as MicrofenceZone),
                )?.value ?? 'No Zone'}
              </Typography>
            </Tooltip>
          </Grid>
          <AssetActivityStatus
            isActive={
              !!props.availableMicrofences
                .find(
                  microfence =>
                    JSON.stringify(microfence.data.id) ===
                    JSON.stringify(props.selectedMicrofence?.id),
                )
                ?.feature.get(ACTIVE_MICROFENCE)
            }
            // TODO: set sinceIso8601
            nowIso8601={props.getNowIso8601()}
          />

          {props.selectedMicrofence.geomobyProperties && (
            <>
              <Tooltip title={'Microfence Properties'}>
                <Typography style={{ color: PRIMARY }}>Microfence Properties</Typography>
              </Tooltip>
              <PropertiesTable
                rows={
                  Array.from(
                    Object.entries(props.selectedMicrofence.geomobyProperties)
                      .sort()
                      .map(([property, value], index) => {
                        return { index, property, value };
                      }),
                  ) as { index: number; property: string; value: string }[]
                }
              ></PropertiesTable>
            </>
          )}
        </Paper>
      )}
    </>
  );
};
