import { EntityType, SearchTypeIDs, SearchTypeValue } from '../../../../../util/enums';
import { Asset } from '../../../types';
import { SearchListProps } from '../../Props';
import { SidebarSelectedAsset } from '../SidebarSelectedAsset';

export const SelectedDevice = (props: SearchListProps & { selectedDevice: Asset }) => {
  return (
    <SidebarSelectedAsset
      asset={props.selectedDevice}
      omitIds={true}
      entityType={EntityType.Device}
      category={SearchTypeIDs.Devices}
      onCategoryClicked={() => {
        props.setSearchType({ id: SearchTypeIDs.Devices, value: SearchTypeValue.Devices });
        props.setSelectedDevice(undefined);
        props.setSelectedAsset(undefined);
        props.setRefreshSearch(true);
      }}
      canNavigate={!!props.userExtent}
      onNavigateClicked={() => props.setNavigateTo(props.selectedDevice.id)}
      isActive={props.selectedAsset?.id?.deviceId === props.selectedDevice.id}
      activeSinceIso8601={props.selectedAsset?.iso8601}
      getNowIso8601={props.getNowIso8601}
    />
  );
};
