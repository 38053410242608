import View from 'ol/View';
import { Coordinate } from 'ol/coordinate';
import { ZOOM_THRESHOLD } from '../../util/constants';
import { Extent } from 'ol/extent';

export const panTo = (view: View, to: Coordinate, done: (d: boolean) => void) =>
  view.animate({
    center: to,
    duration: 300,
  });

export const getCoordinateDifference = (from: number[], to: number[]): number =>
  Math.abs(from[0] - to[0]) + Math.abs(from[1] - to[1]);

export const getExtentDifference = (from: Extent, to: Extent): number =>
  Math.abs(from[0] - to[0]) +
  Math.abs(from[1] - to[1]) +
  Math.abs(from[2] - to[2]) +
  Math.abs(from[3] - to[3]);

export const fitToExtent = (view: View, extent: number[]) =>
  view.fit(extent, {
    padding: [10, 10, 10, 10],
    duration: 100,
    maxZoom: ZOOM_THRESHOLD - 3,
  });
