import {
  FenceZone,
  FenceZoneValue,
  MicrofenceZone,
  MicrofenceZoneValue,
  SearchTypeIDs,
  SearchTypeValue,
} from '../../util/enums';
import {
  SearchType,
  MicrofenceOrderByType,
  GeofenceOrderByType,
  OrderType,
  MicrofenceType,
  MicrofencePropType,
  OperatorType,
  ToolOrderByType,
  FenceZoneType,
  MicrofenceZoneType,
} from './types';

export const AlertStylePropertyValues: string[] = ['error', 'info', 'warn'];

export const GeofenceOrderByTypes: GeofenceOrderByType[] = [
  { id: 'geofenceType', value: 'Geofence Type' },
  { id: 'name', value: 'Name' },
];

export const MicrofencePropTypes: MicrofencePropType[] = [
  { id: 'boundaryRSSI', value: 'Boundary RSSI' },
  { id: 'timeoutSeconds', value: 'Timeout (seconds)' },
];

export const MicrofenceTypes: MicrofenceType[] = [
  { id: 'beacon', value: 'Beacon' },
  { id: 'device', value: 'Device' },
  { id: 'gateway', value: 'Gateway' },
];

export const MicrofenceOrderByTypes: MicrofenceOrderByType[] = [
  { id: 'microfenceType', value: 'Microfence Type' },
  { id: 'name', value: 'Name' },
];

export const OperatorTypes: OperatorType[] = [
  { id: 'EQUAL_TO', value: 'Equals' },
  { id: 'GREATER_THAN', value: 'Greater Than' },
  { id: 'LESS_THAN', value: 'Less Than' },
];

export const OrderTypes: OrderType[] = [
  { id: 'ASC', value: 'Ascending' },
  { id: 'DESC', value: 'Descending' },
];

export const SearchTypes: SearchType[] = [
  { id: SearchTypeIDs.ActiveAssets, value: SearchTypeValue.ActiveAssets },
  { id: SearchTypeIDs.Beacons, value: SearchTypeValue.Beacons },
  { id: SearchTypeIDs.Devices, value: SearchTypeValue.Devices },
  { id: SearchTypeIDs.GPSTrackers, value: SearchTypeValue.GPSTrackers, forEditing: true },
  { id: SearchTypeIDs.Geofences, value: SearchTypeValue.Geofences, forEditing: true },
  { id: SearchTypeIDs.Locations, value: SearchTypeValue.Locations, forEditing: true },
  { id: SearchTypeIDs.Microfences, value: SearchTypeValue.Microfences, forEditing: true },
  { id: SearchTypeIDs.Tools, value: SearchTypeValue.Tools },
];

export const ToolOrderByTypes: ToolOrderByType[] = [
  { id: 'toolType', value: 'Tool Type' },
  { id: 'id', value: 'ID' },
];

export const FenceZoneTypes: FenceZoneType[] = [
  { id: FenceZone.breach, value: FenceZoneValue.Breach },
  { id: FenceZone.buffer, value: FenceZoneValue.Buffer },
  { id: FenceZone.cleared, value: FenceZoneValue.Cleared },
  { id: FenceZone.none, value: FenceZoneValue.NoZone },
  { id: FenceZone.loading, value: FenceZoneValue.Loading },
  { id: FenceZone.dumping, value: FenceZoneValue.Dumping },
  { id: FenceZone.maintenance, value: FenceZoneValue.Maintenance },
];

export const MicrofenceZoneTypes: MicrofenceZoneType[] = [
  { id: MicrofenceZone.dumping, value: MicrofenceZoneValue.Dumping },
  { id: MicrofenceZone.loading, value: MicrofenceZoneValue.Loading },
  { id: MicrofenceZone.maintenance, value: MicrofenceZoneValue.Maintenance },
  { id: MicrofenceZone.none, value: MicrofenceZoneValue.NoZone },
];
