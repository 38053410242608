import { Feature, Map as OlMap, View } from 'ol';
import { getCenter } from 'ol/extent';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import { Vector as VectorLayer } from 'ol/layer';
import * as olProj from 'ol/proj';
import RenderFeature from 'ol/render/Feature';
import { Cluster, Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style, Text, Icon, Circle } from 'ol/style';
import { StyleFunction } from 'ol/style/Style';
import {
  CLUSTER_DISTANCE,
  CLUSTER_FONT_FAMILY,
  LOCALISED_MAX_DISTANCE_M,
  MAP_SOURCE_TYPES,
  POINT,
  SENSED_ASSETS,
  TRACKING_BOUNDS,
  UNKNOWN,
} from '../../../util/constants';
import { LayerFenceData, PointData } from '../Messages';
import { transform } from 'ol/proj';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import { Draw, Modify } from 'ol/interaction';
import { FONT_FAMILY_VALUE, WHITE } from '../../../Style/GeoMobyBaseTheme';
import { getExtentDifference } from '../../../hooks/geomoby/MapAnimation';
import { StrongFeatureHolder } from '../../../hooks/geomoby/useLiveMapLoader';
import { ZIndexes } from '../../../util/ZIndexes';
import {
  FenceGeometryType,
  FenceEvent,
  FenceZone,
  MicrofenceEntity,
  GeoJsonType,
  Interactions,
} from '../../../util/enums';
import { geojsonTypeOfEntity, geometryTypeOfEntity, microfenceTypeOfEntity } from '../commons';
import { getMicrofenceStyleFromType } from './MicrofenceStyles';
import { dwellIcon, enteredIcon, exitedIcon, measurementTool } from './MiscStyles';
import { LayerParts } from '../types';

export const defaults = {
  default: {
    stroke: [76, 153, 196],
    fill: [76, 153, 196, 0.3],
    width: 3,
  },
  selected: {
    width: 4.5,
    lindash: [4, 8],
  },
  breach: {
    stroke: [209, 27, 21],
    fill: [209, 27, 21, 0.3],
  },
  buffer: {
    stroke: [235, 138, 12],
    fill: [235, 138, 12, 0.3],
  },
  cleared: {
    stroke: [182, 66, 245],
    fill: [182, 66, 245, 0.3],
  },
  tracking: {
    stroke: [255, 255, 255],
    fill: [255, 255, 255, 0.3],
  },
};
export const textStroke = new Stroke({
  color: 'rgba(0, 0, 0, 0.6)',
  width: defaults.default.width,
});

/* Default Zone */
export const defaultFenceStyle = new Style({
  fill: new Fill({ color: defaults.default.fill }),
  stroke: new Stroke({ color: defaults.default.stroke, width: defaults.default.width }),
  zIndex: ZIndexes.GEOFENCE,
});
export const selectedDefaultFenceStyle = new Style({
  fill: new Fill({ color: defaults.default.fill }),
  stroke: new Stroke({
    color: defaults.default.stroke,
    width: defaults.selected.width,
    lineDash: defaults.selected.lindash,
  }),
  zIndex: ZIndexes.GEOFENCE,
});

/* Breach Zone */
export const breachFenceStyle = new Style({
  fill: new Fill({ color: defaults.breach.fill }),
  stroke: new Stroke({ color: defaults.breach.stroke, width: defaults.default.width }),
  zIndex: ZIndexes.GEOFENCE_FOREGROUND,
});
export const selectedBreachFenceStyle = new Style({
  fill: new Fill({ color: defaults.breach.fill }),
  stroke: new Stroke({
    color: defaults.breach.stroke,
    width: defaults.selected.width,
    lineDash: defaults.selected.lindash,
  }),
  zIndex: ZIndexes.GEOFENCE_FOREGROUND,
});

/* Buffer Zone */
export const bufferFenceStyle = new Style({
  fill: new Fill({ color: defaults.buffer.fill }),
  stroke: new Stroke({ color: defaults.buffer.stroke, width: defaults.default.width }),
  zIndex: ZIndexes.GEOFENCE,
});
export const selectedBufferFenceStyle = new Style({
  fill: new Fill({ color: defaults.buffer.fill }),
  stroke: new Stroke({
    color: defaults.buffer.stroke,
    width: defaults.selected.width,
    lineDash: defaults.selected.lindash,
  }),
  zIndex: ZIndexes.GEOFENCE,
});

/* Cleared Zone */
export const clearedFenceStyle = new Style({
  fill: new Fill({ color: defaults.cleared.fill }),
  stroke: new Stroke({ color: defaults.cleared.stroke, width: defaults.default.width }),
  zIndex: ZIndexes.GEOFENCE,
});
export const selectedClearedFenceStyle = new Style({
  fill: new Fill({ color: defaults.cleared.fill }),
  stroke: new Stroke({
    color: defaults.cleared.stroke,
    width: defaults.selected.width,
    lineDash: defaults.selected.lindash,
  }),
  zIndex: ZIndexes.GEOFENCE,
});
export const clearedFenceDrawStartStyle = new Style({
  fill: new Fill({ color: defaults.cleared.fill }),
  stroke: new Stroke({ color: [0, 0, 0, 0], width: defaults.default.width }),
});

/* Tracking Bounds */
export const trackingFenceStyle = new Style({
  fill: new Fill({ color: defaults.tracking.fill }),
  stroke: new Stroke({ color: defaults.tracking.stroke, width: defaults.default.width }),
  zIndex: ZIndexes.GEOFENCE,
});
export const selectedTrackingFenceStyle = new Style({
  fill: new Fill({ color: defaults.tracking.fill }),
  stroke: new Stroke({
    color: defaults.tracking.stroke,
    width: defaults.selected.width,
    lineDash: defaults.selected.lindash,
  }),
  zIndex: ZIndexes.GEOFENCE,
});
export const trackingFenceDrawStartStyle = new Style({
  fill: new Fill({ color: defaults.tracking.fill }),
  stroke: new Stroke({ color: [0, 0, 0, 0], width: defaults.default.width }),
});

/* Clusters */
const innerCluster = new Circle({
  radius: 10,
  fill: new Fill({
    color: 'rgba(0, 184, 217, 0.7)',
  }),
});
const outerCluster = new Circle({
  radius: 18,
  fill: new Fill({
    color: 'rgba(0, 184, 217, 0.3)',
  }),
});
export const initialiseGeofenceClustersOverlay = (
  fences: StrongFeatureHolder<Point, PointData>[],
): AnimatedCluster => {
  const id = POINT;
  const styleCache = new Map<string, Style[]>();
  const vs = new VectorSource();

  vs.addFeatures(
    fences.map(fence => {
      fence.feature.set('id', fence.data.fenceId);
      fence.feature.set('name', fence.data.name);
      fence.feature.set('layerId', fence.data.layerId);
      fence.feature.set('type', fence.data.type);
      fence.feature.set('fenceType', fence.data.fenceType);
      return fence.feature;
    }),
  );

  const layer = new AnimatedCluster({
    source: new Cluster({
      source: vs,
      distance: CLUSTER_DISTANCE,
    }),
    style: clusterStyle(styleCache),
    properties: { id },
  });
  return layer;
};
export const clusterStyle = (cache: Map<string, Style | Style[]>): StyleFunction => {
  return (cluster: Feature<Geometry> | RenderFeature, p1) => {
    const size = cluster.get('features').length;
    let extentDifference = Number.MAX_SAFE_INTEGER;
    let styles: Style[] = [];
    if (size > 1) {
      const features: Feature<Geometry>[] = cluster.get('features');
      const firstExent = cluster.get('features')[0].getGeometry()?.getExtent();
      const center = getCenter(firstExent);
      styles.push(geofenceMarkerStyle(new Point([center[0], center[1]])));

      for (let f = 1; f < features.length; f++) {
        const currentExtent = cluster.get('features')[f].getGeometry()?.getExtent();
        if (firstExent && currentExtent) {
          extentDifference = getExtentDifference(firstExent, currentExtent);
          if (extentDifference <= 1) {
            const center = getCenter(currentExtent);
            styles.push(geofenceMarkerStyle(new Point([center[0] + f * 20, center[1]])));
          } else {
            styles = [];
            break;
          }
        }
      }
    }

    if (styles.length > 0 && extentDifference <= 1) {
      return styles;
    }

    if (size <= 1) {
      return geofenceMarkerStyle();
    }

    return [
      new Style({
        image: outerCluster,
        zIndex: ZIndexes.MARKER,
      }),
      new Style({
        image: innerCluster,
        zIndex: ZIndexes.MARKER,
        text: new Text({
          text: size.toString(),
          font: CLUSTER_FONT_FAMILY,
          fill: new Fill({
            color: WHITE,
          }),
          stroke: textStroke,
        }),
      }),
    ];
  };
};

/* Layers */
export const updateLayerOverlay = (
  layers: { id: string; name: string; source: StrongFeatureHolder<Polygon, LayerFenceData>[] }[],
): { id: string; name: string; source: VectorLayer<VectorSource<Geometry>> }[] | undefined => {
  const styleCache = new Map<string, Style[]>();
  return layers.map(layer => {
    const source = new VectorSource();
    source.setProperties({ id: layer.id, name: layer.name });

    const vectorLayer = new VectorLayer({
      source,
      style: defaultGeofencesStyle(styleCache),
    });

    layer.source.forEach(f => source.addFeature(f.feature));
    return { id: layer.id, name: layer.name ?? layer.id, source: vectorLayer };
  });
};

/* Geofences */
export const defaultGeofencesStyle = (
  cache: Map<string, Style | Style[]>,
  styleType?: string,
): StyleFunction => {
  return (geofence: Feature<Geometry> | RenderFeature, p1) => {
    const cachePrefix = geofence.get('id')
      ? `${geofence.get('selected') ? 'selected_' : ''}${geofence.get('id')}`
      : '';
    const zoneSuffix = geofence.get('zone');
    const key = `${cachePrefix}${zoneSuffix ? '-' + zoneSuffix : ''}`;
    if (cachePrefix && cache.has(key)) {
      return cache.get(key);
    }
    const style = geofence.get('id')
      ? geofencesStyle(geofence)
      : geofence.get('selected')
      ? selectedDefaultFenceStyle
      : defaultFenceStyle;
    if (style) {
      cache.set(key, style);
    }
    return style;
  };
};

export const getGeofenceStyleFromZone = (props: {
  zone: FenceZone;
  type?: FenceGeometryType;
  layerName?: string;
  selected?: boolean;
}): Style => {
  if (props.zone === FenceZone.breach) {
    return props.selected ? selectedBreachFenceStyle : breachFenceStyle;
  } else if (props.zone === FenceZone.buffer) {
    return props.selected ? selectedBufferFenceStyle : bufferFenceStyle;
  } else if (props.zone === FenceZone.cleared || props.type === FenceGeometryType.Multipolygon) {
    return props.selected ? selectedClearedFenceStyle : clearedFenceStyle;
  } else if (props.layerName === TRACKING_BOUNDS && props.type !== FenceGeometryType.Line) {
    return props.selected ? selectedTrackingFenceStyle : trackingFenceStyle;
  } else {
    return props.selected ? selectedDefaultFenceStyle : defaultFenceStyle;
  }
};
const geofencesStyle = (feature: RenderFeature | Feature<Geometry>): Style | Style[] => {
  const { selected, zone, layerName, geometry, isMeasurementTool } = feature.getProperties();
  const type = geometryTypeOfEntity(feature.getProperties());
  if (type === FenceGeometryType.Microfence) {
    const microfenceType = microfenceTypeOfEntity(feature.getProperties());
    if (feature.getGeometry() instanceof Point && microfenceType) {
      return getMicrofenceStyleFromType(microfenceType, selected);
    }
  }
  const style = getGeofenceStyleFromZone({ zone, type, layerName, selected });
  if (type !== FenceGeometryType.Line) {
    return style;
  }
  return isMeasurementTool ? measurementTool : [style, ...tripwiresStyle(geometry.flatCoordinates)];
};
export const updateGeofencesStyle = (
  cache: Map<string, Style | Style[]>,
  fenceId?: string | null,
): StyleFunction => {
  const cachePrefix = fenceId ? `selected_${fenceId}` : '';
  return (geofence: Feature<Geometry> | RenderFeature, resolution: number) => {
    const zoneSuffix = geofence.get('zone');
    const key = `${cachePrefix}${zoneSuffix ? '-' + zoneSuffix : ''}`;
    if (cachePrefix && cache.has(key)) {
      return cache.get(key);
    }
    const style = geofencesStyle(geofence);
    if (style) {
      cache.set(key, style);
    }
    return style;
  };
};

/* Tripwires */
const tripwireArrowStyle = (point: Point, rotation: number): Style => {
  return new Style({
    geometry: point,
    image: new Icon({
      src: '../../../triangle.svg',
      rotateWithView: true,
      rotation: rotation,
      scale: 1,
    }),
  });
};
export const tripwiresStyle = (coordinates: number[]): Style[] => {
  const coordsA: number[] = transform([coordinates[0], coordinates[1]], 'EPSG:3857', 'EPSG:4326');
  const coordsB: number[] = transform([coordinates[2], coordinates[3]], 'EPSG:3857', 'EPSG:4326');
  const coords = [coordsA, coordsB];
  const x1 = Number(coords[0][0]);
  const x2 = Number(coords[1][0]);
  const y1 = Number(coords[0][1]);
  const y2 = Number(coords[1][1]);
  const dx = x1 - x2;
  const dy = y2 - y1;
  const x1Direction = x1 > x2 ? -Math.abs(x2 - x1) : Math.abs(x2 - x1);
  const x2Direction = x1 > x2 ? Math.abs(x2 - x1) : -Math.abs(x2 - x1);
  const y1Direction = y1 > y2 ? -Math.abs(y2 - y1) : Math.abs(y2 - y1);
  const y2Direction = y1 > y2 ? Math.abs(y2 - y1) : -Math.abs(y2 - y1);
  const rotation = -Math.atan2(dx, dy);

  const point1 = new Point(
    transform([x1 + x1Direction * 0.1, y1 + y1Direction * 0.1], 'EPSG:4326', 'EPSG:3857'),
  );
  const point2 = new Point(
    transform([x1 + x1Direction * 0.3, y1 + y1Direction * 0.3], 'EPSG:4326', 'EPSG:3857'),
  );
  const point3 = new Point(
    transform([x1 + x1Direction * 0.5, y1 + y1Direction * 0.5], 'EPSG:4326', 'EPSG:3857'),
  );
  const point4 = new Point(
    transform([x2 + x2Direction * 0.3, y2 + y2Direction * 0.3], 'EPSG:4326', 'EPSG:3857'),
  );
  const point5 = new Point(
    transform([x2 + x2Direction * 0.1, y2 + y2Direction * 0.1], 'EPSG:4326', 'EPSG:3857'),
  );

  return [
    tripwireArrowStyle(point1, rotation),
    tripwireArrowStyle(point2, rotation),
    tripwireArrowStyle(point4, rotation),
    tripwireArrowStyle(point5, rotation),
  ];
};

/* Geofence Markers */
const geofenceMarkerStyle = (geometry?: Point) => {
  return new Style({
    geometry,
    image: new Icon({
      src: '../../../markerPolygon.png',
    }),
    zIndex: ZIndexes.MARKER,
  });
};

/* Events */
export const defaultGeofenceEventOverlay = (): LayerParts => {
  const wsc = new Map<string, Style>();
  const src = new VectorSource();
  return {
    src: src,
    lyr: new VectorLayer({
      source: src,
      style: geofenceEventStyle(wsc),
      zIndex: ZIndexes.MARKER,
    }),
  };
};
const geofenceEventStyle = (cache: Map<string, Style>): StyleFunction => {
  return (geofence: Feature<Geometry> | RenderFeature, p1) => {
    const key = geofence.get('eventId') ?? UNKNOWN;
    if (cache.has(key)) {
      return cache.get(key);
    }
    const style = new Style({
      image:
        key === FenceEvent.Dwell
          ? dwellIcon
          : key === FenceEvent.Entered
          ? enteredIcon
          : exitedIcon,
    });
    if (style) {
      cache.set(key, style);
    }
    return style;
  };
};

/* Interaction */
export const interactionStyle = (
  interaction: Interactions,
  layerSource: VectorSource<Geometry>,
  type: string,
  color: string,
) => {
  const style = [
    new Style({
      image: new Circle({
        radius: 6.5,
        fill: new Fill({
          color: WHITE,
        }),
      }),
      stroke: new Stroke({
        color,
        width: defaults.selected.width,
      }),
      zIndex: ZIndexes.LINE,
    }),
    new Style({
      image: new Circle({
        radius: 5,
        fill: new Fill({
          color,
        }),
      }),
      stroke: new Stroke({
        color,
        width: defaults.default.width,
      }),
      zIndex: ZIndexes.MARKER,
    }),
  ];

  if (interaction === Interactions.Modify) {
    return new Modify({
      source: layerSource,
      style,
    });
  }

  return new Draw({
    source: layerSource,
    type,
    stopClick: true,
    style,
  });
};
