import axios, { AxiosRequestConfig } from 'axios';
import { Feature } from 'ol';
import { GeoJSONPolygon } from 'ol/format/GeoJSON';
import Geometry from 'ol/geom/Geometry';
import Polygon from 'ol/geom/Polygon';
import { Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { Cluster } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import {
  microfencesToMicrofenceCluster,
  featuresToLayer,
  LayerResult,
} from '../Components/Map/LiveAndReplay/TypeConversions';
import { StrongFeatureHolder } from '../hooks/geomoby/useLiveMapLoader';
import { FenceZone, MicrofenceZone } from '../util/enums';
import { updateLayerOverlay } from '../Components/Map/Styles/GeofenceStyles';
import { GlobalProjectId, LayerFenceData, MicrofenceData } from '../Components/Map/Messages';
import { Bounds, GeomobyOverride, MicrofenceAssetId } from '../Components/Map/types';
import { initialiseMicrofencesOverlay } from '../Components/Map/Styles/MicrofenceStyles';

// FIX ME: The endpoinst within the API folder should ONLY be fecthing and returning data,
// and NOT setting OpenLayers state or React state/props or similar. (should also be
// grouped by backend service, e.g. triggers, and exported from the api.ts file)

// GETTING LAYERS
export const getVectorLayers = async (
  triggersUrl: string,
  requestConfig: AxiosRequestConfig,
  gpid: GlobalProjectId,
  bounds: Bounds,
): Promise<
  { id: string; name: string; source: VectorLayer<VectorSource<Geometry>> }[] | undefined
> => {
  const layers = await axios.get<
    {
      id: string;
      name: string;
      polygons: { id: string; name: string; points: GeoJSONPolygon }[];
      multipolygons: { id: string; name: string; points: GeoJSONPolygon }[];
      lines: { id: string; name: string; points: GeoJSONPolygon }[];
    }[]
  >(
    // Pushed the extentInDegrees to its maximum for now. To speed up the loading.
    `${triggersUrl}/${gpid.cid}/${gpid.pid}/geofences/withinExtent?latitude=${
      bounds.latitude
    }&longitude=${bounds.longitude}&extentInDegrees=${1}&maxPoints=100`,
    requestConfig,
  );
  const lrs: LayerResult[] = layers.data.map(({ id, name, polygons, multipolygons, lines }) =>
    featuresToLayer(id, name, [
      ...polygons,
      ...multipolygons.map(m => {
        return {
          ...m,
          zone: FenceZone.cleared,
        };
      }),
      ...lines,
    ]),
  );
  return updateLayerOverlay(
    lrs.map(({ lid, name, layer }) => {
      return { id: lid, name, source: layer };
    }),
  );
};

export const getVectorLayer = async (
  triggersUrl: string,
  requestConfig: AxiosRequestConfig,
  gpid: GlobalProjectId,
  layerId: string,
  bounds: Bounds,
): Promise<
  { id: string; name: string; source: VectorLayer<VectorSource<Geometry>> } | undefined
> => {
  try {
    const { data: layer } = await axios.get<{
      id: string;
      name: string;
      polygons: { id: string; name: string; points: GeoJSONPolygon }[];
      multipolygons: { id: string; name: string; points: GeoJSONPolygon }[];
      lines: { id: string; name: string; points: GeoJSONPolygon }[];
    }>(
      `${triggersUrl}/${gpid.cid}/${gpid.pid}/geofences/withinExtent/${layerId}?latitude=${bounds.latitude}&longitude=${bounds.longitude}&extentInDegrees=${bounds.extentInDegrees}&maxPoints=100`,
      requestConfig,
    );
    const lr: LayerResult = featuresToLayer(layerId, layer.name, [
      ...layer.polygons,
      ...layer.multipolygons.map(m => {
        return {
          ...m,
          zone: FenceZone.cleared,
        };
      }),
      ...layer.lines,
    ]);

    return updateLayerOverlay([{ id: lr.lid, name: layer.name, source: lr.layer }])?.find(
      lyr => lyr.id === layerId,
    );
  } catch (error) {
    return;
  }
};

export const getMicrofencesVectorLayer = async (
  triggersUrl: string,
  requestConfig: AxiosRequestConfig,
  gpid: GlobalProjectId,
  bounds: Bounds,
): Promise<VectorLayer<VectorSource<Geometry>>> => {
  const { data: microfences } = await axios.get<MicrofenceData[]>(
    `${triggersUrl}/${gpid.cid}/${gpid.pid}/microfences`,
    requestConfig,
  );
  return initialiseMicrofencesOverlay(microfencesToMicrofenceCluster(microfences), new Map(), {
    showActivity: false,
  });
};
