import axios from 'axios';
import addYears from 'date-fns/addYears';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AppInvite } from '../Components/SuperAdmin/types';
import { AUTHED_REQUEST_CONFIG } from '../store/auth';
import { AUTHN_URL } from '../store/url';
import { useAsyncTask } from '../util/AsyncTask';
import { toUnixTs } from '../util/time';
import { AccessItem, ClientProjectAccessItem } from './useInviteUser';

export const useMakeAppInvite = (accessItem: ClientProjectAccessItem, app: string) => {
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const httpBaseUrl = useAtomValue(AUTHN_URL);

  const appInvite = useCallback(
    async () =>
      (
        await axios.post<AppInvite>(
          `${httpBaseUrl}/open/dynamic-link-invite`,
          {
            expiryEpoch: toUnixTs(addYears(new Date(), 10)),
            access: [accessItem],
            app,
          },
          authedConfig,
        )
      ).data,
    [httpBaseUrl, authedConfig, accessItem, app],
  );

  return useAsyncTask<AppInvite, typeof appInvite>(appInvite);
};
