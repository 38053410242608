import { Clear, Delete, FilterList, ViewColumn } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import QuickSearch from '../../../Common/QuickSearch';
import { debounce } from 'lodash';
import { PopperCheckList } from '../../../Common/PopperCheckList';
import { useMobile } from '../../../util/useMobile';
import { PropertiesFilter, PropertiesSort, Property } from '../types';
import { PropertiesColumns, PropertyType } from '../enums';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { SaveResult, SAVE_NOTIFICATION } from '../../../store/notifications';
import { ACCESS_JWT_TOKEN, AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { TRIGGERS_URL } from '../../../store/url';
import { CID, PID } from '../../../store/user';
import InputContainer from '../../Global/InputContainer';
import { jsUcFirst } from '../../Global/StringFormatterFunctions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { applyFilter, PropertiesTable } from './PropertiesTable';
import { ItemsTable } from '../../../Common/ItemsTable';
import { PRIMARY, WHITE } from '../../../Style/GeoMobyBaseTheme';
import { normaliseErrorMessage } from '../../../util/ErrorMessages';
import API from '../../../API/api';

export const Properties = () => {
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const accessJwtToken = useAtomValue(ACCESS_JWT_TOKEN);
  const Authorization = `Bearer ${accessJwtToken}`;
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);
  const isMobile = useMobile();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [availableProperties, setAvailableProperties] = useState<Property[]>([]);
  const [expandAdvancedOptions, setExpandAdvancedOptions] = useState<boolean>(false);
  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
  const [propertiesColumns, setPropertiesColumns] = useState<
    { id: string; value: string; checked: boolean; index: number }[]
  >(
    Object.values(PropertiesColumns).map((column, index) => {
      return {
        id: column,
        value: column,
        checked: true,
        index,
      };
    }),
  );
  const [propertiesFilter, setPropertiesFilter] = useState<PropertiesFilter | undefined>();
  const [propertiesSort, setPropertiesSort] = useState<PropertiesSort | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValidRegex, setIsValidRegex] = useState(true);
  const [resultCount, setResultCount] = useState<number>(0);
  const [selectedProperty, setSelectedProperty] = useState<Property | undefined>();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showManageColumns, setShowManageColumns] = useState(false);
  const [updatedItems, setUpdatedItems] = useState<
    { name: string; checked?: boolean; fresh?: boolean }[]
  >([]);

  const allowManageColumnsBlur = useRef<boolean>(true);
  const debouncedOnSearch = useRef(
    debounce(
      (filter: PropertiesFilter) => {
        setPropertiesFilter(filter);
      },
      100,
      { leading: true },
    ),
  ).current;

  const getProjectProperties = useCallback(async () => {
    setIsLoading(true);
    const properties = await API.triggers.getProjectProperties({
      clientId: cid,
      projectId: pid,
      Authorization,
    });
    setAvailableProperties(properties);
    setIsLoading(false);
  }, [cid, pid, Authorization]);

  const generateMessage = (): string => {
    if (selectedProperty?.message) {
      return selectedProperty?.message;
    } else {
      if (selectedProperty?.type === PropertyType.List) {
        return `${selectedProperty?.label} value must be one of the following values: ${updatedItems
          .map(item => {
            return item.name;
          })
          .join(', ')}.`;
      } else if (selectedProperty?.type === PropertyType.Number) {
        const limitChanged = !!selectedProperty?.limit?.[0] || !!selectedProperty?.limit?.[1];
        return `${selectedProperty?.label} value must be a number${
          limitChanged
            ? ' between ' + selectedProperty?.limit?.[0] + ' and ' + selectedProperty?.limit?.[1]
            : ''
        }.`;
      } else {
        return `${selectedProperty?.label} value must be a string with a length size between ${
          selectedProperty?.limit?.[0] ?? 1
        } and ${selectedProperty?.limit?.[1] ?? 256}.`;
      }
    }
  };

  const processProperty = (property: Property) => {
    const limit =
      property.type === PropertyType.Number && (!property?.limit?.[0] || !property?.limit?.[1])
        ? [-Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]
        : property?.limit;
    return {
      ...property,
      type: property.type.toLowerCase(),
      items:
        property.type === PropertyType.List
          ? updatedItems.map(item => {
              return item.name;
            })
          : undefined,
      limit,
      message: property?.message ?? generateMessage(),
    };
  };

  const createProperty = async () => {
    if (!selectedProperty) return;
    try {
      const createdProperty = (
        await axios.post<Property>(
          `${triggersUrl}/${cid}/${pid}/geofences/properties/project`,
          processProperty(selectedProperty),
          authedConfig,
        )
      ).data;

      setAvailableProperties(
        [...availableProperties, createdProperty]
          .map(property => {
            return {
              ...property,
              selected: false,
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label)),
      );
      setSelectedProperty(undefined);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (error) {
      const errorMessage = normaliseErrorMessage(error as AxiosError);
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: errorMessage,
      });
    }
  };

  const updateProperty = async () => {
    if (!selectedProperty) return;
    try {
      const updatedProperty = (
        await axios.patch<Property>(
          `${triggersUrl}/${cid}/${pid}/geofences/properties/project/${selectedProperty.id}`,
          processProperty(selectedProperty),
          authedConfig,
        )
      ).data;

      setAvailableProperties(
        [
          ...availableProperties.filter(property => property.id !== selectedProperty.id),
          updatedProperty,
        ]
          .map(property => {
            return {
              ...property,
              selected: false,
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label)),
      );
      setSelectedProperty(undefined);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (error) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: ((error as AxiosError).response as AxiosResponse).data.message,
      });
    }
  };

  const deleteProperties = async () => {
    try {
      await Promise.all(
        availableProperties
          .filter(property => property.selected)
          ?.map(
            async property =>
              await axios.delete(
                `${triggersUrl}/${cid}/${pid}/geofences/properties/project/${property.id}`,
                authedConfig,
              ),
          ),
      );

      setAvailableProperties(
        availableProperties
          .filter(property => !property.selected)
          .sort((a, b) => a.label.localeCompare(b.label)),
      );
      setSelectedProperty(undefined);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (error) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: ((error as AxiosError).response as AxiosResponse).data.message,
      });
    }
  };

  const validateRegex = (value: string) => {
    // setInput(value);

    try {
      new RegExp(value); // Validate regex
      setIsValidRegex(true);
    } catch (error) {
      setIsValidRegex(false);
    }
  };

  useEffect(() => {
    // On demount
    () => {
      setAnchorEl(null);
      setShowManageColumns(false);
    };
  }, []);

  // Get Properties
  useEffect(() => {
    getProjectProperties();
  }, [getProjectProperties]);

  // Get result count
  useEffect(() => {
    setResultCount(Number(applyFilter(availableProperties, propertiesFilter).length));
  }, [propertiesFilter, availableProperties]);

  return (
    <>
      <Paper
        onClick={() => {
          if (showManageColumns) {
            if (!allowManageColumnsBlur.current) {
              allowManageColumnsBlur.current = true;
              return;
            }
            setAnchorEl(null);
            setShowManageColumns(false);
          }
        }}
      >
        <Grid
          id="create-property"
          container
          direction="column"
          style={{
            alignSelf: 'center',
            alignItems: 'center',
            marginBottom: '22px',
          }}
        >
          {/* Create/Update */}
          <Grid
            container
            direction="column"
            style={{
              alignSelf: 'center',
              alignItems: 'center',
              marginBottom: '22px',
            }}
          >
            <Typography
              style={{
                alignSelf: 'center',
                marginBottom: '10px',
              }}
              variant="h6"
            >
              {selectedProperty?.id ? 'Update Property' : 'Create Property'}
            </Typography>

            {/* Label */}
            <FormControl
              style={{
                width: '500px',
                marginBottom: '22px',
              }}
            >
              <TextField
                id="label"
                label="Label"
                value={selectedProperty?.label ?? ''}
                sx={{
                  '& p': {
                    color: PRIMARY,
                  },
                }}
                onChange={(e: { target: { value: string } }) => {
                  setSelectedProperty({
                    ...selectedProperty,
                    label: e.target.value,
                  } as Property);
                }}
                helperText={selectedProperty?.label ? '' : 'Required'}
                placeholder={
                  availableProperties.length > 0 ? availableProperties[0].label : 'Property'
                }
              />
            </FormControl>

            {/* Types */}
            <FormControl
              style={{
                width: '500px',
              }}
            >
              <InputLabel id="property-values">Types</InputLabel>
              <Select
                fullWidth
                labelId="property-types"
                id="property-types"
                value={selectedProperty?.type ? selectedProperty.type : ''}
                label="Types"
                onChange={e => {
                  let items;
                  const type = e.target.value as PropertyType;
                  let limit;
                  if (type === PropertyType.Number) {
                    setUpdatedItems([]);
                  } else if (type === PropertyType.String) {
                    limit = [1, 256];
                    setUpdatedItems([]);
                  } else {
                    items = selectedProperty?.items?.map(item => {
                      return {
                        name: item,
                      };
                    }) ?? [
                      {
                        name: '',
                        fresh: true,
                        checked: true,
                      },
                    ];
                    setUpdatedItems(items);
                  }
                  setSelectedProperty({
                    ...selectedProperty,
                    limit,
                    items,
                    type,
                  } as Property);
                  setExpandAdvancedOptions(false);
                }}
              >
                {Object.values(PropertyType).map(type => (
                  <MenuItem key={type} value={type}>
                    <Tooltip title={type}>
                      <Typography
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'calc(100% - 50px)',
                        }}
                      >
                        {type}
                      </Typography>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Message */}
            {selectedProperty?.type && selectedProperty?.label && (
              <FormControl
                style={{
                  width: '500px',
                  marginTop: '22px',
                }}
              >
                <TextField
                  id="message"
                  label="Validation Message"
                  value={selectedProperty?.message ?? ''}
                  onChange={(e: { target: { value: string } }) => {
                    setSelectedProperty({
                      ...selectedProperty,
                      message: e.target.value,
                    } as Property);
                  }}
                  helperText={
                    selectedProperty?.pattern
                      ? 'Change is recommended when setting Regex Pattern'
                      : ''
                  }
                  placeholder={selectedProperty?.message ?? generateMessage()}
                />
              </FormControl>
            )}

            {(selectedProperty?.type === PropertyType.String ||
              selectedProperty?.type === PropertyType.Number) && (
              <Accordion
                expanded={expandAdvancedOptions}
                style={{
                  width: '500px',
                  marginTop: '28px',
                  boxShadow: '0 0 0 1px rgba(255, 255, 255, 0.3)',
                  borderRadius: '4px',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="users-content"
                  id="users-header"
                  onClick={() => {
                    setExpandAdvancedOptions(!expandAdvancedOptions);
                  }}
                  sx={{
                    '& .MuiAccordionSummary-content': {
                      width: '90%',
                    },
                  }}
                >
                  <Typography component="span">Advanced Options</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Limit */}
                  <Grid
                    container
                    direction="column"
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '220px 220px',
                      gap: '22px',
                      marginTop: '21px',
                    }}
                  >
                    <FormControl>
                      <TextField
                        id="lower-limit"
                        label={
                          selectedProperty?.type === PropertyType.Number
                            ? 'Lower number Limit'
                            : 'Lower string size Limit'
                        }
                        type={'number'}
                        value={
                          selectedProperty?.limit?.[0] ??
                          (selectedProperty?.type === PropertyType.String ? 1 : '')
                        }
                        onChange={(e: { target: { value: string } }) => {
                          let lowerLimit = Number(e.target.value);
                          let upperLimit = selectedProperty?.limit?.[1];
                          if (selectedProperty?.type === PropertyType.String) {
                            lowerLimit = lowerLimit < 1 || isNaN(lowerLimit) ? 1 : lowerLimit;
                            upperLimit = selectedProperty?.limit?.[1]
                              ? selectedProperty.limit[1]
                              : 256;
                          } else {
                            lowerLimit =
                              lowerLimit < -Number.MAX_SAFE_INTEGER || isNaN(lowerLimit)
                                ? -Number.MAX_SAFE_INTEGER
                                : lowerLimit;
                            upperLimit = selectedProperty?.limit?.[1]
                              ? selectedProperty.limit[1]
                              : 0;
                          }
                          setSelectedProperty({
                            ...selectedProperty,
                            limit: [lowerLimit, upperLimit],
                          } as Property);
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        id="upper-limit"
                        label={
                          selectedProperty?.type === PropertyType.Number
                            ? 'Upper number Limit'
                            : 'Upper string size Limit'
                        }
                        type={'number'}
                        value={
                          selectedProperty?.limit?.[1] ??
                          (selectedProperty?.type === PropertyType.String ? 256 : '')
                        }
                        onChange={(e: { target: { value: string } }) => {
                          let lowerLimit = selectedProperty?.limit?.[0];
                          let upperLimit = Number(e.target.value);
                          if (selectedProperty?.type === PropertyType.String) {
                            lowerLimit = selectedProperty?.limit?.[0]
                              ? selectedProperty.limit[0]
                              : 1;
                            upperLimit = upperLimit > 256 || isNaN(upperLimit) ? 256 : upperLimit;
                          } else {
                            lowerLimit = selectedProperty?.limit?.[0]
                              ? selectedProperty.limit[0]
                              : 0;
                            upperLimit =
                              upperLimit > Number.MAX_SAFE_INTEGER || isNaN(upperLimit)
                                ? Number.MAX_SAFE_INTEGER
                                : upperLimit;
                          }
                          setSelectedProperty({
                            ...selectedProperty,
                            limit: [lowerLimit, upperLimit],
                          } as Property);
                        }}
                      />
                    </FormControl>
                  </Grid>

                  {/* Pattern */}
                  <FormControl
                    style={{
                      width: '462px',
                      marginTop: '21px',
                    }}
                  >
                    <TextField
                      id="regex-pattern"
                      label={'Regex Pattern'}
                      value={selectedProperty?.pattern ?? ''}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <strong>/</strong>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <strong>/</strong>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e: { target: { value: string } }) => {
                        validateRegex(e.target.value);
                        setSelectedProperty({
                          ...selectedProperty,
                          pattern: e.target.value,
                        } as Property);
                      }}
                      error={!isValidRegex}
                      helperText={!isValidRegex ? 'Invalid regex pattern' : ''}
                      placeholder="e.g. ^[a-zA-Z]+$"
                    />
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            )}

            {selectedProperty?.type === PropertyType.List && (
              <Grid
                container
                direction="column"
                style={{
                  width: '500px',
                  marginTop: '28px',
                }}
              >
                <Typography
                  style={{
                    marginBottom: '10px',
                  }}
                  variant="subtitle2"
                >
                  {updatedItems.length === 1 && updatedItems[0].fresh
                    ? 'Create list'
                    : 'Add to list'}
                </Typography>
                {ItemsTable({
                  updatedItems,
                  onItemsUpdated: (
                    items: { name: string; checked?: boolean; fresh?: boolean }[],
                  ) => {
                    setUpdatedItems(items);
                  },
                })}
              </Grid>
            )}
          </Grid>

          {/* Submit/Cancel */}
          <Grid
            container
            direction="column"
            style={
              selectedProperty?.id
                ? {
                    width: '100%',
                    marginTop: '20px',
                    display: 'grid',
                    gap: '20px',
                    gridTemplateColumns: '500px 500px',
                    placeContent: 'center',
                  }
                : {
                    width: '500px',
                    marginTop: '20px',
                  }
            }
          >
            <Button
              variant="contained"
              color="secondary"
              disabled={!selectedProperty?.label || !selectedProperty?.type}
              onClick={() => {
                if (selectedProperty?.id) {
                  updateProperty();
                } else {
                  createProperty();
                }
              }}
            >
              {selectedProperty?.id ? 'Update' : 'Create'}
            </Button>
            {selectedProperty?.id && (
              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  setSelectedProperty(undefined);
                }}
              >
                Cancel
              </Button>
            )}
          </Grid>
        </Grid>

        <Stack p={2} spacing={2} style={{ maxHeight: '1000px' }}>
          {/* Select All, Delete, Filter, Search, Hide Columns */}
          <Grid
            container
            direction="column"
            style={
              isMobile
                ? {
                    display: 'grid',
                    justifyItems: 'self-start',
                  }
                : {
                    margin: '-30px 0px -18px -14px',
                    display: 'grid',
                    gridTemplateColumns: '100px 81px 69px 200px 170px 90px',
                  }
            }
          >
            <Box
              style={{
                width: 'max-content',
                margin: '7px 0px 0px 15px',
                fontSize: 'x-small',
                color: PRIMARY,
                opacity: availableProperties.length > 0 ? 1 : 0.3,
                fontFamily: `"Open Sans", "Montserrat", "Arial", sans-serif`,
                fontWeight: '500',
              }}
            >
              SELECT ALL
              <Checkbox
                style={{
                  marginLeft: '-8px',
                  color: PRIMARY,
                  opacity: availableProperties.length > 0 ? 1 : 0.3,
                }}
                disabled={availableProperties.length === 0}
                checked={
                  availableProperties.filter(property => property.selected)?.length ===
                    availableProperties.length && availableProperties.length > 0
                }
                onChange={e => {
                  setTimeout(() => {
                    if (availableProperties.length > 1) {
                      setSelectedProperty(undefined);
                      setUpdatedItems([]);
                    }
                    setAvailableProperties(
                      availableProperties.map(property => {
                        return {
                          ...property,
                          selected: !e.target.checked,
                        };
                      }),
                    );
                  });
                }}
              />
            </Box>
            <Button
              disabled={!availableProperties.find(property => property.selected && property.id)}
              onClick={() => setShowDeleteDialog(true)}
            >
              <span style={{ fontSize: '10px' }}>Delete</span>
              <Delete />
            </Button>

            <Button onClick={() => setPropertiesFilter(undefined)}>
              <span style={{ fontSize: '10px' }}>Clear</span>
              <Clear />
            </Button>

            <div style={{ margin: '-10px 0px 0px 7px' }}>
              {QuickSearch({
                showQuickSearch: true,
                searchString: propertiesFilter?.label ?? '',
                onChange: (label: string) => {
                  debouncedOnSearch({
                    ...propertiesFilter,
                    label,
                  });
                },
              })}
            </div>

            <Button
              aria-describedby={'manage-columns-popper'}
              onClick={e => {
                setAnchorEl(e.currentTarget);
                setShowManageColumns(previousOpen => !previousOpen);
                e.stopPropagation();
              }}
            >
              <span style={{ fontSize: '10px' }}>Manage Columns</span>
              <ViewColumn />
            </Button>
            {PopperCheckList({
              list: propertiesColumns.filter(col => col.id !== PropertiesColumns.Selected),
              showManageColumns,
              anchorEl,
              onChecked: column => {
                allowManageColumnsBlur.current = false;
                const selectedColumn = propertiesColumns.find(c => c.id === column.id);
                if (!selectedColumn) return;
                setPropertiesColumns(
                  [
                    ...propertiesColumns.filter(c => c.id !== column.id),
                    {
                      ...selectedColumn,
                      checked: !selectedColumn.checked,
                    },
                  ].sort((a, b) => a.index - b.index),
                );
              },
            })}
          </Grid>

          <Grid
            container
            direction="column"
            style={{
              marginBottom: '15px',
              marginTop: isMobile ? '-30px' : '-26px',
              alignContent: 'end',
            }}
          >
            <Typography>{`${resultCount} ${resultCount === 1 ? 'result' : 'results'}`}</Typography>
          </Grid>

          {/* Table */}
          {PropertiesTable({
            availableProperties: availableProperties ?? [],
            setAvailableProperties,
            selectedProperty,
            setSelectedProperty,
            setUpdatedItems,
            propertiesColumns,
            propertiesFilter,
            propertiesSort,
            setPropertiesSort,
          })}
        </Stack>
      </Paper>

      <Dialog open={!!showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
        <DialogTitle>{`Are you sure you want to delete this Tool?`}</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              deleteProperties();
              setShowDeleteDialog(false);
            }}
          >
            Yes
          </Button>
          <Button color="secondary" onClick={() => setShowDeleteDialog(false)}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
