import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAtomValue } from 'jotai';
import { uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAllInvites } from '../../Authn/useAllInvites';
import { useAllUsers } from '../../Authn/useAllUsers';
import { useCreateProject } from '../../Authn/useCreateProject';
import { AccessItem, ClientProjectAccessItem, useInviteUser } from '../../Authn/useInviteUser';
import { useMakeAppInvite } from '../../Authn/useMakeAppInvite';
import { CID_PID_PAIRS, UserAccess } from '../../store/user';
import { Users } from './Users';
import { ActiveEmailInvitations } from './ActiveEmailInvitations';
import { CreateAppInvitation } from './CreateAppInvitation';
import { CreateNewProject } from './CreateNewProject';
import { SendEmailInvite } from './SendEmailInvite';
import { AdminCategories } from './enums';

export const SuperAdmin = () => {
  const { execute: refreshUsers, data: users } = useAllUsers();
  const { execute: refreshInvites, data: userInvites } = useAllInvites();

  const [newlyCreated, setNewlyCreated] = useState<UserAccess[]>([]);
  const clientProjects = useAtomValue(CID_PID_PAIRS);
  const clientInputColour = useCallback(
    (client: string) =>
      !client
        ? undefined
        : [...clientProjects, ...newlyCreated].find(({ clientId }) => clientId === client)
        ? 'success'
        : 'warning',
    [clientProjects, newlyCreated],
  );
  const projectInputColour = useCallback(
    (client: string, project: string) =>
      !client || !project
        ? undefined
        : [...clientProjects, ...newlyCreated].find(
            ({ clientId, projectId }) => clientId === client && projectId === project,
          )
        ? 'success'
        : 'warning',
    [clientProjects, newlyCreated],
  );

  const [email, setEmail] = useState('');
  const [emailClientId, setEmailClientId] = useState('');
  const [emailProjectId, setEmailProjectId] = useState('');

  const [appInviteAppName, setAppInviteAppName] = useState('');
  const [appInviteClientId, setAppInviteClientId] = useState('');
  const [appInviteProjectId, setAppInviteProjectId] = useState('');

  const [newClientId, setNewClientId] = useState('');
  const [newProjectId, setNewProjectId] = useState('');
  const [expandCategory, setExpandCategory] = useState<AdminCategories | undefined>(
    AdminCategories.CreateNewProject,
  );

  const emailAccess = useMemo<AccessItem[]>(
    () => [
      {
        type: 'client-project',
        clientId: emailClientId,
        projectId: emailProjectId,
      },
    ],
    [emailClientId, emailProjectId],
  );

  const newClientProjectAccess = useMemo<ClientProjectAccessItem>(
    () => ({
      type: 'client-project',
      clientId: newClientId,
      projectId: newProjectId,
    }),
    [newClientId, newProjectId],
  );

  const appInviteAccess = useMemo<ClientProjectAccessItem>(
    () => ({
      type: 'client-project',
      clientId: appInviteClientId,
      projectId: appInviteProjectId,
    }),
    [appInviteClientId, appInviteProjectId],
  );

  const {
    execute: createUser,
    data: emailInviteData,
    error: emailInviteError,
  } = useInviteUser(emailAccess, email);

  const {
    execute: createProject,
    data: createProjectData,
    error: createProjectError,
  } = useCreateProject(newClientProjectAccess);

  const {
    execute: createAppInvite,
    data: appInviteData,
    error: appInviteError,
  } = useMakeAppInvite(appInviteAccess, appInviteAppName ?? '');

  useEffect(() => {
    refreshUsers();
    refreshInvites();
  }, [refreshUsers, refreshInvites]);

  useEffect(() => {
    refreshUsers();
  }, [refreshUsers, emailInviteData]);

  useEffect(() => {
    if (createProjectData && createProjectData.length > 1) {
      setNewlyCreated(prev =>
        uniqBy(
          [...prev, ...createProjectData.flatMap(x => (x.type === 'superadmin' ? [] : x))],
          ({ clientId, projectId }) => `${clientId}/${projectId}`,
        ),
      );
    }
  }, [createProjectData, setNewlyCreated]);

  return (
    <div
      style={{
        margin: '10px',
      }}
    >
      {/* Create New Project */}
      <Accordion expanded={expandCategory === AdminCategories.CreateNewProject}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="create-new-project-content"
          id="create-new-project-header"
          onClick={async () => {
            setExpandCategory(
              expandCategory === AdminCategories.CreateNewProject
                ? undefined
                : AdminCategories.CreateNewProject,
            );
          }}
          sx={{
            '& .MuiAccordionSummary-content': {
              width: '90%',
            },
          }}
        >
          <Typography component="span">{AdminCategories.CreateNewProject}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {CreateNewProject({
            newProjectId,
            setNewProjectId,
            newClientId,
            setNewClientId,
            createProject,
            createProjectData,
            createProjectError,
          })}
        </AccordionDetails>
      </Accordion>
      {/* Create App Invitation */}
      <Accordion expanded={expandCategory === AdminCategories.CreateAppInvitation}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="create-app-invitation-content"
          id="create-app-invitation-header"
          onClick={async () => {
            setExpandCategory(
              expandCategory === AdminCategories.CreateAppInvitation
                ? undefined
                : AdminCategories.CreateAppInvitation,
            );
          }}
          sx={{
            '& .MuiAccordionSummary-content': {
              width: '90%',
            },
          }}
        >
          <Typography component="span">{AdminCategories.CreateAppInvitation}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {CreateAppInvitation({
            appInviteAppName,
            setAppInviteAppName,
            appInviteClientId,
            setAppInviteClientId,
            appInviteProjectId,
            setAppInviteProjectId,
            appInviteError,
            createAppInvite,
            appInviteData,
            clientInputColour,
            projectInputColour,
          })}
        </AccordionDetails>
      </Accordion>
      {/* Send Email Invitation */}
      <Accordion expanded={expandCategory === AdminCategories.SendEmailInvitation}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="send-app-invitation-content"
          id="send-app-invitation-header"
          onClick={async () => {
            setExpandCategory(
              expandCategory === AdminCategories.SendEmailInvitation
                ? undefined
                : AdminCategories.SendEmailInvitation,
            );
          }}
          sx={{
            '& .MuiAccordionSummary-content': {
              width: '90%',
            },
          }}
        >
          <Typography component="span">{AdminCategories.SendEmailInvitation}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {SendEmailInvite({
            emailClientId,
            setEmailClientId,
            emailProjectId,
            setEmailProjectId,
            email,
            setEmail,
            createUser,
            emailInviteData,
            emailInviteError,
            clientInputColour,
            projectInputColour,
          })}
        </AccordionDetails>
      </Accordion>
      {/* Active Email Invitations */}
      <Accordion expanded={expandCategory === AdminCategories.ActiveEmailInvitations}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="active-email-invitations-content"
          id="active-email-invitations-header"
          onClick={async () => {
            setExpandCategory(
              expandCategory === AdminCategories.ActiveEmailInvitations
                ? undefined
                : AdminCategories.ActiveEmailInvitations,
            );
          }}
          sx={{
            '& .MuiAccordionSummary-content': {
              width: '90%',
            },
          }}
        >
          <Typography component="span">{AdminCategories.ActiveEmailInvitations}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {ActiveEmailInvitations({
            userInvites: userInvites ?? [],
            refreshInvites,
            expandCategory,
          })}
        </AccordionDetails>
      </Accordion>
      {/* Users */}
      <Accordion expanded={expandCategory === AdminCategories.Users}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="users-content"
          id="users-header"
          onClick={async () => {
            setExpandCategory(
              expandCategory === AdminCategories.Users ? undefined : AdminCategories.Users,
            );
          }}
          sx={{
            '& .MuiAccordionSummary-content': {
              width: '90%',
            },
          }}
        >
          <Typography component="span">{AdminCategories.Users}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {Users({
            users: users ?? [],
            refreshUsers,
            expandCategory,
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
