import { Clear, FilterList, ViewColumn } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Paper,
  SortDirection,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import QuickSearch from '../../Common/QuickSearch';
import { truncationStyle } from '../../Style/GeoMobyBaseTheme';
import { UsersProps } from './Props';
import { User, UserFilter, UserSort } from './types';
import { debounce } from 'lodash';
import { UsersFilter } from './Filter/UsersFilter';
import { AdminCategories, UserColumns } from './enums';
import { PopperCheckList } from '../../Common/PopperCheckList';
import { useMobile } from '../../util/useMobile';

// All to be overwritten in Phase 2 https://geomoby.atlassian.net/browse/LTP-1301
const applyFilter = (users: User[], filter?: UserFilter, sort?: UserSort): User[] => {
  let filteredUsers: User[] = users;
  if (filter?.label) {
    filteredUsers = filteredUsers.filter(user => user.label.includes(filter?.label ?? ''));
  }
  if (filter?.client) {
    filteredUsers = filteredUsers.filter(user => {
      if (
        user.access.find(
          access => access.type === 'client-project' && access.clientId === filter.client,
        )
      ) {
        return user;
      }
    });
  }
  if (filter?.project) {
    filteredUsers = filteredUsers.filter(user => {
      if (
        user.access.find(
          access => access.type === 'client-project' && access.projectId === filter.project,
        )
      ) {
        return user;
      }
    });
  }

  if (sort) {
    if (sort.sortBy !== UserColumns.Accesss) {
      const column =
        sort.sortBy === UserColumns.ID
          ? 'id'
          : sort.sortBy === UserColumns.CreatedAt
          ? 'createdAt'
          : 'label';
      return filteredUsers.sort((a, b) => {
        return sort.order === 'DESC'
          ? b[column].localeCompare(a[column])
          : a[column].localeCompare(b[column]);
      });
    } else {
      return filteredUsers.sort((a, b) => {
        const Aaccess = a.access
          .map(access =>
            access.type === 'superadmin' ? 'STAFF' : `${access.clientId}/${access.projectId}`,
          )
          .join(', ');
        const Baccess = b.access
          .map(access =>
            access.type === 'superadmin' ? 'STAFF' : `${access.clientId}/${access.projectId}`,
          )
          .join(', ');
        return sort.order === 'DESC'
          ? Baccess.localeCompare(Aaccess)
          : Aaccess.localeCompare(Baccess);
      });
    }
  }
  return filteredUsers.sort((a, b) => a.label.localeCompare(b.label));
};

const UsersTable = (
  props: UsersProps & {
    userFilter: UserFilter | undefined;
    userSort: UserSort | undefined;
    setUserSort: Dispatch<SetStateAction<UserSort | undefined>>;
    userColumns: { id: string; value: string; checked: boolean; index: number }[];
  },
) => {
  const [expandRow, setExpandRow] = useState<string | undefined>();
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow
            style={{
              height: '55px',
            }}
          >
            {props.userColumns
              .filter(column => column.checked)
              .map(column => {
                return (
                  <TableCell
                    key={column.id}
                    style={{ fontSize: '15px' }}
                    sortDirection={
                      props.userSort?.sortBy === column.id
                        ? (props.userSort?.order?.toLowerCase() as SortDirection)
                        : undefined
                    }
                  >
                    <TableSortLabel
                      active={true}
                      direction={
                        props.userSort?.sortBy === column.id
                          ? ((props.userSort?.order ?? 'asc').toLowerCase() as 'asc' | 'desc')
                          : 'asc'
                      }
                      onClick={e => {
                        props.setUserSort({
                          sortBy: column.id as UserColumns,
                          order: props.userSort?.order === 'ASC' ? 'DESC' : 'ASC',
                        });
                      }}
                    >
                      {column.value}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {applyFilter(props.users, props.userFilter, props.userSort).map(user => {
            const access = user.access
              .map(access =>
                access.type === 'superadmin' ? 'STAFF' : `${access.clientId}/${access.projectId}`,
              )
              .join(', ');
            return (
              <TableRow
                key={user.id}
                sx={{
                  height: '55px',
                  fontSize: '15px',
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
                onClick={() => {
                  setExpandRow(expandRow === user.id ? undefined : user.id);
                }}
              >
                {props.userColumns.find(
                  column => column.id === UserColumns.ID && !!column.checked,
                ) && (
                  <Tooltip title={user.id}>
                    <TableCell style={{ maxWidth: '100px' }}>{user.id}</TableCell>
                  </Tooltip>
                )}
                {props.userColumns.find(
                  column => column.id === UserColumns.Label && !!column.checked,
                ) && (
                  <Tooltip title={user.label}>
                    <TableCell
                      style={{
                        ...(expandRow === user.id
                          ? {
                              overflowWrap: 'break-word',
                            }
                          : truncationStyle),
                        maxWidth: '50px',
                      }}
                    >
                      {user.label}
                    </TableCell>
                  </Tooltip>
                )}
                {props.userColumns.find(
                  column => column.id === UserColumns.Accesss && !!column.checked,
                ) && (
                  <Tooltip title={access}>
                    <TableCell
                      style={{
                        ...(expandRow === user.id
                          ? {
                              overflowWrap: 'break-word',
                            }
                          : truncationStyle),
                        maxWidth: '50px',
                      }}
                    >
                      {access}
                    </TableCell>
                  </Tooltip>
                )}
                {props.userColumns.find(
                  column => column.id === UserColumns.CreatedAt && !!column.checked,
                ) && (
                  <Tooltip title={new Date(user.createdAt).toString()}>
                    <TableCell style={{ maxWidth: '50px' }}>
                      {new Date(user.createdAt).toString()}
                    </TableCell>
                  </Tooltip>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const Users = (props: UsersProps) => {
  const isMobile = useMobile();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
  const [resultCount, setResultCount] = useState<number>(0);
  const [showManageColumns, setShowManageColumns] = useState(false);
  const [userColumns, setUserColumns] = useState<
    { id: string; value: string; checked: boolean; index: number }[]
  >(
    Object.values(UserColumns).map((column, index) => {
      return {
        id: column,
        value: column,
        checked: true,
        index,
      };
    }),
  );
  const [userFilter, setUserFilter] = useState<UserFilter | undefined>();
  const [userSort, setUserSort] = useState<UserSort | undefined>();

  const allowManageColumnsBlur = useRef<boolean>(true);
  const debouncedOnSearch = useRef(
    debounce(
      (filter: UserFilter) => {
        setUserFilter(filter);
      },
      100,
      { leading: true },
    ),
  ).current;

  useEffect(() => {
    // On demount
    () => {
      setAnchorEl(null);
      setShowManageColumns(false);
    };
  }, []);

  useEffect(() => {
    if (props.expandCategory !== AdminCategories.Users) {
      setAnchorEl(null);
      setShowManageColumns(false);
    }
  }, [props.expandCategory]);

  useEffect(() => {
    setResultCount(Number(applyFilter(props.users, userFilter).length));
  }, [userFilter, props.users]);

  return (
    <>
      <Paper
        onClick={() => {
          if (showManageColumns) {
            if (!allowManageColumnsBlur.current) {
              allowManageColumnsBlur.current = true;
              return;
            }
            setAnchorEl(null);
            setShowManageColumns(false);
          }
        }}
      >
        <Stack p={2} spacing={2} style={{ maxHeight: '1000px' }}>
          <Grid
            container
            direction="column"
            style={
              isMobile
                ? {
                    display: 'grid',
                    justifyItems: 'self-start',
                  }
                : {
                    margin: '-30px 0px -18px 10px',
                    display: 'grid',
                    gridTemplateColumns: '65px 100px 200px 170px 90px',
                  }
            }
          >
            <Button onClick={() => setOpenFilterDialog(true)}>
              <span style={{ fontSize: '10px' }}>Filter</span>
              <FilterList />
            </Button>

            <Button onClick={() => setUserFilter(undefined)}>
              <span style={{ fontSize: '10px' }}>Clear</span>
              <Clear />
            </Button>

            <div style={{ margin: '-10px 0px 0px 7px' }}>
              {QuickSearch({
                showQuickSearch: true,
                searchString: userFilter?.label ?? '',
                onChange: (label: string) => {
                  debouncedOnSearch({
                    ...userFilter,
                    label,
                  });
                },
              })}
            </div>

            <Button
              aria-describedby={'manage-columns-popper'}
              onClick={e => {
                setAnchorEl(e.currentTarget);
                setShowManageColumns(previousOpen => !previousOpen);
                e.stopPropagation();
              }}
            >
              <span style={{ fontSize: '10px' }}>Manage Columns</span>
              <ViewColumn />
            </Button>
            {PopperCheckList({
              list: userColumns,
              showManageColumns,
              anchorEl,
              onChecked: column => {
                allowManageColumnsBlur.current = false;
                const selectedColumn = userColumns.find(c => c.id === column.id);
                if (!selectedColumn) return;
                setUserColumns(
                  [
                    ...userColumns.filter(c => c.id !== column.id),
                    {
                      ...selectedColumn,
                      checked: !selectedColumn.checked,
                    },
                  ].sort((a, b) => a.index - b.index),
                );
              },
            })}
          </Grid>

          <Grid
            container
            direction="column"
            style={{
              marginBottom: '15px',
              marginTop: isMobile ? '-30px' : '-26px',
              alignContent: 'end',
            }}
          >
            <Typography>{`${resultCount} ${resultCount === 1 ? 'result' : 'results'}`}</Typography>
          </Grid>
          {UsersTable({
            ...props,
            userColumns,
            userFilter,
            userSort,
            setUserSort,
          })}
        </Stack>
      </Paper>
      <Dialog
        open={openFilterDialog}
        onClose={() => setOpenFilterDialog(false)}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '70%',
            maxHeight: '80%',
            width: '650px',
          },
        }}
      >
        {UsersFilter({
          users: props.users ?? [],
          userFilter,
          setUserFilter,
          setOpenFilterDialog,
        })}
      </Dialog>
    </>
  );
};
