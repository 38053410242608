import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Grid, Button, InputLabel, Typography, Box } from '@mui/material';
import { ALL, ALL_GEOFENCE_TYPES, ALL_GEOFENCE_ZONES } from '../../../../util/constants';
import { EBTListenerTypeId, EBTTriggerTypeOptionsId } from '../../../../util/enums';
import { LayerList } from '../../../Layer/LayerList';
import { NameId } from '../../../Map/types';
import { EBTProps } from '../../Props';
import { GeofenceType } from '../../types';

const SelectALayer = (props: EBTProps) => {
  return (
    <>
      <Box style={{ height: '275px' }}>
        {/* Layer List */}
        <Grid
          container
          style={{
            display: 'grid',
            gridTemplateColumns: '50% 50%',
          }}
        >
          <InputLabel id="select-layer">
            {props.availableLayers.length > 0 ? 'Select a group' : 'No groups available'}
          </InputLabel>
          <Typography style={{ justifySelf: 'self-end' }}>{`${props.availableLayers.length} ${
            Number(props.availableLayers.length) === 1 ? 'result' : 'results'
          }`}</Typography>
        </Grid>
        {props.availableLayers.length > 0 && (
          <Grid
            container
            direction={'row'}
            style={{
              marginTop: '10px',
              height: 'fit-content',
              maxHeight: '239px',
              overflowY: 'hidden',
            }}
          >
            {LayerList({
              showList: true,
              availableLayers: props.availableLayers.slice(
                props.selectionIterator,
                props.selectionIterator + 5,
              ),
              selectedLayer: props.selectedLayer,
              disable:
                !!props.eventIds &&
                props.selectedLocationListenerType === EBTListenerTypeId.Geofence,
              onClick: (layer: NameId) => {
                props.featurePageRef.current = 1;
                props.setSearchString(undefined);
                props.setSelectedLayer(prevLayer => (prevLayer === layer ? undefined : layer));
                props.setSelectedLocationListenerType(EBTListenerTypeId.Layer);
                props.setAvailableFeatures([]);
                if (
                  props.selectedTriggerOption?.id === EBTTriggerTypeOptionsId.Entry ||
                  props.selectedTriggerOption?.id === EBTTriggerTypeOptionsId.Exit ||
                  props.selectedTriggerOption?.id === EBTTriggerTypeOptionsId.Dwell
                ) {
                  props.setSelectedZone({ id: ALL, value: ALL_GEOFENCE_ZONES });
                  props.setSelectedGeofenceType({ id: ALL, value: ALL_GEOFENCE_TYPES });
                }
              },
            })}
          </Grid>
        )}
      </Box>

      {/* Next and Previous */}
      <Grid
        container
        style={{
          display: 'grid',
          gridTemplateColumns: '60px 60px',
          gap: '10px',
          justifyContent: 'right',
          paddingRight: '21px',
          paddingTop: '12px',
          marginBottom: '-40px',
        }}
      >
        <Button
          variant="outlined"
          disabled={props.availableLayers.length === 0}
          onClick={() => {
            props.setSelectionIterator(selectionIterator =>
              selectionIterator - 5 < 0 ? 0 : selectionIterator - 5,
            );
          }}
        >
          <ChevronLeft />
        </Button>
        <Button
          variant="outlined"
          disabled={props.availableLayers.length === 0}
          onClick={() => {
            props.setSelectionIterator(selectionIterator =>
              selectionIterator + 5 >= props.availableLayers.length
                ? selectionIterator
                : selectionIterator + 5,
            );
          }}
        >
          <ChevronRight />
        </Button>
      </Grid>
    </>
  );
};
export default SelectALayer;
