import { Layers } from '@mui/icons-material';
import { Button, Fab, Grid, Popover } from '@mui/material';
import { useRef, useState } from 'react';
import { MAP_SOURCE_TYPES } from '../../../../util/constants';
import { MapSourceType } from '../../types';

export const ChangeMapSourceType = ({
  mapSource,
  setMapSource,
}: {
  mapSource: MapSourceType;
  setMapSource: (source: MapSourceType) => void;
}) => {
  const [selecting, setSelecting] = useState(false);
  const popoverRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Fab ref={popoverRef} color="default" size="small" onClick={() => setSelecting(!selecting)}>
        <Layers />
      </Fab>
      <Popover
        open={selecting}
        anchorEl={popoverRef.current}
        onClose={() => setSelecting(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid container direction="column">
          {MAP_SOURCE_TYPES.map(source => (
            <Grid item key={source}>
              <Button
                color={mapSource === source ? 'primary' : 'inherit'}
                fullWidth
                key={source}
                onClick={() => {
                  setMapSource(source);
                }}
              >
                {source}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </>
  );
};
