import { Accordion, AccordionSummary, Grid } from '@mui/material';
import { GridRowData } from '@material-ui/data-grid';
import { ActiveAssetText, AssetLabel, getMicroFenceIcon } from '../Map/Helpers';
import { BACKGROUND_OFFSET } from '../../Style/GeoMobyBaseTheme';

export const MicrofenceList = (props: {
  availableMicrofences: GridRowData[];
  selectedMicroFence?: GridRowData;
  showList: boolean;
  onClick: (fence: GridRowData) => void;
}) =>
  props.showList &&
  props.availableMicrofences.map(microfence => {
    const isActive = microfence.isActive;
    return (
      <Accordion id={microfence.id} key={microfence.id} expanded={false} style={{ width: '98%' }}>
        <AccordionSummary
          onClick={async () => props.onClick(microfence)}
          sx={{
            background:
              microfence.id === props.selectedMicroFence?.id ? '#1E3748' : BACKGROUND_OFFSET,
            '& .MuiAccordionSummary-content': {
              width: '90%',
            },
          }}
        >
          {getMicroFenceIcon(microfence.type)}
          <Grid
            container
            direction="row"
            style={{
              display: 'grid',
              gridTemplateColumns: isActive ? '78% 22%' : '100%',
            }}
          >
            {AssetLabel(microfence.name, microfence.name)}
            {isActive && ActiveAssetText()}
          </Grid>
        </AccordionSummary>
      </Accordion>
    );
  });
