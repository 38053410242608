import { Feature, Map as OlMap, View } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Extent, getCenter } from 'ol/extent';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat, transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, Style, Text } from 'ol/style';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { MapContainer } from '../MapContainer/MapContainer';
import {
  defaultGeofenceEventOverlay,
  initialiseGeofenceClustersOverlay,
  updateLayerOverlay,
} from '../Styles/GeofenceStyles';
import {
  AssetState,
  DeviceLocation,
  LocalBeacons,
  SensedAsset,
  SensedAssetsReport,
} from '../Messages';
import { MapToolbar } from '../Toolbar/MapToolbar';
import { ZoomIn } from '../Toolbar/ZoomTools/ZoomIn';
import { ZoomOut } from '../Toolbar/ZoomTools/ZoomOut';
import { Follow } from '../Toolbar/Follow';
import { updateLocationTrace } from '../../../hooks/geomoby/locationTrace';
import {
  fitToExtent,
  getCoordinateDifference,
  getExtentDifference,
  panTo,
} from '../../../hooks/geomoby/MapAnimation';
import { LiveStreamData } from '../../../hooks/geomoby/useGeomobyLiveStream';
import {
  PointsLayersMicrofences,
  StrongFeatureHolder,
} from '../../../hooks/geomoby/useLiveMapLoader';
import { LocalBeaconsPopover, useLocalBeacons } from '../../../hooks/geomoby/localBeacons';
import { useAtomValue, useSetAtom } from 'jotai';
import { CID, PID } from '../../../store/user';
import { Cluster } from 'ol/source';
import { transformExtent } from 'ol/proj';
import { debounce } from 'lodash';
import { LoadIndicator } from '../Toolbar/LayerTools/LoadIndicator';
import { click } from 'ol/events/condition';
import { Select } from 'ol/interaction';
import { GeoJSONFeature } from 'ol/format/GeoJSON';
import { GridRowData } from '@material-ui/data-grid';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import Popup from 'ol-ext/overlay/Popup';
import { MAP_API_KEYS, MAP_STATE } from '../../../store/map';
import { SensedAssetsPopover, useSensedAssets } from '../../../hooks/geomoby/sensedAssets';
import RenderFeature from 'ol/render/Feature';
import { TRIGGERS_URL } from '../../../store/url';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import axios from 'axios';
import { MultiPolygon } from 'ol/geom';
import {
  GeomobyOverride,
  Asset,
  PortableAssetTool,
  SelectedAsset,
  LastKnownLocation,
  MapSourceType,
} from '../types';
import { LocationDisplayType, LocationSearch, LocationSearchData } from '../Toolbar/LocationSearch';
import CircleStyle from 'ol/style/Circle';
import { KNOWN_TOOLS } from '../../../store/tools';
import {
  ASSET_ID,
  ASSET_LABEL,
  CLUSTER_MAX_ZOOM,
  FENCE_VIEWING_HEIGHT,
  initialExtentInDegrees,
  initialLatitude,
  initialLongitude,
  initialZoomHeight,
  LOCAL_BEACONS,
  MICROFENCE_LAYER_ID,
  POINT,
  SENSED_ASSETS,
  SENSED_EXITED_ASSETS_IDS,
  UNKNOWN,
  ZOOM_THRESHOLD,
} from '../../../util/constants';
import { deselectFences } from '../Helpers';
import { AssetEntity, EntityType, FenceGeometryType, MapType } from '../../../util/enums';
import {
  doEventUpdates,
  unlocaliseAssetLabel,
  updateAssetSelection,
  updateMicrofencesActivity,
} from './EventUpdates';
import { MapState } from './Props';
import { useLocation } from 'react-router-dom';
import { addWeeks } from 'date-fns';
import { ToolLocationState } from '../../Tools/Tools';
import { stringifyIdRecord, truncate } from '../../../util/stringUtils';
import { createMap, createMapDefaults } from '../InitMap';
import {
  updateMicrofenceClustersOverlay,
  clickedGeoOrMicroFenceStyle,
  updateMicrofenceStyle,
} from '../Styles/MicrofenceStyles';
import { initialiseAssetOverlay } from '../Styles/AssetStyles';
import {
  defaultDropPinStyle,
  defaultLabelLayer,
  defaultPopUpStyle,
  initialiseLocationTraceOverlay,
} from '../Styles/MiscStyles';
import { geometryTypeOfEntity } from '../commons';
import { ChangeMapSourceType } from '../Toolbar/LayerTools/ChangeMapSourceType';
import { jsUcFirst } from '../../Global/StringFormatterFunctions';

/* Live/Replay Map */
export function MapRenderer({
  selectedAsset,
  setSelectedAsset,
  streamedData,
  userExtent,
  setUserExtent,
  selectedGeofence,
  setSelectedGeofence,
  selectedMicrofence,
  setSelectedMicrofence,
  selectedBeacon,
  setSelectedBeacon,
  selectedDevice,
  setSelectedDevice,
  selectedGPSTracker,
  setSelectedGPSTracker,
  selectedTool,
  setSelectedTool,
  liveMapStaticData,
  resetStyling,
  mapType,
  resetStateRef,
  onExtentChanged,
  fencesLoading,
  locationSearchData,
  setCurrentCenter,
  locationDisplay,
  navigateTo,
  setNavigateTo,
  selectedFromMap,
  setSelectedFromMap,
  deselectFence,
  setDeselectFence,
  setLocationDisplay,
  setLocationSearchData,
  getNow,
  setRange,
}: {
  selectedAsset: SelectedAsset | undefined;
  setSelectedAsset: Dispatch<SetStateAction<SelectedAsset | undefined>>;
  streamedData: LiveStreamData;
  userExtent: Extent | undefined;
  setUserExtent: Dispatch<SetStateAction<Extent | undefined>>;
  selectedGeofence: GridRowData | undefined;
  setSelectedGeofence: Dispatch<GridRowData | undefined>;
  selectedMicrofence: GridRowData | undefined;
  setSelectedMicrofence: Dispatch<GridRowData | undefined>;
  selectedBeacon: Asset | undefined;
  setSelectedBeacon: Dispatch<Asset | undefined>;
  selectedDevice: Asset | undefined;
  setSelectedDevice: Dispatch<Asset | undefined>;
  selectedGPSTracker: Asset | undefined;
  setSelectedGPSTracker: Dispatch<Asset | undefined>;
  selectedTool: PortableAssetTool | undefined;
  setSelectedTool: Dispatch<PortableAssetTool | undefined>;
  liveMapStaticData: PointsLayersMicrofences | undefined;
  resetStyling: boolean;
  mapType: MapType;
  resetStateRef?: { current: () => void };
  onExtentChanged: Dispatch<
    SetStateAction<{ latitude: number; longitude: number; extentInDegrees: number }>
  >;
  fencesLoading: boolean;
  locationSearchData: LocationSearchData | undefined;
  setCurrentCenter: Dispatch<SetStateAction<number[] | undefined>>;
  locationDisplay: LocationDisplayType | undefined;
  navigateTo: string | null;
  setNavigateTo: Dispatch<SetStateAction<string | null>>;
  selectedFromMap: boolean;
  setSelectedFromMap: Dispatch<SetStateAction<boolean>>;
  deselectFence: boolean;
  setDeselectFence: Dispatch<SetStateAction<boolean>>;
  setLocationDisplay: Dispatch<LocationDisplayType | undefined>;
  setLocationSearchData: Dispatch<LocationSearchData | undefined>;
  getNow: () => Date;
  setRange: Dispatch<SetStateAction<[Date | null, Date | null]>>;
}) {
  const [followingAssetIdString, setFollowingAssetIdString] = useState<string | undefined>();
  const [localBeacons, setLocalBeacons] = useLocalBeacons();
  const [locationStateFromTools, setLocationStateFromTools] =
    useState<ToolLocationState | undefined>();
  const [mapSource, setMapSource] = useState<MapSourceType>('Terrain & Roads');
  const [mapState, setMapState] = useState<MapState | undefined>();
  const [sensedAssets, setSensedAssets] = useSensedAssets();
  const [specifiedCoordinates, setSpecifiedCoordinates] = useState<[number, number]>();

  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const triggersBaseUrl = useAtomValue(TRIGGERS_URL);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const mapApiKeys = useAtomValue(MAP_API_KEYS);
  const knownTools = useAtomValue(KNOWN_TOOLS);

  const assetSelectedRef = useRef<boolean>(false);
  const debouncedOnMapMoved = useRef(
    debounce(
      (olmap: OlMap) => {
        changeBounds(olmap);
      },
      500,
      { leading: true },
    ),
  ).current;
  const fenceViewingHeight = useRef<number>(FENCE_VIEWING_HEIGHT);
  const popUpHoverRef = useRef<Popup>(new Popup());
  const storeMapState = useSetAtom(MAP_STATE);
  const styleCache = new Map<string, Style[]>();

  const { state: liveState, lastUpdates } = streamedData;

  // Pre-selected state from Tools tab.
  const location = useLocation();
  if ((location.state as unknown as ToolLocationState)?.origin === 'Tools') {
    const locationState = location.state as unknown as ToolLocationState;
    setLocationStateFromTools(locationState);
    location.state = undefined;
  }

  if (!specifiedCoordinates) {
    navigator.geolocation?.getCurrentPosition(
      loc => {
        setSpecifiedCoordinates([loc?.coords.longitude, loc?.coords.latitude]);
      },
      () => {
        setSpecifiedCoordinates([initialLongitude, initialLatitude]);
      },
    );
  }

  const animateToSearchedLocation = useCallback(
    async (view: View, coords: number[], isStreetAddress?: boolean): Promise<void> => {
      return new Promise((resolve, reject) => {
        const duration = Math.min(
          6000,
          Math.max(
            300,
            getCoordinateDifference(view.getCenter() ?? [0, 0], coords ?? [0, 0]) / 1000,
          ),
        );

        view.animate(
          {
            center: coords,
            zoom: duration > 300 ? 9 - duration / 1000 : view.getZoom(),
            duration: duration,
          },
          () => {
            view.animate(
              {
                resolution: isStreetAddress ? 18 : 14,
                duration: 2000,
              },
              () => {
                resolve();
              },
            );
          },
        );
      });
    },
    [],
  );

  const getGeofence = useCallback(
    async (fenceId: string, layerId: string, fenceType: FenceGeometryType) => {
      return (
        await axios.get<GridRowData>(
          `${triggersBaseUrl}/${cid}/${pid}/geofences/${layerId}/${fenceType}/${fenceId}`,
          authedConfig,
        )
      ).data;
    },
    [triggersBaseUrl, cid, pid, authedConfig],
  );

  const animateToFeature = useCallback(
    async ([lon1, lat1, lon2, lat2]: Extent, olmap: OlMap) => {
      const duration = Math.min(
        6000,
        Math.max(
          300,
          getCoordinateDifference(
            olmap.getView().getCenter() ?? [0, 0],
            getCenter([lon1, lat1, lon2, lat2]) ?? [0, 0],
          ) / 1000,
        ),
      );
      olmap.getView().animate(
        {
          center: getCenter([lon1, lat1, lon2, lat2]),
          zoom: duration > 300 ? 9 - duration / 1000 : olmap.getView().getZoom(),
          duration: duration,
        },
        () => {
          const zoom = olmap.getView().getZoom();
          if (zoom && zoom > fenceViewingHeight.current) {
            fitToExtent(olmap.getView(), [lon1, lat1, lon2, lat2]);
            debouncedOnMapMoved(olmap);
            return;
          }

          olmap.getView().animate(
            {
              center: getCenter([lon1, lat1, lon2, lat2]),
              duration: 250,
            },
            () => {
              if (selectedGeofence) {
                debouncedOnMapMoved(olmap);
                fitToExtent(olmap.getView(), [lon1, lat1, lon2, lat2]);
              }
            },
          );
        },
      );
    },
    [debouncedOnMapMoved, selectedGeofence],
  );

  const dropLocationMarker = useCallback(async (olmap: OlMap, pin: Feature<Point>) => {
    const pinLayer = olmap?.getAllLayers().find(layer => layer?.getClassName() === 'pin-layer');
    if (pinLayer) {
      pinLayer.getSource()?.forEachFeature((feature: Feature<Geometry>) => {
        pinLayer.getSource().removeFeature(feature);
      });
      pinLayer.getSource().addFeatures([pin]);
    } else {
      olmap?.addLayer(
        new VectorLayer({
          className: 'pin-layer',
          source: new VectorSource({
            features: [pin],
          }),
          style: defaultDropPinStyle,
        }),
      );
    }
  }, []);

  const updateClusters = useCallback(async (olmap: OlMap) => {
    const zoom = olmap.getView().getZoom() || 0;
    // To stop errors in console when zooming too close.
    if (zoom > ZOOM_THRESHOLD) {
      olmap.getView().setZoom(ZOOM_THRESHOLD);
    }
    // Show geofences
    if (zoom > fenceViewingHeight.current) {
      // AnimatedCluster extends VectorLayer, so the filter is for both.
      olmap
        .getAllLayers()
        ?.filter(layer => layer instanceof VectorLayer && layer.get('id') !== MICROFENCE_LAYER_ID)
        ?.forEach(layer => {
          layer.setVisible(!(layer instanceof AnimatedCluster));
        });
    }
    // Show clusters
    else {
      olmap
        .getAllLayers()
        ?.filter(layer => layer instanceof VectorLayer && layer.get('id') !== MICROFENCE_LAYER_ID)
        ?.forEach(layer => {
          if (
            layer
              .getSource()
              .getFeatures()
              .some((f: Feature<Geometry>) => f.get(ASSET_ID) || f.get('eventId'))
          ) {
            layer.setVisible(true);
          } else {
            layer.setVisible(layer instanceof AnimatedCluster);
          }
        });
    }
  }, []);

  // On init/demount
  useEffect(() => {
    if (!mapState) return;
    storeMapState(mapState);
    return () => {
      mapState.map?.dispose();
    };
  }, [mapState, storeMapState]);

  // Initialise map overlays and mouse events.
  useEffect(() => {
    if (!liveMapStaticData || !mapType || mapState || !specifiedCoordinates) return;
    const { map: olmap, setSource: setMapSource } = createMap(
      mapType,
      createMapDefaults({
        sourceType: mapSource,
        edit: mapType?.includes('EDIT'),
        specifiedCoordinates: specifiedCoordinates ?? [initialLongitude, initialLatitude],
        mapApiKeys,
      }),
    );

    const { src: locationTraceSrc, lyr: locationTraceLyr } = initialiseLocationTraceOverlay();
    olmap.addLayer(locationTraceLyr);

    const { src: labelSrc, lyr: labelLyr } = defaultLabelLayer();
    olmap.addLayer(labelLyr);

    const layers = updateLayerOverlay(liveMapStaticData.layers);
    layers?.forEach(layer => {
      layer.source.setVisible(false);
      olmap.addLayer(layer.source);
    });

    const microfenceLayer = updateMicrofenceClustersOverlay(
      liveMapStaticData.microfences,
      styleCache,
      {
        showActivity: true,
      },
    );
    olmap.addLayer(microfenceLayer);

    const pointLayer = initialiseGeofenceClustersOverlay(liveMapStaticData.points);
    olmap.addLayer(pointLayer);

    const { src: assetSrc, lyr: assetLyr } = initialiseAssetOverlay();
    olmap.addLayer(assetLyr);

    const { src: geofenceEventSrc, lyr: geofenceEventLyr } = defaultGeofenceEventOverlay();
    olmap.addLayer(geofenceEventLyr);

    setMapClickSelection(olmap, layers, {
      excludeLayers: [locationTraceLyr, labelLyr, assetLyr, geofenceEventLyr],
    });

    // OnClick... because setMapClickSelection is not 100% reliable.
    olmap.on('click', clickEvent => {
      deselectFences(olmap);
      fenceViewingHeight.current = FENCE_VIEWING_HEIGHT;
      let clusterSelected = false;
      popUpHoverRef.current.hide();
      setSelectedGeofence(undefined);
      setSelectedMicrofence(undefined);
      setSelectedBeacon(undefined);
      setSelectedDevice(undefined);
      setSelectedGPSTracker(undefined);
      setSelectedTool(undefined);
      setLocationDisplay(undefined);
      setLocationSearchData(undefined);
      setSelectedAsset(undefined);
      setUserExtent(undefined);
      locationTraceSrc.clear();
      labelSrc.clear();
      const geofences: Feature<Geometry>[] = [];
      const asset = olmap.forEachFeatureAtPixel(clickEvent.pixel, (feature, layer) => {
        if (layer === assetLyr) {
          return feature;
        }
        if (layer.get('id') === POINT) {
          clusterSelected = true;
        }
        const type = geometryTypeOfEntity(feature.getProperties());
        if (type && type !== FenceGeometryType.Microfence) {
          geofences.push(feature as Feature<Geometry>);
        }
      });

      // Selected a geofence or microfence.
      if (geofences.length > 0 && !(asset || clusterSelected)) {
        let smallestExtentArea = Number.MAX_SAFE_INTEGER;
        let selectedFence: Feature<Geometry> = geofences[0];
        geofences.forEach(feature => {
          const extent = feature.getGeometry()?.getExtent();
          const extentArea = extent
            ? Math.abs(extent[0] - extent[2]) * Math.abs(extent[1] - extent[2])
            : Number.MAX_SAFE_INTEGER;
          if (smallestExtentArea > extentArea) {
            smallestExtentArea = extentArea;
            selectedFence = feature;
          }
        });
        setUserExtent(selectedFence.get('geometry')?.getExtent());
        if (selectedFence.get('layerId') === MICROFENCE_LAYER_ID) {
          setFollowingAssetIdString(undefined);
          setSelectedAsset({
            id: selectedFence.get('assetId'),
            label: selectedFence.get('name'),
            prefix: jsUcFirst(EntityType.Microfence),
            following: false,
          });
        } else {
          setSelectedGeofence({
            ...selectedFence.getProperties(),
            type: geometryTypeOfEntity(selectedFence.getProperties()),
            selected: true,
          });
        }
        return;
      }

      if (!asset || clusterSelected) {
        if (!clusterSelected) {
          setSelectedGeofence(undefined);
          setSelectedMicrofence(undefined);
        }
        setFollowingAssetIdString(undefined);
        setSelectedAsset(undefined);
        return;
      }
      const id = asset?.get(ASSET_ID);
      const label = asset?.get(ASSET_LABEL);
      if (!id || !label) {
        console.warn('Should not be able to select an asset without id or label', asset);
        return;
      }

      // Selected an asset.
      const newSelectedAsset: SelectedAsset = { id, label, following: false };
      setFollowingAssetIdString(undefined);
      setSelectedAsset(newSelectedAsset);
      assetSelectedRef.current = true;

      if (newSelectedAsset.id?.deviceId) {
        setSelectedDevice({
          id: newSelectedAsset.id?.deviceId as string,
          label: newSelectedAsset.label ?? '',
          type: AssetEntity.Device,
        });
      } else if (newSelectedAsset.id?.gpsTrackerId) {
        setSelectedGPSTracker({
          id: newSelectedAsset.id?.gpsTrackerId as string,
          label: newSelectedAsset.label ?? '',
          type: AssetEntity.GPSTracker,
        });
      } else if (newSelectedAsset.id?.beaconId) {
        const foundTool = knownTools.find(t => t.id === newSelectedAsset.id?.beaconId);
        if (foundTool) {
          setSelectedTool(foundTool as PortableAssetTool);
        } else {
          setSelectedBeacon({
            id: newSelectedAsset.id.beaconId as string,
            label: newSelectedAsset.label ?? '',
            type: AssetEntity.Beacon,
          });
        }
      }
    });

    olmap.on('moveend', () => {
      const zoom = olmap.getView().getZoom() || 0;
      setCurrentCenter(olmap.getView().getCenter());
      debouncedOnMapMoved(olmap);
      popUpHoverRef.current.hide();
    });

    olmap.on('pointermove', moveEvent => {
      let mousedOver: RenderFeature | Feature<Geometry> | undefined;
      const zoom = olmap.getView().getZoom() || 0;
      const coordinate = [
        moveEvent.coordinate[0],
        moveEvent.coordinate[1] - (olmap.getView().getMaxZoom() - zoom),
      ];
      const nonFences = olmap
        .getFeaturesAtPixel(moveEvent.pixel)
        .filter(nf => nf.get('ASSET_LABEL') || nf.get('event') || nf.get('searchedCoordinates'));
      let foundFeature = nonFences.length > 0;

      if (nonFences.length === 0) {
        const geofences = olmap
          .getFeaturesAtPixel(moveEvent.pixel)
          .filter(
            feature =>
              (feature.get('id') && feature.get('layerId')) ||
              (feature.get('features')?.length > 0 &&
                feature.get('features')[0].get('id') &&
                feature.get('features')[0].get('layerId')),
          );
        if (geofences.length > 0) {
          let smallestExtentArea = Number.MAX_SAFE_INTEGER;
          mousedOver = geofences[0];
          geofences.forEach(feature => {
            const extent = feature.getGeometry()?.getExtent();
            const extentArea = extent
              ? Math.abs(extent[0] - extent[2]) * Math.abs(extent[1] - extent[2])
              : Number.MAX_SAFE_INTEGER;
            if (extent && smallestExtentArea > extentArea) {
              smallestExtentArea = extentArea;
              mousedOver = feature;
              foundFeature = true;
            }
          });
        }
      }

      if (!foundFeature) {
        popUpHoverRef.current.hide();
        return;
      }
      const fences: GeoJSONFeature[] = mousedOver?.get('features') ?? mousedOver ?? [];

      if (
        Array.isArray(fences) &&
        fences.length > 1 &&
        fences[0].get('type') === POINT &&
        !fenceSharesCommonPoint(fences)
      ) {
        const labels = `${fences
          .slice(0, 4)
          .map(
            f =>
              f.get('name') ??
              f.get(ASSET_LABEL) ??
              f.get('searchedCoordinates') ??
              f.get('eventId') ??
              f.get('beacon_id') ??
              UNKNOWN,
          )
          .join('\n')} ${fences.length > 4 ? '\n...' : ''}`;
        showPopupLabel(coordinate, labels);
        return;
      }

      const features = nonFences.length > 0 ? nonFences : Array.isArray(fences) ? fences : [fences];
      showPopupLabel(
        coordinate,
        features
          .map(
            f =>
              f.get('name') ??
              f.get(ASSET_LABEL) ??
              f.get('searchedCoordinates') ??
              f.get('eventId') ??
              f.get('beacon_id') ??
              UNKNOWN,
          )
          .join('\n'),
      );
    });

    olmap.getView().on('change:resolution', () => {
      updateClusters(olmap);
    });

    // Remove close button
    popUpHoverRef.current?.getElement()?.childNodes[0].remove();
    olmap.addOverlay(popUpHoverRef.current);

    setMapState({
      map: olmap,
      layers,
      microfenceLayer,
      microfences: liveMapStaticData.microfences,
      assets: undefined,
      assetSrc,
      assetLyr,
      fenceEvents: undefined,
      geofenceEventSrc,
      geofenceEventLyr,
      locationTraceSrc,
      labelSrc,
      selectedAsset: undefined,
      setMapSource: setMapSource,
      setLocalBeacons,
      setSensedAssets,
    });

    olmap.getView().adjustZoom(-1);
    olmap.getView().adjustZoom(+1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveMapStaticData, setLocalBeacons, setSensedAssets, specifiedCoordinates]);

  // Do Event updates.
  useEffect(() => {
    if (!lastUpdates || !mapState) return;
    setTimeout(() => {
      doEventUpdates(mapState, lastUpdates, cid, pid, !!followingAssetIdString);
    }, 10);
  }, [mapState, lastUpdates, cid, pid, followingAssetIdString]);

  // Do Microfence updates.
  useEffect(() => {
    if (!mapState) return;
    const interval = setInterval(() => updateMicrofencesActivity(mapState, getNow()));
    return () => clearInterval(interval);
  }, [mapState, getNow]);

  // Set local beacons and sensed assets
  useEffect(() => {
    if (!mapState) return;

    const asset =
      selectedAsset && selectedAsset.label
        ? mapState.assets?.find(a => a.id === selectedAsset.label)
        : null;

    const idOfSelectedAsset = selectedAsset?.id; // assigning to a variable keeps typescript happy
    const microfence = idOfSelectedAsset
      ? mapState.microfences.find(
          m => stringifyIdRecord(m.data.assetId) === stringifyIdRecord(idOfSelectedAsset),
        )
      : undefined;

    if (microfence) {
      setSensedAssets(
        microfence.feature?.get(SENSED_ASSETS) ?? undefined,
        transform(microfence.data.point.coordinates, 'EPSG:4326', 'EPSG:3857'),
      );
    } else {
      setSensedAssets(undefined, [0, 0]);
    }

    updateAssetSelection(mapState, selectedAsset);
    setLocalBeacons(
      asset?.point.get(LOCAL_BEACONS) ?? undefined,
      asset?.point.getGeometry()?.getCoordinates() ?? [0, 0],
    );
  }, [selectedAsset, lastUpdates, mapState, setLocalBeacons, setSensedAssets]);

  // Unset welfare check from assets.
  useEffect(() => {
    if (!resetStyling || !mapState) return;
    mapState.assetSrc.getFeatures().map(f => {
      f.unset('wfCheck');
    });
  }, [mapState, resetStyling]);

  // Navigate to tools when pre-selected from tools tab.
  useEffect(() => {
    if (!locationStateFromTools || !mapState) return;
    setSelectedTool(locationStateFromTools.asset);
    setRange([
      new Date(locationStateFromTools.range[0]),
      new Date(locationStateFromTools.range[1]),
    ]);
    const coords = transform(
      [locationStateFromTools.coordinates[1], locationStateFromTools.coordinates[0]],
      'EPSG:4326',
      'EPSG:3857',
    );
    setUserExtent([...coords, ...coords]);
  }, [
    mapState,
    locationStateFromTools,
    setSelectedTool,
    setSelectedAsset,
    setRange,
    setUserExtent,
  ]);

  // Display purple location marker
  useEffect(() => {
    if (!mapState) return;
    if (!locationSearchData) {
      mapState.map?.setLayers(
        mapState.map?.getAllLayers().filter(layer => layer?.getClassName() !== 'pin-layer'),
      );
      return;
    }

    const { coords, address, isStreetAddress } = locationSearchData;
    animateToSearchedLocation(mapState.map?.getView(), coords, isStreetAddress);
    const pin = new Feature(new Point(coords));
    const displayedCoords = transform(coords, 'EPSG:3857', 'EPSG:4326');
    pin.set(
      'searchedCoordinates',
      parseFloat(displayedCoords[1].toFixed(5)) + ', ' + parseFloat(displayedCoords[0].toFixed(5)),
    );
    pin.set('searchedAddress', address);
    dropLocationMarker(mapState.map, pin);
  }, [locationSearchData, mapState, animateToSearchedLocation, selectedAsset, dropLocationMarker]);

  // Navigate to
  useEffect(() => {
    if (!navigateTo || !userExtent || !mapState || !mapState?.map) return;
    animateToFeature(userExtent, mapState?.map);
    if (!followingAssetIdString) {
      setNavigateTo(null);
    }
    if (selectedGeofence || selectedMicrofence || selectedAsset) return;
    const coordinates = [userExtent[0], userExtent[1]];
    const pin = new Feature(new Point(coordinates));
    const displayedCoords = transform(coordinates, 'EPSG:3857', 'EPSG:4326');
    pin.set(
      'searchedCoordinates',
      parseFloat(displayedCoords[1].toFixed(5)) + ', ' + parseFloat(displayedCoords[0].toFixed(5)),
    );
    pin.set('isLastKnownLocation', true);
    dropLocationMarker(mapState.map, pin);
  }, [
    selectedGeofence,
    selectedMicrofence,
    selectedAsset,
    navigateTo,
    animateToFeature,
    mapState?.map,
    mapState,
    setNavigateTo,
    userExtent,
    locationSearchData,
    dropLocationMarker,
    followingAssetIdString,
  ]);

  // Load more geofences
  useEffect(() => {
    if (!mapState || !liveMapStaticData) return;
    const loadedLayers = updateLayerOverlay(liveMapStaticData.layers);
    if (!loadedLayers) return;
    const oldLayers = mapState.map
      .getAllLayers()
      .filter(layer => layer instanceof VectorLayer && layer.getSource().get('id'));
    const newLayers: VectorLayer<VectorSource<Geometry>>[] = [];
    const zoom = mapState.map.getView().getZoom() || 0;

    oldLayers.forEach(layer => {
      const newLayer = loadedLayers?.find(
        loadedLayer => loadedLayer.id === layer.getSource().get('id'),
      );
      if (!newLayer) return;
      mapState.map.removeLayer(layer);
      newLayers.push(newLayer.source);
    });
    newLayers.forEach(layer => {
      mapState.map.addLayer(layer);
    });

    mapState.layers = mapState.map
      .getAllLayers()
      .filter(layer => layer instanceof VectorLayer && layer.get('id') !== MICROFENCE_LAYER_ID)
      .map(layer => {
        return {
          id: layer.getSource().get('id'),
          name: layer.getSource().get('name'),
          source: layer as VectorLayer<VectorSource<Geometry>>,
        };
      });
    if (selectedGeofence) {
      deselectFences(mapState?.map, selectedGeofence.id);
    }
    mapState?.map.getView().adjustZoom(-1);
    mapState?.map.getView().adjustZoom(+1);
  }, [liveMapStaticData, mapState, selectedGeofence]);

  // Deselect geofence/microfence remotely
  useEffect(() => {
    if (!deselectFence || !mapState) return;
    deselectFences(mapState.map);
    setDeselectFence(false);
    mapState.map.getView().adjustZoom(-1);
    mapState.map.getView().adjustZoom(+1);
  }),
    [deselectFence, mapState];

  // On select/deselect anything
  useEffect(() => {
    const anythingSelected =
      selectedAsset ||
      selectedGeofence ||
      selectedMicrofence ||
      selectedBeacon ||
      selectedDevice ||
      selectedTool ||
      selectedGPSTracker;

    if (JSON.stringify(anythingSelected?.id) !== followingAssetIdString) {
      setFollowingAssetIdString(undefined);
    }
    if (anythingSelected) {
      fenceViewingHeight.current = 0;
      if (selectedMicrofence) {
        deselectFences(mapState?.map, selectedMicrofence.id);
      }
      mapState?.map.getView().adjustZoom(-1);
      mapState?.map.getView().adjustZoom(+1);
      return;
    }
    mapState?.locationTraceSrc.clear();
    mapState?.labelSrc.clear();
    fenceViewingHeight.current = FENCE_VIEWING_HEIGHT;
    mapState?.map.getView().adjustZoom(-1);
    mapState?.map.getView().adjustZoom(+1);
  }, [
    selectedAsset,
    selectedGeofence,
    selectedMicrofence,
    selectedBeacon,
    selectedDevice,
    selectedTool,
    selectedGPSTracker,
    followingAssetIdString,
    mapState,
  ]);

  const changeBounds = (olmap: OlMap) => {
    const view = olmap?.getView();
    if (!view) return;
    const viewCenter = view.getCenter();
    if (!viewCenter) return;
    const center = transform(viewCenter, view.getProjection(), 'EPSG:4326');
    const zoom = view.getZoom() || 0;
    const res = view.getResolution() || 0;
    if (isNaN(center[0]) || isNaN(center[1])) return;
    onExtentChanged({
      latitude: center[1],
      longitude: center[0],
      extentInDegrees:
        (zoom <= initialZoomHeight
          ? initialExtentInDegrees
          : (res / Math.min(zoom, ZOOM_THRESHOLD)) * 0.1) ?? initialExtentInDegrees,
    });
  };

  const showPopupLabel = (coordinate: string | Coordinate | undefined, name: string) => {
    const formattedName = truncate(name.replace(/(.{50})/g, '$1\n'), {
      length: 1000,
      omission: '...',
    });
    popUpHoverRef.current.show(coordinate, defaultPopUpStyle(formattedName));
  };

  const fenceSharesCommonPoint = (fences: GeoJSONFeature[]): boolean => {
    let extentDifference = Number.MAX_SAFE_INTEGER;
    const firstExent = fences[0]?.getGeometry()?.getExtent();
    for (let f = 1; f < fences.length; f++) {
      const currentExtent = fences[f]?.getGeometry()?.getExtent();
      if (firstExent && currentExtent) {
        extentDifference = getExtentDifference(firstExent, currentExtent);
        if (extentDifference > 1) return false;
      }
    }
    return true;
  };

  const setMapClickSelection = (
    olmap: OlMap,
    layers: { id: string; name: string; source: VectorLayer<VectorSource<Geometry>> }[] | undefined,
    { excludeLayers }: { excludeLayers: VectorLayer<VectorSource<Geometry>>[] },
  ) => {
    const select: Select = new Select({
      condition: click,
      filter: (feature, layer) => {
        if (!(layer instanceof VectorLayer || layer instanceof AnimatedCluster)) return false;

        const excluded = excludeLayers?.includes(layer as VectorLayer<VectorSource<Geometry>>);
        return !excluded;
      },
      style: clickedGeoOrMicroFenceStyle(styleCache, { showActivity: true }),
    });

    select.on('select', async clickedEvent => {
      setSelectedGeofence(undefined);
      setSelectedMicrofence(undefined);
      if (assetSelectedRef.current) {
        assetSelectedRef.current = false;
        return;
      }

      clickedEvent.preventDefault();
      const features: GeoJSONFeature[] =
        olmap.getFeaturesAtPixel(clickedEvent.mapBrowserEvent.pixel) ?? [];
      if (!features || !features.length || features.length === 0) return;

      // Clicked overlapping features
      let smallestExtentArea = Number.MAX_SAFE_INTEGER;
      let smallestFeature: Feature<Geometry> = features[0];
      features.forEach(feature => {
        const extent = feature.getGeometry()?.getExtent();
        if (
          extent &&
          smallestExtentArea > Math.abs(extent[0] - extent[2]) * Math.abs(extent[1] - extent[2])
        ) {
          smallestExtentArea = Math.abs(extent[0] - extent[2]) * Math.abs(extent[1] - extent[2]);
          smallestFeature = feature;
        }
      });
      let feature = selectedMicrofence
        ? liveMapStaticData?.microfences.find(
            f => f.feature.get('fenceId') === selectedMicrofence.id,
          )?.feature
        : smallestFeature;

      const fenceId = feature?.get('id') ?? feature?.get('features')?.[0]?.get('id') ?? '';
      const layerId =
        feature?.get('layerId') ?? feature?.get('features')?.[0]?.get('layerId') ?? '';
      const featureIsPoint =
        feature?.get('type') === POINT ||
        (feature?.get('features')?.length === 1 &&
          feature?.get('features')[0].get('type') === POINT);
      if (mapState?.layers && featureIsPoint) {
        mapState?.layers.map(layer => {
          if (layer.id === layerId) {
            const foundFeature = layer.source
              .getSource()
              .getFeatures()
              .find((f: Feature<Geometry>) => f.get('id') === fenceId);
            if (foundFeature) {
              feature = foundFeature;
            }
          }
        });
      }

      // Clicked a cluster
      if (!feature?.get('geometry')?.getExtent()) return;
      if (feature?.get('features')?.length > 1) {
        const extent = [
          ...olmap.getCoordinateFromPixel(clickedEvent.mapBrowserEvent.pixel),
          ...olmap.getCoordinateFromPixel(clickedEvent.mapBrowserEvent.pixel),
        ];
        olmap.getView().animate(
          {
            center: extent,
            zoom: FENCE_VIEWING_HEIGHT,
            duration: 1000,
          },
          () => {
            debouncedOnMapMoved(olmap);
            setTimeout(() => {
              olmap.getView().adjustZoom(0.001);
            }, 1000);
          },
        );
        return;
      }

      // Clicked a geofence marker
      if (featureIsPoint && feature?.get('features')?.length === 1) {
        debouncedOnMapMoved(olmap);
        const type = feature?.get('features')[0].get('fenceType');
        if (type) {
          const foundFence = await getGeofence(fenceId, layerId, type);
          if (foundFence) {
            setSelectedGeofence({
              ...foundFence,
              layerId,
            });
            const newFence =
              type === FenceGeometryType.Polygon
                ? new Polygon(foundFence.points.coordinates)
                : type === FenceGeometryType.Multipolygon
                ? new MultiPolygon(foundFence.points.coordinates)
                : new LineString(foundFence.points.coordinates);
            const extent = transformExtent(newFence.getExtent(), 'EPSG:4326', 'EPSG:3857');
            setUserExtent(extent);
            return;
          }
        }
      }
      setSelectedFromMap(true);
      if (!layerId) return;

      if (layerId !== MICROFENCE_LAYER_ID) {
        const { id, name, layerId, geomobyProperties, geomobyOverrides, points, zone } =
          feature.getProperties();
        const type = geometryTypeOfEntity(feature.getProperties());
        setSelectedGeofence({
          id,
          name,
          type,
          layerId,
          geomobyProperties,
          geomobyOverrides,
          zone,
          selected: true,
        });
        setUserExtent(feature.get('geometry')?.getExtent());
        feature?.set('selected', true);
        olmap.getView().adjustZoom(-1);
        olmap.getView().adjustZoom(+1);
      } else {
        feature?.get('features')[0].set('selected', true);
        const microfenceAsset: SelectedAsset = {
          id: feature.get('assetId') ?? feature?.get('features')[0].get('assetId'),
          label: feature.get('name') ?? feature?.get('features')[0].get('name'),
          prefix: jsUcFirst(EntityType.Microfence),
          following: false,
        };
        setFollowingAssetIdString(undefined);
        setSelectedAsset(microfenceAsset);
        setSelectedMicrofence(feature?.get('features')[0].getProperties());
        setUserExtent(feature?.get('features')[0].get('geometry')?.getExtent());
      }
    });
    olmap.addInteraction(select);
  };

  if (resetStateRef)
    resetStateRef.current = () => {
      setSelectedAsset(undefined);
      setFollowingAssetIdString(undefined);
      if (mapState) {
        mapState.assets = undefined;
        mapState.assetSrc.clear();
        mapState.fenceEvents = undefined;
        mapState.geofenceEventSrc.clear();
        mapState.locationTraceSrc.clear();
        mapState.labelSrc.clear();
        mapState.microfences.forEach(({ feature }) => {
          feature.unset(SENSED_ASSETS);
          feature.unset(SENSED_EXITED_ASSETS_IDS);
        });
        mapState.assetLyr.getSource().clear();
        mapState.geofenceEventLyr.getSource().clear();
      }
    };

  return (
    <MapContainer id={mapType}>
      <MapToolbar>
        {fencesLoading && <LoadIndicator what="geofences" />}

        {selectedAsset && (
          <Follow
            following={!!followingAssetIdString}
            onClick={() => {
              selectedAsset.following = !selectedAsset.following;
              setFollowingAssetIdString(
                selectedAsset.following ? JSON.stringify(selectedAsset.id) : undefined,
              );
            }}
          />
        )}

        {mapState && (
          <ChangeMapSourceType
            mapSource={mapSource}
            setMapSource={source => {
              if (!mapState.setMapSource) return;
              setMapSource(source);
              mapState.setMapSource(source);
            }}
          />
        )}

        <ZoomIn onClick={() => mapState?.map?.getView().adjustZoom(0.5)} />

        <ZoomOut onClick={() => mapState?.map?.getView().adjustZoom(-0.5)} />
      </MapToolbar>
      {sensedAssets && <SensedAssetsPopover sensedAssets={sensedAssets} />}
      {localBeacons && <LocalBeaconsPopover localBeacons={localBeacons} />}
    </MapContainer>
  );
}
