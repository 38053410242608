import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  FC,
  MutableRefObject,
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { GeofenceFilter, SearchType, GeomobyOverride, FenceZoneType, NameId } from '../../../types';
import { Geometry } from 'ol/geom';
import { GridRowData } from '@material-ui/data-grid';
import { GeomobyProperties, PropertiesTable } from '../Geofence/GeomobyProperties';
import { GeomobyOverrides, OverridesTable } from '../Geofence/GeomobyOverrides';
import {
  BufferShapeType,
  EntityType,
  FenceGeometryType,
  FenceZone,
  GeofenceEntityTypeId,
  RequestType,
  SearchTypeIDs,
  SearchTypeValue,
} from '../../../../../util/enums';
import { geometryTypeOfEntity } from '../../../commons';
import { Delete, Upload, Edit, NearMe } from '@mui/icons-material';
import { FenceZoneTypes } from '../../../values';
import { useMobile } from '../../../../../util/useMobile';
import { debounce } from 'lodash';
import { useAtomValue } from 'jotai';
import { CID, PID, SUPER_ADMIN } from '../../../../../store/user';
import { TRIGGERS_URL } from '../../../../../store/url';
import { AUTHED_REQUEST_CONFIG } from '../../../../../store/auth';
import axios from 'axios';
import { Extent } from 'ol/extent';
import { LayersProps, SearchListProps, SelectedGeofenceProps } from '../../Props';
import {
  ALL_GROUPS,
  ALL_LAYERS,
  DEFAULT_BUFFER_METERS,
  FRESH,
  FRESH_LAYER,
  UNKNOWN_LAYER,
} from '../../../../../util/constants';
import { lineLimitStyle, PRIMARY, truncationStyle } from '../../../../../Style/GeoMobyBaseTheme';
import { getFreshLayerName } from '../../../Helpers';

export const useDisplayLayer = (props: LayersProps & SearchListProps) => {
  const isSuperAdmin = useAtomValue(SUPER_ADMIN);
  const showAllLayers = useMemo(
    () => props.selectedLayer?.id === ALL_LAYERS || props.selectedLayer?.id === UNKNOWN_LAYER,
    [props.selectedLayer?.id],
  );

  return (
    <>
      {!props.selectedGeofence &&
        !props.selectedMicrofence &&
        props.searchType?.id === SearchTypeIDs.Geofences &&
        props.selectedLayer &&
        !props.createEditLayer && (
          <Paper
            variant="outlined"
            style={{
              padding: '10px',
              height: 'fit-content',
            }}
          >
            <Grid
              container
              direction="row"
              style={{
                width: '100%',
                marginBottom: showAllLayers ? '10px' : '0px',
                marginTop: showAllLayers ? '10px' : '0px',
              }}
            >
              {!showAllLayers && (
                <Tooltip title={'Group'}>
                  <Typography style={{ color: PRIMARY, marginInlineEnd: 'auto' }}>Group</Typography>
                </Tooltip>
              )}

              {isSuperAdmin && !showAllLayers && (
                <Tooltip title={'UPLOAD'}>
                  <span>
                    <IconButton
                      id="upload-geojson"
                      color="primary"
                      style={{
                        justifySelf: 'end',
                      }}
                      onClick={() => props.setUploadLayerJson(true)}
                    >
                      <Upload />
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              {!showAllLayers && (
                <Tooltip title={RequestType.Edit}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      props.setLayerNameInput(props.selectedLayer?.name ?? '');
                      if (props.selectedLayer?.id === FRESH_LAYER) {
                        props.setCreateEditLayer(RequestType.Create);
                        props.setLayersHaveChanged(true);
                      } else {
                        props.setCreateEditLayer(RequestType.Edit);
                      }
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              )}

              {!showAllLayers && props.selectedLayer.id !== FRESH_LAYER && (
                <Tooltip title={RequestType.Delete}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      props.setDeleting(EntityType.Layer);
                      props.setLayerIds(
                        props.layerIds.filter(lyr => lyr.id !== props.selectedLayer?.id),
                      );
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>

            <Grid
              container
              direction="row"
              spacing={1}
              marginLeft={0}
              justifyContent="left"
              alignItems="center"
            >
              <Tooltip
                title={props.selectedLayer.name}
                style={{ ...lineLimitStyle, fontWeight: showAllLayers ? 'bolder' : 'normal' }}
              >
                <Typography variant="h5">
                  {showAllLayers ? ALL_GROUPS : props.selectedLayer.name}
                </Typography>
              </Tooltip>
            </Grid>
          </Paper>
        )}
    </>
  );
};

export const useEditLayer = (props: LayersProps & SearchListProps) => {
  const updateLayer = () => {
    if (
      props.layerIds.find(
        lyr => lyr.name === props.layerNameInput && lyr.id !== props.selectedLayer?.id,
      ) ||
      (!props.selectedLayer && props.layerIds.find(lyr => lyr.name === props.layerNameInput))
    ) {
      props.setLayerConflict(true);
      return;
    }
    const olmap = props.mapState?.map;
    if (!olmap) return;

    // Existing Layer
    if (props.selectedLayer?.id !== FRESH_LAYER && props.selectedLayer?.id) {
      props.setSelectedLayer({ name: props.layerNameInput, id: props.selectedLayer?.id });
      props.setRenamingLayer(props.layerNameInput);
    } else {
      // Updating a new one
      if (props.selectedLayer?.id === FRESH_LAYER) {
        props.changeVisibility(olmap, String(props.selectedLayer?.id), true);
        props.setSelectedLayer({ name: props.layerNameInput, id: props.selectedLayer?.id });
      }
    }
    props.setLayersHaveChanged(true);
  };
  return (
    <>
      {/* Add/Edit layer */}
      {!props.selectedGeofence &&
        !props.selectedMicrofence &&
        props.searchType?.id === SearchTypeIDs.Geofences &&
        props.selectedLayer &&
        !!props.createEditLayer && (
          <Paper
            variant="outlined"
            style={{
              padding: '10px',
              height: 'fit-content',
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                '& .MuiTypography-root': {
                  marginLeft: '0px',
                  width: '22vw',
                  ...truncationStyle,
                },
                '& .MuiStack-root': {
                  width: '22vw',
                  justifyContent: 'start',
                },
                marginTop: '10px',
              }}
            >
              <FormControl
                style={{
                  width: '100%',
                  marginBottom: '15px',
                }}
              >
                <InputLabel id="rename">Name</InputLabel>
                <OutlinedInput
                  id="rename"
                  label="Name"
                  key={'rename'}
                  name={'rename'}
                  inputRef={props.layerNameRef}
                  placeholder="Group 1"
                  value={props.layerNameInput}
                  onChange={({ target: { value } }) => {
                    if (!props.selectedLayer) return;
                    props.selectedLayer.name = value;
                    props.setLayerNameInput(value.replaceAll(' ', '_'));
                    props.setLayersHaveChanged(true);
                  }}
                  onBlur={async ({ target: { value } }) => {
                    updateLayer();
                  }}
                />
              </FormControl>
            </Grid>
          </Paper>
        )}
    </>
  );
};

export const SelectedLayer = (props: LayersProps & SearchListProps) => {
  useEffect(() => {
    props.setLayerNameInput(props.selectedLayer?.name ?? '');
  }, [props]);

  return (
    <>
      {!props.selectedGeofence &&
        !props.selectedMicrofence &&
        props.searchType?.id === SearchTypeIDs.Geofences &&
        !props.selectedLayer && (
          <Grid>
            <Button
              variant="outlined"
              fullWidth
              disabled={props.isLoading || props.layersHaveChanged || !!props.createEditLayer}
              style={{
                marginBottom: '20px',
              }}
              onClick={() => {
                const olmap = props.mapState?.map;
                if (!olmap) return;
                const newName = getFreshLayerName(props.layerIds);
                props.createNewLayer(olmap, FRESH_LAYER, newName);
                props.setLayerNameInput(newName);
                props.layerIds
                  ?.filter(layer => layer.id !== FRESH_LAYER)
                  .forEach(layer => props.changeVisibility(olmap, layer.id, false));
                props.changeVisibility(olmap, FRESH_LAYER, true);
                props.setCount(0);
                props.setAvailableGeofences([]);
              }}
            >
              Create A Group
            </Button>
          </Grid>
        )}
      <Grid>
        {useDisplayLayer({ ...props })}
        {useEditLayer({ ...props })}
      </Grid>
    </>
  );
};
