import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import { Dispatch, SetStateAction } from 'react';
import { EntityType, GeomobyPropertiesValues } from '../../../util/enums';
import { jsUcFirst } from '../../Global/StringFormatterFunctions';
import { GeomobyOverride } from '../types';
import { Property } from '../../ProjectConfig/types';
import { PropertyType } from '../../ProjectConfig/enums';

export const featureHasUndefinedName = (
  features: Feature<Geometry>[],
  type: EntityType.Geofence | EntityType.Microfence,
  setDirtySave: Dispatch<
    SetStateAction<{
      isDirty: boolean;
      issue: string | null;
    }>
  >,
): Feature<Geometry> | undefined => {
  const hasUndefinedName = features.find(f => !f.get('name'));
  if (hasUndefinedName !== undefined) {
    setDirtySave({
      isDirty: !!hasUndefinedName,
      issue: hasUndefinedName ? `${jsUcFirst(type)} name is required` : null,
    });
  }
  return hasUndefinedName;
};

export const featureHasDuplicateName = (
  features: Feature<Geometry>[],
  type: EntityType.Geofence | EntityType.Microfence,
  setDirtySave: Dispatch<
    SetStateAction<{
      isDirty: boolean;
      issue: string | null;
    }>
  >,
): Feature<Geometry> | undefined => {
  const hasDuplicateName = features.find(feature =>
    features.find(
      f =>
        feature.get('name') === f.get('name') &&
        feature.get('id') !== f.get('id') &&
        (f.get('fresh') || f.get('updated')),
    ),
  );
  if (hasDuplicateName !== undefined) {
    setDirtySave({
      isDirty: !!hasDuplicateName,
      issue: hasDuplicateName ? `${jsUcFirst(type)} name must be unique.` : null,
    });
  }
  return hasDuplicateName;
};

export const featureHasEmptyGeomobyPropertyValue = (
  features: Feature<Geometry>[],
  setDirtySave: Dispatch<
    SetStateAction<{
      isDirty: boolean;
      issue: string | null;
    }>
  >,
): Feature<Geometry> | undefined => {
  const hasEmptyGeomobyPropertyName = features.find(
    f =>
      JSON.stringify(Object.entries(f.get('geomobyProperties')))?.includes('["",') ||
      JSON.stringify(Object.entries(f.get('geomobyProperties')))?.includes(',""'),
  );

  if (hasEmptyGeomobyPropertyName !== undefined) {
    setDirtySave({
      isDirty: !!hasEmptyGeomobyPropertyName,
      issue: hasEmptyGeomobyPropertyName ? 'Geofence Property values must not be left empty' : null,
    });
  }
  return hasEmptyGeomobyPropertyName;
};

export const featureHasIncompleteOverride = (
  features: Feature<Geometry>[],
  setDirtySave: Dispatch<
    SetStateAction<{
      isDirty: boolean;
      issue: string | null;
    }>
  >,
): Feature<Geometry> | undefined => {
  return features.find(f =>
    f.get('geomobyOverrides')?.find((override: GeomobyOverride) => {
      if (!override.property || !override.value || override.deviceIds?.length === 0) {
        setDirtySave({
          isDirty: true,
          issue: `All Geomoby Overrides ${
            override.deviceIds?.length === 0 ? 'require device IDs.' : 'fields must be complete.'
          }`,
        });
        return override;
      }
    }),
  );
};

export const featureHasDuplicateOverride = (
  features: Feature<Geometry>[],
  setDirtySave: Dispatch<SetStateAction<{ isDirty: boolean; issue: string | null }>>,
): Feature<Geometry> | undefined => {
  return features.find(f => {
    const propertyValueSet = new Set<string>();
    const propertyDeviceMap = new Map<string, Set<string>>();
    const uniqueOverrides = f.get('geomobyOverrides')?.filter((override: GeomobyOverride) => {
      const propertyValueKey = JSON.stringify({
        property: override.property,
        value: override.value,
      });

      if (propertyValueSet.has(propertyValueKey)) return false;

      propertyValueSet.add(propertyValueKey);
      const existingIds = propertyDeviceMap.get(override.property) || new Set<string>();

      for (const id of override.deviceIds) {
        if (existingIds.has(id)) return false;
      }

      override.deviceIds.forEach(deviceId => existingIds.add(deviceId));
      propertyDeviceMap.set(override.property, existingIds);
      return true;
    });

    if (uniqueOverrides.length < f.get('geomobyOverrides')?.length) {
      setDirtySave({
        isDirty: true,
        issue: 'Geomoby Overrides must be unique.',
      });
      return f;
    }
    return undefined;
  });
};

const propertyIsInvalid = (property: string, value: string, prop: Property): boolean => {
  const lowerLimit = prop.limit?.[0];
  const upperLimit = prop.limit?.[1];
  if (!lowerLimit || !upperLimit) {
    return prop.type !== PropertyType.List.toLowerCase();
  }
  if (prop.label === property) {
    // Outside of number limit.
    if (
      prop.type === PropertyType.Number.toLowerCase() &&
      (Number(value) < lowerLimit || Number(value) > upperLimit)
    ) {
      return true;
    }
    // Outside of string limit.
    else if (
      prop.type === PropertyType.String.toLowerCase() &&
      (String(value).length < lowerLimit || String(value).length > upperLimit)
    ) {
      return true;
    }
    // Fails regex test.
    else if (!!prop.pattern && !new RegExp(prop.pattern).test(value)) {
      return true;
    }
  }
  return false;
};

export const featureHasInvalidPropertyOrOverride = (
  features: Feature<Geometry>[],
  properties: Property[],
  setDirtySave: Dispatch<SetStateAction<{ isDirty: boolean; issue: string | null }>>,
): Feature<Geometry> | undefined => {
  const featureWithInvalidGeomobyProperty = features
    .filter(feature => feature.get('geomobyProperties'))
    .find(feature => {
      return Object.entries(feature.get('geomobyProperties')).find(([property, value]) =>
        properties.find(prop => {
          const foundInvalidProperty = propertyIsInvalid(property, value as string, prop);
          if (foundInvalidProperty) {
            setDirtySave({
              isDirty: true,
              issue: prop.message,
            });
          }
          return foundInvalidProperty;
        }),
      );
    });
  const featureWithInvalidGeomobyOverride = features
    .filter(feature => feature.get('geomobyOverrides'))
    .find(feature => {
      return feature.get('geomobyOverrides').find((override: GeomobyOverride) =>
        properties.find(prop => {
          const foundInvalidOverride = propertyIsInvalid(
            override.property,
            override.value as string,
            prop,
          );
          if (foundInvalidOverride) {
            setDirtySave({
              isDirty: true,
              issue: prop.message,
            });
          }
          return foundInvalidOverride;
        }),
      );
    });

  return featureWithInvalidGeomobyProperty ?? featureWithInvalidGeomobyOverride;
};
