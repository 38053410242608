import {
  Checkbox,
  Paper,
  SortDirection,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { truncationStyle } from '../../../Style/GeoMobyBaseTheme';
import { jsUcFirst } from '../../Global/StringFormatterFunctions';
import { PropertiesColumns, PropertyType } from '../enums';
import { PropertiesFilter, PropertiesSort, Property } from '../types';

export const applyFilter = (
  properties: Property[] | undefined,
  filter?: PropertiesFilter,
  sort?: PropertiesSort,
): Property[] => {
  if (!properties) return [];
  let filteredProperties: Property[] = properties;
  if (filter?.label) {
    filteredProperties = filteredProperties.filter(property =>
      property.label.includes(filter?.label ?? ''),
    );
  }

  if (sort) {
    const column = sort.sortBy === PropertiesColumns.Label ? 'label' : 'message';
    return filteredProperties.sort((a, b) => {
      return sort.order === 'DESC'
        ? b[column].localeCompare(a[column])
        : a[column].localeCompare(b[column]);
    });
  }
  return filteredProperties.sort((a, b) => a.label.localeCompare(b.label));
};

export const PropertiesTable = ({
  availableProperties,
  setAvailableProperties,
  selectedProperty,
  setSelectedProperty,
  setUpdatedItems,
  propertiesFilter,
  propertiesSort,
  setPropertiesSort,
  propertiesColumns,
}: {
  availableProperties: Property[];
  setAvailableProperties: Dispatch<SetStateAction<Property[]>>;
  selectedProperty: Property | undefined;
  setSelectedProperty: Dispatch<SetStateAction<Property | undefined>>;
  setUpdatedItems: Dispatch<SetStateAction<{ name: string; checked?: boolean; fresh?: boolean }[]>>;
  propertiesFilter: PropertiesFilter | undefined;
  propertiesSort: PropertiesSort | undefined;
  setPropertiesSort: Dispatch<SetStateAction<PropertiesSort | undefined>>;
  propertiesColumns: { id: string; value: string; checked: boolean; index: number }[];
}) => {
  const [expandRow, setExpandRow] = useState<string | undefined>();
  return (
    <TableContainer
      component={Paper}
      style={{
        maxHeight: '1000px',
      }}
    >
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow
            key={'header-row'}
            style={{
              height: '55px',
            }}
          >
            {propertiesColumns
              .filter(column => column.checked)
              .map(column => {
                return column.id === PropertiesColumns.Selected ? (
                  <TableCell
                    key={PropertiesColumns.Selected}
                    style={{ fontSize: '15px' }}
                    align="left"
                  ></TableCell>
                ) : (
                  <TableCell
                    key={column.id}
                    style={{ fontSize: '15px' }}
                    sortDirection={
                      propertiesSort?.sortBy === column.id
                        ? (propertiesSort?.order?.toLowerCase() as SortDirection)
                        : undefined
                    }
                  >
                    <TableSortLabel
                      active={true}
                      direction={
                        propertiesSort?.sortBy === column.id
                          ? ((propertiesSort?.order ?? 'asc').toLowerCase() as 'asc' | 'desc')
                          : 'asc'
                      }
                      onClick={e => {
                        setPropertiesSort({
                          sortBy: column.id as PropertiesColumns,
                          order: propertiesSort?.order === 'ASC' ? 'DESC' : 'ASC',
                        });
                      }}
                    >
                      {column.value}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {applyFilter(availableProperties, propertiesFilter, propertiesSort).map(property => {
            return (
              <TableRow
                key={property.id}
                sx={{
                  height: '55px',
                  fontSize: '15px',
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
                onClick={() => {
                  setExpandRow(expandRow === property.label ? undefined : property.label);
                }}
              >
                <TableCell
                  key={`${property.id}-checkox-${property.selected}`}
                  style={{
                    width: '10px',
                    fontSize: '15px',
                  }}
                >
                  <Checkbox
                    checked={property.selected}
                    onChange={e => {
                      const oneSelected =
                        (!property.selected &&
                          availableProperties.filter(p => p.selected)?.length === 0) ||
                        (property.selected &&
                          availableProperties.filter(p => p.selected)?.length === 2);
                      if (oneSelected) {
                        setSelectedProperty({
                          ...property,
                          type: jsUcFirst(property.type) as PropertyType,
                        });
                        setUpdatedItems(
                          property?.items?.map(i => {
                            return {
                              name: i,
                              fresh: false,
                              checked: false,
                            };
                          }) ?? [],
                        );
                      } else if (!oneSelected) {
                        setSelectedProperty(undefined);
                        setUpdatedItems([]);
                      }
                      setAvailableProperties([
                        { ...property, selected: e.target.checked },
                        ...availableProperties.filter(p => p.id !== property.id),
                      ]);
                    }}
                  />
                </TableCell>

                {propertiesColumns.find(
                  column => column.id === PropertiesColumns.Label && !!column.checked,
                ) && (
                  <Tooltip title={property.label}>
                    <TableCell
                      style={{
                        ...(expandRow === property.label
                          ? {
                              overflowWrap: 'break-word',
                            }
                          : truncationStyle),
                        maxWidth: '50px',
                      }}
                    >
                      {property.label}
                    </TableCell>
                  </Tooltip>
                )}
                {propertiesColumns.find(
                  column => column.id === PropertiesColumns.Message && !!column.checked,
                ) && (
                  <Tooltip title={property.message}>
                    <TableCell style={{ maxWidth: '100px' }}>{property.message}</TableCell>
                  </Tooltip>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
