import { SearchTypeIDs } from '../../../../../util/enums';
import { BeaconFilterComponent } from '../../../Filter/BeaconFilterComponent';
import { DeviceFilterComponent } from '../../../Filter/DeviceFilterComponent';
import { GeofenceFilterComponent } from '../../../Filter/GeofenceFilterComponent';
import { MicrofenceFilterComponent } from '../../../Filter/MicrofenceFilterComponent';
import { ToolFilterComponent } from '../../../Filter/ToolFilterComponent';
import { TrackerFilterComponent } from '../../../Filter/TrackerFilterComponent';
import { PortableAssetTool, SearchType } from '../../../types';
import { SearchListProps } from '../../Props';

export const FilterComponent = (
  props: SearchListProps & { searchType: SearchType | undefined; knownTools: PortableAssetTool[] },
) => {
  // Work around for "Rendered more hooks than during the previous render".
  // My intention was to return just what was needed but that will need to wait
  // for tech debt to be resolved -- specially, using a Provider for storing
  // shared Map+Sidebar state (similar to how Underground uses Providers),
  // instead of passing dozens of `props` around, including state setters).\
  // Specifically, this component was meant to look like:
  // ```
  // return (
  //   <>
  //     {props.searchType?.id === SearchTypeIDs.Beacons && BeaconFilterComponent(props)}
  //     {props.searchType?.id === SearchTypeIDs.GPSTrackers && TrackerFilterComponent(props)}
  //     {props.searchType?.id === SearchTypeIDs.Devices && DeviceFilterComponent(props)}
  //     {props.searchType?.id === SearchTypeIDs.Geofences && GeofenceFilterComponent(props)}
  //     {props.searchType?.id === SearchTypeIDs.Microfences && MicrofenceFilterComponent(props)}
  //     {props.searchType?.id === SearchTypeIDs.Tools && ToolFilterComponent(props)}
  //   </>
  // );
  // ```

  // const filters: Record<SearchTypeIDs,JSX.Element> = {
  //   [SearchTypeIDs.Beacons]: BeaconFilterComponent(props),
  //   [SearchTypeIDs.Devices]: DeviceFilterComponent(props),
  //   [SearchTypeIDs.GPSTrackers]: TrackerFilterComponent(props),
  //   [SearchTypeIDs.Geofences]: GeofenceFilterComponent(props),
  //   [SearchTypeIDs.Microfences]: MicrofenceFilterComponent(props),
  //   [SearchTypeIDs.Tools]: ToolFilterComponent(props),
  //   [SearchTypeIDs.ActiveAssets]: <></>,
  //   [SearchTypeIDs.Locations]: <></>,
  // };

  // return props.searchType?.id ? filters[props.searchType?.id] : <></>;
  return (
    <>
      <div style={{ display: props.searchType?.id !== SearchTypeIDs.Beacons ? 'none' : undefined }}>
        {BeaconFilterComponent(props)}
      </div>
      <div
        style={{ display: props.searchType?.id !== SearchTypeIDs.GPSTrackers ? 'none' : undefined }}
      >
        {TrackerFilterComponent(props)}
      </div>
      <div style={{ display: props.searchType?.id !== SearchTypeIDs.Devices ? 'none' : undefined }}>
        {DeviceFilterComponent(props)}
      </div>
      <div
        style={{ display: props.searchType?.id !== SearchTypeIDs.Geofences ? 'none' : undefined }}
      >
        {GeofenceFilterComponent(props)}
      </div>
      <div
        style={{ display: props.searchType?.id !== SearchTypeIDs.Microfences ? 'none' : undefined }}
      >
        {MicrofenceFilterComponent(props)}
      </div>
      <div style={{ display: props.searchType?.id !== SearchTypeIDs.Tools ? 'none' : undefined }}>
        {ToolFilterComponent(props)}
      </div>
    </>
  );
};
