import { LocationOn, Sensors } from '@mui/icons-material';
import { SubHeader } from '../../../../Common/Sidebar';
import { Box, Grid, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { AssetState, MicrofenceData, SensedByDevice } from '../../Messages';
import { useAtomValue } from 'jotai';
import { KNOWN_TOOLS } from '../../../../store/tools';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Asset, PortableAssetTool, SelectedAsset } from '../../types';
import { GridRowData } from '@material-ui/data-grid';
import { StrongFeatureHolder } from '../../../../hooks/geomoby/useLiveMapLoader';
import { Point } from 'ol/geom';
import { AssetEntity, EntityType } from '../../../../util/enums';
import { truncationStyle } from '../../../../Style/GeoMobyBaseTheme';
import { jsUcFirst } from '../../../Global/StringFormatterFunctions';
import { Extent } from 'ol/extent';
import { transform } from 'ol/proj';

export const BeaconLatestLocation = ({
  sensedBeacon,
  microfences,
  activeAssets,
  setSelectedDevice,
  setSelectedGPSTracker,
  setSelectedTool,
  setSelectedBeacon,
  setSelectedMicrofence,
  setSelectedAsset,
  userExtent,
  setUserExtent,
  now,
}: {
  sensedBeacon: SensedByDevice | undefined;
  microfences: StrongFeatureHolder<Point, MicrofenceData>[];
  activeAssets: SelectedAsset[];
  setSelectedDevice: Dispatch<Asset | undefined>;
  setSelectedGPSTracker: Dispatch<Asset | undefined>;
  setSelectedMicrofence: Dispatch<GridRowData | undefined>;
  setSelectedTool: Dispatch<PortableAssetTool | undefined>;
  setSelectedBeacon: Dispatch<PortableAssetTool | undefined>;
  setSelectedAsset: Dispatch<SelectedAsset | undefined>;
  userExtent: Extent | undefined;
  setUserExtent: (extent: Extent | undefined) => void;
  now: () => Date;
}) => {
  const activeDevice = activeAssets.find(
    active =>
      active.prefix !== jsUcFirst(EntityType.Microfence) &&
      JSON.stringify(active.id) === JSON.stringify(sensedBeacon?.source?.id),
  );
  const activeMicrofence = activeAssets.find(
    active =>
      active.prefix === jsUcFirst(EntityType.Microfence) &&
      JSON.stringify(active.id) === JSON.stringify(sensedBeacon?.source?.id),
  );
  const foundMicrofence = microfences.find(
    microfence => JSON.stringify(microfence.data.id) === JSON.stringify(activeMicrofence?.id),
  );
  const { lat: sourceLat, lon: sourceLon } =
    (foundMicrofence?.feature.get('point')
      ? {
          lon: foundMicrofence.feature.get('point').coordinates[0],
          lat: foundMicrofence.feature.get('point')?.coordinates[1],
        }
      : sensedBeacon?.source?.coordinates) ?? {};
  const userExtentLat = userExtent?.[1];
  const userExtentLon = userExtent?.[0];

  useEffect(() => {
    if (sourceLat === undefined || sourceLon === undefined) return;
    const coords = transform([sourceLon, sourceLat], 'EPSG:4326', 'EPSG:3857');
    if (userExtentLat !== coords[0] && userExtentLon !== coords[1]) {
      setUserExtent([...coords, ...coords]);
    }
  }, [sourceLat, sourceLon, userExtentLat, userExtentLon, setUserExtent]);

  const getLastLocationOfSensor = (
    coordinates: { lat: number; lon: number },
    timestamp: string,
  ) => {
    return (
      <Paper
        style={{
          marginTop: '10px',
        }}
      >
        <Box p={2} overflow="auto">
          <SubHeader icon={<LocationOn />} from={now().toISOString()} to={timestamp}>
            Last Known Location
          </SubHeader>
          <div style={{ marginLeft: '1rem' }}>
            <Tooltip title={coordinates.lat.toFixed(5)}>
              <pre style={truncationStyle}>lat: {coordinates.lat.toFixed(5)},</pre>
            </Tooltip>
            <Tooltip title={coordinates.lon.toFixed(5).toString()}>
              <pre style={truncationStyle}>lon: {coordinates.lon.toFixed(5)}</pre>
            </Tooltip>
            <Tooltip title={new Date(timestamp).toLocaleString()}>
              <pre style={truncationStyle}>at: {new Date(timestamp).toLocaleString()}</pre>
            </Tooltip>
          </div>

          {!((foundMicrofence || activeDevice) && sensedBeacon) && <Typography>UNKNOWN</Typography>}
        </Box>
      </Paper>
    );
  };

  return (
    <Stack>
      {sensedBeacon && (
        <Paper>
          <Box p={2} overflow="auto">
            <SubHeader icon={<Sensors />} from={now().toISOString()} to={sensedBeacon?.timestamp}>
              Sensed By
            </SubHeader>
            <div style={{ marginLeft: '1rem' }}>
              <Grid
                container
                direction="row"
                style={{
                  display: 'grid',
                  gridTemplateColumns: '90% 10%',
                  width: '100%',
                }}
              >
                <Tooltip title={sensedBeacon.source?.label}>
                  <pre style={truncationStyle}>
                    {foundMicrofence
                      ? foundMicrofence.feature.get('type')
                      : sensedBeacon.source?.id?.gpsTrackerId
                      ? 'tracker'
                      : 'device'}
                    : {sensedBeacon.source?.label}
                  </pre>
                </Tooltip>

                <Grid
                  item
                  style={{
                    marginTop: '-7px',
                    width: 'fit-content',
                  }}
                >
                  <Tooltip title={'SENSED BY'}>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        if (foundMicrofence) {
                          setSelectedMicrofence(foundMicrofence.feature.getProperties());
                          setSelectedAsset(activeMicrofence);
                        } else {
                          if (sensedBeacon.source?.id?.gpsTrackerId) {
                            setSelectedGPSTracker({
                              id: sensedBeacon.source?.id?.gpsTrackerId as string,
                              label: sensedBeacon.source?.label ?? '',
                              type: AssetEntity.GPSTracker,
                            });
                          } else {
                            setSelectedDevice({
                              id: sensedBeacon.source?.id?.deviceId as string,
                              label: sensedBeacon.source?.label ?? '',
                              type: AssetEntity.Device,
                            });
                          }
                          setSelectedAsset(activeDevice);
                        }
                        setSelectedTool(undefined);
                        setSelectedBeacon(undefined);
                      }}
                    >
                      <Sensors />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Paper>
      )}

      {sensedBeacon?.source?.coordinates &&
        getLastLocationOfSensor(sensedBeacon.source.coordinates, sensedBeacon.timestamp)}
      {foundMicrofence?.feature.get('point') &&
        sensedBeacon &&
        getLastLocationOfSensor(
          {
            lon: foundMicrofence.feature.get('point').coordinates[0],
            lat: foundMicrofence.feature.get('point')?.coordinates[1],
          },
          sensedBeacon.timestamp,
        )}
    </Stack>
  );
};
