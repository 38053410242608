import axios, { AxiosError, AxiosResponse } from 'axios';
import { Collection, Feature, Overlay, View } from 'ol';
import { always, click, never, platformModifierKeyOnly, primaryAction } from 'ol/events/condition';
import Circle from 'ol/geom/Circle';
import Geometry from 'ol/geom/Geometry';
import GeometryType from 'ol/geom/GeometryType';
import Polygon, { fromCircle } from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { Draw, Interaction, Modify, Select, Snap, Translate } from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import olMap from 'ol/Map';
import 'ol/ol.css';
import { Cluster, Vector as VectorSource } from 'ol/source';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { useCallback, useEffect, useRef, useState } from 'react';
import { layerToJson, gpsToMap } from '../LiveAndReplay/TypeConversions';
import { fitToExtent, getCoordinateDifference } from '../../../hooks/geomoby/MapAnimation';
import {
  tripwiresStyle,
  interactionStyle,
  updateGeofencesStyle,
  getGeofenceStyleFromZone,
  clearedFenceDrawStartStyle,
  trackingFenceDrawStartStyle,
} from '../Styles/GeofenceStyles';
import { getMicrofencesVectorLayer, getVectorLayer, getVectorLayers } from '../../../API/layers';
import { MapContainer } from '../MapContainer/MapContainer';
import { SidebarAndMap } from '../SidebarAndMap/SidebarAndMap';
import { ZoomIn } from '../Toolbar/ZoomTools/ZoomIn';
import { ZoomOut } from '../Toolbar/ZoomTools/ZoomOut';
import { MapToolbar } from '../Toolbar/MapToolbar';
import { ChangeMapSourceType } from '../Toolbar/LayerTools/ChangeMapSourceType';
import { useAtomValue, useSetAtom } from 'jotai';
import { CID, PID } from '../../../store/user';
import { ACCESS_JWT_TOKEN, AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { TRIGGERS_URL } from '../../../store/url';
import GeoJSON, { GeoJSONFeature } from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point';
import { containsExtent, Extent, getCenter } from 'ol/extent';
import { StyleFunction } from 'ol/style/Style';
import RenderFeature from 'ol/render/Feature';
import CircleStyle from 'ol/style/Circle';
import { transform, transformExtent } from 'ol/proj';
import { debounce } from 'lodash';
import LineString from 'ol/geom/LineString';
import BaseVectorLayer from 'ol/layer/BaseVector';
import CanvasVectorLayerRenderer from 'ol/renderer/canvas/VectorLayer';
import CanvasVectorTileLayerRenderer from 'ol/renderer/canvas/VectorTileLayer';
import CanvasVectorImageLayerRenderer from 'ol/renderer/canvas/VectorImageLayer';
import WebGLPointsLayerRenderer from 'ol/renderer/webgl/PointsLayer';
import { ModifyEvent } from 'ol/interaction/Modify';
import { SaveResult, SAVE_NOTIFICATION } from '../../../store/notifications';
import { LoadIndicator } from '../Toolbar/LayerTools/LoadIndicator';
import { getLength } from 'ol/sphere';
import { Button, Dialog, DialogActions, DialogTitle, Grid } from '@mui/material';
import { ToolPanel } from '../ControlPanels/ToolPanel';
import { MAP_API_KEYS } from '../../../store/map';
import { LocationDisplayType, LocationSearch, LocationSearchData } from '../Toolbar/LocationSearch';
import { SearchList } from './Sidebar/Search/SearchList';
import { GridRowData } from '@material-ui/data-grid';
import {
  AssetFilter,
  Bounds,
  FenceNameIdZone,
  GeofenceFilter,
  GeomobyOverride,
  MapSourceType,
  MicrofenceAssetId,
  MicrofenceFilter,
  NameId,
  ReassignedFence,
  SearchType,
} from '../types';
import { FilterComponent } from './Sidebar/Search/FilterComponent';
import { normaliseErrorMessage } from '../../../util/ErrorMessages';
import {
  BufferShapeType,
  DrawType,
  EditType,
  EntityType,
  FenceGeometryType,
  FenceZone,
  GeofenceEntityTypeId,
  GeomobyPropertiesValues,
  Interactions,
  MapType,
  MeasurementType,
  MicrofenceEntity,
  MicrofenceZone,
  RequestType,
  SearchTypeIDs,
  SearchTypeValue,
} from '../../../util/enums';
import { geometryTypeOfEntity } from '../commons';
import {
  calculateArea,
  calculateCenterOfFence,
  calculateMeasurementDistance,
  defaultScaleRotFenceStyle,
  featureContainsFeature,
} from './Draw';
import {
  featureHasDuplicateName,
  featureHasDuplicateOverride,
  featureHasEmptyGeomobyPropertyValue,
  featureHasIncompleteOverride,
  featureHasInvalidPropertyOrOverride,
  featureHasUndefinedName,
} from './Validator';
import { jsUcFirst } from '../../Global/StringFormatterFunctions';
import {
  ALL_LAYERS,
  FRESH,
  UNKNOWN_LAYER,
  initialExtentInDegrees,
  initialLatitude,
  initialLongitude,
  initialZoomHeight,
  TRACKING_BOUNDS,
  FRESH_LAYER,
  CLUSTER_MAX_ZOOM,
  ZOOM_THRESHOLD,
  MICROFENCE_LAYER_ID,
  MICROFENCE_DEFAULT_PROPS,
  MICROFENCE_LAYER_LABEL,
  MICROFENCE_PROP_LABELS,
} from '../../../util/constants';
import { deselectFences, dropPin } from '../Helpers';
import { CLEARED_ZONE, WHITE } from '../../../Style/GeoMobyBaseTheme';
import { EditState, MapState } from './Props';
import { ObjectEvent } from 'ol/Object';
import { createMap, createMapDefaults } from '../InitMap';
import {
  defaultDropPinStyle,
  measurementToolOff,
  measurementToolPonter,
} from '../Styles/MiscStyles';
import API from '../../../API/api';

type AnyRenderer =
  | CanvasVectorLayerRenderer
  | CanvasVectorTileLayerRenderer
  | CanvasVectorImageLayerRenderer
  | WebGLPointsLayerRenderer;
type HitDetectionLayer = BaseVectorLayer<VectorSource<Geometry>, AnyRenderer>;

const styleCache = new Map<string, Style[]>();

/* Editor Map */
export const EditorMap = () => {
  const MAX_NUMBER_OF_POLYGONS = 10000;

  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedRequestConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const accessJwtToken = useAtomValue(ACCESS_JWT_TOKEN);
  const Authorization = `Bearer ${accessJwtToken}`;
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const mapApiKeys = useAtomValue(MAP_API_KEYS);

  const deletedFenceIdsRef = useRef<string[]>([]);
  const editRef = useRef<EditState | undefined>();
  const knownExtentsRef = useRef<Extent[]>([]);
  const measureTooltipRef = useRef<Overlay>();
  const measureTooltipElementRef = useRef<HTMLElement>();
  const freshGeofencesRef = useRef<GridRowData[]>([]);
  const setSourceRef = useRef<(type: MapSourceType) => void>();
  const totalFenceCountRef = useRef<number>(0);

  const [availableGeofences, setAvailableGeofences] = useState<GridRowData[]>([]);
  const [availableMicrofences, setAvailableMicrofences] = useState<GridRowData[]>([]);
  const [bounds, setBounds] = useState<Bounds>({
    latitude: initialLatitude,
    longitude: initialLongitude,
    extentInDegrees: initialExtentInDegrees,
  });
  const [clearFilter, setClearFilter] = useState<boolean>(false);
  const [createEditFence, setCreateEditFence] = useState<RequestType | undefined>();
  const [createEditLayer, setCreateEditLayer] = useState<RequestType | undefined>();
  const [currentCenter, setCurrentCenter] = useState<number[] | undefined>();
  const [deletedFenceIds, setDeletedFenceIds] = useState<{ type: FenceGeometryType; id: string }[]>(
    [],
  );
  const [deselectFence, setDeselectFence] = useState<boolean>(false);
  const [dirtySave, setDirtySave] = useState<{
    isDirty: boolean;
    issue: string | null;
  }>({
    isDirty: false,
    issue: null,
  });
  const [displayGeomobyOverrides, setDisplayGeomobyOverrides] = useState<
    (GeomobyOverride & { index: number })[]
  >([]);
  const [displayGeomobyProperties, setDisplayGeomobyProperties] = useState<
    { index: number; property: string; value: string }[]
  >([]);
  const [drawType, setDrawType] = useState<DrawType | undefined>();
  const [dropLocationPin, setDropLocationPin] = useState<Feature<Point> | undefined>();
  const [editing, setEditing] = useState<boolean>(false);
  const [editType, setEditType] = useState<EditType | undefined>();
  const [errorCode, serErrorCode] = useState<number | undefined>();
  const [fencesLoading, setFencesLoading] = useState<boolean>(true);
  const [geofenceFilter, setGeofenceFilter] = useState<GeofenceFilter | undefined>();
  const [hasFences, setHasFences] = useState<boolean>(false);
  const [paginating, setPaginating] = useState<boolean>(false);
  const [layersHaveChanged, setLayersHaveChanged] = useState<boolean>(false);
  const [layerIds, setLayerIds] = useState<NameId[]>([]);
  const [locationDisplay, setLocationDisplay] = useState<LocationDisplayType>();
  const [locationSearchData, setLocationSearchData] = useState<LocationSearchData | undefined>();
  const [mapIsLoading, setMapIsLoading] = useState<boolean>(true);
  const [mapState, setMapState] = useState<MapState | undefined>();
  const [mapSourceType, setMapSourceType] = useState<MapSourceType>('Terrain & Roads');
  const [measurementType, setMeasurementType] = useState<MeasurementType | undefined>();
  const [microfenceFilter, setMicrofenceFilter] = useState<MicrofenceFilter | undefined>();
  const [assetFilter, setAssetFilter] = useState<AssetFilter | undefined>();
  const [freshGeofences, setFreshGeofences] = useState<GridRowData[]>([]);
  const [openGenericDialog, setOpenGenericDialog] = useState<boolean>(false);
  const [paginatedCount, setPaginatedCount] = useState<number>(0);
  const [reassignedFences, setReassignedFences] = useState<ReassignedFence[]>([]);
  const [renamingLayer, setRenamingLayer] = useState<string | null>(null);
  const [refreshSearch, setRefreshSearch] = useState<boolean>(false);
  const [selectedFromMap, setSelectedFromMap] = useState<boolean>(false);
  const [selectedGeofence, setSelectedGeofence] = useState<GridRowData | undefined>();
  const [selectedMicrofence, setSelectedMicrofence] = useState<GridRowData | undefined>();
  const [selectedLayer, setSelectedLayer] = useState<NameId | undefined>();
  const [searchType, setSearchType] = useState<SearchType | undefined>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showGhostGeofences, setShowGhostGeofences] = useState<boolean>(false);
  const [specifiedCoordinates, setSpecifiedCoordinates] = useState<[number, number]>();
  const [userExtent, setUserExtent] = useState<Extent | undefined>();
  const [zoneChange, setZoneChange] = useState<Date | null>(null);

  if (!specifiedCoordinates) {
    navigator.geolocation?.getCurrentPosition(
      loc => {
        setSpecifiedCoordinates([loc?.coords.longitude, loc?.coords.latitude]);
      },
      () => {
        setSpecifiedCoordinates([initialLongitude, initialLatitude]);
      },
    );
  }

  const debouncedOnMapMoved = useRef(
    debounce(
      (olmap: olMap, extentInDegrees: number) => {
        const view = olmap?.getView();
        if (!view) return;
        const viewCenter = view.getCenter();
        if (!viewCenter) return;
        const center = transform(viewCenter, view.getProjection(), 'EPSG:4326');
        const newBounds = {
          latitude: center[1],
          longitude: center[0],
          extentInDegrees: extentInDegrees ?? initialExtentInDegrees,
        };
        setBounds(newBounds);
        updateMap(olmap, newBounds);
      },
      1000,
      { leading: true },
    ),
  ).current;

  const animateToSearchedLocation = useCallback(
    async (
      view: View,
      coords: number[],
      address?: string,
      isStreetAddress?: boolean,
    ): Promise<Feature<Point>> => {
      return new Promise(
        debounce((resolve, reject) => {
          const duration = Math.min(
            6000,
            Math.max(
              300,
              getCoordinateDifference(view.getCenter() ?? [0, 0], coords ?? [0, 0]) / 1000,
            ),
          );

          view.animate(
            {
              center: coords,
              zoom: duration > 300 ? 9 - duration / 1000 : view.getZoom(),
              duration: duration,
            },
            () => {
              view.animate(
                {
                  resolution: isStreetAddress ? 18 : 14,
                  duration: 2000,
                },
                () => {
                  resolve(dropPin(coords, address));
                },
              );
            },
          );
        }, 2000),
      );
    },
    [],
  );

  const getGeofence = useCallback(
    async (id: string, layerId: string, type: FenceGeometryType | undefined) => {
      if (!type || type === FenceGeometryType.Microfence) return;
      return (
        await axios.get<{
          id: string;
          name: string;
          points: { coordinates: Extent };
          geomobyProperties: Record<string, string>;
          geomobyOverrides: GeomobyOverride[];
        }>(`${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${type}/${id}`, authedRequestConfig)
      ).data;
    },
    [triggersUrl, cid, pid, authedRequestConfig],
  );

  const animateToLocation = useCallback(
    async ([lon1, lat1, lon2, lat2]: [number, number, number, number]) => {
      if (!mapState?.map) return;
      const duration = Math.min(
        6000,
        Math.max(
          300,
          getCoordinateDifference(
            mapState?.map.getView().getCenter() ?? [0, 0],
            getCenter([lon1, lat1, lon2, lat2]) ?? [0, 0],
          ) / 1000,
        ),
      );
      mapState?.map.getView().animate(
        {
          center: [lon1, lat1, lon2, lat2],
          zoom: duration > 300 ? 9 - duration / 1000 : mapState?.map.getView().getZoom(),
          duration: duration,
        },
        () => {
          if (selectedLayer?.id === MICROFENCE_LAYER_ID) {
            const extent = selectedMicrofence?.geometry?.getExtent() ?? [];
            if (extent.length === 0) return;
            mapState?.map.getView().animate({
              center: getCenter(extent),
              duration: 250,
              zoom: CLUSTER_MAX_ZOOM,
            });
            return;
          }
          fitToExtent(mapState?.map.getView(), [lon1, lat1, lon2, lat2]);
        },
      );
    },
    [mapState?.map, selectedMicrofence, selectedLayer?.id],
  );

  const animateToFeature = useCallback(
    async (feature: GridRowData) => {
      if (feature.layerId === MICROFENCE_LAYER_ID || feature?.id?.includes(FRESH)) {
        animateToLocation(feature?.geometry?.getExtent() as [number, number, number, number]);
        return;
      }

      if (deletedFenceIds.find(f => f.id === feature.id) || feature.layerId === MICROFENCE_LAYER_ID)
        return;

      const geofence = await getGeofence(
        feature?.id,
        feature?.layerId,
        geometryTypeOfEntity(feature),
      );
      if (!geofence) return;
      const fenceType = geometryTypeOfEntity(feature);
      const newFence =
        fenceType === FenceGeometryType.Polygon
          ? new Polygon(geofence.points.coordinates)
          : fenceType === FenceGeometryType.Multipolygon
          ? new MultiPolygon(geofence.points.coordinates)
          : new LineString(geofence.points.coordinates);
      const extent = transformExtent(newFence.getExtent(), 'EPSG:4326', 'EPSG:3857');
      if (!extent) return;
      animateToLocation(extent as [number, number, number, number]);
    },
    [deletedFenceIds, getGeofence, animateToLocation],
  );

  const getLayerFromMap = (
    olmap: olMap,
    id: string | undefined,
    name?: string,
  ): VectorLayer<VectorSource<Geometry>> | undefined => {
    const layer = olmap
      ?.getAllLayers()
      .find(layer =>
        layer.get('id') === MICROFENCE_LAYER_ID
          ? layer.get('name') === name || layer.get('id') === id
          : layer instanceof VectorLayer &&
            ((layer.getSource().get('name') ?? layer.get('name')) === name ||
              (layer.getSource().get('id') ?? layer.get('id')) === id),
      );
    if (!layer) {
      name
        ? console.error('map layer not found for name', name)
        : console.error('map layer not found for id', id);
      return;
    }

    if (layer.getProperties().id === MICROFENCE_LAYER_ID)
      return layer as VectorLayer<VectorSource<Geometry>>;
    return layer as VectorLayer<VectorSource<Geometry>>;
  };

  const setMapClickDeselection = (olmap: olMap) => {
    olmap.on('click', e => {
      const fences: GeoJSONFeature[] = olmap.getFeaturesAtPixel(e.pixel) ?? [];
      deselectFences(olmap);
      if (editRef.current) return;
      if (freshGeofencesRef.current.find(f => f.layerId === UNKNOWN_LAYER)) {
        setOpenGenericDialog(true);
        return;
      }
      if (fences.length === 0) {
        setSelectedLayer(undefined);
        setSelectedGeofence(undefined);
        setSelectedMicrofence(undefined);
        setDeselectFence(true);
        setRefreshSearch(true);
        resetLayerChanges(olmap);
        return;
      }

      let smallestExtentArea = Number.MAX_SAFE_INTEGER;
      let selectedFence = fences[0];
      fences.forEach(f => {
        const extent = f.getGeometry()?.getExtent();
        const extentArea = extent
          ? Math.abs(extent[0] - extent[2]) * Math.abs(extent[1] - extent[2])
          : Number.MAX_SAFE_INTEGER;
        if (smallestExtentArea > extentArea) {
          smallestExtentArea = extentArea;
          selectedFence = f;
        }
      });
      const layerId = selectedFence.get('layerId');
      if (!layerId) return;

      selectedFence.set('selected', true);
      if (layerId === MICROFENCE_LAYER_ID) {
        setSelectedLayer({ name: MICROFENCE_LAYER_LABEL, id: MICROFENCE_LAYER_ID });
        setSelectedMicrofence(selectedFence?.getProperties());
        setSearchType({ id: SearchTypeIDs.Microfences, value: SearchTypeValue.Microfences });
      } else {
        if (showGhostGeofences) return;
        setShowGhostGeofences(false);
        const foundLayer = getLayerFromMap(olmap, layerId);
        if (!foundLayer) return;

        setSelectedLayer(
          foundLayer ? { id: layerId, name: foundLayer.getSource().get('name') } : undefined,
        );
        setSelectedGeofence(selectedFence?.getProperties() as GridRowData);
        setSearchType({ id: SearchTypeIDs.Geofences, value: SearchTypeValue.Geofences });
        if (geometryTypeOfEntity(selectedFence.getProperties()) === FenceGeometryType.Polygon) {
          const geometry = selectedFence?.getGeometry();
          if (geometry && selectedLayer) {
            setHasFences(!!featureContainsFeature(geometry, foundLayer?.getSource()));
          }
        }
      }

      setSelectedFromMap(true);
      layerIds.forEach(layer =>
        changeVisibility(olmap, layer.id, layer.id === layerId || showGhostGeofences),
      );
    });
  };

  const unsetMapClickSelection = (olmap: olMap) => {
    olmap.getInteractions().forEach(i => i instanceof Select && olmap.removeInteraction(i));
  };

  const setMapMoveHandler = (olmap: olMap) => {
    const onEvent = () => {
      setCurrentCenter(olmap.getView().getCenter());
      const zoom = olmap.getView().getZoom() || 0;
      const res = olmap.getView().getResolution() || 0;

      if (zoom > ZOOM_THRESHOLD) {
        olmap.getView().setZoom(ZOOM_THRESHOLD);
      }
      const extentInDegrees =
        zoom <= initialZoomHeight
          ? initialExtentInDegrees
          : (res / Math.min(zoom, ZOOM_THRESHOLD)) * 0.1;
      debouncedOnMapMoved(olmap, extentInDegrees);
    };
    olmap.on('moveend', onEvent);
    olmap.getView().on('change:resolution', onEvent);
  };

  const layerChanged = (olmap: olMap) => {
    if (!olmap) return;
    if (
      !(selectedGeofence ?? selectedMicrofence) &&
      olmap
        .getAllLayers()
        .find(
          lyr => lyr.get('id') === UNKNOWN_LAYER || lyr.getSource()?.get('id') === UNKNOWN_LAYER,
        )
    )
      return;
    setLayersHaveChanged(true);
  };

  const featureModified = (olmap: olMap, event: ModifyEvent, source: VectorSource<Geometry>) => {
    const modifiedFids = event.features.getArray();
    source.forEachFeature(feature => {
      const modifiedFeature = modifiedFids.find(f => f.get('id')?.includes(feature.get('id')));
      deselectFences(olmap, modifiedFids?.[0].get('id'));
      if (modifiedFeature) {
        feature.setProperties({ updated: true });

        if (modifiedFeature && modifiedFeature.get('layerId') !== MICROFENCE_LAYER_ID) {
          feature.setGeometry((modifiedFeature as Feature<Geometry>).getGeometry());
          const geometry = modifiedFeature?.getGeometry();

          const type = geometryTypeOfEntity(feature.getProperties());
          if (geometry) {
            if (type === FenceGeometryType.Polygon) {
              const coordinates = (geometry as Polygon).getCoordinates()[0];
              const newCoords = coordinates.map(coord =>
                transform(coord, 'EPSG:3857', 'EPSG:4326'),
              );
              const points = (modifiedFeature as Feature<Geometry>).get('points');
              if (newCoords && feature.get('points')) {
                feature.get('points').coordinates = [newCoords];
              }
            } else if (type === FenceGeometryType.Multipolygon) {
              const coordinates = (geometry as MultiPolygon).getCoordinates()[0][0];
              const newCoords = coordinates.map(coord =>
                transform(coord, 'EPSG:3857', 'EPSG:4326'),
              );
              const points = (modifiedFeature as Feature<Geometry>).get('points');
              if (newCoords && feature.get('points')) {
                feature.get('points').coordinates = [[newCoords]];
              }
            } else {
              const coordinates = (geometry as LineString).getCoordinates();
              const newCoords = coordinates.map(coord =>
                transform(coord, 'EPSG:3857', 'EPSG:4326'),
              );
              const points = (modifiedFeature as Feature<Geometry>).get('points');
              if (newCoords && feature.get('points')) {
                feature.get('points').coordinates = newCoords;
              }
            }
          }
        }
      }
    });
    layerChanged(olmap);
  };

  const updateFenceOnMap = (
    layer: VectorLayer<VectorSource<Geometry>>,
    id: string,
    name: string,
    fenceZone: FenceZone | undefined,
    assetId?: MicrofenceAssetId,
    microfenceZone?: MicrofenceZone,
  ) => {
    const feature = layer
      .getSource()
      .getFeatures()
      .find(f => f.get('id') === id);
    if (!feature) return;
    feature.setProperties({ ...feature.getProperties(), name, updated: true });
    if (assetId) {
      feature.set('zone', microfenceZone);
      feature.set('assetId', assetId);
    }
    if (fenceZone) {
      feature.set('zone', fenceZone);
    }
  };

  const updateFenceIdentifiers = async (
    olmap: olMap,
    id: string,
    name: string,
    type: FenceGeometryType | undefined,
    layerId: string,
    fenceZone: FenceZone | undefined,
    assetId: MicrofenceAssetId | undefined,
    microfenceZone: MicrofenceZone | undefined,
  ): Promise<void> => {
    if (!id)
      throw new Error(
        'UI should not be able to update a feature name or ID while no feature is selected',
      );

    const foundLayer = getLayerFromMap(olmap, layerId);
    if (!foundLayer)
      throw new Error(
        'UI should not be able to update a feature name or ID while no layer is selected',
      );

    if (layerId !== MICROFENCE_LAYER_ID) {
      await findFeature(olmap, id, layerId, type);
    }

    updateFenceOnMap(foundLayer, id, name, fenceZone, assetId, microfenceZone);
    layerChanged(olmap);

    const updatedNameFeature = freshGeofencesRef.current.find(f => f.id === id);
    if (updatedNameFeature) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== id),
        { ...updatedNameFeature, name },
      ];
    }

    if (layerId !== MICROFENCE_LAYER_ID) {
      const bufferZoneFence = foundLayer
        .getSource()
        .getFeatures()
        .find(f => f.get('parentId') === id && f.get('zone') === FenceZone.buffer);
      const relatedFenceName = bufferZoneFence ? `${name}_warning` : undefined;
      if (relatedFenceName) {
        updateFenceOnMap(
          foundLayer,
          bufferZoneFence?.get('id'),
          relatedFenceName,
          fenceZone === FenceZone.breach ? FenceZone.buffer : undefined,
        );
        layerChanged(olmap);

        const bufferIsFresh = freshGeofencesRef.current.find(
          f => f.id === bufferZoneFence?.get('id'),
        );
        if (bufferIsFresh) {
          freshGeofencesRef.current = [
            ...freshGeofencesRef.current.filter(f => f.id !== bufferZoneFence?.get('id')),
            { ...bufferIsFresh, name: relatedFenceName },
          ];
        }
      }
      setAvailableGeofences(
        availableGeofences.map(fence => {
          if (fence.id === id) {
            return { ...fence, name };
          } else if (relatedFenceName && fence.id === bufferZoneFence?.get('id')) {
            return { ...fence, name: relatedFenceName };
          }
          return fence;
        }),
      );
    } else if (layerId === MICROFENCE_LAYER_ID) {
      setAvailableMicrofences(
        availableMicrofences.map(fence => {
          if (fence.id === id && assetId) {
            return { ...fence, name, assetId };
          }
          return fence;
        }),
      );
    }
    setFreshGeofences(freshGeofencesRef.current);
    setLayersHaveChanged(true);
  };

  const changeVisibility = (olmap: olMap, layerId: string, visible: boolean, opacity?: number) => {
    const layer = layerIds.find(lyr => lyr.id === layerId);
    if (!layer) return;

    const foundLayer = getLayerFromMap(olmap, layerId);
    if (foundLayer) {
      foundLayer.setVisible(visible);
      if (visible && selectedLayer?.id === MICROFENCE_LAYER_ID) {
        foundLayer.setOpacity(layerId === MICROFENCE_LAYER_ID ? 1 : 0.6);
      } else if (visible) {
        foundLayer.setOpacity(1);
      }
      if (opacity) foundLayer.setOpacity(opacity);
    }
  };

  const resetLayerChanges = async (olmap: olMap): Promise<void> => {
    if (!selectedLayer) return; // UI should not need to reset a layer when no layer is selected;
    if (!olmap) throw new Error('UI should not be able to modify a layer while no map exists');

    deselectFences(olmap);
    if (selectedLayer) {
      changeVisibility(olmap, selectedLayer?.id, false);
    }

    setLayersHaveChanged(false);
    setSelectedGeofence(undefined);
    setSelectedMicrofence(undefined);
    setCreateEditLayer(undefined);
    setCreateEditFence(undefined);
    setAvailableGeofences([]);
    setReassignedFences([]);
    freshGeofencesRef.current = [];
    setFreshGeofences([]);

    const scaleRotLayer = olmap
      .getAllLayers()
      .find(l => l.getClassName() === 'Scalable-rotatable-layer');
    if (scaleRotLayer) {
      olmap.removeLayer(scaleRotLayer);
    }

    setMapIsLoading(true);
    const restoredLayer =
      selectedLayer?.id === MICROFENCE_LAYER_ID
        ? {
            id: MICROFENCE_LAYER_ID,
            name: MICROFENCE_LAYER_LABEL,
            source: await getMicrofencesVectorLayer(
              triggersUrl,
              authedRequestConfig,
              { cid, pid },
              bounds,
            ),
          }
        : await getVectorLayer(
            triggersUrl,
            authedRequestConfig,
            { cid, pid },
            selectedLayer?.id,
            bounds,
          );
    setMapIsLoading(false);

    // If no layer with these credentials is returned from db, then remove it from front-end.
    if (!restoredLayer) {
      if (layerIds.length === 0) return;

      const layerToRemove = layerIds.find(i => i.id === selectedLayer?.id)?.id;
      if (layerToRemove) {
        setLayerIds(layerIds.filter(lyr => lyr.id !== layerToRemove));
        olmap.setLayers(
          olmap
            .getAllLayers()
            .filter(
              lyr =>
                lyr?.getSource().get('id') !== layerToRemove &&
                lyr.get('id') !== layerToRemove &&
                lyr?.getSource().get('id') !== FRESH_LAYER &&
                lyr.get('id') !== FRESH_LAYER,
            ),
        );
      }

      setSelectedLayer(undefined);
      setMapIsLoading(false);
      return;
    }
    restoredLayer.source.setStyle(updateGeofencesStyle(new Map()));

    setTimeout(() => {
      if (!olmap) return;
      const foundLayer = getLayerFromMap(olmap, selectedLayer.id);
      if (foundLayer) {
        olmap.removeLayer(foundLayer);
      }
      olmap.addLayer(restoredLayer.source);
      setLayerIds([
        { id: selectedLayer?.id, name: restoredLayer.name },
        ...layerIds.filter(i => i.id !== selectedLayer?.id),
      ]);
      setSelectedLayer({ id: selectedLayer?.id, name: restoredLayer.name });
      setDeletedFenceIds([]);

      // Wait for old layer to be set before refreshing.
      setTimeout(() => {
        setRefreshSearch(true);
        // Force modified/undeleted features to be redrawn
        olmap.getView().adjustZoom(-1);
        olmap.getView().adjustZoom(+1);
      });
    });
  };

  const processNextReassignedFence = (reassignedFence: ReassignedFence, layerId: string) => () =>
    axios
      .patch(
        `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${reassignedFence.type}/change-layer/${reassignedFence.id}/${reassignedFence.newLayerId}`,
        {},
        authedRequestConfig,
      )
      .then(() => true);

  const saveMicrofenceLayerChanges = async (olmap: olMap): Promise<undefined> => {
    if (!selectedLayer || selectedLayer?.id !== MICROFENCE_LAYER_ID) return;
    const microfenceLayer = getLayerFromMap(olmap, MICROFENCE_LAYER_ID);
    if (!microfenceLayer) return;

    try {
      await Promise.all(
        deletedFenceIds
          .filter(f => !f.id?.includes(FRESH))
          .map(({ id }) =>
            axios.delete(`${triggersUrl}/${cid}/${pid}/microfences/${id}`, authedRequestConfig),
          ),
      );
      setDeletedFenceIds([]);

      const newIdsbyOldId: { [oldId: string]: string | undefined } = Object.fromEntries(
        await Promise.all(
          microfenceLayer
            .getSource()
            ?.getFeatures()
            ?.filter(
              (microfence: Feature<Geometry>) =>
                microfence.get('updated') || microfence.get('fresh'),
            )
            .map(async (microfence: Feature<Geometry>) => {
              if (microfence.getGeometry()?.getType() !== 'Point') {
                console.error(
                  `Should not be trying to save non-point feature for microfence: ${microfence}`,
                );
              }

              const { id, assetId, name, type, zone, geomobyProperties } =
                microfence.getProperties();
              const body = {
                assetId,
                name,
                type: type?.replace(MicrofenceEntity.Smartplug, MicrofenceEntity.Gateway), // fix legacy type,
                zone: zone === MicrofenceZone.none || zone === undefined ? null : zone,
                boundaryRssi: Number(
                  geomobyProperties.boundaryRssi ??
                    geomobyProperties[MICROFENCE_PROP_LABELS.boundaryRssi],
                ),
                timeoutSeconds: Number(
                  geomobyProperties.timeoutSeconds ??
                    geomobyProperties[MICROFENCE_PROP_LABELS.timeoutSeconds],
                ),
                geometry: {
                  type: 'Point',
                  coordinates: transform(
                    (microfence.getGeometry() as Point)?.getCoordinates(),
                    'EPSG:3857',
                    'EPSG:4326',
                  ),
                },
              };

              if (id?.includes(FRESH)) {
                const newId: string = (
                  await axios.post(
                    `${triggersUrl}/${cid}/${pid}/microfences/`,
                    body,
                    authedRequestConfig,
                  )
                ).data.id;
                return [id, newId];
              } else {
                await axios.patch(
                  `${triggersUrl}/${cid}/${pid}/microfences/${id}`,
                  body,
                  authedRequestConfig,
                );
              }
              return [];
            }),
        ),
      );

      const features = getLayerFromMap(olmap, MICROFENCE_LAYER_ID)?.getSource()?.getFeatures();
      features?.forEach((feature: Feature<Geometry>) => {
        feature.setProperties({ updated: undefined, fresh: undefined });
        const newId = newIdsbyOldId[feature.get('id')];
        if (newId) {
          feature.set('id', newId);
        }
      });
      setLayersHaveChanged(false);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
      const newMicrofences = features;
      if (!newMicrofences) return;
      setAvailableMicrofences(newMicrofences.map(fence => fence.getProperties()));
    } catch (error) {
      const errorMessage = normaliseErrorMessage(error as AxiosError, EntityType.Microfence);
      resetLayerChanges(olmap);
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: errorMessage,
      });
    }
    deselectFences(olmap);
  };

  const saveGeofenceLayerChanges = async (olmap: olMap): Promise<string | undefined> => {
    if (!selectedLayer) return;
    const foundLayer = getLayerFromMap(olmap, selectedLayer.id);
    if (!foundLayer) return;

    let layerId = selectedLayer?.id;
    const layerData = {
      id: selectedLayer.id,
      name: selectedLayer.name,
      source: foundLayer.getSource(),
    };
    const geojson = layerToJson(layerData.source);
    freshGeofencesRef.current = [];
    setFreshGeofences([]);

    const features: {
      properties: {
        id: string;
        name: string;
        type: string;
        zone: FenceZone;
        parentId: string;
        geomobyProperties: Record<string, string>;
        geomobyOverrides: GeomobyOverride[];
      };
    }[] = geojson.features
      .filter(
        (feature: GeoJSONFeature) => feature?.properties?.updated || feature?.properties?.fresh,
      )
      .map((feature: GeoJSONFeature) => {
        const type = feature.geometry.type?.toLowerCase()?.includes(FenceGeometryType.Line)
          ? FenceGeometryType.Line
          : feature.geometry.type?.toLowerCase();
        return {
          ...feature,
          properties: {
            id: feature?.properties?.id,
            name: feature?.properties?.name,
            type,
            geomobyProperties: feature?.properties?.geomobyProperties,
            geomobyOverrides: feature?.properties?.geomobyOverrides,
            zone:
              type === FenceGeometryType.Polygon
                ? feature?.properties?.zone ?? FenceZone.none
                : undefined,
            parentId: feature?.properties?.parentId,
          },
        };
      });

    const existingFences = features.filter(f => !f.properties.id?.includes(FRESH));
    const newPolygons = features.filter(
      f =>
        f.properties.type?.toLowerCase() === FenceGeometryType.Polygon &&
        f.properties.id?.includes(FRESH),
    );

    const newMultipolygons = features.filter(
      f =>
        f.properties.type?.toLowerCase() === FenceGeometryType.Multipolygon &&
        f.properties.id?.includes(FRESH),
    );

    const newLines = features.filter(
      f =>
        f.properties.type?.toLowerCase()?.includes(FenceGeometryType.Line) &&
        f.properties.id?.includes(FRESH),
    );

    const newlyCreatedPolygons: { id: string; name: string; parentId: string | undefined }[] = [];
    let newlyCreatedMultiPolygons: { id: string; name: string; parentId: string | undefined }[] =
      [];
    let newlyCreatedLines: { id: string; name: string; parentId: string | undefined }[] = [];

    try {
      // Create layer
      if (layerId === FRESH_LAYER) {
        layerId = (
          await axios.post(
            `${triggersUrl}/${cid}/${pid}/geofences`,
            {
              name: layerData.name,
            },
            authedRequestConfig,
          )
        ).data.id;
        const freshLayer = olmap
          ?.getAllLayers()
          .find(
            lyr =>
              lyr instanceof VectorLayer &&
              (lyr.getSource()?.get('id') === FRESH_LAYER || lyr.get('id') === FRESH_LAYER),
          );
        if (freshLayer) {
          freshLayer.set('id', layerId);
          freshLayer
            .getSource()
            .getFeatures()
            .forEach((f: Feature<Geometry>) => {
              f.set('layerId', layerId);
            });
          freshLayer.getSource().setProperties({
            id: layerId,
            name: freshLayer.get('name'),
          });
        }
      } else if (layerId !== FRESH_LAYER && renamingLayer) {
        // Update layer
        layerId = (
          await axios.patch(
            `${triggersUrl}/${cid}/${pid}/geofences/${layerId}`,
            {
              name: renamingLayer,
            },
            authedRequestConfig,
          )
        ).data.id;
      }

      // Delete fences
      if (deletedFenceIds.length > 0) {
        await Promise.all(
          deletedFenceIds
            .filter(f => !f.id?.includes(FRESH))
            .map(({ type, id }) =>
              axios.delete(
                `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${type}/${id}`,
                authedRequestConfig,
              ),
            ),
        );
        setDeletedFenceIds([]);
      }

      // Update existing fences
      if (existingFences.length > 0) {
        await Promise.all(
          existingFences.map(async (feature: GeoJSONFeature) => {
            await axios.patch(
              `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${feature?.properties.type}/${feature?.properties?.id}`,
              feature,
              authedRequestConfig,
            );
          }),
        );
      }

      // Reassign to another layer
      if (reassignedFences.length > 0) {
        setMapIsLoading(true);
        await reassignedFences
          .map(r => processNextReassignedFence(r, layerId))
          .reduce((prev, cur) => {
            return prev.then(() => cur());
          }, Promise.resolve(true));

        if (availableGeofences.length > 0) {
          reassignedFences.forEach(reassignedFence => {
            const foundFeature = layerData.source
              .getFeatures()
              .find((f: Feature<Geometry>) => f.get('id') === reassignedFence.id);
            if (foundFeature) {
              const newlayer = olmap
                .getAllLayers()
                .find(
                  l =>
                    l instanceof VectorLayer &&
                    l.getProperties().source.get('id') === reassignedFence.newLayerId,
                );

              if (newlayer) {
                const updatedFeature = foundFeature;
                updatedFeature.setProperties({
                  ...updatedFeature.getProperties(),
                  layerId: newlayer.getProperties().source.get('id'),
                  layerName: newlayer.getProperties().source.get('name'),
                });

                const foundBuffer = layerData.source
                  .getFeatures()
                  .find((f: Feature<Geometry>) => f.get('parentId') === foundFeature.get('id'));
                if (foundBuffer) {
                  const updatedBuffer = foundBuffer;
                  updatedBuffer.setProperties({
                    ...updatedBuffer.getProperties(),
                    layerId: newlayer.getProperties().source.get('id'),
                    layerName: newlayer.getProperties().source.get('name'),
                  });
                  newlayer.getProperties().source.addFeature(updatedBuffer as Feature<Geometry>);
                  layerData.source.removeFeature(foundBuffer as Feature<Geometry>);
                }
                newlayer.getProperties().source.addFeature(updatedFeature as Feature<Geometry>);
                layerData.source.removeFeature(foundFeature as Feature<Geometry>);
              }
            }
          });

          setAvailableGeofences(
            availableGeofences.filter(f => !reassignedFences.find(r => r.id === f.id)),
          );
        }
        setReassignedFences([]);
        setMapIsLoading(false);
      }

      // Create polygons
      if (newPolygons.length > 0) {
        const createdPolygons = (
          await axios.post(
            `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/polygon/multiple`,
            { polygons: newPolygons },
            authedRequestConfig,
          )
        ).data;
        newlyCreatedPolygons.push(
          ...createdPolygons.map(
            (p: { id: string; name: string; parentId: string | undefined }) => {
              return {
                id: p.id,
                name: p.name,
                parentId: p.parentId,
              };
            },
          ),
        );
      }

      // Create multipolygons
      newlyCreatedMultiPolygons =
        newMultipolygons.length > 0
          ? await Promise.all(
              newMultipolygons.map(async (multipolygon: GeoJSONFeature) => {
                return (
                  await axios.post(
                    `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/multipolygon`,
                    multipolygon,
                    authedRequestConfig,
                  )
                ).data;
              }),
            )
          : [];

      // Create lines
      newlyCreatedLines =
        newLines.length > 0
          ? await Promise.all(
              newLines.map(async (line: GeoJSONFeature) => {
                return (
                  await axios.post(
                    `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/line`,
                    line,
                    authedRequestConfig,
                  )
                ).data;
              }),
            )
          : [];

      await resetLayerChanges(olmap);
      setSelectedLayer({ id: layerId, name: layerData.name });
      setRefreshSearch(true);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (error) {
      const errorMessage = normaliseErrorMessage(error as AxiosError, EntityType.Geofence);
      await resetLayerChanges(olmap);
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: errorMessage,
      });
    }

    const layer = getLayerFromMap(olmap, layerId);
    if (layer) {
      layer
        .getSource()
        ?.getFeatures()
        ?.forEach((feature: Feature<Geometry>) => {
          feature.setProperties({ updated: undefined, fresh: undefined });
          const newFence = [
            ...newlyCreatedPolygons,
            ...newlyCreatedMultiPolygons,
            ...newlyCreatedLines,
          ].find(n => n.name === feature.get('name'));

          if (newFence) {
            feature.set('id', newFence.id);
            if (newFence.parentId) {
              feature.set('parentId', newFence.parentId);
            }
          }
        });
      setLayerIds([
        { id: layerId, name: layerData?.name },
        ...layerIds.filter(lyr => lyr.id !== selectedLayer?.id),
      ]);
      setSelectedLayer({ id: layerId, name: layerData.name });
    }

    setSelectedGeofence(undefined);
    setLayersHaveChanged(false);
    deselectFences(olmap);
    reassignedFences.map(r => findFeature(olmap, r.id, r.newLayerId, r.type));
    return layerId;
  };

  const saveLayerChanges = async (olmap: olMap): Promise<string | undefined> => {
    if (!selectedLayer) return;
    const foundLayer = getLayerFromMap(olmap, selectedLayer.id);
    if (!foundLayer) return;

    if (selectedLayer?.id === MICROFENCE_LAYER_ID) {
      // Validate Microfences
      let hasDuplicateAssetId: Feature<Geometry> | undefined;
      const microfences = foundLayer.getSource().getFeatures();

      if (microfences) {
        const hasUndefinedAssetId = microfences.find(microfence => {
          const assetId = microfence.get('assetId');
          const type = microfence.get('type');
          if (type === MicrofenceEntity.Beacon)
            return !assetId?.uuid || !assetId?.major || !assetId?.minor;

          return (
            ((type === MicrofenceEntity.Gateway || type === MicrofenceEntity.Smartplug) &&
              !assetId?.gatewayId) ||
            (type === MicrofenceEntity.Device && !assetId?.deviceId)
          );
        });

        setDirtySave({
          isDirty: !!hasUndefinedAssetId,
          issue: hasUndefinedAssetId
            ? hasUndefinedAssetId.get('type') === MicrofenceEntity.Beacon
              ? `Beacon IDs are required`
              : `${
                  hasUndefinedAssetId.get('type') === MicrofenceEntity.Gateway ||
                  hasUndefinedAssetId.get('type') === MicrofenceEntity.Smartplug
                    ? jsUcFirst(MicrofenceEntity.Gateway)
                    : jsUcFirst(MicrofenceEntity.Device)
                } ID is required`
            : null,
        });

        const hasUndefinedName = featureHasUndefinedName(
          microfences,
          EntityType.Microfence,
          setDirtySave,
        );
        const hasDuplicateName = featureHasDuplicateName(
          microfences,
          EntityType.Microfence,
          setDirtySave,
        );

        if (!hasUndefinedAssetId) {
          microfences.forEach(microfence => {
            const foundDuplicateId = getLayerFromMap(olmap, MICROFENCE_LAYER_ID)
              ?.getSource()
              ?.getFeatures()
              ?.find(f => {
                const differentId = f.get('id') !== microfence.get('id');
                if (!differentId) return false;

                const duplicateBeaconIds =
                  !!f.get('assetId').uuid &&
                  f.get('assetId').uuid === microfence.get('assetId').uuid &&
                  !!f.get('assetId').major &&
                  f.get('assetId').major === microfence.get('assetId').major &&
                  !!f.get('assetId').minor &&
                  f.get('assetId').minor === microfence.get('assetId').minor;
                const duplicateGatewayId =
                  !!f.get('assetId').gatewayId &&
                  f.get('assetId').gatewayId === microfence.get('assetId').gatewayId;
                const duplicateDeviceId =
                  !!f.get('assetId').deviceId &&
                  f.get('assetId').deviceId === microfence.get('assetId').deviceId;

                return duplicateBeaconIds || duplicateGatewayId || duplicateDeviceId;
              });
            if (foundDuplicateId) hasDuplicateAssetId = microfence;
          });

          if (hasDuplicateAssetId !== undefined && !hasUndefinedName) {
            setDirtySave({
              isDirty: !!hasDuplicateAssetId,
              issue: hasDuplicateAssetId
                ? hasDuplicateAssetId.get('type') === MicrofenceEntity.Beacon
                  ? 'UUID, Major and Minor combination must be unique'
                  : 'Microfence ID must be unique'
                : null,
            });
          }
        }

        const dirtyFence =
          hasUndefinedAssetId ?? hasUndefinedName ?? hasDuplicateName ?? hasDuplicateAssetId;
        if (dirtyFence) {
          setSelectedMicrofence(dirtyFence?.getProperties() as GridRowData);
          return;
        }
      }
      setSelectedMicrofence(undefined);
      return await saveMicrofenceLayerChanges(olmap);
    } else {
      // Validate Geofences
      let hasDuplicateName;
      let hasEmptyGeomobyPropertyName;
      let hasIncompleteOverride;
      let hasDuplicateOverride;
      let hasInvalidPropertyOrOverride;

      const features = foundLayer
        .getSource()
        .getFeatures()
        .filter(feature => !feature.get('isMeasurementTool'));
      if (features) {
        const layerNotSelected = selectedGeofence?.layerId === UNKNOWN_LAYER;
        setDirtySave({
          isDirty: !!layerNotSelected,
          issue: layerNotSelected
            ? 'A group has not yet been selected. Please assign one to this geofence.'
            : null,
        });
        if (layerNotSelected) return;

        const properties = [
          ...(await API.triggers.getProjectProperties({
            clientId: cid,
            projectId: pid,
            Authorization,
          })),
          ...(await API.triggers.getGeomobyProperties({
            clientId: cid,
            projectId: pid,
            Authorization,
          })),
        ];
        const hasUndefinedName = featureHasUndefinedName(
          features,
          EntityType.Geofence,
          setDirtySave,
        );

        if (!hasUndefinedName)
          hasDuplicateName = featureHasDuplicateName(features, EntityType.Geofence, setDirtySave);

        if (!hasUndefinedName && !hasDuplicateName)
          hasEmptyGeomobyPropertyName = featureHasEmptyGeomobyPropertyValue(features, setDirtySave);

        if (!hasUndefinedName && !hasDuplicateName && !hasEmptyGeomobyPropertyName)
          hasIncompleteOverride = featureHasIncompleteOverride(features, setDirtySave);

        if (
          !hasUndefinedName &&
          !hasDuplicateName &&
          !hasEmptyGeomobyPropertyName &&
          !hasIncompleteOverride
        )
          hasDuplicateOverride = featureHasDuplicateOverride(features, setDirtySave);

        if (
          !hasUndefinedName &&
          !hasDuplicateName &&
          !hasIncompleteOverride &&
          !hasDuplicateOverride &&
          !hasEmptyGeomobyPropertyName
        )
          hasInvalidPropertyOrOverride = featureHasInvalidPropertyOrOverride(
            features,
            properties,
            setDirtySave,
          );

        const dirtyFence =
          hasUndefinedName ??
          hasDuplicateName ??
          hasEmptyGeomobyPropertyName ??
          hasIncompleteOverride ??
          hasDuplicateOverride ??
          hasInvalidPropertyOrOverride;
        if (dirtyFence) {
          setSelectedGeofence(dirtyFence?.getProperties() as GridRowData);
          setDisplayGeomobyProperties(
            Object.entries(dirtyFence.get('geomobyProperties'))?.map(([property, value], index) => {
              return {
                index: index as number,
                property: property as string,
                value: value as string,
              };
            }),
          );
          setDisplayGeomobyOverrides(dirtyFence.get('geomobyOverrides') ?? []);
          return;
        }
      }
      return await saveGeofenceLayerChanges(olmap);
    }
  };

  const stopEditingFeature = (olmap: olMap) => {
    if (!editRef.current)
      throw new Error('UI should not be able to unset editing while not in editing mode');
    if (!olmap) throw new Error('UI should not be able to unset editing while no map exists');

    const collections = new Collection(
      olmap
        .getInteractions()
        .getArray()
        .filter(
          (i: Interaction) =>
            !(i instanceof Modify || i instanceof Draw) ||
            i instanceof Snap ||
            i instanceof Translate,
        ),
    );
    olmap.getInteractions().clear();
    collections.forEach(i => olmap.addInteraction(i));
    editRef.current = undefined;
    setEditing(false);
    document.removeEventListener('keyup', removeLastDrawnSegment);
  };

  const createNewLayer = (olmap: olMap, id: string, name: string) => {
    if (!olmap) throw new Error('UI is unable to create a new layer when no map exists.');

    const newSource = new VectorSource();
    const newLayer = new VectorLayer({
      source: newSource,
      style: updateGeofencesStyle(styleCache, selectedGeofence?.id),
      properties: {
        name,
        id,
        geomobyProperties: {},
      },
    });
    setLayerIds([{ id, name }, ...layerIds]);
    olmap.addLayer(newLayer);
    setSelectedLayer({ id, name });
    layerChanged(olmap);
  };

  const deleteLayer = async (olmap: olMap): Promise<void> => {
    if (!selectedLayer) throw new Error('UI should not trigger delete when no layer selected');
    if (!olmap) throw new Error('UI should not be able to delete a layer while no map exists');

    const foundLayer = getLayerFromMap(olmap, undefined, selectedLayer.name);
    if (foundLayer) {
      olmap.removeLayer(foundLayer);
    }
    const freshLayer = olmap
      .getAllLayers()
      .find(
        (l, i) =>
          l instanceof VectorLayer &&
          (l.getSource()?.get('id') === FRESH_LAYER || l.get('id') === FRESH_LAYER),
      );
    if (freshLayer) {
      olmap.removeLayer(freshLayer);
    }

    setLayerIds(layerIds.filter(lyr => lyr.id !== selectedLayer.id));
    try {
      await axios.delete(
        `${triggersUrl}/${cid}/${pid}/geofences/${selectedLayer.id}`,
        authedRequestConfig,
      );
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Delete' });
    } catch (error) {
      const errorMessage = normaliseErrorMessage(error as AxiosError, EntityType.Layer);
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Delete',
        message: errorMessage,
      });
    }
    setDeletedFenceIds([]);
    setSelectedLayer(undefined);
    setSelectedGeofence(undefined);
    setSelectedMicrofence(undefined);
    setCreateEditLayer(undefined);
    setCreateEditFence(undefined);
    deselectFences(olmap);
    setDrawType(undefined);
    layerIds.forEach(layer => changeVisibility(olmap, layer.id, layer.id !== selectedLayer.id));
  };

  const findFeature = async (
    olmap: olMap,
    fenceId: string,
    layerId: string,
    type: FenceGeometryType | undefined,
  ) => {
    let feature;

    const layer = getLayerFromMap(olmap, layerId);
    if (!layer) return;

    if (layerId === MICROFENCE_LAYER_ID) {
      return getLayerFromMap(olmap, MICROFENCE_LAYER_ID)
        ?.getSource()
        ?.getFeatures()
        ?.find((f: Feature<Geometry>) => f.get('id') === fenceId);
    }

    feature = layer
      ?.getSource()
      .getFeatures()
      .find(f => f.get('id') === fenceId);
    if (!feature) {
      const geofence = await getGeofence(fenceId, layerId, type);
      if (geofence) {
        const newFence =
          type === FenceGeometryType.Polygon
            ? new Polygon(geofence.points.coordinates)
            : type === FenceGeometryType.Multipolygon
            ? new MultiPolygon(geofence.points.coordinates)
            : new LineString(geofence.points.coordinates);

        if (type === FenceGeometryType.Polygon) {
          const coords: number[][] = (newFence as Polygon).getCoordinates()[0];
          (newFence as Polygon).setCoordinates([
            coords.map(coord => transform(coord, 'EPSG:4326', 'EPSG:3857')),
          ]);
        } else if (type === FenceGeometryType.Multipolygon) {
          const coords: number[][][][] = (newFence as MultiPolygon)
            .getCoordinates()
            .map(polygons => {
              return polygons.map(polygon =>
                polygon.map(coord => transform(coord, 'EPSG:4326', 'EPSG:3857')),
              );
            });
          (geofence as GridRowData).zone = FenceZone.cleared;
          (newFence as MultiPolygon).setCoordinates(coords);
        } else if (type === FenceGeometryType.Line) {
          const coords: number[][] = (newFence as LineString).getCoordinates();
          (newFence as LineString).setCoordinates(
            coords.map(coord => transform(coord, 'EPSG:4326', 'EPSG:3857')),
          );
        }
        feature = new Feature(newFence);
        feature.setGeometry(newFence);
        feature.setProperties({ ...geofence, layerId });
        // Check again!
        const existingFeature = layer
          ?.getSource()
          .getFeatures()
          .find(f => f.get('id') === fenceId);
        if (existingFeature) {
          layer?.getSource().removeFeature(existingFeature);
        }
        layer?.getSource().addFeature(feature);
        return feature;
      }
    }
    return feature;
  };

  const deleteFeature = async (
    olmap: olMap,
    fence: GridRowData,
    type: FenceGeometryType | undefined,
  ): Promise<void> => {
    const fenceId = fence.id ?? fence.get('id');
    const layerId = fence.layerId ?? fence.get('layerId');
    const layer = getLayerFromMap(olmap, layerId);
    if (!layer) throw new Error('UI should not be able to delete a feature nonexistent layer.');
    if (!olmap) throw new Error('UI should not be able to delete a feature while no map exists');

    const microfences = getLayerFromMap(olmap, MICROFENCE_LAYER_ID)?.getSource()?.getFeatures();
    if (!microfences) return;

    const feature = await findFeature(olmap, fenceId, layerId, type);
    if (!feature) throw new Error(`Failed to find fence to perform deletion [${fenceId}]`);

    if (!fenceId?.includes(FRESH))
      setDeletedFenceIds(list => [
        ...list,
        {
          id: fenceId,
          type:
            feature.getGeometry()?.getType()?.toLowerCase() === FenceGeometryType.Polygon ||
            feature.get('points')?.type?.toLowerCase() === FenceGeometryType.Polygon
              ? FenceGeometryType.Polygon
              : feature.getGeometry()?.getType()?.toLowerCase() ===
                  FenceGeometryType.Multipolygon ||
                feature.get('points')?.type?.toLowerCase() === FenceGeometryType.Multipolygon
              ? FenceGeometryType.Multipolygon
              : FenceGeometryType.Line,
        },
      ]);

    const bufferFeature =
      feature.get('zone') === FenceZone.breach
        ? layer
            .getSource()
            .getFeatures()
            .find(f => f.get('parentId') === feature.get('id'))
        : undefined;

    layer.getSource().removeFeature(feature);
    setSelectedGeofence(undefined);
    setSelectedMicrofence(undefined);

    if (layerId !== MICROFENCE_LAYER_ID && availableGeofences.length > 0) {
      setAvailableGeofences(availableGeofences.filter(f => f.id !== fenceId));
    } else if (
      selectedLayer?.id === MICROFENCE_LAYER_ID &&
      (availableMicrofences.length > 0 || microfences.length)
    ) {
      setAvailableMicrofences(
        (availableMicrofences ?? microfences.map(m => m.getProperties())).filter(
          f => f.id !== fenceId,
        ),
      );
    }

    deletedFenceIdsRef.current.push(fenceId);
    if (bufferFeature) deleteFeature(olmap, bufferFeature, FenceGeometryType.Polygon);
  };

  const editFeature = (olmap: olMap) => {
    if (editRef.current)
      throw new Error('UI should not be able to set editing while already editing');
    if (!olmap) throw new Error('UI should not be able to edit a feature while no map exists');

    let foundLayer: VectorLayer<VectorSource<Geometry>> | undefined;
    if (!selectedLayer || selectedLayer?.id === ALL_LAYERS) {
      createNewLayer(olmap, UNKNOWN_LAYER, UNKNOWN_LAYER);
      foundLayer = getLayerFromMap(olmap, undefined, UNKNOWN_LAYER);
    } else if (selectedLayer) {
      foundLayer = getLayerFromMap(olmap, selectedLayer.id);
    }
    if (!foundLayer)
      throw new Error('UI should not be able to set editing while no layer selected');

    setClearFilter(true);
    if (
      (selectedGeofence &&
        geometryTypeOfEntity(selectedGeofence) !== FenceGeometryType.Multipolygon) ||
      selectedMicrofence
    ) {
      setSelectedGeofence(undefined);
      setSelectedMicrofence(undefined);
      setAvailableGeofences([]);
      setAvailableMicrofences([]);
      deselectFences(olmap);
    }
    unsetMapClickSelection(olmap);

    foundLayer.getSource().on('addfeature', async e => {
      if (!foundLayer?.getSource())
        throw new Error('UI should not be able to set editing while no layer selected');
      if (!e.feature) throw new Error('UI should not be able add an undefined feature');
      if (e.feature.get('id') || e.feature.get('isMeasurementTool')) return;

      const layerId =
        (e.target as VectorSource<Geometry>)?.get('id') ??
        (!selectedLayer?.id || selectedLayer.id === ALL_LAYERS ? UNKNOWN_LAYER : selectedLayer.id);

      if (
        selectedLayer?.id !== MICROFENCE_LAYER_ID &&
        !olmap
          .getAllLayers()
          .find(
            lyr =>
              lyr instanceof VectorLayer &&
              (lyr.get('id') === UNKNOWN_LAYER || lyr.getSource()?.get('id') === UNKNOWN_LAYER),
          )
      ) {
        const foundLayer = getLayerFromMap(olmap, layerId);
        if (foundLayer && (layerId !== selectedLayer?.id || (layerId && !selectedLayer))) {
          setSelectedLayer({
            id: layerId,
            name: foundLayer.get('name') ?? foundLayer.getSource()?.get('name'),
          });
        }
      }

      const currentSelectedMultipolygon = foundLayer
        .getSource()
        .getFeatures()
        .find(f => !!f.get('selected'));
      if (
        e.feature?.getGeometry().getType().toLowerCase() === FenceGeometryType.Multipolygon &&
        currentSelectedMultipolygon &&
        geometryTypeOfEntity(currentSelectedMultipolygon.getProperties()) ===
          FenceGeometryType.Multipolygon
      ) {
        const coords = (
          currentSelectedMultipolygon.getGeometry() as MultiPolygon
        )?.getCoordinates();
        if (
          coords.find(
            c => JSON.stringify(c) === JSON.stringify(e.feature?.getGeometry().getCoordinates()[0]),
          )
        )
          return;
        coords.push(e.feature.getGeometry().getCoordinates()[0]);
        currentSelectedMultipolygon.setGeometry(new MultiPolygon(coords));

        if (currentSelectedMultipolygon.get('points')) {
          currentSelectedMultipolygon.get('points')?.coordinates.push([
            e.feature
              .getGeometry()
              .getCoordinates()[0][0]
              .map((c: [number, number]) => transform(c, 'EPSG:3857', 'EPSG:4326')),
          ]);
          if (!currentSelectedMultipolygon.get('id')?.includes(FRESH)) {
            currentSelectedMultipolygon.set('updated', true);
          }
        }
        setSelectedGeofence(currentSelectedMultipolygon.getProperties());

        const featureToRemove = foundLayer
          .getSource()
          .getFeatures()
          .filter(f => !f.get('isMeasurementTool'))
          .find((feature: Feature<Geometry>) => feature.get('id') === e.feature?.get('id'));
        if (featureToRemove) {
          foundLayer.getSource().removeFeature(featureToRemove);
        }
        setEditing(true);
        return;
      }
      currentSelectedMultipolygon?.set('selected', false);

      e.feature.setProperties({
        ...e.feature.getProperties(),
        id: `${FRESH}-${new Date().getTime()}`,
        name: getFreshGeofenceName(
          olmap,
          layerId,
          layerId === MICROFENCE_LAYER_ID ? EntityType.Microfence : EntityType.Geofence,
        ),
        fresh: true,
        selected: true,
        layerId,
        layerName: foundLayer.getSource()?.get('name') ?? foundLayer.get('name'),
      });

      setCreateEditFence(RequestType.Create);
      if (layerId === MICROFENCE_LAYER_ID) {
        setSelectedMicrofence(e.feature.getProperties());
        setAvailableMicrofences(
          foundLayer
            .getSource()
            ?.getFeatures()
            ?.filter(microfence => !deletedFenceIds.find(m => m.id === microfence.get('id')))
            .map(microfence => microfence.getProperties())
            .sort((a, b) => a.name?.localeCompare(b.name ?? '')),
        );
      } else {
        freshGeofencesRef.current = [...freshGeofencesRef.current, e.feature.getProperties()];
        setFreshGeofences(freshGeofencesRef.current);
        setAvailableGeofences(
          foundLayer
            .getSource()
            .getFeatures()
            .filter(fence => !deletedFenceIds.find(f => f.id === fence.get('id')))
            .map(f => f.getProperties() as GridRowData),
        );
        setSelectedGeofence(e.feature.getProperties() as GridRowData);
      }
    });
    const draw = new Draw({
      type: GeometryType.POLYGON,
      source: foundLayer.getSource(),
      stopClick: true,
    });

    draw.on('drawend', () => {
      layerChanged(olmap);
    });
    const modify = new Modify({
      source: foundLayer.getSource(),
    });

    const translate = new Translate({
      condition: event => {
        return primaryAction(event) && platformModifierKeyOnly(event);
      },
      layers: olmap.getAllLayers(),
    });

    if (foundLayer.getSource()) {
      modify.on('modifyend', (e: ModifyEvent) =>
        featureModified(olmap, e, foundLayer?.getSource() as VectorSource<Geometry>),
      );
    }

    const es = {
      draw: draw,
      modify: modify,
      modifyScaleRot: modify,
      snap: new Snap({
        source: foundLayer.getSource(),
      }),
      translate: translate,
    };

    editRef.current = es;
    olmap.addInteraction(es.draw);
    olmap.addInteraction(es.snap);
    olmap.addInteraction(es.translate);
    setEditing(true);
    document.addEventListener('keyup', removeLastDrawnSegment);
  };

  const createScalableRotatableLayer = (
    olmap: olMap,
  ): VectorLayer<VectorSource<Geometry>> | undefined => {
    if (!selectedLayer) return;
    const foundLayer = getLayerFromMap(olmap, selectedLayer?.id);
    return new VectorLayer({
      className: 'Scalable-rotatable-layer',
      source: foundLayer?.getSource() ?? new VectorSource(),
      style: feature => {
        const type = geometryTypeOfEntity(feature.getProperties());
        const zoneStyle = getGeofenceStyleFromZone({
          zone: feature.get('zone'),
          type,
          layerName: feature.get('layerName'),
        });

        const styles = [
          new Style({
            geometry: feature => {
              const modifyGeometry = feature.get('modifyGeometry');
              return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
            },
            stroke: zoneStyle.getStroke(),
            zIndex: zoneStyle.getZIndex(),
          }),
        ];
        const modifyGeometry = feature.get('modifyGeometry');
        const geometry = modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
        const result = calculateCenterOfFence(
          geometry,
          geometryTypeOfEntity(feature.getProperties() as GridRowData),
        );
        const center = result.center;
        if (center) {
          const coordinates = result.coordinates;
          if (coordinates) {
            const minRadius = result.minRadius;
            const sqDistances = result.sqDistances;
            const rsq = minRadius * minRadius;
            const points = (coordinates as number[][]).filter(
              (coordinate: number[], index: number) => {
                return sqDistances[index] > rsq;
              },
            );
          }
        }
        if (type === FenceGeometryType.Line) {
          return [...styles, ...tripwiresStyle(geometry.flatCoordinates)];
        }
        return styles;
      },
    });
  };

  const updateMeasureTooltip = (olmap: olMap) => {
    if (measureTooltipElementRef.current) {
      measureTooltipElementRef.current.parentNode?.removeChild(measureTooltipElementRef.current);
    }
    measureTooltipElementRef.current = document.createElement('div');
    measureTooltipRef.current = new Overlay({
      element: measureTooltipElementRef.current,
      offset: [0, -15],
      positioning: 'bottom-center',
      stopEvent: false,
      insertFirst: false,
    });
    olmap.addOverlay(measureTooltipRef.current);
  };

  const addShapeChangeModifyInteration = (
    olmap: olMap,
    source: VectorSource<Geometry>,
    type: GeofenceEntityTypeId,
    feature?: Feature<Geometry>,
  ) => {
    if (!editRef.current)
      throw new Error('UI should not be able to modify a feature while not in editing mode');
    if (!olmap) throw new Error('UI should not be able to modify a feature while no map exists');
    refreshInteractions(olmap);

    const modifiableFeatures: Collection<Feature<Geometry>> = new Collection();
    if (feature) {
      modifiableFeatures.push(feature);
    }
    source.forEachFeature(feature => {
      if (
        geometryTypeOfEntity(feature.getProperties() as GridRowData) ===
        (type as unknown as FenceGeometryType)
      ) {
        modifiableFeatures.push(feature);
      }
    });
    editRef.current.modify = new Modify({
      source: source,
      insertVertexCondition: type === GeofenceEntityTypeId.Line ? never : always,
      features: modifiableFeatures,
    });
    if (source.get('name') === TRACKING_BOUNDS && type === GeofenceEntityTypeId.Polygon) {
      editRef.current.modify = interactionStyle(
        Interactions.Modify,
        source,
        GeometryType.POLYGON,
        WHITE,
      ) as Modify;
    } else if (type === GeofenceEntityTypeId.Multipolygon) {
      editRef.current.modify = interactionStyle(
        Interactions.Modify,
        source,
        GeometryType.MULTI_POLYGON,
        CLEARED_ZONE,
      ) as Modify;
    }

    editRef.current.modify.on('modifyend', (e: ModifyEvent) => {
      featureModified(olmap, e, source);
      if (type === GeofenceEntityTypeId.Polygon || type === GeofenceEntityTypeId.Multipolygon) {
        e.features.forEach(feature => {
          const geometry = feature.getGeometry();
          if (!(geometry instanceof Geometry)) return;
          if (selectedGeofence !== feature.get('id')) return;
          if (type === GeofenceEntityTypeId.Polygon) {
            setHasFences(!!featureContainsFeature(geometry, source));
          }
          setSelectedGeofence({
            ...((feature as Feature<Geometry>).getProperties() as GridRowData),
            selected: true,
          });
        });
      }
      if (type !== GeofenceEntityTypeId.Line || !feature) return;
      feature.setStyle(updateGeofencesStyle(new Map()));
    });
    editRef.current.modify.on('modifystart', (e: ModifyEvent) => {
      const feature = e.features.getArray()[0] as Feature<Geometry>;
      feature.set('selected', true);
      setSelectedGeofence({
        ...feature.getProperties(),
      } as GridRowData);
    });
    olmap.addInteraction(editRef.current.modify);
  };

  const addScaleRotModifyInteration = (
    olmap: olMap,
    source: VectorSource<Geometry>,
    type: GeofenceEntityTypeId,
    addedFeature?: Feature<Geometry>,
  ) => {
    refreshInteractions(olmap);
    if (!editRef.current)
      throw new Error('UI should not be able to modify a feature while not in editing mode');
    if (!olmap) throw new Error('UI should not be able to modify a feature while no map exists');

    const modifiableFeatures: Collection<Feature<Geometry>> = new Collection();
    if (addedFeature) {
      modifiableFeatures.push(addedFeature);
    }
    source.forEachFeature(feature => {
      if (
        geometryTypeOfEntity(feature.getProperties() as GridRowData) ===
        (type as unknown as FenceGeometryType)
      ) {
        modifiableFeatures.push(feature);
      }
    });

    editRef.current.modifyScaleRot = new Modify({
      source: source,
      condition: event => {
        return primaryAction(event) && !platformModifierKeyOnly(event);
      },
      deleteCondition: never,
      insertVertexCondition: type === GeofenceEntityTypeId.Line ? never : always,
      style: defaultScaleRotFenceStyle(new Map()),
    });

    const scalableRotatableLayer = createScalableRotatableLayer(olmap);
    if (!scalableRotatableLayer) return;
    editRef.current.translate = new Translate({
      condition: event => {
        return primaryAction(event) && platformModifierKeyOnly(event);
      },
      layers: [scalableRotatableLayer],
    });
    olmap.addLayer(scalableRotatableLayer);

    editRef.current.modifyScaleRot.on('modifystart', (e: ModifyEvent) => {
      olmap.getAllLayers().forEach(layer => {
        if (!(layer instanceof VectorLayer) || layer.getClassName() === 'Scalable-rotatable-layer')
          return;
        layer.setVisible(false);
      });

      e.features.forEach(feature => {
        const geom = feature.getGeometry();
        if (!(geom instanceof Geometry)) return;
        (feature as Feature<Geometry>).set('modifyGeometry', { geometry: geom.clone() }, true);
        setSelectedGeofence({
          ...((feature as Feature<Geometry>).getProperties() as GridRowData),
          selected: true,
        });
      });
      if (type !== GeofenceEntityTypeId.Line) return;
      if (!addedFeature) return;
    });

    editRef.current.modifyScaleRot.on('modifyend', (e: ModifyEvent) => {
      const foundLayer = getLayerFromMap(olmap, selectedLayer?.id);
      if (!selectedLayer || !foundLayer) return;
      changeVisibility(olmap, selectedLayer.id, true);
      e.features.forEach(feature => {
        const geom = feature.getGeometry();
        if (!(geom instanceof Geometry)) return;
        const modifyGeometry = feature.get('modifyGeometry');
        if (modifyGeometry) {
          (feature as Feature<Geometry>).setGeometry(modifyGeometry.geometry);
          (feature as Feature<Geometry>).unset('modifyGeometry', true);
          if (type === GeofenceEntityTypeId.Polygon) {
            setHasFences(!!featureContainsFeature(modifyGeometry.geometry, foundLayer.getSource()));
          }
          setSelectedGeofence({
            ...((feature as Feature<Geometry>).getProperties() as GridRowData),
            selected: true,
          });
        }
      });

      featureModified(olmap, e, source);
      if (type !== GeofenceEntityTypeId.Line || !addedFeature) return;
      addedFeature.setStyle(updateGeofencesStyle(new Map()));
    });

    olmap.addInteraction(editRef.current.modifyScaleRot);
    olmap.addInteraction(editRef.current.translate);
  };

  const refreshInteractions = (olmap: olMap) => {
    if (!olmap || !editRef.current) return;

    olmap.removeInteraction(editRef.current.draw);
    olmap.removeInteraction(editRef.current.modify);
    olmap.removeInteraction(editRef.current.modifyScaleRot);
    olmap.removeInteraction(editRef.current.snap);
    olmap.removeInteraction(editRef.current.translate);
    const scaleRotLayer = olmap
      .getAllLayers()
      .find(l => l.getClassName() === 'Scalable-rotatable-layer');
    if (scaleRotLayer) {
      olmap.removeLayer(scaleRotLayer);
    }
  };

  const removeLastDrawnSegment = (e: KeyboardEvent) => {
    if (e.key === 'Backspace') {
      editRef.current?.draw.removeLastPoint();
    }
  };

  const drawEnd = (
    olmap: olMap,
    geometry: Geometry,
    layerSource: VectorSource<Geometry>,
    type: GeofenceEntityTypeId,
  ) => {
    layerChanged(olmap);
    if (type === GeofenceEntityTypeId.Polygon) {
      setHasFences(!!featureContainsFeature(geometry, layerSource));
    }
    if (!editRef.current) return;
    // Slight hack to reset the editType. The default editType will not be active otherwise.
    setEditType(editType);
    // Needs a short interval for the new polygon to be registered as part of the layer.
    setTimeout(() => {
      addShapeChangeModifyInteration(olmap, layerSource, type);
      if (!editRef.current) return;
      olmap.addInteraction(editRef.current.draw);
    });
  };

  const updateMeasurementType = (
    olmap: olMap,
    type: MeasurementType,
    fenceType: 'Polygon' | 'LineString',
  ) => {
    if (!olmap || !editRef.current) return;
    const foundLayer = getLayerFromMap(olmap, selectedLayer?.id);
    refreshInteractions(olmap);
    if (!selectedLayer || !foundLayer) return;

    editRef.current.draw = new Draw({
      source: foundLayer.getSource(),
      type: fenceType,
      style: measurementToolPonter,
    });

    olmap.addInteraction(editRef.current.draw);
    editRef.current.draw.on('drawstart', e => {
      updateMeasureTooltip(olmap);
      let tooltipCoord = e.feature?.getGeometry().getCoordinates();
      e.feature.getGeometry().on('change', (evt: Event) => {
        const geom = evt.target;
        if (geom instanceof Polygon) {
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
          const area = calculateArea(geom, type);
          if (measureTooltipElementRef.current && measureTooltipRef.current && area) {
            measureTooltipElementRef.current.innerHTML = area;
            measureTooltipElementRef.current.style.background = `rgb(${[0, 0, 0, 0.25]})`;
            measureTooltipElementRef.current.style.borderRadius = '5px';
            measureTooltipRef.current.setPosition(tooltipCoord);
          }
        } else if (geom instanceof LineString) {
          tooltipCoord = geom.getLastCoordinate();
          const distance = calculateMeasurementDistance(geom, type);
          if (measureTooltipElementRef.current && measureTooltipRef.current && distance) {
            measureTooltipElementRef.current.innerHTML = distance;
            measureTooltipElementRef.current.style.background = `rgb(${[0, 0, 0, 0.25]})`;
            measureTooltipElementRef.current.style.borderRadius = '5px';
            measureTooltipRef.current.setPosition(tooltipCoord);
          }
        }
      });
    });

    editRef.current.draw.on('drawend', e => {
      e.feature.set('isMeasurementTool', true);
      e.feature.setStyle(measurementToolOff);
      const foundFence = foundLayer
        .getSource()
        .getFeatures()
        .find(f => f.get('id') === e.feature.get('id'));
      if (foundFence) {
        foundLayer.getSource().removeFeature(foundFence);
      }
      updateMeasureTooltip(olmap);
    });
  };

  const changeDrawShape = (olmap: olMap) => {
    if (!olmap || !editRef.current || !drawType) return;
    const foundLayer = getLayerFromMap(olmap, selectedLayer?.id);
    refreshInteractions(olmap);
    if (!selectedLayer || !foundLayer) return;

    if (editType) {
      switch (editType) {
        case EditType.ShapeChange:
          addShapeChangeModifyInteration(
            olmap,
            foundLayer.getSource(),
            drawType === DrawType.Circle || drawType === DrawType.Polygon
              ? GeofenceEntityTypeId.Polygon
              : drawType === DrawType.Multipolygon
              ? GeofenceEntityTypeId.Multipolygon
              : GeofenceEntityTypeId.Line,
          );
          break;
        case EditType.ScaleRot:
          addScaleRotModifyInteration(
            olmap,
            foundLayer.getSource(),
            drawType === DrawType.Circle || drawType === DrawType.Polygon
              ? GeofenceEntityTypeId.Polygon
              : drawType === DrawType.Multipolygon
              ? GeofenceEntityTypeId.Multipolygon
              : GeofenceEntityTypeId.Line,
          );
          break;
      }
    }

    switch (drawType) {
      case DrawType.Circle:
        if (foundLayer.getSource().get('name') === TRACKING_BOUNDS) {
          editRef.current.draw = interactionStyle(
            Interactions.Draw,
            foundLayer.getSource(),
            GeometryType.CIRCLE,
            WHITE,
          ) as Draw;
          editRef.current.modify = interactionStyle(
            Interactions.Modify,
            foundLayer.getSource(),
            GeometryType.POLYGON,
            WHITE,
          ) as Modify;
        } else {
          editRef.current.draw = new Draw({
            source: foundLayer.getSource(),
            type: GeometryType.CIRCLE,
            stopClick: true,
          });
        }
        olmap.addInteraction(editRef.current.draw);
        editRef.current.draw.on('drawend', e => {
          if (foundLayer.getSource().get('name') === TRACKING_BOUNDS) e.feature.setStyle(undefined);
          drawEnd(
            olmap,
            e.feature.getGeometry(),
            foundLayer.getSource(),
            GeofenceEntityTypeId.Polygon,
          );
          e.feature.set('type', FenceGeometryType.Polygon);
          e.feature.set('zone', FenceZone.none);
          e.feature.set('geomobyProperties', {});
          e.feature.set('geomobyOverrides', []);
          e.feature.setGeometry(fromCircle(e.feature.getGeometry() as Circle));
        });
        break;
      case DrawType.Polygon:
        if (foundLayer.getSource().get('name') === TRACKING_BOUNDS) {
          editRef.current.draw = interactionStyle(
            Interactions.Draw,
            foundLayer.getSource(),
            GeometryType.POLYGON,
            WHITE,
          ) as Draw;
          editRef.current.modify = interactionStyle(
            Interactions.Modify,
            foundLayer.getSource(),
            GeometryType.POLYGON,
            WHITE,
          ) as Modify;
          olmap.addInteraction(editRef.current.draw);
          editRef.current.draw.on('drawstart', e => {
            e.feature.setStyle(trackingFenceDrawStartStyle);
          });
        } else {
          editRef.current.draw = new Draw({
            source: foundLayer.getSource(),
            type: GeometryType.POLYGON,
            stopClick: true,
          });
          olmap.addInteraction(editRef.current.draw);
        }
        foundLayer.setStyle(updateGeofencesStyle(new Map()));
        editRef.current.draw.on('drawend', e => {
          if (foundLayer.getSource().get('name') === TRACKING_BOUNDS) e.feature.setStyle(undefined);
          drawEnd(
            olmap,
            e.feature.getGeometry(),
            foundLayer.getSource(),
            GeofenceEntityTypeId.Polygon,
          );
          e.feature.set('type', FenceGeometryType.Polygon);
          e.feature.set('zone', FenceZone.none);
          e.feature.set('geomobyProperties', {});
          e.feature.set('geomobyOverrides', []);
        });
        break;
      case DrawType.Multipolygon:
        editRef.current.draw = interactionStyle(
          Interactions.Draw,
          foundLayer.getSource(),
          GeometryType.MULTI_POLYGON,
          CLEARED_ZONE,
        ) as Draw;
        editRef.current.modify = interactionStyle(
          Interactions.Modify,
          foundLayer.getSource(),
          GeometryType.MULTI_POLYGON,
          CLEARED_ZONE,
        ) as Modify;
        olmap.addInteraction(editRef.current.draw);
        editRef.current.draw.on('drawstart', e => {
          e.feature.setStyle(clearedFenceDrawStartStyle);
        });

        foundLayer.setStyle(updateGeofencesStyle(new Map()));
        editRef.current.draw.on('drawend', e => {
          e.feature.setStyle(undefined);
          drawEnd(
            olmap,
            e.feature.getGeometry(),
            foundLayer.getSource(),
            GeofenceEntityTypeId.Multipolygon,
          );
          e.feature.set('type', FenceGeometryType.Multipolygon);
          e.feature.set('zone', FenceZone.cleared);
          e.feature.set('geomobyProperties', {});
          e.feature.set('geomobyOverrides', []);
        });
        break;
      case DrawType.Tripwire:
        editRef.current.draw = new Draw({
          source: foundLayer.getSource(),
          type: GeometryType.LINE_STRING,
          stopClick: true,
          maxPoints: 2,
          minPoints: 2,
        });
        olmap.addInteraction(editRef.current.draw);
        foundLayer.setStyle(updateGeofencesStyle(new Map()));
        editRef.current.draw.on('drawend', e => {
          drawEnd(
            olmap,
            e.feature.getGeometry(),
            foundLayer.getSource(),
            GeofenceEntityTypeId.Line,
          );
          e.feature.set('type', FenceGeometryType.Line);
          e.feature.set('geomobyProperties', {});
          e.feature.set('geomobyOverrides', []);
          if (!editRef.current) return;
          olmap.removeInteraction(editRef.current.modify);
        });
        break;
      case DrawType.MicrofenceGateway:
        editRef.current.draw = new Draw({
          source: foundLayer.getSource(),
          type: GeometryType.POINT,
          stopClick: true,
        });
        olmap.addInteraction(editRef.current.draw);
        editRef.current.draw.on('drawend', e => {
          layerChanged(olmap);
          e.feature.set('type', MicrofenceEntity.Gateway);
          e.feature.set(
            'geomobyProperties',
            Object.fromEntries(
              Object.entries(MICROFENCE_DEFAULT_PROPS).map(([key, val]) => [key, String(val)]),
            ),
          );
          e.feature.set('assetId', {
            gatewayId: '',
          });
          e.feature.set('point', {
            type: 'Point',
            coordinates: transform(
              (e.feature.getGeometry() as Point)?.getCoordinates(),
              'EPSG:3857',
              'EPSG:4326',
            ),
          });
        });

        editRef.current.modify = new Modify({
          source: foundLayer.getSource(),
          hitDetection: olmap.getAllLayers().find(l => l.get('id') === MICROFENCE_LAYER_ID) as
            | HitDetectionLayer
            | undefined,
        });
        olmap.addInteraction(editRef.current.modify);
        editRef.current.modify.on('modifyend', (e: ModifyEvent) =>
          featureModified(olmap, e, foundLayer.getSource()),
        );
        break;
      case DrawType.MicrofenceBeacon:
        editRef.current.draw = new Draw({
          source: foundLayer.getSource(),
          type: GeometryType.POINT,
          stopClick: true,
        });
        olmap.addInteraction(editRef.current.draw);
        editRef.current.draw.on('drawend', e => {
          layerChanged(olmap);
          e.feature.set('type', MicrofenceEntity.Beacon);
          e.feature.set(
            'geomobyProperties',
            Object.fromEntries(
              Object.entries(MICROFENCE_DEFAULT_PROPS).map(([key, val]) => [key, String(val)]),
            ),
          );
          e.feature.set('assetId', {
            uuid: '',
            major: 0,
            minor: 0,
          });
          e.feature.set('point', {
            type: 'Point',
            coordinates: transform(
              (e.feature.getGeometry() as Point)?.getCoordinates(),
              'EPSG:3857',
              'EPSG:4326',
            ),
          });
        });

        editRef.current.modify = new Modify({
          source: foundLayer.getSource(),
          hitDetection: olmap.getAllLayers().find(l => l.get('id') === MICROFENCE_LAYER_ID) as
            | HitDetectionLayer
            | undefined,
        });
        olmap.addInteraction(editRef.current.modify);
        editRef.current.modify.on('modifyend', (e: ModifyEvent) =>
          featureModified(olmap, e, foundLayer.getSource()),
        );
        break;
      case DrawType.MicrofenceDevice:
        editRef.current.draw = new Draw({
          source: foundLayer.getSource(),
          type: GeometryType.POINT,
          stopClick: true,
        });
        olmap.addInteraction(editRef.current.draw);
        editRef.current.draw.on('drawend', e => {
          layerChanged(olmap);
          e.feature.set('type', MicrofenceEntity.Device);
          e.feature.set(
            'geomobyProperties',
            Object.fromEntries(
              Object.entries(MICROFENCE_DEFAULT_PROPS).map(([key, val]) => [key, String(val)]),
            ),
          );
          e.feature.set('assetId', {
            deviceId: '',
          });
          e.feature.set('point', {
            type: 'Point',
            coordinates: transform(
              (e.feature.getGeometry() as Point)?.getCoordinates(),
              'EPSG:3857',
              'EPSG:4326',
            ),
          });
        });
        editRef.current.modify = new Modify({
          source: foundLayer.getSource(),
          hitDetection: olmap.getAllLayers().find(l => l.get('id') === MICROFENCE_LAYER_ID) as
            | HitDetectionLayer
            | undefined,
        });
        olmap.addInteraction(editRef.current.modify);
        editRef.current.modify.on('modifyend', (e: ModifyEvent) =>
          featureModified(olmap, e, foundLayer.getSource()),
        );
        break;
      case DrawType.Measure:
        changeMeasurementType(olmap, MeasurementType.M);
        break;
    }
  };

  const changeMeasurementType = (olmap: olMap, type: MeasurementType | undefined) => {
    setMeasurementType(type);
    if (!type) return;

    if (type.includes('2')) {
      updateMeasurementType(olmap, type, 'Polygon');
    } else {
      updateMeasurementType(olmap, type, 'LineString');
    }
  };

  const updateBufferZoneGeometry = async (
    olmap: olMap,
    fence: GridRowData,
    offset: number,
    bufferShape: BufferShapeType,
  ): Promise<{ breach: Feature<Polygon>; buffer: Feature<Polygon> } | undefined> => {
    if (!olmap) throw new Error('UI should not be able to modify a feature while no map exists');

    const feature = await findFeature(olmap, fence.id, fence.layerId, FenceGeometryType.Polygon);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');

    const geometry = (feature as Feature<Geometry>).getGeometry();
    if (!geometry)
      throw new Error('UI should not be able to add zones to a feature not without a geometry');

    const coords: number[][] = (geometry as Polygon).getCoordinates()[0];
    if (!coords.length)
      throw new Error('UI should not be able to add zones to a feature without coords');

    // Find a circle that contains the feature
    const center = getCenter(geometry.getExtent());
    const centerRadius = Math.max(
      ...coords.map(coord => new LineString([center, coord]).getLength()),
    );

    // Using the centroid may result in a smaller circle
    const centroid = coords
      .reduce((acc, cur) => [acc[0] + cur[0], acc[1] + cur[1]])
      .map(sum => sum / coords.length);
    const centroidRadius = Math.max(
      ...coords.map(coord => new LineString([centroid, coord]).getLength()),
    );

    const radiusScaleFactor = 1.005; // Determined by trial and error
    const coordinates = (geometry as Polygon)
      .getCoordinates()[0]
      .map(coord => transform(coord, 'EPSG:3857', 'EPSG:4326'));
    const maxY = Math.max(...coordinates.map(coord => coord[1]));
    const minY = Math.min(...coordinates.map(coord => coord[1]));
    const scaledOffset = offset / Math.cos((((maxY + minY) / 2) * Math.PI) / 180);

    // Use whichever has the smaller radius, adding on the buffer distance (scaling both as per the above)
    if (bufferShape === BufferShapeType.Circle) {
      return {
        breach: feature as Feature<Polygon>,
        buffer: new Feature(
          fromCircle(
            centerRadius < centroidRadius
              ? new Circle(center, centerRadius * radiusScaleFactor + scaledOffset)
              : new Circle(centroid, centroidRadius * radiusScaleFactor + scaledOffset),
          ),
        ),
      };
    } else {
      const bufferZone = (
        await axios.post<{ points: { type: string; coordinates: number[][][] } }>(
          `${triggersUrl}/${cid}/${pid}/geofences/${feature.get('layerId')}/polygon/build-zone`,
          {
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates],
            },
            bufferMetres: offset === undefined || offset < 1 ? 1 : offset,
            limit: 1000,
          },
          authedRequestConfig,
        )
      ).data;

      const bufferPoints = new GeoJSON().readFeature(bufferZone.points, {
        featureProjection: 'EPSG:4326',
      });
      bufferPoints.getGeometry().applyTransform(gpsToMap);
      return {
        breach: feature as Feature<Polygon>,
        buffer: new Feature({
          geometry: new Polygon((bufferPoints?.getGeometry() as Polygon).getCoordinates()),
        }),
      };
    }
  };

  const setAsBreachZone = async (
    olmap: olMap,
    fence: GridRowData,
    bufferId: string | undefined,
    offset: number,
    bufferShape: BufferShapeType,
  ): Promise<void> => {
    const layer = getLayerFromMap(olmap, fence.layerId);
    if (!layer) throw new Error('UI should not be able to delete a feature nonexistent layer.');

    const geometries = await updateBufferZoneGeometry(olmap, fence, offset, bufferShape);
    if (!geometries) throw new Error('UI was unable to create/update the desired buffer zone.');

    const breach = geometries.breach;
    const buffer = geometries.buffer;
    if (bufferId) {
      const existingBuffer = await findFeature(
        olmap,
        bufferId,
        fence.layerId,
        FenceGeometryType.Polygon,
      );
      if (existingBuffer) {
        const updatedBuffer = buffer;
        updatedBuffer.setProperties({
          ...existingBuffer.getProperties(),
          geometry: updatedBuffer.getGeometry(),
          fresh: existingBuffer.get('id')?.includes(FRESH),
          updated: !existingBuffer.get('id')?.includes(FRESH),
        });
        layer.getSource().removeFeature(existingBuffer);
        layer.getSource().addFeature(updatedBuffer);
        return;
      }
    }

    const newFenceName = `${breach.get('name')}_warning`.replace(/(\D)0$/, '$1');
    const newfenceId = `fresh-buffer-${new Date().getTime()}`;
    buffer.setProperties({
      name: newFenceName,
      id: newfenceId,
      layerId: breach.get('layerId'),
      type: 'polygon',
      fresh: true,
      zone: FenceZone.buffer,
      geomobyProperties: {},
      geomobyOverrides: [],
      parentId: breach.get('id'),
    });
    layer.getSource().addFeature(buffer);

    breach.setProperties({
      zone: FenceZone.breach,
      parentId: undefined,
      updated: true,
    });

    const breachIsFresh = freshGeofencesRef.current.find(f => f.id === breach.get('id'));
    if (breachIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== breach.get('id')),
        { ...breachIsFresh, zone: FenceZone.breach },
      ];
    }

    if (availableGeofences.length > 0) {
      const index = availableGeofences.findIndex(f => f.id === fence.id);
      const existingBufferIndex = availableGeofences.findIndex(
        f => f.id === breach.get('parentId'),
      );
      if (existingBufferIndex > -1) {
        availableGeofences.splice(existingBufferIndex, 1);
      }
      const bufferZone = {
        name: newFenceName,
        id: newfenceId,
        type: 'polygon',
        zone: FenceZone.buffer,
        geomobyProperties: {},
        geomobyOverrides: [] as GeomobyOverride[],
        parentId: breach.get('id'),
      } as FenceNameIdZone;
      if (index > -1) {
        availableGeofences[index].zone = FenceZone.breach;
        availableGeofences[index].parentId = undefined;
      }
      freshGeofencesRef.current = [...freshGeofencesRef.current, bufferZone];
      availableGeofences.splice(index + 1, 0, bufferZone);
    }
    setAvailableGeofences(availableGeofences);
    setFreshGeofences(freshGeofencesRef.current);
    setZoneChange(new Date());
  };

  const setAsBufferZone = async (olmap: olMap, fence: GridRowData): Promise<void> => {
    const foundLayer = getLayerFromMap(olmap, selectedLayer?.id);
    if (!selectedLayer || !foundLayer)
      throw new Error('UI should not be able to modify a feature while no layer selected');
    if (!olmap) throw new Error('UI should not be able to modify a feature while no map exists');

    const feature = await findFeature(olmap, fence.id, selectedLayer.id, FenceGeometryType.Polygon);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');

    const geometry = feature.getGeometry();
    if (!geometry)
      throw new Error('UI should not be able to add zones to a feature without geometry');
    const containedFeature = featureContainsFeature(geometry, foundLayer.getSource());

    if (!containedFeature?.get('parentId')) {
      containedFeature?.setProperties({
        zone: FenceZone.breach,
        type: 'polygon',
        parentId: undefined,
        updated: true,
      });
      feature.setProperties({
        zone: FenceZone.buffer,
        type: 'polygon',
        parentId: containedFeature?.get('id'),
        name: `${containedFeature?.get('name')}_warning`,
        updated: true,
      });
    }

    const bufferIsFresh = freshGeofencesRef.current.find(f => f.id === feature.get('id'));
    if (bufferIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== feature.get('id')),
        {
          ...bufferIsFresh,
          name: `${containedFeature?.get('name')}_warning`,
          zone: FenceZone.buffer,
        },
      ];
    }
    const breachIsFresh = freshGeofencesRef.current.find(f => f.id === containedFeature?.get('id'));
    if (breachIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== containedFeature?.get('id')),
        { ...breachIsFresh, zone: FenceZone.breach },
      ];
    }

    if (selectedLayer?.id !== MICROFENCE_LAYER_ID) {
      if (availableGeofences.length > 0) {
        const bufferIndex = availableGeofences.findIndex(f => f.id === fence.id);
        if (bufferIndex > -1 && containedFeature) {
          const containedFeatureIndex = availableGeofences.findIndex(
            f => f.id === containedFeature.getProperties().id,
          );
          if (containedFeatureIndex > -1) {
            availableGeofences[containedFeatureIndex].zone = FenceZone.breach;
            availableGeofences[containedFeatureIndex].parentId = undefined;
          }
          availableGeofences[bufferIndex].zone = FenceZone.buffer;
          availableGeofences[bufferIndex].parentId = containedFeature.get('id');
          availableGeofences[bufferIndex].name = `${containedFeature.get('name')}_warning`;
        }
      }
      setAvailableGeofences(availableGeofences);
    }
    setSelectedGeofence(feature.getProperties() as GridRowData);
    setZoneChange(new Date());
    setFreshGeofences(freshGeofencesRef.current);
  };

  const unsetAsBreachZone = async (
    olmap: olMap,
    fence: GridRowData,
    bufferId: string,
  ): Promise<void> => {
    const foundLayer = getLayerFromMap(olmap, selectedLayer?.id);
    if (!selectedLayer || !foundLayer)
      throw new Error('UI should not be able to modify a feature while no layer selected');
    if (!olmap) throw new Error('UI should not be able to modify a feature while no map exists');

    const feature = await findFeature(olmap, fence.id, selectedLayer.id, FenceGeometryType.Polygon);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');

    const relatedFeature = await findFeature(
      olmap,
      bufferId,
      selectedLayer.id,
      FenceGeometryType.Polygon,
    );
    if (relatedFeature) {
      deleteFeature(olmap, relatedFeature, FenceGeometryType.Polygon);
    }
    feature.setProperties({
      zone: FenceZone.none,
      parentId: undefined,
      updated: true,
    });

    const breachIsFresh = freshGeofencesRef.current.find(f => f.id === feature.get('id'));
    if (breachIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== feature.get('id')),
        { ...breachIsFresh, zone: FenceZone.none },
      ];
    }

    if (selectedLayer?.id !== MICROFENCE_LAYER_ID) {
      if (availableGeofences.length > 0 && relatedFeature) {
        const relatedFeatureIndex = availableGeofences.findIndex(
          f => f.id === relatedFeature.get('id'),
        );
        if (relatedFeatureIndex > -1) {
          freshGeofencesRef.current = freshGeofencesRef.current.filter(
            f => f.id !== relatedFeature?.get('id'),
          );
          setFreshGeofences(freshGeofencesRef.current);
          availableGeofences.splice(relatedFeatureIndex, 1);
        }
        const breachIndex = availableGeofences.findIndex(f => f.id === feature.get('id'));
        if (breachIndex > -1) {
          availableGeofences[breachIndex].zone = FenceZone.none;
          availableGeofences[breachIndex].parentId = undefined;
        }
      }
      setAvailableGeofences(availableGeofences);
    }
    setSelectedGeofence(feature.getProperties() as GridRowData);
    setZoneChange(new Date());
  };

  const setZone = async (olmap: olMap, fence: GridRowData, zone: FenceZone): Promise<void> => {
    const foundLayer = getLayerFromMap(olmap, selectedLayer?.id);
    if (!selectedLayer || !foundLayer)
      throw new Error('UI should not be able to modify a feature while no layer selected');
    if (!olmap) throw new Error('UI should not be able to modify a feature while no map exists');

    const feature = await findFeature(olmap, fence.id, selectedLayer.id, FenceGeometryType.Polygon);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');

    feature.setProperties({
      ...feature.getProperties(),
      zone: zone,
      updated: true,
    });

    const zoneIsFresh = freshGeofencesRef.current.find(f => f.id === feature.get('id'));
    if (zoneIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== feature.get('id')),
        { ...zoneIsFresh, zone: zone },
      ];
    }

    if (selectedLayer?.id !== MICROFENCE_LAYER_ID) {
      if (availableGeofences.length > 0) {
        const index = availableGeofences.findIndex(f => f.id === fence.id);
        if (index > -1) {
          availableGeofences[index].zone = zone;
          availableGeofences[index].parentId = undefined;
        }
      }
      setAvailableGeofences(availableGeofences);
    }

    setSelectedGeofence(feature.getProperties() as GridRowData);
    setFreshGeofences(freshGeofencesRef.current);
  };

  const unsetZone = async (olmap: olMap, fence: GridRowData): Promise<void> => {
    const foundLayer = getLayerFromMap(olmap, selectedLayer?.id);
    if (!selectedLayer || !foundLayer)
      throw new Error('UI should not be able to modify a feature while no layer selected');
    if (!olmap) throw new Error('UI should not be able to modify a feature while no map exists');

    const feature = await findFeature(olmap, fence.id, selectedLayer.id, FenceGeometryType.Polygon);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');

    feature.setProperties({
      zone: FenceZone.none,
      parentId: undefined,
      updated: true,
    });

    const clearedIsFresh = freshGeofencesRef.current.find(f => f.id === feature.get('id'));
    if (clearedIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== feature.get('id')),
        { ...clearedIsFresh, zone: FenceZone.none },
      ];
    }

    if (selectedLayer?.id !== MICROFENCE_LAYER_ID) {
      if (availableGeofences.length > 0) {
        const clearedIndex = availableGeofences.findIndex(f => f.id === feature.get('id'));
        if (clearedIndex > -1) {
          availableGeofences[clearedIndex].zone = FenceZone.none;
          availableGeofences[clearedIndex].parentId = undefined;
        }
      }
      setAvailableGeofences(availableGeofences);
    }
    setSelectedGeofence(feature.getProperties() as GridRowData);
    setZoneChange(new Date());
    setFreshGeofences(freshGeofencesRef.current);
  };

  const removeBufferZone = async (olmap: olMap, fence: GridRowData): Promise<void> => {
    const foundLayer = getLayerFromMap(olmap, selectedLayer?.id);
    if (!selectedLayer || !foundLayer)
      throw new Error('UI should not be able to modify a feature while no layer selected');
    if (!olmap) throw new Error('UI should not be able to modify a feature while no map exists');

    const feature = await findFeature(olmap, fence.id, selectedLayer.id, FenceGeometryType.Polygon);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');

    const relatedFeature = feature.get('parentId')
      ? foundLayer
          .getSource()
          .getFeatures()
          .find(f => f.get('id') === feature.get('parentId'))
      : undefined;
    relatedFeature?.setProperties({
      zone: FenceZone.none,
      updated: true,
    });

    deleteFeature(olmap, feature, FenceGeometryType.Polygon);

    if (selectedLayer?.id !== MICROFENCE_LAYER_ID) {
      if (availableGeofences.length > 0) {
        const relatedFeatureIndex = availableGeofences.findIndex(
          f => f.id === relatedFeature?.get('id') || f.id === feature.get('parentId'),
        );
        if (relatedFeatureIndex > -1) {
          availableGeofences[relatedFeatureIndex].zone = FenceZone.none;
          availableGeofences[relatedFeatureIndex].parentId = undefined;
        }

        const bufferIndex = availableGeofences.findIndex(f => f.id === fence.id);
        if (bufferIndex > -1) {
          availableGeofences.splice(bufferIndex, 1);
        }
      }
      setAvailableGeofences(availableGeofences);
    }
    setSelectedGeofence(feature.getProperties() as GridRowData);
    setZoneChange(new Date());
  };

  const updateGeomobyOverrides = async (
    olmap: olMap,
    geomobyOverrides: GeomobyOverride[],
  ): Promise<GeomobyOverride[] | undefined> => {
    const foundLayer = getLayerFromMap(olmap, selectedLayer?.id);
    if (!selectedLayer || !foundLayer) return;
    if (!olmap) throw new Error('UI should not be able to modify a feature while no map exists');
    if (!selectedGeofence)
      throw new Error('UI should not be able to add geomobyOverrides while no feature is selected');

    const feature = foundLayer
      .getSource()
      .getFeatures()
      .find(f => f.get('id') === selectedGeofence?.id);
    let foundFeature;
    if (!feature) {
      const fenceType = geometryTypeOfEntity(selectedGeofence);
      foundFeature = await findFeature(
        olmap,
        selectedGeofence?.id,
        selectedGeofence?.layerId,
        fenceType,
      );
      if (foundFeature) {
        foundFeature.setProperties({
          ...foundFeature.getProperties(),
          geomobyOverrides,
          updated: true,
        });
      }
    }

    if (!feature && !foundFeature)
      throw new Error(
        'UI should not be able to add geomobyOverrides to a feature not in the selected layer',
      );

    if (feature) {
      feature.setProperties({
        geomobyOverrides,
        updated: true,
      });
    }

    setLayersHaveChanged(true);
    return geomobyOverrides;
  };

  const updateGeomobyProperties = async (
    olmap: olMap,
    geomobyProperties: Record<string, string>,
  ): Promise<Record<string, string> | undefined> => {
    const foundLayer = getLayerFromMap(olmap, selectedLayer?.id);
    if (!selectedLayer || !foundLayer)
      throw new Error('UI should not be able to modify a feature while no layer selected');
    if (!olmap) throw new Error('UI should not be able to modify a feature while no map exists');
    if (!selectedGeofence && !selectedMicrofence)
      throw new Error(
        'UI should not be able to add geomobyProperties while no feature is selected',
      );

    const feature = foundLayer
      .getSource()
      .getFeatures()
      .find(f => f.get('id') === (selectedGeofence?.id ?? selectedMicrofence?.id));
    let foundFeature;
    if (!feature) {
      const fenceType = selectedGeofence ? geometryTypeOfEntity(selectedGeofence) : undefined;
      foundFeature = await findFeature(
        olmap,
        selectedGeofence?.id,
        selectedGeofence?.layerId,
        fenceType,
      );
      if (foundFeature) {
        foundFeature.setProperties({
          ...foundFeature.getProperties(),
          geomobyProperties,
          updated: true,
        });
      }
    }

    if (!feature && !foundFeature)
      throw new Error(
        'UI should not be able to add geomobyProperties to a feature not in the selected layer',
      );

    if (feature) {
      feature.setProperties({
        geomobyProperties,
        updated: true,
      });
    }

    setLayersHaveChanged(true);
    return geomobyProperties;
  };

  const getFreshGeofenceName = (olmap: olMap, layerId: string, type: EntityType): string => {
    const features = getLayerFromMap(olmap, layerId)?.getSource()?.getFeatures() ?? [];
    const freshFeatureCount = features.filter(f => f.get('fresh'))?.length;
    return (
      jsUcFirst(type) +
      Number(
        layerId === MICROFENCE_LAYER_ID
          ? features.length
          : totalFenceCountRef.current + freshFeatureCount + 1,
      )
    );
  };

  const moveUnknownFenceToExistingLayer = (olmap: olMap, layerId: string) => {
    const freshUnknownLayer = getLayerFromMap(olmap, undefined, UNKNOWN_LAYER);
    const existingLayer = getLayerFromMap(olmap, layerIds.find(lyr => lyr.id === layerId)?.id);
    if (freshUnknownLayer && existingLayer && olmap) {
      const freshUnknownFence = freshUnknownLayer.getSource().getFeatures()?.[0];
      freshUnknownFence.set('layerId', existingLayer.getSource().get('id'));
      freshUnknownFence.set('layerName', existingLayer.getSource().get('name'));
      existingLayer.getSource().addFeature(freshUnknownFence);
      olmap.removeLayer(freshUnknownLayer);
      setLayerIds(layerIds.filter(lyr => lyr.id !== UNKNOWN_LAYER));
    }
  };

  // Initialise map
  useEffect(() => {
    if (!specifiedCoordinates || mapState?.map) return;

    const initialise = async () => {
      const { map: olmap, setSource: setMapSource } = createMap(
        MapType.EDIT_MAP,
        createMapDefaults({
          sourceType: mapSourceType,
          edit: true,
          specifiedCoordinates: specifiedCoordinates ?? [initialLongitude, initialLatitude],
          mapApiKeys,
        }),
      );
      setSourceRef.current = setMapSource;

      setFencesLoading(true);
      const [vectorLayers, microfenceLayer] = await Promise.all([
        getVectorLayers(triggersUrl, authedRequestConfig, { cid, pid }, bounds),
        getMicrofencesVectorLayer(triggersUrl, authedRequestConfig, { cid, pid }, bounds),
      ]);

      setLayerIds([
        {
          id: MICROFENCE_LAYER_ID,
          name: MICROFENCE_LAYER_LABEL,
        },
        ...(vectorLayers?.map(vectorLayer => {
          return {
            id: vectorLayer.id,
            name: vectorLayer.name,
          };
        }) ?? []),
      ]);
      vectorLayers?.forEach(vectorLayer => {
        vectorLayer.source.setSource(vectorLayer.source.getSource());
        olmap.addLayer(vectorLayer.source);
      });
      olmap.addLayer(microfenceLayer);

      setFencesLoading(false);
      setMapIsLoading(false);
      if (!olmap || olmap.hasListener('click')) return;
      setMapClickDeselection(olmap);
      setMapMoveHandler(olmap);
      setCurrentCenter(olmap.getView().getCenter());

      // LTP-1236 Editor map re-factoring Phase 3 may involve putting crucial values in the one object, as is done with Live/Replay
      setMapState({
        map: olmap,
      });
    };

    const timerId: NodeJS.Timeout = setTimeout(() => {
      initialise();
    }, 100);
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specifiedCoordinates, mapState?.map]);

  // Load More Fences
  const updateMap = useCallback(
    async (olmap: olMap, newBounds: Bounds, totalRefresh?: boolean) => {
      if (!olmap || editing) return;

      const loadMoreFences = async () => {
        setFencesLoading(true);
        const { latitude, longitude, extentInDegrees } = newBounds;
        const [xMax, yMax] = transform(
          [longitude + extentInDegrees, latitude + extentInDegrees / 2],
          'EPSG:4326',
          'EPSG:3857',
        );
        const [xMin, yMin] = transform(
          [longitude - extentInDegrees, latitude - extentInDegrees / 2],
          'EPSG:4326',
          'EPSG:3857',
        );
        const extent: [number, number, number, number] = [xMax, yMax, xMin, yMin];
        // already got features for this extent
        if (knownExtentsRef.current.some(ext => containsExtent(ext, extent))) {
          setFencesLoading(false);
          return;
        }

        getVectorLayers(triggersUrl, authedRequestConfig, { cid, pid }, newBounds)
          .then(vectorLayers => {
            setFencesLoading(false);
            vectorLayers?.forEach(vectorLayer => {
              const existingLayer = getLayerFromMap(olmap, undefined, vectorLayer.name);
              const newFeatures = vectorLayer.source.getSource().getFeatures();
              const existingSource = existingLayer?.getSource();
              const existingFeatures = existingSource?.getFeatures() || [];
              const deletedFeatures: [string, boolean][] = deletedFenceIds.map(({ id }) => [
                id,
                true,
              ]);
              const keepIds = Object.fromEntries([
                ...existingFeatures.map(f => [`${f.get('id')}`, true]),
                ...deletedFeatures,
              ]);
              const keepFeatures = newFeatures.filter(f => !keepIds[f.get('id')]);
              existingSource?.addFeatures(keepFeatures);
            });
          })
          .catch(error => {
            setFencesLoading(false);
            const errorMessage = normaliseErrorMessage(error as AxiosError, EntityType.Microfence);
            setSaveNotification({
              id: SaveResult.FAIL,
              action: '',
              message: errorMessage,
            });
          });
        knownExtentsRef.current = [...knownExtentsRef.current, extent];
      };
      loadMoreFences();
      if (
        selectedGeofence &&
        selectedLayer?.id !== MICROFENCE_LAYER_ID &&
        geometryTypeOfEntity(selectedGeofence) !== FenceGeometryType.Line
      ) {
        const geometry = selectedGeofence?.geometry;
        const foundLayer = getLayerFromMap(olmap, selectedLayer?.id);
        if (geometry && foundLayer) {
          setHasFences(!!featureContainsFeature(geometry, foundLayer.getSource()));
        }
      }
    },
    [
      authedRequestConfig,
      cid,
      deletedFenceIds,
      editing,
      pid,
      selectedGeofence,
      selectedLayer?.id,
      setSaveNotification,
      triggersUrl,
    ],
  );

  // Handle selected layer change
  useEffect(() => {
    if (!mapState?.map) return;
    if (selectedLayer?.name === UNKNOWN_LAYER) return;
    if (selectedLayer) {
      setDrawType(undefined);
      if (editing) {
        stopEditingFeature(mapState.map);
      }
      layerIds.forEach(layer =>
        changeVisibility(
          mapState.map,
          layer.id,
          layer.id === selectedLayer?.id || showGhostGeofences,
        ),
      );
    } else {
      setAvailableGeofences([]);
      setSelectedGeofence(undefined);
      setSelectedMicrofence(undefined);
      deselectFences(mapState?.map);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLayer, mapState?.map]);

  useEffect(() => {
    // Ideally it would be better do this everytime fenceIds changes but we can't always capture that event.
    if (!mapState?.map) return;
    if (editRef.current) {
      const e = editRef.current;
      mapState.map.removeInteraction(e.modify);
      mapState.map.removeInteraction(e.modifyScaleRot);
    }

    if (selectedLayer && drawType) {
      const foundLayer = getLayerFromMap(mapState.map, selectedLayer.id);
      if (!foundLayer) return;
      const modifiedInteration =
        drawType === DrawType.Tripwire
          ? GeofenceEntityTypeId.Line
          : drawType === DrawType.Circle || drawType === DrawType.Polygon
          ? GeofenceEntityTypeId.Polygon
          : drawType === DrawType.Multipolygon
          ? GeofenceEntityTypeId.Multipolygon
          : undefined;
      if (modifiedInteration && foundLayer) {
        addShapeChangeModifyInteration(mapState.map, foundLayer.getSource(), modifiedInteration);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoneChange, mapState?.map]);

  useEffect(() => {
    if (!mapState?.map) return;
    changeDrawShape(mapState.map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawType, editType, mapState?.map]);

  // Drop pin for location search
  useEffect(() => {
    if (!mapState?.map) return;
    if (!locationSearchData) {
      mapState.map.setLayers(
        mapState.map.getAllLayers().filter(layer => layer?.getClassName() !== 'pin-layer'),
      );
      return;
    }

    const { coords, address, isStreetAddress } = locationSearchData;
    animateToSearchedLocation(mapState?.map.getView(), coords, address, isStreetAddress).then(
      dropLocationPin => {
        const pinLayer = mapState.map
          .getAllLayers()
          .find(layer => layer?.getClassName() === 'pin-layer');
        if (pinLayer) return;
        mapState.map.addLayer(
          new VectorLayer({
            className: 'pin-layer',
            source: new VectorSource({
              features: [dropLocationPin],
            }),
            style: defaultDropPinStyle,
          }),
        );
      },
    );
  }, [locationSearchData, animateToSearchedLocation, mapState?.map]);

  // Get a true count of the features in the current layer.
  useEffect(() => {
    if (geofenceFilter !== undefined) return;
    totalFenceCountRef.current = paginatedCount;
  }, [paginatedCount, geofenceFilter]);

  return (
    <>
      <SidebarAndMap
        sidebar={
          <>
            <SearchList
              mapState={mapState}
              layerIds={layerIds?.sort((a, b) => a.name.localeCompare(b.name))}
              setLayerIds={setLayerIds}
              availableGeofences={availableGeofences}
              setAvailableGeofences={setAvailableGeofences}
              freshGeofences={freshGeofences}
              setFreshGeofences={setFreshGeofences}
              availableMicrofences={availableMicrofences}
              setAvailableMicrofences={setAvailableMicrofences}
              selectedGeofence={selectedGeofence}
              setSelectedGeofence={setSelectedGeofence}
              selectedMicrofence={selectedMicrofence}
              setSelectedMicrofence={setSelectedMicrofence}
              count={paginatedCount}
              setCount={setPaginatedCount}
              setExtent={setUserExtent}
              searchType={searchType}
              setSearchType={setSearchType}
              geofenceFilter={geofenceFilter}
              setGeofenceFilter={setGeofenceFilter}
              microfenceFilter={microfenceFilter}
              setMicrofenceFilter={setMicrofenceFilter}
              assetFilter={assetFilter}
              setAssetFilter={setAssetFilter}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              showGhostGeofences={showGhostGeofences}
              setShowGhostGeofences={setShowGhostGeofences}
              createEditLayer={createEditLayer}
              setCreateEditLayer={setCreateEditLayer}
              refreshSearch={refreshSearch}
              setRefreshSearch={setRefreshSearch}
              setLocationSearchData={setLocationSearchData}
              currentCenter={currentCenter}
              locationDisplay={locationDisplay}
              setLocationDisplay={setLocationDisplay}
              setDrawType={setDrawType}
              layersHaveChanged={layersHaveChanged}
              setLayersHaveChanged={setLayersHaveChanged}
              selectedLayer={selectedLayer}
              setSelectedLayer={setSelectedLayer}
              setRenamingLayer={setRenamingLayer}
              reassignedFences={reassignedFences}
              setReassignedFences={setReassignedFences}
              setDirtySave={setDirtySave}
              dirtySave={dirtySave}
              openGenericDialog={openGenericDialog}
              setOpenGenericDialog={setOpenGenericDialog}
              mapIsLoading={mapIsLoading}
              isLoading={paginating}
              setIsLoading={setPaginating}
              createEditFence={createEditFence}
              setCreateEditFence={setCreateEditFence}
              selectedFromMap={selectedFromMap}
              setSelectedFromMap={setSelectedFromMap}
              hasFences={hasFences}
              deselectFence={deselectFence}
              setDeselectFence={setDeselectFence}
              displayGeomobyOverrides={displayGeomobyOverrides}
              setDisplayGeomobyOverrides={setDisplayGeomobyOverrides}
              displayGeomobyProperties={displayGeomobyProperties}
              setDisplayGeomobyProperties={setDisplayGeomobyProperties}
              knownExtentsRef={knownExtentsRef}
              deletedFenceIdsRef={deletedFenceIdsRef}
              createNewLayer={createNewLayer}
              deleteLayer={deleteLayer}
              deleteFence={deleteFeature}
              changeVisibility={changeVisibility}
              editing={editing}
              unsetEditing={stopEditingFeature}
              deselectFences={() => deselectFences(mapState?.map)}
              moveUnknownFenceToExistingLayer={moveUnknownFenceToExistingLayer}
              resetLayerChanges={resetLayerChanges}
              saveLayerChanges={saveLayerChanges}
              updateFenceIdentifiers={updateFenceIdentifiers}
              updateGeomobyProperties={updateGeomobyProperties}
              updateGeomobyOverrides={updateGeomobyOverrides}
              setAsBufferZone={setAsBufferZone}
              removeBufferZone={removeBufferZone}
              setAsBreachZone={setAsBreachZone}
              unsetAsBreachZone={unsetAsBreachZone}
              setZone={setZone}
              unsetZone={unsetZone}
              getLayerFromMap={getLayerFromMap}
              animateToFeature={animateToFeature}
              findFeature={findFeature}
            />

            <FilterComponent
              searchType={searchType}
              geofenceFilter={geofenceFilter}
              setGeofenceFilter={setGeofenceFilter}
              microfenceFilter={microfenceFilter}
              setMicrofenceFilter={setMicrofenceFilter}
              assetFilter={assetFilter}
              setAssetFilter={setAssetFilter}
              selectedMicrofence={selectedMicrofence}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              setRefreshSearch={setRefreshSearch}
            />
          </>
        }
        map={
          <MapContainer id={MapType.EDIT_MAP}>
            {mapState?.map && (
              <ToolPanel
                olmap={mapState.map}
                selectedLayer={
                  !selectedLayer && searchType?.id === SearchTypeIDs.Geofences
                    ? ALL_LAYERS
                    : createEditLayer
                    ? undefined
                    : selectedLayer?.id
                }
                geofenceType={selectedGeofence ? geometryTypeOfEntity(selectedGeofence) : undefined}
                geofenceTooBig={
                  selectedGeofence
                    ? selectedGeofence?.points?.coordinates > MAX_NUMBER_OF_POLYGONS
                    : false
                }
                editing={editing}
                isLoading={mapIsLoading || paginating}
                setEditing={editFeature}
                unsetEditing={stopEditingFeature}
                drawType={drawType}
                setDrawType={setDrawType}
                editType={editType}
                setEditType={setEditType}
                measurementType={measurementType}
                changeMeasurementType={changeMeasurementType}
              />
            )}

            <MapToolbar>
              {fencesLoading && <LoadIndicator what="geofences" />}
              {mapState && (
                <ChangeMapSourceType
                  mapSource={mapSourceType}
                  setMapSource={source => {
                    setMapSourceType(source);
                    setSourceRef.current && setSourceRef.current(source);
                  }}
                />
              )}

              <ZoomIn onClick={() => mapState?.map?.getView().adjustZoom(0.5)} />
              <ZoomOut onClick={() => mapState?.map?.getView().adjustZoom(-0.5)} />
            </MapToolbar>
          </MapContainer>
        }
      />

      <Dialog open={!!errorCode} onClose={() => serErrorCode(undefined)}>
        <DialogTitle>{`The geofence you are attempting to modify is too large, please contact GeoMoby Support.`}</DialogTitle>
        <DialogActions>
          <Button onClick={() => serErrorCode(undefined)}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditorMap;
