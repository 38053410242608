import { FilterList } from '@mui/icons-material';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Header } from '../../../Common/Sidebar';
import { UsersFilterProps } from '../Props';
import { UserFilter } from '../types';

export const UsersFilter = (props: UsersFilterProps) => {
  const [clients, setClients] = useState<string[]>([]);
  const [projects, setProjects] = useState<string[]>([]);

  useEffect(() => {
    setClients(
      Array.from(
        new Set(
          props.users
            .flatMap(user => user.access)
            .flatMap(access => (access.type === 'client-project' ? access.clientId : ''))
            .filter(clientId => !!clientId),
        ),
      ).sort((a, b) => a.localeCompare(b)),
    );
  }, [props.users]);

  return (
    <>
      {/*Filter*/}
      <div
        style={{
          width: '100px',
          alignSelf: 'start',
          margin: '25px 0px 5px 25px',
        }}
      >
        <Header icon={<FilterList />}>Filters</Header>
      </div>

      <Grid
        style={{
          height: '80%',
          width: 'calc(100% - 10vw)',
          alignSelf: 'center',
        }}
      >
        {/* Client */}
        <FormControl
          fullWidth
          style={{
            marginTop: 20,
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="client-selector-label">Client</InputLabel>
          <Select
            fullWidth
            labelId="client-selector-label-id"
            id="client-selector-dropdown"
            value={props.userFilter?.client ?? ''}
            label="Client"
            onChange={e => {
              props.setUserFilter({
                ...props.userFilter,
                client: e.target.value,
                project: undefined,
              });
              setProjects(
                Array.from(
                  new Set(
                    props.users
                      .flatMap(user => user.access)
                      .flatMap(access =>
                        access.type === 'client-project' && access.clientId === e.target.value
                          ? access.projectId
                          : '',
                      )
                      .filter(projectId => !!projectId),
                  ),
                ).sort((a, b) => a.localeCompare(b)),
              );
            }}
          >
            {clients.map(client => (
              <MenuItem key={client} value={client}>
                <Tooltip title={client}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {client}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Project */}
        <FormControl
          fullWidth
          disabled={projects.length === 0}
          style={{
            marginTop: 20,
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="project-selector-label">Project</InputLabel>
          <Select
            fullWidth
            labelId="project-selector-label-id"
            id="project-selector-dropdown"
            value={props.userFilter?.project ?? ''}
            label="Project"
            onChange={e => {
              props.setUserFilter({
                ...props.userFilter,
                project: e.target.value,
              });
            }}
          >
            {projects.map(project => (
              <MenuItem key={project} value={project}>
                <Tooltip title={project}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {project}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        container
        direction="column"
        style={{
          alignSelf: 'end',
          margin: '25px',
          width: 'fit-content',
          display: 'grid',
          gap: '6%',
          // gridTemplateColumns: '30% 30% 30%',
          gridTemplateColumns: '30%',
        }}
      >
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => {
            props.setUserFilter(undefined);
          }}
        >
          Clear
        </Button>
        {/* These buttons arent necessary for Phase 1 */}
        {/*<Button*/}
        {/*    size="small"*/}
        {/*    color="secondary"*/}
        {/*    variant="contained"*/}
        {/*    onClick={() => props.setOpenFilterDialog(false)}*/}
        {/*>*/}
        {/*    Cancel*/}
        {/*</Button>*/}
        {/*<Button*/}
        {/*    size="small"*/}
        {/*    color="primary"*/}
        {/*    variant="outlined"*/}
        {/*    onClick={() => {*/}
        {/*        props.setOpenFilterDialog(false);*/}
        {/*    }}*/}
        {/*>*/}
        {/*    Search*/}
        {/*</Button>*/}
      </Grid>
    </>
  );
};
