import { Feature, Map as OlMap, View } from 'ol';
import { MapApiKeysRecord } from '../../store/map';
import { MapType } from '../../util/enums';
import { MapDefaults, MapSourceType } from './types';
import { OVERLAY } from './MapOverlay';
import { GOOGLE_FLAG_FOR_MAP_TYPE, initialZoomHeight } from '../../util/constants';
import { get } from 'ol/proj';
import * as olProj from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import { XYZ as XYZSource } from 'ol/source';
import { defaults as DefaultControls } from 'ol/control';

export const createMapSource = (mapType: MapSourceType, keys: MapApiKeysRecord) => {
  switch (mapType) {
    case 'Light map':
      return `https://api.mapbox.com/styles/v1/mapbox/light-v11/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoidmFsZ2VvbW9ieSIsImEiOiJjazdteW5oNzcwMXU0M2ZtZjk5aGw2dHpkIn0.yudO2qXxDBAow-2-JyWspA`;
    case 'Nearmap':
      return `https://api.nearmap.com/tiles/v3/Vert/{z}/{x}/{y}.img?tertiary=satellite&apikey=${keys['nearmap']}`;
    default:
      return `https://mt0.google.com/vt/lyrs=${GOOGLE_FLAG_FOR_MAP_TYPE[mapType]}&hl=en&x={x}&y={y}&z={z}`;
  }
};

export const userMapView = (edit: boolean, specifiedCoordinates: [number, number]) =>
  new View({
    center: olProj.fromLonLat(specifiedCoordinates),
    projection: 'EPSG:3857',
    extent: edit ? get('EPSG:3857').getExtent() : undefined,
    zoom: initialZoomHeight,
  });

export const createMapDefaults = ({
  sourceType,
  edit,
  specifiedCoordinates,
  mapApiKeys,
}: {
  sourceType: MapSourceType;
  edit: boolean;
  specifiedCoordinates: [number, number];
  mapApiKeys: MapApiKeysRecord;
}): MapDefaults => {
  const controls = DefaultControls();
  controls.clear();

  const baseLayer = new TileLayer({
    source: new XYZSource({
      url: createMapSource(sourceType, mapApiKeys),
    }),
  });

  return {
    baseLayer,
    view: userMapView(edit, specifiedCoordinates),
    controls,
    setSource: (type: MapSourceType) =>
      baseLayer.setSource(
        new XYZSource({
          url: createMapSource(type, mapApiKeys),
        }),
      ),
  };
};

export const createMap = (
  mapType: MapType,
  defaults: MapDefaults,
): { map: OlMap; setSource?: (source: MapSourceType) => void } => {
  return {
    map: new OlMap({
      target: mapType,
      overlays: [OVERLAY],
      layers: [defaults.baseLayer],
      controls: defaults.controls,
      view: defaults.view,
    }),
    setSource: defaults.setSource,
  };
};
