import { Delete, FormatListBulleted } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { Header } from '../../Common/Sidebar';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { SaveResult, SAVE_NOTIFICATION } from '../../store/notifications';
import { TRIGGERS_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { useMobile } from '../../util/useMobile';
import InputContainer from '../Global/InputContainer';
import { ProjectConfigCategories } from './enums';
import { Properties } from './Properties/Properties';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const ProjectConfig = () => {
  const [expandCategory, setExpandCategory] = useState<ProjectConfigCategories | undefined>(
    ProjectConfigCategories.Properties,
  );
  return (
    <div
      style={{
        margin: '10px',
      }}
    >
      <Accordion expanded={expandCategory === ProjectConfigCategories.Properties}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="users-content"
          id="users-header"
          onClick={async () => {
            setExpandCategory(
              expandCategory === ProjectConfigCategories.Properties
                ? undefined
                : ProjectConfigCategories.Properties,
            );
          }}
          sx={{
            '& .MuiAccordionSummary-content': {
              width: '90%',
            },
          }}
        >
          <Typography component="span">{ProjectConfigCategories.Properties}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Properties />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
