const fromEnv = (key: string, defaultValue: string) => process.env[key] ?? defaultValue;

const serviceUrl = (name: string, port: number) =>
  location.origin
    .replace('live.geomoby', `${name}.service.geomoby`)
    .replace('live-beta.geomoby', `${name}.service.geomoby`)
    .replace('live', name)
    .replace('3000', port.toString());

export const AUTHN_URL = fromEnv('REACT_APP_AUTHN_URL', serviceUrl('authn', 8009));
export const INGESTOR_URL = fromEnv('REACT_APP_INGESTOR_URL', serviceUrl('ingestor', 8010));
export const PERSISTOR_URL = fromEnv('REACT_APP_PERSISTOR_URL', serviceUrl('persistor', 8011));
export const TRIGGERS_URL = fromEnv('REACT_APP_TRIGGERS_URL', serviceUrl('triggers', 8012));
export const STREAMER_URL = fromEnv('REACT_APP_STREAMER_URL', serviceUrl('streamer', 8013));
export const NOTIFIER_URL = fromEnv('REACT_APP_NOTIFIER_URL', serviceUrl('notifier', 8014));
export const METADATA_URL = fromEnv('REACT_APP_METADATA_URL', serviceUrl('metadata', 8015));
