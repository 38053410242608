import { ChevronLeft, ChevronRight, LocationOn, NearMe, Sensors } from '@mui/icons-material';
import { DateRangePicker } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { addWeeks, differenceInDays, subDays } from 'date-fns';
import { useAtomValue, useSetAtom } from 'jotai';
import { Extent } from 'ol/extent';
import { transform } from 'ol/proj';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CenteredProgress, Header } from '../../Common/Sidebar';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { SaveResult, SAVE_NOTIFICATION } from '../../store/notifications';
import { PERSISTOR_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { AssetEntity, EntityType } from '../../util/enums';
import { normaliseErrorMessage } from '../../util/ErrorMessages';
import InputContainer from '../Global/InputContainer';
import { jsUcFirst } from '../Global/StringFormatterFunctions';
import {
  Asset,
  LastKnownLocation,
  OrderType,
  OrderTypeValue,
  PortableAssetTool,
  ToolOrderByTypeValue,
  ToolType,
} from '../Map/types';
import { OrderTypes, ToolOrderByTypes } from '../Map/values';
import { ToolLocationState } from './Tools';

export const getAssetType = (ids: Record<string, string>): string => {
  for (const varName in ids) {
    switch (varName) {
      case 'gatewayId':
        return jsUcFirst(EntityType.Gateway);
      case 'gpsTrackerId':
        return jsUcFirst(EntityType.Tracker);
    }
  }
  return jsUcFirst(EntityType.Device);
};

export const timeFrameTooLargeError = (
  openDiaglog: boolean,
  setOpenDialog: Dispatch<SetStateAction<boolean>>,
) => {
  return (
    <>
      <Dialog open={openDiaglog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          Your search request has timed out. Please try using a smaller timeframe.
        </DialogTitle>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const LastKnownLocationTable = ({
  asset,
  lastKnownLocation,
  range,
  fromMap,
}: {
  asset: { id: string; label: string; type?: string; tool?: string } | undefined;
  lastKnownLocation: LastKnownLocation | undefined;
  range: [Date | null, Date | null];
  fromMap?: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <TableContainer
      component={Paper}
      style={{
        width: '99.8%',
        marginTop: '20px',
      }}
    >
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {/* ID */}
          <TableRow key={'ID'}>
            <Tooltip title={'ID'}>
              <TableCell align="left" component="th" scope="row" style={{ width: '30%' }}>
                <Typography>ID</Typography>
              </TableCell>
            </Tooltip>
            <Tooltip title={asset?.type === AssetEntity.Device ? asset?.label : asset?.id}>
              <TableCell align="left" component="th" scope="row">
                <Typography>
                  {asset?.type === AssetEntity.Device ? asset?.label : asset?.id}
                </Typography>
              </TableCell>
            </Tooltip>
          </TableRow>

          {/* Label/Serial */}
          {asset?.type !== AssetEntity.Device && (
            <TableRow key={'Label'}>
              <Tooltip title={asset?.tool ? 'Label/Serial' : 'Label'}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>{asset?.tool ? 'Label/Serial' : 'Label'}</Typography>
                </TableCell>
              </Tooltip>
              <Tooltip title={asset?.label}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>{asset?.label}</Typography>
                </TableCell>
              </Tooltip>
            </TableRow>
          )}

          {/* Tool Type */}
          {!!asset?.tool && (
            <TableRow key={'ToolType'}>
              <Tooltip title={'Tool Type'}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>Tool Type</Typography>
                </TableCell>
              </Tooltip>
              <Tooltip title={asset?.tool}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>{asset?.tool}</Typography>
                </TableCell>
              </Tooltip>
            </TableRow>
          )}

          {/* No Location */}
          {!lastKnownLocation && (
            <TableRow key={'NoLocation'}>
              <Tooltip title={'Location'}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>Location</Typography>
                </TableCell>
              </Tooltip>
              <Tooltip
                title={`No location found between: ${new Date(range[0] ?? '')} and ${new Date(
                  range[1] ?? '',
                )}`}
              >
                <TableCell align="left" component="th" scope="row">
                  <Typography>{`No location found between: ${new Date(
                    range[0] ?? '',
                  ).toLocaleString()} and ${new Date(
                    range[1] ?? '',
                  ).toLocaleString()}`}</Typography>
                </TableCell>
              </Tooltip>
            </TableRow>
          )}

          {/* Location */}
          {!!lastKnownLocation && (
            <TableRow key={'Location'}>
              <Tooltip title={'Location'}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>Location</Typography>
                </TableCell>
              </Tooltip>

              <Tooltip
                title={`${lastKnownLocation.coordinates?.latitude?.toFixed(
                  5,
                )}, ${lastKnownLocation.coordinates?.longitude?.toFixed(5)}${
                  fromMap ? '' : ' - SENSED BY'
                }`}
              >
                <TableCell align="left" component="th" scope="row">
                  <Typography>
                    <span>
                      {`${lastKnownLocation.coordinates?.latitude?.toFixed(
                        5,
                      )}, ${lastKnownLocation.coordinates?.longitude?.toFixed(5)}`}
                    </span>
                    <span>
                      {!fromMap && (
                        <IconButton
                          color="primary"
                          onClick={() => {
                            const state: ToolLocationState = {
                              origin: 'Tools',
                              asset: asset as PortableAssetTool,
                              range: range as [Date, Date],
                              coordinates: [
                                lastKnownLocation.coordinates.longitude,
                                lastKnownLocation.coordinates.latitude,
                              ],
                            };
                            navigate('/live-map', { state });
                            // To stop the state from hanging around after refresh.
                            setTimeout(() => {
                              navigate('/live-map', { state: undefined, replace: true });
                            });
                          }}
                        >
                          <Sensors />
                        </IconButton>
                      )}
                    </span>
                  </Typography>
                </TableCell>
              </Tooltip>
            </TableRow>
          )}

          {/* Time */}
          {!!lastKnownLocation && (
            <TableRow key={'Time'}>
              <Tooltip title={'At'}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>At</Typography>
                </TableCell>
              </Tooltip>
              <Tooltip title={new Date(String(lastKnownLocation.iso8601)).toLocaleString()}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>
                    {new Date(String(lastKnownLocation.iso8601)).toLocaleString()}
                  </Typography>
                </TableCell>
              </Tooltip>
            </TableRow>
          )}

          {/* Sensed By */}
          {!!lastKnownLocation?.sourceIds && (
            <TableRow key={'SensedBy'}>
              <Tooltip title={'Sensed By'}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>{`Sensed By ${
                    lastKnownLocation.sourceIds?.id
                      ? getAssetType(lastKnownLocation.sourceIds?.id)
                      : ''
                  }`}</Typography>
                </TableCell>
              </Tooltip>
              <Tooltip title={lastKnownLocation.sourceIds?.label ?? 'UNKNOWN'}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>{lastKnownLocation.sourceIds?.label ?? 'UNKNOWN'}</Typography>
                </TableCell>
              </Tooltip>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const LastKnownLocationForAsset = ({
  asset,
  range,
  setRange,
  lastKnownLocation,
  setLastKnownLocation,
  setUserExtent,
  fromMap,
}: {
  asset: { id: string; label: string; type: string; tool?: string } | undefined;
  range: [Date | null, Date | null];
  setRange: Dispatch<SetStateAction<[Date | null, Date | null]>>;
  lastKnownLocation: LastKnownLocation | undefined;
  setLastKnownLocation: Dispatch<SetStateAction<LastKnownLocation | undefined>>;
  setUserExtent?: (extent: Extent | undefined) => void;
  fromMap?: boolean;
}) => {
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const persistorUrl = useAtomValue(PERSISTOR_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timeframeTooLarge, setTimeframeTooLarge] = useState<boolean>(false);
  const initRef = useRef<boolean>(false);

  const getLastKnownLocation = useCallback(
    async (newRange: Date[]) => {
      setIsLoading(true);

      try {
        const lastLocation = (
          await axios.post<LastKnownLocation>(
            `${persistorUrl}/${cid}/${pid}/analytics/last-known-coordinates`,
            {
              fromIso8601: new Date(newRange[0])?.toISOString(),
              toIso8601: new Date(newRange[1])?.toISOString(),
              id:
                asset?.type === AssetEntity.Device
                  ? { deviceId: asset?.id }
                  : asset?.type === AssetEntity.GPSTracker
                  ? { gpsTrackerId: asset?.id }
                  : { beaconId: asset?.id },
            },
            authedConfig,
          )
        )?.data;

        setLastKnownLocation(lastLocation ?? undefined);
        if (setUserExtent) {
          const coords = lastLocation
            ? transform(
                [lastLocation.coordinates.longitude, lastLocation.coordinates.latitude],
                'EPSG:4326',
                'EPSG:3857',
              )
            : undefined;
          setUserExtent(coords ? [...coords, ...coords] : undefined);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        const errorMessage = normaliseErrorMessage(error as AxiosError, EntityType.Layer);
        setSaveNotification({
          id: SaveResult.FAIL,
          action: '',
          message: errorMessage,
        });
        if (((error as AxiosError).response as AxiosResponse)?.data?.message?.statusCode === 408)
          setTimeframeTooLarge(true);
      }
    },
    [
      cid,
      pid,
      persistorUrl,
      authedConfig,
      asset,
      setLastKnownLocation,
      setTimeframeTooLarge,
      setSaveNotification,
      setUserExtent,
    ],
  );

  useEffect(() => {
    if (initRef.current === true || range?.[0] === null || range?.[1] === null) return;
    initRef.current = true;
    getLastKnownLocation(range as Date[]);
  }, [range, getLastKnownLocation]);

  return (
    <>
      <div
        style={{
          alignSelf: 'start',
          margin: '25px 0px 20px 25px',
        }}
      >
        <Header icon={<LocationOn />}>Last Known Location</Header>
      </div>

      <Grid
        style={{
          alignSelf: 'center',
          margin: '20px',
          minWidth: '93%',
        }}
      >
        {/* Select Dates */}
        <DateRangePicker
          value={range}
          onChange={value => {
            // NO CHANGE
          }}
          disableFuture
          renderInput={(startProps, endProps) => (
            <Stack direction="row" spacing={2} style={{ width: '100%' }}>
              <TextField fullWidth {...startProps} label="Start date" />
              <TextField fullWidth {...endProps} label="End date" />
            </Stack>
          )}
        />

        <Grid
          container
          direction="column"
          style={{
            marginTop: '20px',
            display: 'grid',
            gap: '76%',
            gridTemplateColumns: '10% 10%',
          }}
        >
          <Tooltip title={'Previous Week'}>
            <span>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                disabled={isLoading}
                onClick={() => {
                  const newRange = [
                    addWeeks(new Date(range[0] ?? ''), -1),
                    new Date(range[0] ?? ''),
                  ];
                  setRange(newRange as [Date | null, Date | null]);
                  getLastKnownLocation(newRange);
                }}
              >
                <ChevronLeft />
              </Button>
            </span>
          </Tooltip>

          <Tooltip title={'Next Week'}>
            <span>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                disabled={isLoading}
                onClick={() => {
                  const newRange = [
                    new Date(range[1] ?? ''),
                    addWeeks(new Date(range[1] ?? ''), 1),
                  ];
                  setRange(newRange as [Date | null, Date | null]);
                  getLastKnownLocation(newRange);
                }}
              >
                <ChevronRight />
              </Button>
            </span>
          </Tooltip>
        </Grid>

        {isLoading && (
          <Grid
            container
            justifyContent={'center'}
            style={{
              marginTop: '100px',
            }}
          >
            <CenteredProgress />
          </Grid>
        )}

        {!isLoading && (
          <LastKnownLocationTable
            asset={asset}
            lastKnownLocation={lastKnownLocation}
            range={range}
            fromMap={fromMap}
          />
        )}
      </Grid>

      {timeFrameTooLargeError(timeframeTooLarge, setTimeframeTooLarge)}
    </>
  );
};
