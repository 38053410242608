import { Box, Button, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { EntityType, SearchTypeIDs, SearchTypeValue } from '../../../../../util/enums';
import {
  AssetActivityStatus,
  displayAssetHeading,
  AssetIdOrUnknown,
  findActiveAsset,
} from '../../../Helpers';
import { SearchListProps } from '../../Props';
import { Build, NearMe } from '@mui/icons-material';
import { PRIMARY, WHITE } from '../../../../../Style/GeoMobyBaseTheme';
import { PortableAssetTool } from '../../../types';
import { SidebarSelectedAsset } from '../SidebarSelectedAsset';

export const SelectedTool = (props: SearchListProps & { selectedTool: PortableAssetTool }) => {
  const activeAsset = findActiveAsset(props.selectedTool.id, props.activeAssets);

  return (
    <SidebarSelectedAsset
      asset={props.selectedTool}
      entityType={EntityType.Tool}
      category={SearchTypeIDs.Tools}
      onCategoryClicked={() => {
        props.setSearchType({ id: SearchTypeIDs.Tools, value: SearchTypeValue.Tools });
        props.setSelectedTool(undefined);
        props.setSelectedAsset(undefined);
        props.setRefreshSearch(true);
      }}
      canNavigate={!!props.userExtent}
      onNavigateClicked={() => props.setNavigateTo(props.selectedTool.id)}
      isActive={!!activeAsset}
      activeSinceIso8601={activeAsset?.iso8601}
      getNowIso8601={props.getNowIso8601}
      extraRows={[['Tool Type', props.selectedTool.tool]]}
    />
  );
};
