import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { GeofenceFilter, GeofenceOrderByTypeValue, OrderTypeValue } from '../types';
import { FenceZoneTypes, GeofenceOrderByTypes, OrderTypes } from '../values';
import { FilterList, Search, Sort } from '@mui/icons-material';
import { Header } from '../../../Common/Sidebar';
import { Extent } from 'ol/extent';
import InputContainer from '../../Global/InputContainer';
import { FilterComponentProps, SearchListProps } from '../Editor/Props';
import {
  FenceZone,
  FenceZoneValue,
  GeofenceEntityTypeId,
  GeofenceEntityTypeValue,
  SearchTypeIDs,
} from '../../../util/enums';
import { ALL, ALL_GEOFENCE_TYPES, ALL_GEOFENCE_ZONES } from '../../../util/constants';
import { getFenceTypeIcon, getZoneIcon } from '../Helpers';
import { GeofenceTypes } from '../../Events/values';

export const GeofenceFilterComponent = (props: SearchListProps | FilterComponentProps) => {
  const disableWhenPolygonZone = () =>
    (props.geofenceFilter?.zone === FenceZone.buffer ||
      props.geofenceFilter?.zone === FenceZone.breach ||
      props.geofenceFilter?.zone === FenceZone.loading ||
      props.geofenceFilter?.zone === FenceZone.dumping ||
      props.geofenceFilter?.zone === FenceZone.maintenance) &&
    props.geofenceFilter.fenceType?.find(type => type === GeofenceEntityTypeId.Polygon);

  return (
    <Grid
      style={{
        marginTop: '0px',
        contentVisibility: props.showFilter ? 'visible' : 'hidden',
      }}
    >
      {/*Filter*/}
      <div
        style={{
          width: '100px',
          alignSelf: 'start',
          marginBottom: '10px',
        }}
      >
        <Header icon={<FilterList />}>Filter</Header>
      </div>
      <Grid
        style={{
          height: '80%',
          alignSelf: 'center',
        }}
      >
        {/*Geofence Type*/}
        <FormControl
          style={{
            width: '100%',
            marginBottom: '15px',
          }}
        >
          <InputLabel id="fence-type-option">Geofence Type</InputLabel>
          <Select
            fullWidth
            labelId="fence-type-action"
            id="fence-type-dropdown"
            disabled={!!disableWhenPolygonZone()}
            value={
              GeofenceTypes.find(t => props.geofenceFilter?.fenceType?.find(type => type === t.id))
                ?.value ?? ALL_GEOFENCE_TYPES
            }
            label="Geofence Type"
            style={{
              height: '60px',
            }}
            onChange={e => {
              const type = GeofenceTypes.find(
                t => t.value === (e.target.value as GeofenceEntityTypeValue),
              );

              props.setGeofenceFilter({
                ...props.geofenceFilter,
                fenceType: type ? [type.id] : undefined,
                ...(type?.id === GeofenceEntityTypeId.Line
                  ? {
                      zone: FenceZone.none,
                    }
                  : type?.id !== undefined && type.id === GeofenceEntityTypeId.Multipolygon
                  ? {
                      zone: FenceZone.cleared,
                    }
                  : {
                      zone: props.geofenceFilter?.zone,
                    }),
              });
            }}
          >
            {[{ id: ALL, value: ALL_GEOFENCE_TYPES }, ...GeofenceTypes].map(type => (
              <MenuItem
                key={type.id}
                value={type.value}
                disabled={
                  (props.geofenceFilter?.zone === FenceZone.cleared &&
                    type.id === GeofenceEntityTypeId.Line) ||
                  (props.geofenceFilter?.zone === FenceZone.none &&
                    type.id === GeofenceEntityTypeId.Multipolygon)
                }
              >
                <Grid
                  container
                  style={
                    type.id !== ALL
                      ? {
                          display: 'grid',
                          gap: '20px',
                          gridTemplateColumns: '30px 200px',
                        }
                      : {
                          marginLeft: '50px',
                        }
                  }
                >
                  {type.id !== ALL ? getFenceTypeIcon(type.id as GeofenceEntityTypeId) : ''}
                  {type.value}
                </Grid>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/*Geofence Zone*/}
        <FormControl
          style={{
            width: '100%',
            marginBottom: '15px',
          }}
        >
          <InputLabel id="zone-type-option">Geofence Zone</InputLabel>
          <Select
            fullWidth
            labelId="zone-type-action"
            id="zone-type-dropdown"
            disabled={
              !!(
                props.geofenceFilter?.fenceType?.find(type => type === GeofenceEntityTypeId.Line) ||
                props.geofenceFilter?.fenceType?.find(
                  type => type === GeofenceEntityTypeId.Multipolygon,
                )
              )
            }
            value={
              FenceZoneTypes.find(z => z.id === props.geofenceFilter?.zone)?.value ??
              ALL_GEOFENCE_ZONES
            }
            label="Geofence Zone"
            style={{
              height: '60px',
            }}
            onChange={e => {
              const zone = FenceZoneTypes.find(z => z.value === (e.target.value as FenceZoneValue));
              props.setGeofenceFilter({
                ...props.geofenceFilter,
                zone: zone?.id,
                ...(zone !== undefined &&
                zone?.id !== FenceZone.none &&
                zone?.id !== FenceZone.cleared
                  ? {
                      fenceType: [GeofenceEntityTypeId.Polygon],
                    }
                  : {
                      fenceType: props.geofenceFilter?.fenceType,
                    }),
              });
            }}
          >
            {[{ id: ALL, value: ALL_GEOFENCE_ZONES }, ...FenceZoneTypes].map(zone => (
              <MenuItem key={zone.id} value={zone.value}>
                <Grid
                  container
                  style={
                    zone.id === FenceZone.breach ||
                    zone.id === FenceZone.buffer ||
                    zone.id === FenceZone.cleared
                      ? {
                          // We can ammend this when the other zones have an icon
                          display: 'grid',
                          gap: '20px',
                          gridTemplateColumns: '30px 200px',
                        }
                      : {
                          marginLeft: '50px',
                        }
                  }
                >
                  {zone.id !== ALL ? getZoneIcon(zone.id as FenceZone) : ''}
                  {zone.value}
                </Grid>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {/*Search*/}
      <div
        style={{
          width: '100px',
          alignSelf: 'start',
          marginTop: '10px',
        }}
      >
        <Header icon={<Search />}>Search</Header>
      </div>
      <div
        style={{
          width: '300px',
          alignSelf: 'start',
          marginBottom: '10px',
        }}
      >
        Search Keywords:
      </div>

      {/*Search Name*/}
      <Grid
        style={{
          height: '80%',
          alignSelf: 'center',
        }}
      >
        {
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="searchName"
              label="Name"
              key={'searchName'}
              name={'searchName'}
              value={props.geofenceFilter?.searchName ?? ''}
              onChange={(e: { target: { value: string } }) => {
                props.setGeofenceFilter({
                  ...props.geofenceFilter,
                  searchName: e.target.value,
                });
              }}
              placeholder="Enter a name"
            />
          </FormControl>
        }
      </Grid>

      {/*Search Geomoby Property Keys */}
      <Grid
        style={{
          height: '80%',
          alignSelf: 'center',
        }}
      >
        {
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="geomobyPropertyKey"
              label="Property Name"
              key={'geomobyPropertyKey'}
              name={'geomobyPropertyKey'}
              value={props.geofenceFilter?.searchGeomobyKey ?? ''}
              onChange={(e: { target: { value: string } }) => {
                props.setGeofenceFilter({
                  ...props.geofenceFilter,
                  searchGeomobyKey: e.target.value,
                });
              }}
              placeholder="Enter a name"
            />
          </FormControl>
        }
      </Grid>

      {/*Search Geomoby Property Values */}
      <Grid
        style={{
          height: '80%',
          alignSelf: 'center',
        }}
      >
        {
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="geomobyPropertyValue"
              label="Property Value"
              key={'geomobyPropertyValue'}
              name={'geomobyPropertyValue'}
              value={props.geofenceFilter?.searchGeomobyValue ?? ''}
              onChange={(e: { target: { value: string } }) => {
                props.setGeofenceFilter({
                  ...props.geofenceFilter,
                  searchGeomobyValue: e.target.value,
                });
              }}
              placeholder="Enter a value"
            />
          </FormControl>
        }
      </Grid>

      {/*Sort*/}
      <div
        style={{
          width: 'fit-content',
          alignSelf: 'start',
          marginTop: '10px',
        }}
      >
        <Header icon={<Sort />}>Sort</Header>
      </div>

      <Grid
        style={{
          height: '80%',
          alignSelf: 'center',
          marginTop: '10px',
        }}
      >
        {/*/!*Sort By*!/*/}
        <FormControl
          fullWidth
          style={{
            marginBottom: '20px',
            alignSelf: 'center',
          }}
        >
          <InputLabel id="sort-option">Sort By</InputLabel>
          <Select
            fullWidth
            labelId="sort-action"
            id="sort-dropdown"
            value={props.geofenceFilter?.orderBy?.value ?? ''}
            label="Sort By"
            onChange={e => {
              const orderBy = GeofenceOrderByTypes.find(
                l => l.value === (e.target.value as GeofenceOrderByTypeValue),
              );
              if (!orderBy) return;
              props.setGeofenceFilter({
                ...props.geofenceFilter,
                orderBy,
              });
            }}
          >
            {GeofenceOrderByTypes.map(orderBy => (
              <MenuItem key={orderBy.id} value={orderBy.value}>
                <Tooltip title={orderBy.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {orderBy.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/*/!*Order*!/*/}
        <FormControl
          fullWidth
          style={{
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="order-option">Order</InputLabel>
          <Select
            fullWidth
            labelId="order-action"
            id="order-dropdown"
            value={props.geofenceFilter?.order?.value ?? ''}
            label="Order"
            onChange={e => {
              const order = OrderTypes.find(l => l.value === (e.target.value as OrderTypeValue));
              props.setGeofenceFilter({
                ...props.geofenceFilter,
                order,
              });
            }}
          >
            {OrderTypes.map(orderBy => (
              <MenuItem key={orderBy.id} value={orderBy.value}>
                <Tooltip title={orderBy.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {orderBy.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        container
        direction="column"
        style={{
          width: 'fit-content',
          marginTop: '30px',
          display: 'grid',
          gap: '6%',
          gridTemplateColumns: '30% 30% 30%',
        }}
      >
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => props.setClearFilter(true)}
        >
          Clear
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => {
            props.setShowFilter(false);
          }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => {
            props.setRefreshSearch(true);
          }}
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
};
