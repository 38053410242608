import { useCallback } from 'react';
import { Asset } from '../../../types';
import { SearchListProps } from '../../Props';
import { SidebarAssetList } from '../SidebarAssetList';

export const DeviceList = ({
  activeAssets,
  ...props
}: SearchListProps & { availableDevices: Asset[] }) => {
  const isActive = useCallback(
    (device: Asset) =>
      !!activeAssets.find(
        active => active.id?.deviceId !== undefined && active.id.deviceId === device.id,
      ),
    [activeAssets],
  );
  const deviceLabel = useCallback((device: Asset) => device.label, []);
  const deviceId = useCallback((device: Asset) => device.id, []);
  const onClick = (device: Asset) => {
    const activeDevice = activeAssets.find(
      active => active.id?.deviceId !== undefined && active.id.deviceId === device.id,
    );
    props.setSelectedDevice(device);
    if (activeDevice) {
      props.setSelectedAsset(activeDevice);
    }
  };

  return (
    <SidebarAssetList
      assets={props.availableDevices}
      assetIsActive={isActive}
      assetLabel={deviceLabel}
      assetId={deviceId}
      onClick={onClick}
    />
  );
};
