// This is a generic component that is used to format sidebar asset lists

import { Accordion, AccordionSummary, Grid, Tooltip, Typography } from '@mui/material';
import { SECONDARY, truncationStyle } from '../../../../Style/GeoMobyBaseTheme';
import { ActiveAssetText } from '../../Helpers';

export const SidebarAssetList = <T,>({
  assets,
  assetIsActive,
  assetLabel,
  assetId,
  onClick,
  assetPrefix,
}: {
  assets: T[];
  assetIsActive: (a: T) => boolean;
  assetLabel: (a: T) => string | undefined;
  assetId: (a: T) => string | undefined;
  onClick: (a: T) => void;
  assetPrefix?: (a: T) => JSX.Element | undefined;
}) => {
  const items = assets.map((asset, index) => {
    const isActive = assetIsActive(asset);
    const label = assetLabel(asset);
    const id = assetId(asset);
    const prefix = assetPrefix?.(asset);
    return (
      <Accordion id={id} key={index} expanded={false} style={{ width: '98%' }}>
        <AccordionSummary
          onClick={() => onClick(asset)}
          sx={{
            '& .MuiAccordionSummary-content': {
              width: '90%',
            },
          }}
        >
          <Grid container direction="row">
            <Grid item style={{ width: isActive ? '70%' : '100%' }}>
              {prefix !== undefined && prefix}
              <Tooltip title={label || (id ? id : 'UNKNOWN ID')}>
                <Typography
                  style={{
                    width: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {label && <span style={truncationStyle}>{label}</span>}
                  {!label && (
                    <span>
                      <span
                        style={{
                          color: SECONDARY,
                        }}
                      >
                        NO LABEL
                      </span>
                      <span style={truncationStyle}>{` (${id ? id : 'UNKNOWN ID'})`}</span>
                    </span>
                  )}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid
              item
              style={{
                width: 'max-content',
                margin: 'auto 0 auto auto',
              }}
            >
              {isActive && <ActiveAssetText />}
            </Grid>
          </Grid>
        </AccordionSummary>
      </Accordion>
    );
  });

  return <>{items}</>;
};
