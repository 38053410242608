import { OrderTypes, SearchTypes } from '../values';
import { Search, Sort } from '@mui/icons-material';
import { Header } from '../../../Common/Sidebar';
import InputContainer from '../../Global/InputContainer';
import { FilterComponentProps, SearchListProps } from '../LiveAndReplay/Props';
import { SearchTypeIDs } from '../../../util/enums';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { OrderTypeValue } from '../types';

export type TrackerFilterProps = Pick<
  FilterComponentProps,
  | 'showFilter'
  | 'assetFilter'
  | 'setAssetFilter'
  | 'setClearFilter'
  | 'setShowFilter'
  | 'setRefreshSearch'
>;

export const TrackerFilterComponent = (props: TrackerFilterProps) => {
  return (
    <Grid
      style={{
        marginTop: '0px',
        contentVisibility: props.showFilter ? 'visible' : 'hidden',
      }}
    >
      {/*Search*/}
      <div
        style={{
          width: '100px',
          alignSelf: 'start',
          marginTop: '10px',
        }}
      >
        <Header icon={<Search />}>Search</Header>
      </div>
      <div
        style={{
          width: '300px',
          alignSelf: 'start',
          marginBottom: '10px',
        }}
      >
        Search Keywords:
      </div>

      {/*Search Label*/}
      <Grid
        style={{
          height: '80%',
          alignSelf: 'center',
        }}
      >
        {
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="searchLabel"
              label="Label"
              key={'searchLabel'}
              name={'searchLabel'}
              value={props.assetFilter?.searchLabel ?? ''}
              onChange={(e: { target: { value: string } }) => {
                props.setAssetFilter({
                  ...props.assetFilter,
                  searchLabel: e.target.value,
                });
              }}
              placeholder="Enter a label"
            />
          </FormControl>
        }
      </Grid>

      {/*Search ID*/}
      <Grid
        style={{
          height: '80%',
          alignSelf: 'center',
        }}
      >
        {
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="searchId"
              label="ID"
              key={'searchId'}
              name={'searchId'}
              value={props.assetFilter?.searchId ?? ''}
              onChange={(e: { target: { value: string } }) => {
                props.setAssetFilter({
                  ...props.assetFilter,
                  searchId: e.target.value,
                });
              }}
              placeholder="Enter an ID"
            />
          </FormControl>
        }
      </Grid>

      {/*Sort*/}
      <div
        style={{
          width: 'fit-content',
          alignSelf: 'start',
          marginTop: '10px',
        }}
      >
        <Header icon={<Sort />}>Sort</Header>
      </div>

      <Grid
        style={{
          height: '80%',
          alignSelf: 'center',
          marginTop: '10px',
        }}
      >
        {/*Order*/}
        <FormControl
          fullWidth
          style={{
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="order-option">Order</InputLabel>
          <Select
            fullWidth
            labelId="order-action"
            id="order-dropdown"
            value={props.assetFilter?.order?.value ?? ''}
            label="Order"
            onChange={e => {
              const order = OrderTypes.find(l => l.value === (e.target.value as OrderTypeValue));
              props.setAssetFilter({
                ...props.assetFilter,
                order,
              });
            }}
          >
            {OrderTypes.map(orderBy => (
              <MenuItem key={orderBy.id} value={orderBy.value}>
                <Tooltip title={orderBy.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {orderBy.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        container
        direction="column"
        style={{
          width: 'fit-content',
          marginTop: '30px',
          display: 'grid',
          gap: '6%',
          gridTemplateColumns: '30% 30% 30%',
        }}
      >
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => props.setClearFilter(true)}
        >
          Clear
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => {
            props.setShowFilter(false);
          }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => {
            props.setRefreshSearch(true);
          }}
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
};
