import axios from 'axios';
import { TRIGGERS_URL } from './urls';

import { Property } from '../Components/ProjectConfig/types';

export const getGeomobyProperties = async ({
  clientId,
  projectId,
  Authorization,
}: {
  clientId: string;
  projectId: string;
  Authorization: string;
}) => {
  return (
    await axios.get<Property[]>(
      `${TRIGGERS_URL}/${clientId}/${projectId}/geofences/properties/geomoby`,
      { headers: { Authorization } },
    )
  ).data;
};

export const getProjectProperties = async ({
  clientId,
  projectId,
  Authorization,
}: {
  clientId: string;
  projectId: string;
  Authorization: string;
}) => {
  return (
    await axios.get<Property[]>(
      `${TRIGGERS_URL}/${clientId}/${projectId}/geofences/properties/project`,
      { headers: { Authorization } },
    )
  ).data;
};
