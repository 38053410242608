import { Accordion, AccordionSummary, Grid } from '@mui/material';
import { SearchTypeIDs } from '../../../../../util/enums';
import { SearchListProps } from '../../Props';
import { ActiveAssetText, AssetLabel, findActiveAsset, selectedElement } from '../../../Helpers';
import { PortableAssetTool } from '../../../types';
import { useCallback } from 'react';
import { SidebarAssetList } from '../SidebarAssetList';

export const ToolList = ({
  activeAssets,
  ...props
}: SearchListProps & { availableTools: PortableAssetTool[] }) => {
  const isActive = useCallback(
    (tool: PortableAssetTool) => !!findActiveAsset(tool.id, activeAssets),
    [activeAssets],
  );
  const toolLabel = useCallback((tool: PortableAssetTool) => tool.label, []);
  const toolId = useCallback((tool: PortableAssetTool) => tool.id, []);
  const onClick = (tool: PortableAssetTool) => {
    props.setSelectedTool(tool);
    const activeTool = findActiveAsset(tool.id, activeAssets);
    if (activeTool) {
      props.setSelectedAsset({
        id: activeTool.id,
        label: tool.label,
      });
    }
  };

  return (
    <SidebarAssetList
      assets={props.availableTools}
      assetIsActive={isActive}
      assetLabel={toolLabel}
      assetId={toolId}
      onClick={onClick}
    />
  );
};
