import axios from 'axios';
import { Paginated, PaginationQueryWithSearch } from './api';
import { METADATA_URL } from './urls';

export type GpsTrackerDto = {
  serial: string;
  label: string;
};
export type GpsTracker = GpsTrackerDto & {
  clientId: string;
  projectId: string;
  createdAt?: string;
  updatedAt?: string | null;
};
export type PagiantedGpsTrackers = Paginated<GpsTracker>;
export type PaginateGpsTrackersQuery = PaginationQueryWithSearch<'serial' | 'label'>;

export const getGpsTrackers = async ({
  clientId,
  projectId,
  Authorization,
  query,
}: {
  clientId: string;
  projectId: string;
  Authorization: string;
  query: PaginateGpsTrackersQuery;
}) => {
  const queryParams = new URLSearchParams(
    Object.entries(query)
      .map(([key, val]) => [key, val?.toString() || undefined])
      .filter((entry): entry is [string, string] => entry[1] !== undefined),
  );
  return (
    await axios.get<PagiantedGpsTrackers>(
      `${METADATA_URL}/${clientId}/${projectId}/gps-tracker?${queryParams.toString()}`,
      { headers: { Authorization } },
    )
  ).data;
};

export const createGpsTracker = async ({
  clientId,
  projectId,
  Authorization,
  tracker,
}: {
  clientId: string;
  projectId: string;
  Authorization: string;
  tracker: GpsTrackerDto;
}) => {
  return (
    await axios.post<GpsTracker>(`${METADATA_URL}/${clientId}/${projectId}/gps-tracker`, tracker, {
      headers: { Authorization },
    })
  ).data;
};

export const updateGpsTracker = async ({
  clientId,
  projectId,
  Authorization,
  tracker,
  serialToUpdate,
}: {
  clientId: string;
  projectId: string;
  Authorization: string;
  tracker: GpsTrackerDto;
  serialToUpdate: string;
}) => {
  return (
    await axios.patch<GpsTracker>(
      `${METADATA_URL}/${clientId}/${projectId}/gps-tracker/${serialToUpdate}`,
      tracker,
      { headers: { Authorization } },
    )
  ).data;
};

export const deleteGpsTracker = async ({
  clientId,
  projectId,
  Authorization,
  serial,
}: {
  clientId: string;
  projectId: string;
  Authorization: string;
  serial: string;
}) => {
  await axios.delete(`${METADATA_URL}/${clientId}/${projectId}/gps-tracker/${serial}`, {
    headers: { Authorization },
  });
};
