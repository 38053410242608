import { Alert, Stack, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import {
  LATEST_UNSEEN_NOTIFICATION,
  LATEST_UNSEEN_REPLAY_NOTIFICATION,
  SaveResult,
  SAVE_NOTIFICATION,
  SEEN_NOTIFICATIONS,
  SEEN_REPLAY_NOTIFICATIONS,
} from '../store/notifications';
import { useNotificationAction } from './useNotificationAction';
import { useGeomobyLiveStream } from '../hooks/geomoby/useGeomobyLiveStream';
import { History } from '@mui/icons-material';
import { ZIndexes } from '../util/ZIndexes';

export const Notifications = ({ map }: { map?: 'live' | 'replay' }) => {
  const saveNotification = useAtomValue(SAVE_NOTIFICATION);
  const latestLiveUnseen = useAtomValue(LATEST_UNSEEN_NOTIFICATION);
  const latestReplayedUnseen = useAtomValue(LATEST_UNSEEN_REPLAY_NOTIFICATION);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);
  const setSeen = useSetAtom(SEEN_NOTIFICATIONS);
  const setSeenReplay = useSetAtom(SEEN_REPLAY_NOTIFICATIONS);
  useGeomobyLiveStream({ useFor: 'notifications' });

  const latestUnseen = useMemo(() => {
    if (latestLiveUnseen) {
      return latestLiveUnseen;
    } else if (map === 'replay') {
      return latestReplayedUnseen;
    }
    return undefined;
  }, [latestLiveUnseen, latestReplayedUnseen, map]);

  const markNotificationAsSeen = useCallback(
    () =>
      latestUnseen?.replayed
        ? setSeenReplay(seens => ({ ...seens, [latestUnseen.notificationId]: true }))
        : setSeen(seens => ({ ...seens, [latestUnseen?.notificationId ?? '-']: true })),
    [latestUnseen, setSeen, setSeenReplay],
  );

  const [action, tooltip] = useNotificationAction(latestUnseen?.interaction, map);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (saveNotification.id !== SaveResult.NONE) {
      timerId = setTimeout(() => {
        setSaveNotification({ id: SaveResult.NONE, action: '', message: '' });
      }, 3000);
    }
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [saveNotification, setSaveNotification]);

  return (
    <Stack
      spacing={2}
      sx={{
        width: 'calc(20% + 9rem)',
        position: 'absolute',
        bottom: '5px',
        left: '5px',
        zIndex: ZIndexes.NOTIFICATION_TOAST,
      }}
    >
      {saveNotification.id !== SaveResult.NONE && (
        <Alert
          style={{
            position: 'fixed',
            width: '300px',
            bottom: '0px',
          }}
          severity={saveNotification.id === SaveResult.SUCCESS ? 'success' : 'warning'}
          variant="filled"
          onClose={() => {
            setSaveNotification({ id: SaveResult.NONE, action: '', message: '' });
          }}
        >
          {saveNotification.message ??
            saveNotification.action +
              (saveNotification.id === SaveResult.SUCCESS ? ' Successful' : ' Error')}
        </Alert>
      )}
      {latestUnseen && (
        <Tooltip title={tooltip ?? ''} style={{ cursor: action ? 'pointer' : undefined }}>
          <Alert
            style={{
              position: 'fixed',
              width: '300px',
              bottom: '0px',
            }}
            severity={latestUnseen.replayed ? 'warning' : 'error'}
            icon={latestUnseen.replayed ? <History fontSize="inherit" /> : undefined}
            variant="filled"
            onClose={e => {
              markNotificationAsSeen();
              e.stopPropagation();
            }}
            onClick={action}
          >
            {format(latestUnseen.datetime, 'h:mmaaa')}, {latestUnseen.text}
          </Alert>
        </Tooltip>
      )}
    </Stack>
  );
};
