import { SearchTypeIDs } from '../../../../../util/enums';
import { GeofenceFilterComponent } from '../../../Filter/GeofenceFilterComponent';
import { MicrofenceFilterComponent } from '../../../Filter/MicrofenceFilterComponent';
import { TrackerFilterComponent, TrackerFilterProps } from '../../../Filter/TrackerFilterComponent';
import { FilterComponentProps } from '../../Props';

export const FilterComponent = (props: FilterComponentProps) => {
  const geofenceFilter = GeofenceFilterComponent(props);
  const microfenceFilter = MicrofenceFilterComponent(props);
  const gpsTrackerFilter = TrackerFilterComponent(props);

  return (
    <>
      <div
        style={{
          margin: '20px',
          display: props.searchType?.id !== SearchTypeIDs.Geofences ? 'none' : undefined,
        }}
      >
        {geofenceFilter}
      </div>
      <div
        style={{
          margin: '20px',
          display: props.searchType?.id !== SearchTypeIDs.Microfences ? 'none' : undefined,
        }}
      >
        {microfenceFilter}
      </div>
      <div
        style={{
          margin: '20px',
          display: props.searchType?.id !== SearchTypeIDs.GPSTrackers ? 'none' : undefined,
        }}
      >
        {gpsTrackerFilter}
      </div>
    </>
  );
};
