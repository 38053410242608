import { AddCircle, Delete, FiberNew, FiberNewOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { GpsTracker } from '../../../../../API/metadata';

export const EditGpsTracker = ({
  tracker,
  onSaveRequest,
  onCancelRequest,
  onDeleteRequest,
  loading,
}: {
  tracker?: Partial<GpsTracker> & { fresh?: boolean };
  onSaveRequest: (
    tracker: Pick<GpsTracker, 'label' | 'serial'>,
    existingSerial?: string,
  ) => Promise<void>;
  onDeleteRequest: (serial: string) => Promise<void>;
  onCancelRequest: () => void;
  loading?: boolean;
}) => {
  const nameRef = useRef<HTMLInputElement>(null);
  const serialRef = useRef<HTMLInputElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);

  const [newLabel, setNewLabel] = useState(tracker?.label ?? '');
  const [newSerial, setNewSerial] = useState(tracker?.serial ?? '');
  const [disabled, setDisabled] = useState(false);
  const [deleteing, setDeleting] = useState(false);
  const [nameTouched, setNameTouched] = useState(false);
  const [serialTouched, setSerialTouched] = useState(false);

  // Handle tracker changing, since this component remains mounted
  useEffect(() => {
    setNewLabel(tracker?.label ?? '');
    setNameTouched(false);
    nameRef.current?.focus();
  }, [tracker?.label]);
  useEffect(() => {
    setNewSerial(tracker?.serial ?? '');
    setSerialTouched(false);
  }, [tracker?.serial]);

  const nameIsEmptyError = nameTouched && !newLabel.trim();
  const serialIsEmptyError = serialTouched && !newSerial.trim();

  const serialHasInvalidCharsError =
    newSerial.trim().length === 0 ? undefined : /\D/.test(newSerial.trim());
  const isValid = !!newLabel.trim() && !!newSerial.trim() && !serialHasInvalidCharsError;
  const hasChanges =
    tracker?.fresh || newLabel.trim() !== tracker?.label || newSerial.trim() !== tracker?.serial;

  return (
    <>
      {tracker && (
        <>
          <Paper
            variant="outlined"
            style={{
              padding: '10px',
              height: 'fit-content',
            }}
          >
            <Grid
              container
              direction="row"
              style={{
                width: '100%',
                marginTop: '10px',
                marginBottom: '-54px',
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip title={tracker.fresh ? 'New tracker not yet saved' : 'DELETE'}>
                <div style={{ display: 'inline-block' }}>
                  <IconButton
                    color="primary"
                    disabled={!tracker || tracker.fresh || loading}
                    onClick={() => {
                      const serial = tracker?.serial ?? '';
                      if (serial) onDeleteRequest(serial);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </div>
              </Tooltip>
            </Grid>

            <Grid>
              {/* Name */}
              <FormControl
                style={{
                  width: 'calc(100% - 50px)',
                  marginBottom: '15px',
                  marginTop: '10px',
                }}
              >
                <TextField
                  variant="outlined"
                  id="rename"
                  label="Name"
                  key={'rename'}
                  name={'rename'}
                  inputRef={nameRef}
                  placeholder="Tracker 1"
                  disabled={loading}
                  required
                  value={newLabel}
                  error={nameIsEmptyError}
                  helperText={nameIsEmptyError ? 'Name is required' : ' '}
                  onBlur={() => setNameTouched(true)}
                  onChange={e => setNewLabel(e.target.value)}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      serialRef.current?.focus();
                    }
                  }}
                />
              </FormControl>

              {/* Serial */}
              <FormControl
                style={{
                  width: 'calc(100% - 50px)',
                  marginBottom: '15px',
                }}
              >
                <TextField
                  variant="outlined"
                  id="serial-id"
                  label="Serial"
                  name="serial"
                  inputRef={serialRef}
                  placeholder="123456"
                  disabled={loading}
                  inputProps={{ inputMode: 'numeric' }}
                  required
                  value={newSerial}
                  error={serialIsEmptyError || serialHasInvalidCharsError}
                  helperText={
                    serialIsEmptyError
                      ? 'Serial is required'
                      : serialHasInvalidCharsError
                      ? 'Must contain only numbers'
                      : ' '
                  }
                  onBlur={() => setSerialTouched(true)}
                  onChange={e => setNewSerial(e.target.value)}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      saveButtonRef.current?.focus();
                    }
                  }}
                />
              </FormControl>
            </Grid>
          </Paper>
          <Grid
            container
            style={{
              display: 'grid',
              gridTemplateColumns: '50% 50%',
              gap: '2%',
              marginTop: '20px',
              paddingRight: '8px',
              width: '100%',
            }}
          >
            <LoadingButton
              ref={saveButtonRef}
              variant="contained"
              color="secondary"
              disabled={loading || disabled || !isValid || !hasChanges}
              onClick={async () => {
                onSaveRequest(
                  { label: newLabel.trim(), serial: newSerial.trim() },
                  tracker.serial || undefined,
                );
              }}
              loading={loading}
            >
              {tracker?.fresh ? 'Save new tracker' : 'Save changes'}
            </LoadingButton>
            <Button variant="outlined" onClick={onCancelRequest} disabled={loading}>
              Cancel
            </Button>
          </Grid>
        </>
      )}
    </>
  );
};
