import { format } from 'date-fns';
import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import { LOCATION_HISTORY } from '../../util/constants';

export type Location = { time: Date; coordinates: number[]; showLabel: boolean };
export type LocationHistory = { time: Date; coordinates: number[]; showLabel: boolean }[];
export const coordinatesEqual = (a: number[], b: number[]) => a.every((value, i) => value === b[i]);

export const clearLocationTrace = ({
  locationTraceSrc,
  labelSrc,
}: {
  locationTraceSrc: VectorSource<Geometry>;
  labelSrc: VectorSource<Geometry>;
}) => {
  locationTraceSrc.clear();
  labelSrc.clear();
};

export const createLocationTraceLines = (coordsOverTime: LocationHistory) => {
  return new Feature({
    geometry: new LineString(coordsOverTime.map(({ coordinates }) => coordinates)),
  });
};

export const createLocationTraceLabels = (coordsOverTime: LocationHistory) => {
  return coordsOverTime
    .slice(0, -1)
    .filter(({ showLabel }) => showLabel)
    .map(({ coordinates, time }) => {
      const feature = new Feature({ geometry: new Point(coordinates) });
      feature.set('label', format(time, 'hh:mm'));
      return feature;
    });
};

export const updateLocationTrace = (
  {
    locationTraceSrc,
    labelSrc,
  }: { locationTraceSrc: VectorSource<Geometry>; labelSrc: VectorSource<Geometry> },
  asset?: Feature<Point> | null | undefined,
) => {
  if (asset) {
    clearLocationTrace({ locationTraceSrc, labelSrc });
  }
  if (asset?.get(LOCATION_HISTORY)) {
    locationTraceSrc.addFeature(createLocationTraceLines(asset?.get(LOCATION_HISTORY)));
    labelSrc.addFeatures(createLocationTraceLabels(asset?.get(LOCATION_HISTORY)));
  }
};
