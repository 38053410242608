import { Feature } from 'ol';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import { Geometry, Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import RenderFeature from 'ol/render/Feature';
import { Cluster } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { Fill, Text, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { StyleFunction } from 'ol/style/Style';
import { Color as OlColor } from 'ol/color';
import { StrongFeatureHolder } from '../../../hooks/geomoby/useLiveMapLoader';
import { WHITE } from '../../../Style/GeoMobyBaseTheme';
import {
  ACTIVE_MICROFENCE,
  CLUSTER_DISTANCE,
  CLUSTER_FONT_FAMILY,
  LOCALISED_MAX_DISTANCE_M,
  MICROFENCE_LAYER_ID,
  MICROFENCE_LAYER_LABEL,
  SENSED_ASSETS,
} from '../../../util/constants';
import { MicrofenceEntity } from '../../../util/enums';
import { ZIndexes } from '../../../util/ZIndexes';
import { MicrofenceData } from '../Messages';
import { countStyle, transmissionArea, iconFromElement } from './AssetStyles';
import { defaultGeofencesStyle, textStroke } from './GeofenceStyles';

/* Gateways */
const gatewayIcon = (options: { color?: OlColor | string; scale?: number; opacity?: number }) =>
  iconFromElement(
    document.getElementById('RouterFar') as HTMLImageElement,
    46,
    46,
    0.5,
    0.5,
    options,
  );
export const defaultMicrofenceGatewayStyle = new Style({
  image: gatewayIcon({ color: [192, 192, 192], scale: 0.8 }),
  zIndex: ZIndexes.MARKER,
});
export const selectedMicrofenceGatewayStyle = new Style({
  image: gatewayIcon({ opacity: 1 }),
  zIndex: ZIndexes.MARKER,
});

/* Beacons */
const beaconIcon = (options: { color?: OlColor | string; scale?: number; opacity?: number }) =>
  iconFromElement(
    document.getElementById('BeaconFar') as HTMLImageElement,
    46,
    46,
    0.5,
    0.5,
    options,
  );
export const defaultMicrofenceBeaconStyle = new Style({
  image: beaconIcon({ color: [192, 192, 192], scale: 0.8 }),
  zIndex: ZIndexes.MARKER,
});
export const selectedMicrofenceBeaconStyle = new Style({
  image: beaconIcon({ opacity: 1 }),
  zIndex: ZIndexes.MARKER,
});

/* Devices */
const deviceIcon = (options: { color?: OlColor | string; scale?: number; opacity?: number }) =>
  iconFromElement(
    document.getElementById('SmartphoneFar') as HTMLImageElement,
    46,
    46,
    0.5,
    0.5,
    options,
  );
export const defaultMicrofenceDeviceStyle = new Style({
  image: deviceIcon({ color: [192, 192, 192], scale: 0.8 }),
  zIndex: ZIndexes.MARKER,
});
export const selectedMicrofenceDeviceStyle = new Style({
  image: deviceIcon({ opacity: 1 }),
  zIndex: ZIndexes.MARKER,
});

/* Clusters */
const innerClusterStyle = new CircleStyle({
  radius: 10,
  fill: new Fill({
    color: 'rgba(0,246,255,0.7)',
  }),
});
const outerClusterStyle = new CircleStyle({
  radius: 18,
  fill: new Fill({
    color: 'rgba(17,246,255,0.3)',
  }),
});
export const defaultMicrofenceClusterStyle = (
  cache: Map<string, Style[]>,
  options: { showActivity: boolean },
): StyleFunction => {
  return (feature: Feature<Geometry> | RenderFeature, p1) => {
    const size = feature.get('features').length;
    if (size === 1) {
      return microfenceStyle(cache, options)(feature.get('features')[0], p1);
    }
    return [
      new Style({
        image: outerClusterStyle,
        zIndex: ZIndexes.MARKER,
      }),
      new Style({
        image: innerClusterStyle,
        zIndex: ZIndexes.MARKER,
        text: new Text({
          text: size.toString(),
          font: CLUSTER_FONT_FAMILY,
          fill: new Fill({
            color: WHITE,
          }),
          stroke: textStroke,
        }),
      }),
    ];
  };
};
export const updateMicrofenceClustersOverlay = (
  microfences: StrongFeatureHolder<Point, MicrofenceData>[],
  styleCache: Map<string, Style[]>,
  options: { showActivity: boolean },
): AnimatedCluster => {
  const vs = new VectorSource();
  vs.addFeatures(microfences.map(microfence => microfence.feature));
  const layer = new AnimatedCluster({
    source: new Cluster({
      source: vs,
      distance: CLUSTER_DISTANCE,
    }),
    style: defaultMicrofenceClusterStyle(styleCache, options),
    properties: { id: MICROFENCE_LAYER_ID, name: MICROFENCE_LAYER_LABEL },
  });
  return layer;
};

/* Microfences */
const getMicrofenceStyleWithActivity = ({
  id,
  selected,
  sensedCount,
  type,
  active,
  showActivity,
}: {
  id: string;
  selected: boolean;
  sensedCount: number;
  type: MicrofenceEntity;
  active: boolean;
  showActivity: boolean;
}): Style[] => {
  const iconStyle = getMicrofenceStyleFromType(type, selected);
  if (!showActivity) return [iconStyle];

  const count = countStyle(sensedCount, {
    active,
    offset_x: 20,
    offset_y: 20,
    text_offset_y: 13,
    text_offset_x: 13,
  });

  if (active || sensedCount > 0) {
    return [iconStyle, count, transmissionArea(LOCALISED_MAX_DISTANCE_M)];
  }
  return [iconStyle, count];
};

export const getMicrofenceStyleFromType = (type: MicrofenceEntity, selected: boolean): Style => {
  if (type === MicrofenceEntity.Beacon) {
    return selected ? selectedMicrofenceBeaconStyle : defaultMicrofenceBeaconStyle;
  } else if (type === MicrofenceEntity.Device) {
    return selected ? selectedMicrofenceDeviceStyle : defaultMicrofenceDeviceStyle;
  }
  return selected ? selectedMicrofenceGatewayStyle : defaultMicrofenceGatewayStyle;
};
const getMicrofenceCacheKey = (
  id: string,
  selected: boolean,
  sensedCount: number,
  active: boolean,
): string => {
  return `${id}_${selected ? 'selected' : 'not_selected'}_${sensedCount}_${
    active ? 'active' : 'inactive'
  }`;
};
export const clickedGeoOrMicroFenceStyle = (
  cache: Map<string, Style[]>,
  options: { showActivity: boolean },
): StyleFunction => {
  return (feature: Feature<Geometry> | RenderFeature, p1) => {
    const innerFeatures = feature.get('features');
    if (
      Array.isArray(innerFeatures) &&
      innerFeatures.length > 0 &&
      innerFeatures[0].get('layerId') === MICROFENCE_LAYER_ID
    ) {
      return defaultMicrofenceClusterStyle(cache, options)(feature, p1);
    } else {
      return defaultGeofencesStyle(cache)(feature, p1);
    }
  };
};
export const updateMicrofenceStyle = (
  cache: Map<string, Style[]>,
  microfences: Feature<Geometry>[],
  resolution: number,
) => {
  cache.forEach((styles: Style[], id: string) => {
    const microfence = microfences.find(m => id?.includes(m.get('id')));
    if (!microfence) return;
    const sensedAssets = microfence.get(SENSED_ASSETS) ?? [];
    const sensedCount = Array.isArray(sensedAssets) ? sensedAssets.length : 0;
    const active = !!microfence.get(ACTIVE_MICROFENCE);

    // Remove any further styles, and recreate if needed
    styles.splice(1, styles.length - 1);

    // Create counter
    styles[1] = countStyle(sensedCount, { active });

    // Create radial field
    if (active || sensedCount > 0) {
      const fieldOpacity = LOCALISED_MAX_DISTANCE_M / resolution > 10;
      styles[2] = new Style({
        image: new CircleStyle({
          radius: LOCALISED_MAX_DISTANCE_M / resolution,
          fill: new Fill({
            color: `rgb(136,61,232,${fieldOpacity ? 0.07 : 0})`,
          }),
          stroke: new Stroke({
            color: `rgb(136,61,232,${fieldOpacity ? 1 : 0})`,
            width: 2,
          }),
        }),
        zIndex: 1,
      });
    }
  });
};
export const microfenceStyle = (
  cache: Map<string, Style | Style[]>,
  options: { showActivity: boolean },
): StyleFunction => {
  return (f0: Feature<Geometry> | RenderFeature, p1) => {
    const id = f0.get('id');
    const sensedAssets = f0.get(SENSED_ASSETS) ?? [];
    const sensedCount = Array.isArray(sensedAssets) ? sensedAssets.length : 0;
    const active = !!f0.get(ACTIVE_MICROFENCE);
    const key = getMicrofenceCacheKey(id, !!f0.get('selected'), sensedCount, active);
    if (cache.has(key)) {
      const cachedStyle = cache.get(key);
      if (cachedStyle !== undefined) return cachedStyle;
    }
    const style = getMicrofenceStyleWithActivity({
      id,
      selected: !!f0.get('selected'),
      sensedCount,
      type: f0.get('type'),
      active,
      showActivity: options.showActivity,
    });
    cache.set(key, style);
    return style;
  };
};
export const initialiseMicrofencesOverlay = (
  microfences: StrongFeatureHolder<Point, MicrofenceData>[],
  styleCache: Map<string, Style[]>,
  options: { showActivity: boolean },
): VectorLayer<VectorSource<Geometry>> => {
  const vs = new VectorSource();
  vs.addFeatures(microfences.map(microfence => microfence.feature));
  const layer = new VectorLayer({
    source: vs,
    style: microfenceStyle(styleCache, options),
    properties: { id: MICROFENCE_LAYER_ID, name: MICROFENCE_LAYER_LABEL },
  });
  return layer;
};
