import {
  Box,
  Checkbox,
  Fade,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

export type PopperCheckListProps = {
  list: { id: string; value: string; checked: boolean; index: number }[];
  showManageColumns: boolean;
  anchorEl: HTMLElement | null;
  onChecked: (element: { id: string; value: string; checked: boolean }) => void;
};

export const PopperCheckList = (props: PopperCheckListProps) => {
  return (
    <Popper
      id={'manage-columns-popper'}
      open={props.showManageColumns}
      anchorEl={props.anchorEl}
      style={{ zIndex: 2 }}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box sx={{ border: 1 }}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  {props.list.map(element => {
                    return (
                      <TableRow
                        key={element.id}
                        sx={{
                          height: '55px',
                          fontSize: '15px',
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                        onClick={() => {
                          props.onChecked(element);
                        }}
                      >
                        <TableCell key={`${element.id}-checkox`} style={{ fontSize: '15px' }}>
                          <Checkbox checked={element.checked} />
                        </TableCell>
                        <TableCell key={`${element.id}-value`} style={{ fontSize: '15px' }}>
                          {element.value}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Fade>
      )}
    </Popper>
  );
};
