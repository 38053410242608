import { NearMe } from '@mui/icons-material';
import { Button, Grid, IconButton, Paper, Tooltip } from '@mui/material';
import { WHITE } from '../../../../../Style/GeoMobyBaseTheme';
import { EntityType, SearchTypeIDs, SearchTypeValue } from '../../../../../util/enums';
import { AssetActivityStatus, displayAssetHeading, AssetIdOrUnknown } from '../../../Helpers';
import { Asset } from '../../../types';
import { SearchListProps } from '../../Props';
import { SidebarSelectedAsset } from '../SidebarSelectedAsset';

export const SelectedTracker = (props: SearchListProps & { selectedGPSTracker: Asset }) => {
  return (
    <SidebarSelectedAsset
      asset={props.selectedGPSTracker}
      entityType={EntityType.Tracker}
      category={SearchTypeIDs.GPSTrackers}
      onCategoryClicked={() => {
        props.setSearchType({
          id: SearchTypeIDs.GPSTrackers,
          value: SearchTypeValue.GPSTrackers,
        });
        props.setSelectedGPSTracker(undefined);
        props.setSelectedAsset(undefined);
        props.setRefreshSearch(true);
      }}
      canNavigate={!!props.userExtent}
      isActive={props.selectedAsset?.id?.gpsTrackerId === props.selectedGPSTracker.id}
      activeSinceIso8601={props.selectedAsset?.iso8601}
      getNowIso8601={props.getNowIso8601}
    />
  );
};
