import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Style, { StyleFunction } from 'ol/style/Style';
import { FONT_FAMILY_VALUE } from '../../../Style/GeoMobyBaseTheme';
import { ZIndexes } from '../../../util/ZIndexes';
import { LayerParts } from '../types';
import { iconFromElement } from './AssetStyles';
import { Circle, Fill, Stroke, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { defaults } from './GeofenceStyles';

/* Measurement Tool */
export const measurementTool = new Style({
  stroke: new Stroke({ color: [1, 1, 1], lineDash: [4, 8] }),
});
export const measurementToolOff = new Style({
  stroke: new Stroke({ color: [1, 1, 1, 0] }),
});
export const measurementToolPonter = [
  new Style({
    image: new CircleStyle({
      radius: 2,
      fill: new Fill({
        color: [1, 1, 1],
      }),
    }),
    stroke: new Stroke({
      color: [1, 1, 1],
      lineDash: [4, 8],
    }),
    zIndex: ZIndexes.LINE,
  }),
];

/* Event Icons */
export const dwellIcon = iconFromElement(
  document.getElementById('Dwell') as HTMLImageElement,
  21,
  22,
  0.5,
  1,
  { scale: 1.5 },
);
export const enteredIcon = iconFromElement(
  document.getElementById('EntryBlue') as HTMLImageElement,
  21,
  22,
  0.5,
  1,
);
export const exitedIcon = iconFromElement(
  document.getElementById('ExitRed') as HTMLImageElement,
  24,
  24,
  0.5,
  1,
);

/* Drop Pin */
export const defaultDropPinStyle = [
  new Style({
    image: new Circle({
      radius: 14,
      fill: new Fill({
        color: 'rgb(198,87,250, 0.5)',
      }),
    }),
  }),
  new Style({
    image: new Circle({
      radius: 7,
      fill: new Fill({
        color: 'rgb(184,0,255)',
      }),
    }),
  }),
];

/* Heatmap */
export const heatConcentration = new Style({
  image: new CircleStyle({
    radius: 5,
    fill: new Fill({
      color: `rgba(17, 65, 255, 0.3)`,
    }),
    stroke: new Stroke({
      color: `rgba(17, 136, 255, 0.3)`,
      width: 2,
    }),
  }),
  zIndex: 1,
});

/* Location Trace */
export const initialiseLocationTraceOverlay = (): LayerParts => {
  const src = new VectorSource();
  return {
    src: src,
    lyr: new VectorLayer({
      source: src,
      style: new Style({
        stroke: new Stroke({ color: defaults.breach.stroke, width: defaults.default.width }),
        zIndex: ZIndexes.LINE,
      }),
      zIndex: ZIndexes.GEOFENCE,
    }),
  };
};

/* Popups and Labels */
export const defaultPopUpStyle = (name: string): string => {
  return (
    '<div style="' +
    'width: max-content;' +
    'padding: 5px;' +
    'border-radius: 12px;' +
    'background: rgba(169,169,169,0.7);' +
    'color: rgb(0,0,0,1);' +
    'box-shadow: 0 3px 14px rgba(0,0,0,0.4);' +
    'line-height: 1.4;' +
    'text-align: left;' +
    'font-family: ' +
    FONT_FAMILY_VALUE +
    ';' +
    'position: absolute;' +
    'font-weight: 700;">' +
    name.replace(/\n/g, '<br />') +
    '</div>'
  );
};
export const eventPopUpStyle = (event: {
  latitude: string;
  longitude: string;
  fenceName: string;
  deviceId: string;
  timestamp: Date;
}): string => {
  return (
    '<div style="' +
    'width: max-content;' +
    'padding: 5px;' +
    'border-radius: 12px;' +
    'background: rgba(169,169,169,0.7);' +
    'color: rgb(0,0,0,1);' +
    'box-shadow: 0 3px 14px rgba(0,0,0,0.4);' +
    'line-height: 1.4;' +
    'text-align: left;' +
    'font-family: ' +
    FONT_FAMILY_VALUE +
    ';' +
    'position: absolute;' +
    'font-weight: 700;">' +
    '<div><span style="font-weight: bolder">Latitude: </span>' +
    Number(event.latitude).toFixed(6) +
    '</div>' +
    '<div><span style="font-weight: bolder">Longitude: </span>' +
    Number(event.longitude).toFixed(6) +
    '</div>' +
    '<div><span style="font-weight: bolder">Geofence Name: </span>' +
    event.fenceName?.replace(/\n/g, '<br /></div>') +
    '<div><span style="font-weight: bolder">Device ID: </span>' +
    event.deviceId?.replace(/\n/g, '<br /></div>') +
    '<div><span style="font-weight: bolder">Occurred on: </span>' +
    event.timestamp +
    '</div>' +
    '</div>'
  );
};
const styleForLabel = (): StyleFunction => {
  return feature => {
    return new Style({
      text: new Text({
        text: feature.get('label'),
        stroke: new Stroke({
          color: '#ffffff',
          width: 3,
        }),
      }),
    });
  };
};
export const defaultLabelLayer = (): LayerParts => {
  const src = new VectorSource();
  return {
    src: src,
    lyr: new VectorLayer({
      source: src,
      style: styleForLabel(),
      zIndex: ZIndexes.GEOFENCE,
    }),
  };
};
