import { Accordion, AccordionSummary, Grid, Tooltip, Typography } from '@mui/material';
import { NameId } from '../Map/types';
import { ALL_LAYERS } from '../../util/constants';
import { BACKGROUND_OFFSET, PRIMARY } from '../../Style/GeoMobyBaseTheme';

export interface LayerProps {
  title?: string;
  showList: boolean;
  availableLayers: NameId[];
  selectedLayer?: NameId;
  disable?: boolean;
  onClick: (layer: NameId) => void;
}

export const LayerList = (props: LayerProps) => {
  return (
    <>
      {props.showList && (
        <Grid container>
          {props.title && (
            <Tooltip title={props.title}>
              <Typography style={{ color: PRIMARY, marginBottom: '10px' }}>
                Select a group
              </Typography>
            </Tooltip>
          )}
          {props.availableLayers.map(layer => {
            return (
              <Accordion
                id={layer.id}
                key={layer.id}
                expanded={false}
                disabled={!!props.disable}
                style={{
                  width: '98%',
                  background: layer.id === props.selectedLayer?.id ? '#1E3748' : BACKGROUND_OFFSET,
                }}
              >
                <AccordionSummary
                  onClick={async () => props.onClick(layer)}
                  sx={{
                    '& .MuiAccordionSummary-content': {
                      width: '90%',
                    },
                  }}
                >
                  <Tooltip title={layer.name}>
                    <Typography
                      style={{
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: layer.id === ALL_LAYERS ? 'bolder' : 'normal',
                      }}
                    >
                      {layer.name}
                    </Typography>
                  </Tooltip>
                </AccordionSummary>
              </Accordion>
            );
          })}
        </Grid>
      )}
    </>
  );
};
