// I need to come up with a better way of doing these. They don't work the way we need them to in LTP.
// https://geomoby.atlassian.net/browse/UR-1356

// export const uniqBy = <T>(items: T[], transformer: (item: T) => string) => {
//   const seen: { [key: string]: 1 } = {};
//
//   return items.filter((item) => {
//     const transformed = transformer(item);
//     if (seen[transformed]) return false;
//
//     seen[transformed] = 1;
//     return true;
//   });
// };

// export const keyBy = <T>(items: T[] | null | undefined, transformer: (item: T) => string | number): Record<string | number, T> => {
//   if (!items) return {};
//
//   return items.reduce((acc, item) => {
//     const key = transformer(item);
//     acc[key] = item;
//     return acc;
//   }, {} as Record<string | number, T>);
// }

export const groupBy = <T>(items: T[], grouper: (item: T) => string) => {
  const groups: { [key: string]: T[] } = {};

  items.forEach(item => {
    const group = grouper(item);
    if (!groups[group]) {
      groups[group] = [item];
    } else {
      groups[group].push(item);
    }
  });

  return groups;
};

export const arrayWithReplacementAtIndex = <T>(index: number, update: T, array: T[]) => {
  if (index < 0) {
    return [update, ...array];
  } else if (index > array.length - 1) {
    return [...array, update];
  } else {
    return [...array.slice(0, index), update, ...array.slice(index + 1)];
  }
};
