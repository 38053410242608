import { Accordion, AccordionSummary, Grid, Tooltip, Typography } from '@mui/material';
import { getZoneIcon } from '../Map/Helpers';
import { FenceZone, GeofenceEntityTypeId } from '../../util/enums';
import { GridRowData } from '@material-ui/data-grid';
import { BACKGROUND_OFFSET } from '../../Style/GeoMobyBaseTheme';

export const GeofenceList = (props: {
  availableGeofences: GridRowData[];
  selectedFence?: GridRowData;
  showList: boolean;
  onClick: (fence: GridRowData) => void;
}) => {
  return (
    <>
      {props.showList &&
        props.availableGeofences.map(fence => {
          return (
            <Accordion id={fence.id} key={fence.id} expanded={false} style={{ width: '98%' }}>
              <AccordionSummary
                onClick={() => props.onClick(fence)}
                sx={{
                  background: fence.id === props.selectedFence?.id ? '#1E3748' : BACKGROUND_OFFSET,
                  '& .MuiAccordionSummary-content': {
                    width: '90%',
                  },
                }}
              >
                {getZoneIcon(
                  fence.type?.toLowerCase()?.includes(GeofenceEntityTypeId.Multipolygon) ||
                    fence.points?.type?.toLowerCase()?.includes(GeofenceEntityTypeId.Multipolygon)
                    ? FenceZone.cleared
                    : fence.zone,
                )}
                <Tooltip
                  title={`${fence.name}${
                    !fence.zone || fence.zone === FenceZone.none
                      ? ''
                      : ' (' + fence.zone.toUpperCase() + ')'
                  }`}
                >
                  <Typography
                    style={{
                      width: '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {fence.name}
                  </Typography>
                </Tooltip>
              </AccordionSummary>
            </Accordion>
          );
        })}
    </>
  );
};
