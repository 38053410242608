import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import {
  GeoJSONLineString,
  GeoJSONMultiPolygon,
  GeoJSONPoint,
  GeoJSONPolygon,
} from 'ol/format/GeoJSON';
import { MultiPolygon } from 'ol/geom';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import * as olProj from 'ol/proj';
import Projection from 'ol/proj/Projection';
import VectorSource from 'ol/source/Vector';
import { LayerFenceData, MicrofenceData, PointData, SimplifiedFence } from '../Messages';
import { MicrofenceAssetId } from '../types';
import { FenceZone, MicrofenceEntity } from '../../../util/enums';
import { StrongFeatureHolder } from '../../../hooks/geomoby/useLiveMapLoader';
import { MICROFENCE_LAYER_ID, MICROFENCE_LAYER_LABEL, POINT } from '../../../util/constants';

export const gpsToMap = olProj.getTransformFromProjections(
  olProj.get('EPSG:4326'),
  olProj.get('EPSG:3857'),
);
export const mapToGPS = olProj.getTransformFromProjections(
  olProj.get('EPSG:3857'),
  olProj.get('EPSG:4326'),
);

export interface LayerResult {
  lid: string;
  name: string;
  layer: StrongFeatureHolder<Polygon, LayerFenceData>[];
}

export function featuresToLayer(
  layerId: string,
  layerName: string,
  features: {
    points: GeoJSONPolygon | GeoJSONLineString | GeoJSONPoint;
    id: string;
    name: string;
    zone?: FenceZone;
  }[],
): LayerResult {
  const layer: StrongFeatureHolder<Polygon, LayerFenceData>[] = features.map(jsonFeature =>
    jsonTofeature(layerId, layerName, jsonFeature),
  );
  return {
    lid: layerId,
    name: layerName,
    layer: layer,
  };
}

export function layerToJson(layerSource: VectorSource<Geometry>) {
  const copied = layerSource
    .getFeatures()
    .map(f => f.clone())
    .flatMap(f => f.get('features') ?? f);
  copied.forEach(f => f.getGeometry()?.applyTransform(mapToGPS));
  return new GeoJSON().writeFeaturesObject(copied, {
    featureProjection: 'EPSG:4326',
  });
}

function jsonTofeature(
  layerId: string,
  layerName: string,
  jsonFeature: {
    points: GeoJSONPolygon | GeoJSONLineString | GeoJSONMultiPolygon;
    id: string;
    name: string;
    zone?: FenceZone;
  },
): StrongFeatureHolder<Polygon, LayerFenceData> {
  const feature = new GeoJSON().readFeature(
    {
      type: 'Feature',
      geometry: jsonFeature.points,
      properties: {
        ...jsonFeature,
        layerId,
        layerName,
      },
    },
    {
      featureProjection: 'EPSG:4326',
    },
  );
  feature.getGeometry().applyTransform(gpsToMap);
  return {
    data: {
      fenceId: jsonFeature.id,
      layerId: layerId,
      fenceName: jsonFeature.name,
      layerName: layerName,
    },
    feature,
  };
}

export function microfencesToMicrofenceCluster(
  data: MicrofenceData[],
): StrongFeatureHolder<Point, MicrofenceData>[] {
  return data
    .filter(m => !!m.point)
    .map((microfence: MicrofenceData) => {
      const feature = new GeoJSON().readFeature(microfence.point, {
        featureProjection: 'EPSG:4326',
      });
      feature.getGeometry().applyTransform(gpsToMap);
      // TODO: Not sure why we need geomobyProperties for microfences. We're just double handling. I'll probably remove it in: https://geomoby.atlassian.net/browse/LTP-1192
      const microfenceData: MicrofenceData = {
        ...microfence,
        layerId: MICROFENCE_LAYER_ID,
        layerName: MICROFENCE_LAYER_LABEL,
        geomobyProperties: {
          boundaryRssi: Number(microfence.boundaryRssi),
          timeoutSeconds: Number(microfence.timeoutSeconds),
        },
      };
      feature.setProperties(microfenceData);
      return {
        data: microfenceData,
        feature,
      };
    });
}

export function jsonToPointCluster(
  simplifiedFences: SimplifiedFence[],
): StrongFeatureHolder<Point, PointData>[] {
  return simplifiedFences
    .filter(SimplifiedFence => !!SimplifiedFence.point)
    .map(SimplifiedFence => {
      const feature = new GeoJSON().readFeature(SimplifiedFence.point, {
        featureProjection: 'EPSG:4326',
      });
      feature.getGeometry().applyTransform(gpsToMap);
      return {
        data: {
          ...SimplifiedFence,
          type: POINT,
        },
        feature,
      };
    });
}
