import { Dispatch, SetStateAction } from 'react';
import { PenToolbar } from '../Toolbar/PenToolbar';
import { createPenTools } from '../ControlPanels/createPenTools';
import { createPenSubTools } from '../ControlPanels/createPenSubTools';
import { DrawType, EditType, FenceGeometryType, MeasurementType } from '../../../util/enums';
import { Map as olMap } from 'ol';

export const ToolPanel = (props: {
  olmap: olMap;
  selectedLayer: string | undefined;
  geofenceType: FenceGeometryType | undefined;
  geofenceTooBig: boolean;
  editing: boolean;
  isLoading: boolean;
  setEditing: (olmap: olMap) => void;
  unsetEditing: (olmap: olMap) => void;
  drawType: DrawType | undefined;
  setDrawType: Dispatch<SetStateAction<DrawType | undefined>>;
  editType: EditType | undefined;
  setEditType: Dispatch<SetStateAction<EditType | undefined>>;
  measurementType: MeasurementType | undefined;
  changeMeasurementType: (olmap: olMap, measurement: MeasurementType | undefined) => void;
}) => {
  return (
    <>
      <PenToolbar>{createPenTools(props)}</PenToolbar>

      {props.drawType === DrawType.Measure && (
        <PenToolbar marginLeft={'73px'}>{createPenSubTools(props)}</PenToolbar>
      )}
    </>
  );
};
