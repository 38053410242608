import { Add, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  SortDirection,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from '@mui/material';
import { uniqBy } from 'lodash';
import { PRIMARY, truncationStyle, WHITE } from '../Style/GeoMobyBaseTheme';

const SelectedItem = ({
  item,
  onItemChecked,
  onItemChange,
  onItemBlur,
}: {
  item: { name: string; checked?: boolean; fresh?: boolean };
  onItemChecked: (item: { name: string; checked?: boolean; fresh?: boolean }) => void;
  onItemChange: (name: string, item: { name: string; checked?: boolean; fresh?: boolean }) => void;
  onItemBlur: () => void;
}) => {
  return (
    <TableRow
      key={'fresh-row'}
      sx={{
        height: '55px',
        fontSize: '15px',
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell
        key={`${item.name}-checkox`}
        style={{
          width: '10px',
          fontSize: '15px',
        }}
      >
        <Checkbox
          checked={item.checked}
          disabled={item.fresh}
          onChange={e =>
            onItemChecked({
              ...item,
              checked: e.target.checked,
            })
          }
        />
      </TableCell>
      <Tooltip title={'Add'}>
        <TableCell style={{ maxWidth: '100px' }}>
          <TextField
            style={{
              width: 'calc(100% - -30px)',
              marginLeft: '-15px',
            }}
            value={item?.name ?? ''}
            variant="filled"
            onChange={(e: { target: { value: string } }) => {
              onItemChange(e.target.value, item);
            }}
            onBlur={() => {
              if (!item.name) return;
              onItemBlur();
            }}
          />
        </TableCell>
      </Tooltip>
    </TableRow>
  );
};

export const ItemsTable = ({
  updatedItems,
  onItemsUpdated,
}: {
  updatedItems: { name: string; checked?: boolean; fresh?: boolean }[];
  onItemsUpdated: (items: { name: string; checked?: boolean; fresh?: boolean }[]) => void;
}) => {
  const onItemChecked = (item: { name: string; checked?: boolean; fresh?: boolean }) => {
    onItemsUpdated(
      uniqBy([...updatedItems.filter(i => i.name !== item.name), item], a =>
        JSON.stringify(a.name),
      ).sort((a, b) => a.name.localeCompare(b.name)),
    );
  };

  const onItemChange = (
    name: string,
    item: { name: string; checked?: boolean; fresh?: boolean },
  ) => {
    const foundIndex = updatedItems.findIndex(i => i.name === item.name);
    onItemsUpdated(
      updatedItems.map((i, index) => {
        return index === foundIndex
          ? {
              ...item,
              name,
            }
          : i;
      }),
    );
  };

  const onItemBlur = () => {
    onItemsUpdated(
      uniqBy(
        updatedItems.map(i => {
          return {
            ...i,
            checked: i.fresh ? false : i.checked,
            fresh: false,
          };
        }),
        a => JSON.stringify(a.name),
      )?.sort((a, b) => a.name.localeCompare(b.name)),
    );
  };

  return (
    <>
      {/* Select All, Delete, Add */}
      <Grid
        container
        direction="column"
        style={{
          margin: '-10px 0px 0px -15px',
          display: 'grid',
          gridTemplateColumns: '110px 67px 50px',
        }}
      >
        <Box
          style={{
            width: 'max-content',
            marginLeft: '15px',
            fontSize: 'x-small',
            color: updatedItems.find(item => item.checked && !item.fresh) ? PRIMARY : WHITE,
            opacity: updatedItems.find(item => item.checked && !item.fresh) ? 1 : 0.3,
            fontFamily: `"Open Sans", "Montserrat", "Arial", sans-serif`,
            fontWeight: '500',
          }}
        >
          SELECT ALL
          <Checkbox
            style={{
              marginLeft: '-8px',
              color: updatedItems.find(item => item.checked && !item.fresh) ? PRIMARY : WHITE,
              opacity: updatedItems.find(item => item.checked && !item.fresh) ? 1 : 0.3,
            }}
            disabled={!!updatedItems.find(item => item.fresh && !item.fresh)}
            checked={
              updatedItems.filter(item => item.checked)?.length === updatedItems.length &&
              updatedItems.length > 1
            }
            onChange={e => {
              setTimeout(() => {
                onItemsUpdated(
                  updatedItems.map(item => {
                    return {
                      ...item,
                      checked: !e.target.checked,
                    };
                  }),
                );
              });
            }}
          />
        </Box>
        <Button
          disabled={!updatedItems.find(item => item.checked && !item.fresh)}
          onClick={() => {
            const toDelete = updatedItems.filter(item => !item.checked);
            onItemsUpdated(
              toDelete.length === 0
                ? [
                    {
                      name: '',
                      fresh: true,
                      checked: true,
                    },
                  ]
                : toDelete,
            );
          }}
        >
          <span style={{ fontSize: '10px' }}>Delete</span>
          <Delete />
        </Button>
        <Button
          disabled={
            !!updatedItems.find(item => item.fresh) || !!updatedItems.find(item => item.checked)
          }
          onClick={() =>
            onItemsUpdated(
              [
                {
                  name: '',
                  checked: true,
                  fresh: true,
                },
                ...updatedItems.filter(item => !item.fresh),
              ].sort((a, b) => a.name.localeCompare(b.name)),
            )
          }
        >
          <span style={{ fontSize: '10px' }}>Add</span>
          <Add />
        </Button>
      </Grid>

      {/* Table */}
      <Box
        sx={{
          boxShadow: '0 0 0 1px rgba(255, 255, 255, 0.3)',
          borderRadius: '4px',
        }}
      >
        <TableContainer
          component={Paper}
          style={{
            maxHeight: '500px',
          }}
        >
          <Table size="small" aria-label="a dense table">
            <TableBody>
              {/* Fresh Item */}
              {updatedItems.filter(i => i.fresh)?.length === 1 &&
                updatedItems
                  .filter(item => item.fresh)
                  ?.map(item => {
                    return SelectedItem({
                      item,
                      onItemChecked,
                      onItemChange,
                      onItemBlur,
                    });
                  })}

              {/* Items */}
              {(updatedItems ?? [])
                ?.filter(item => !item.fresh)
                .map(item => {
                  return !item.checked || updatedItems.filter(i => i.checked)?.length > 1 ? (
                    <TableRow
                      key={JSON.stringify(item)}
                      sx={{
                        height: '55px',
                        fontSize: '15px',
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                      onClick={() =>
                        onItemsUpdated(
                          [
                            ...updatedItems.filter(i => i.name !== item.name),
                            {
                              ...item,
                              checked: !item.checked,
                            },
                          ].sort((a, b) => a.name.localeCompare(b.name)),
                        )
                      }
                    >
                      <TableCell
                        key={`${item.name}-checkox`}
                        style={{
                          width: '10px',
                          fontSize: '15px',
                        }}
                      >
                        <Checkbox
                          checked={item.checked}
                          disabled={!!updatedItems.find(i => i.fresh)}
                          onChange={e =>
                            onItemsUpdated(
                              [
                                ...updatedItems.filter(i => i.name !== item.name),
                                {
                                  ...item,
                                  checked: e.target.checked,
                                },
                              ].sort((a, b) => a.name.localeCompare(b.name)),
                            )
                          }
                        />
                      </TableCell>
                      <Tooltip title={item.name}>
                        <TableCell style={{ maxWidth: '100px' }}>{item.name}</TableCell>
                      </Tooltip>
                    </TableRow>
                  ) : (
                    item.checked &&
                      updatedItems.filter(i => i.checked)?.length === 1 &&
                      SelectedItem({
                        item,
                        onItemChecked,
                        onItemChange,
                        onItemBlur,
                      })
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
