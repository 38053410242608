import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CreateAppInvitationProps } from './Props';
import { apps } from './values';

export const CreateAppInvitation = (props: CreateAppInvitationProps) => {
  return (
    <Stack p={2} direction="column" spacing={2}>
      <Typography variant="h6">Create app invite</Typography>
      <Stack direction="row" spacing={2}>
        <FormControl sx={{ minWidth: 226 }}>
          <InputLabel id="app-invite-app-select-label">App</InputLabel>
          <Select
            labelId="app-invite-app-select-label"
            id="app-invite-app-select"
            value={props.appInviteAppName}
            label="App"
            onChange={e => props.setAppInviteAppName(e.target.value)}
          >
            {apps.map(({ label, value }, i) => (
              <MenuItem key={'app' + i} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Client ID"
          onChange={e => props.setAppInviteClientId(e.target.value)}
          color={props.clientInputColour(props.appInviteClientId)}
        />
        <TextField
          label="Project ID"
          onChange={e => props.setAppInviteProjectId(e.target.value)}
          color={props.projectInputColour(props.appInviteClientId, props.appInviteProjectId)}
        />
      </Stack>
      <Button
        onClick={props.createAppInvite}
        disabled={!props.appInviteAppName || !props.appInviteClientId || !props.appInviteProjectId}
      >
        Create app invite
      </Button>
      {props.appInviteData && (
        <>
          <Typography variant="subtitle2" color="greenyellow">
            Success! Save this{' '}
            <strong>
              {apps.find(app => app.value === props.appInviteData?.app)?.label ??
                props.appInviteData.app}
            </strong>{' '}
            invite link, it can not be viewed again:
          </Typography>
          <Typography variant="body1" fontWeight="bold">
            {props.appInviteData.inviteUrl}
          </Typography>
        </>
      )}
      {props.appInviteError && (
        <Typography variant="caption" color="red">
          Error generating invite
        </Typography>
      )}
    </Stack>
  );
};
