import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { Dispatch } from 'react';
import {
  MicrofenceFilter,
  MicrofenceTypeValue,
  MicrofenceOrderByTypeValue,
  OrderTypeValue,
  MicrofenceTypePropValue,
  MicrofencePropertyFilter,
  OperatorTypeValue,
} from '../types';
import {
  MicrofenceTypes,
  MicrofenceOrderByTypes,
  OrderTypes,
  SearchTypes,
  MicrofencePropTypes,
  OperatorTypes,
} from '../values';
import { FilterList, Search, Sort } from '@mui/icons-material';
import { Header } from '../../../Common/Sidebar';
import { Extent } from 'ol/extent';
import InputContainer from '../../Global/InputContainer';
import { FilterComponentProps } from '../Editor/Props';
import { SearchTypeIDs } from '../../../util/enums';

export const MicrofenceFilterComponent = (props: FilterComponentProps) => {
  return (
    <Grid
      style={{
        marginTop: '0px',
        contentVisibility: props.showFilter ? 'visible' : 'hidden',
      }}
    >
      {/*Filter*/}
      <div
        style={{
          width: '100px',
          alignSelf: 'start',
          marginBottom: '10px',
        }}
      >
        <Header icon={<FilterList />}>Filter</Header>
      </div>
      <Grid
        style={{
          height: '80%',
          alignSelf: 'center',
        }}
      >
        {/*Microfence Type*/}
        <FormControl
          fullWidth
          style={{
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="microfence-type-option">Microfence Type</InputLabel>
          <Select
            fullWidth
            labelId="microfence-type-action"
            id="microfence-type-dropdown"
            value={props.microfenceFilter?.microfenceType?.value ?? ''}
            label="Microfence Type"
            onChange={e => {
              const microfenceType = MicrofenceTypes.find(
                m => m.value === (e.target.value as MicrofenceTypeValue),
              );
              if (!microfenceType) return;
              props.setMicrofenceFilter({ ...props.microfenceFilter, microfenceType });
            }}
          >
            {MicrofenceTypes.map(mfType => (
              <MenuItem key={mfType.id} value={mfType.value}>
                <Tooltip title={mfType.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {mfType.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/*Geomoby Property Name  */}
        <FormControl
          fullWidth
          style={{
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="microfence-property-option">Property</InputLabel>
          <Select
            fullWidth
            labelId="microfence-property-action"
            id="microfence-property-dropdown"
            value={props.microfenceFilter?.microfenceProperties?.type?.value ?? ''}
            label="Property"
            onChange={e => {
              const type = MicrofencePropTypes.find(
                m => m.value === (e.target.value as MicrofenceTypePropValue),
              );
              if (!type) return;
              const microfenceProperties: MicrofencePropertyFilter = {
                type,
                operator: props.microfenceFilter?.microfenceProperties?.operator ?? {
                  id: 'GREATER_THAN',
                  value: 'Greater Than',
                },
                value: 0,
              };
              props.setMicrofenceFilter({ ...props.microfenceFilter, microfenceProperties });
            }}
          >
            {MicrofencePropTypes.map(mfPropType => (
              <MenuItem key={mfPropType.id} value={mfPropType.value}>
                <Tooltip title={mfPropType.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {mfPropType.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/*Geomoby Property Value  */}
        <Grid
          style={{
            marginBottom: 20,
            display: 'grid',
            gridTemplateColumns: '47% 47%',
            gap: '6%',
          }}
        >
          {/*Operator  */}
          <FormControl>
            <InputLabel id="microfence-property-operator-option">Operator</InputLabel>
            <Select
              fullWidth
              labelId="microfence-property-operator-action"
              id="microfence-property-operator-dropdown"
              value={props.microfenceFilter?.microfenceProperties?.operator?.value ?? ''}
              label="Operator"
              onChange={e => {
                const operator = OperatorTypes.find(
                  o => o.value === (e.target.value as OperatorTypeValue),
                );
                if (!operator) return;
                const microfenceProperties: MicrofencePropertyFilter = {
                  type: props.microfenceFilter?.microfenceProperties?.type ?? {
                    id: 'boundaryRSSI',
                    value: 'Boundary RSSI',
                  },
                  operator,
                  value: 0,
                };
                props.setMicrofenceFilter({ ...props.microfenceFilter, microfenceProperties });
              }}
            >
              {OperatorTypes.map(operator => (
                <MenuItem key={operator.id} value={operator.value}>
                  <Tooltip title={operator.value}>
                    <Typography
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 'calc(100% - 50px)',
                      }}
                    >
                      {operator.value}
                    </Typography>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            style={{
              marginTop: '-8px',
            }}
          >
            <InputContainer
              id="geomobyPropertyValue"
              label=""
              key={'geomobyPropertyValue'}
              name={'geomobyPropertyValue'}
              type={'number'}
              value={String(
                props.microfenceFilter?.microfenceProperties?.value
                  ? props.microfenceFilter?.microfenceProperties?.value
                  : 0,
              )}
              onChange={(e: { target: { value: string } }) => {
                const microfenceProperties: MicrofencePropertyFilter = {
                  type: props.microfenceFilter?.microfenceProperties?.type ?? {
                    id: 'boundaryRSSI',
                    value: 'Boundary RSSI',
                  },
                  operator: props.microfenceFilter?.microfenceProperties?.operator ?? {
                    id: 'GREATER_THAN',
                    value: 'Greater Than',
                  },
                  value: Number(e.target.value),
                };
                props.setMicrofenceFilter({ ...props.microfenceFilter, microfenceProperties });
              }}
              placeholder="Enter a value"
            />
          </FormControl>
        </Grid>
      </Grid>

      {/*Search*/}
      <div
        style={{
          width: '100px',
          alignSelf: 'start',
          marginTop: '10px',
        }}
      >
        <Header icon={<Search />}>Search</Header>
      </div>
      <div
        style={{
          width: '300px',
          alignSelf: 'start',
          marginBottom: '10px',
        }}
      >
        Search Keywords:
      </div>

      {/*Search Name*/}
      <Grid
        style={{
          height: '80%',
          alignSelf: 'center',
        }}
      >
        {
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="searchName"
              label="Name"
              key={'searchName'}
              name={'searchName'}
              value={props.microfenceFilter?.searchName ?? ''}
              onChange={(e: { target: { value: string } }) => {
                props.setMicrofenceFilter({
                  ...props.microfenceFilter,
                  searchName: e.target.value,
                });
              }}
              placeholder="Enter a name"
            />
          </FormControl>
        }
      </Grid>

      {/*Search ID*/}
      <Grid
        style={{
          height: '80%',
          alignSelf: 'center',
        }}
      >
        {
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="searchId"
              label="ID"
              key={'searchId'}
              name={'searchId'}
              value={props.microfenceFilter?.searchId ?? ''}
              onChange={(e: { target: { value: string } }) => {
                props.setMicrofenceFilter({
                  ...props.microfenceFilter,
                  searchId: e.target.value,
                });
              }}
              placeholder="Enter an ID"
            />
          </FormControl>
        }
      </Grid>

      {/*Sort*/}
      <div
        style={{
          width: 'fit-content',
          alignSelf: 'start',
          marginTop: '10px',
        }}
      >
        <Header icon={<Sort />}>Sort</Header>
      </div>

      <Grid
        style={{
          height: '80%',
          alignSelf: 'center',
          marginTop: '10px',
        }}
      >
        {/*/!*Sort By*!/*/}
        <FormControl
          fullWidth
          style={{
            marginBottom: '20px',
            alignSelf: 'center',
          }}
        >
          <InputLabel id="sort-option">Sort By</InputLabel>
          <Select
            fullWidth
            labelId="sort-action"
            id="sort-dropdown"
            value={props.microfenceFilter?.orderBy?.value ?? ''}
            label="Sort By"
            onChange={e => {
              const orderBy = MicrofenceOrderByTypes.find(
                l => l.value === (e.target.value as MicrofenceOrderByTypeValue),
              );
              if (!orderBy) return;
              props.setMicrofenceFilter({
                ...props.microfenceFilter,
                orderBy,
              });
            }}
          >
            {MicrofenceOrderByTypes.map(orderBy => (
              <MenuItem key={orderBy.id} value={orderBy.value}>
                <Tooltip title={orderBy.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {orderBy.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/*Order*/}
        <FormControl
          fullWidth
          style={{
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="order-option">Order</InputLabel>
          <Select
            fullWidth
            labelId="order-action"
            id="order-dropdown"
            value={props.microfenceFilter?.order?.value ?? ''}
            label="Order"
            onChange={e => {
              const order = OrderTypes.find(l => l.value === (e.target.value as OrderTypeValue));
              props.setMicrofenceFilter({
                ...props.microfenceFilter,
                order,
              });
            }}
          >
            {OrderTypes.map(orderBy => (
              <MenuItem key={orderBy.id} value={orderBy.value}>
                <Tooltip title={orderBy.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {orderBy.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        container
        direction="column"
        style={{
          width: 'fit-content',
          marginTop: '30px',
          display: 'grid',
          gap: '6%',
          gridTemplateColumns: '30% 30% 30%',
        }}
      >
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => props.setClearFilter(true)}
        >
          Clear
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => {
            props.setShowFilter(false);
          }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => {
            props.setRefreshSearch(true);
          }}
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
};
