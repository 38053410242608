import { Accordion, AccordionSummary, Grid, Tooltip, Typography } from '@mui/material';
import { SearchTypeIDs } from '../../../../../util/enums';
import { ActiveAssetText, AssetLabel, findActiveAsset, selectedElement } from '../../../Helpers';
import { SearchListProps } from '../../Props';
import { useAtomValue } from 'jotai';
import { KNOWN_TOOLS } from '../../../../../store/tools';
import { Asset, PortableAssetTool } from '../../../types';
import { SidebarAssetList } from '../SidebarAssetList';
import { useCallback } from 'react';

export const BeaconList = ({
  activeAssets,
  knownTools,
  ...props
}: SearchListProps & { availableBeacons: Asset[]; knownTools: PortableAssetTool[] }) => {
  const isActive = useCallback(
    (beacon: Asset) => !!findActiveAsset(beacon.id, activeAssets),
    [activeAssets],
  );
  const beaconLabel = useCallback((beacon: Asset) => beacon.label, []);
  const beaconId = useCallback((beacon: Asset) => beacon.id, []);
  const onClick = (beacon: Asset) => {
    const foundTool = knownTools.find(t => t.id === beacon.id);
    const activeBeacon = findActiveAsset(beacon.id, activeAssets);
    if (activeBeacon && foundTool) {
      props.setSelectedAsset({
        id: activeBeacon.id,
        label: foundTool.label,
      });
    } else if (activeBeacon) {
      props.setSelectedAsset(activeBeacon);
    }
    props.setSelectedBeacon(beacon);
  };

  return (
    <SidebarAssetList
      assets={props.availableBeacons}
      assetIsActive={isActive}
      assetLabel={beaconLabel}
      assetId={beaconId}
      onClick={onClick}
    />
  );
};
