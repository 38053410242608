import { useAtomValue } from 'jotai';
import { AssetEntity, EntityType } from '../../../../../util/enums';
import { PortableAssetTool, SelectedAsset } from '../../../types';
import { AssetsListProps, SearchListProps } from '../../Props';
import { AssetPrefixText, findKnownTool } from '../../../Helpers';
import { ACTIVE_MICROFENCE } from '../../../../../util/constants';
import { TRACKED_DEVICE } from '../../../../../util/constants';
import { KNOWN_TOOLS } from '../../../../../store/tools';
import { transformExtent } from 'ol/proj';
import { StrongFeatureHolder } from '../../../../../hooks/geomoby/useLiveMapLoader';
import { MicrofenceData } from '../../../Messages';
import { Point } from 'ol/geom';
import { jsUcFirst } from '../../../../Global/StringFormatterFunctions';
import { SidebarAssetList } from '../SidebarAssetList';
import { useCallback } from 'react';
import { Typography } from '@mui/material';

export const ActiveList = (props: Omit<AssetsListProps & SearchListProps, 'isLoading'>) => {
  const knownTools = useAtomValue(KNOWN_TOOLS);

  const findAvailableMicrofence = (
    asset: SelectedAsset,
  ): StrongFeatureHolder<Point, MicrofenceData> | undefined => {
    return props.microfences.find(microfence => {
      const assetId = microfence.feature.get('assetId');
      if (!assetId) return undefined;
      return (
        (assetId.gatewayId !== undefined && assetId.gatewayId === asset.id?.gatewayId) ||
        (assetId.uuid !== undefined &&
          assetId.major !== undefined &&
          assetId.minor !== undefined &&
          assetId.uuid === asset.id?.uuid &&
          assetId.major === asset.id?.major &&
          assetId.minor === asset.id?.minor) ||
        (assetId.deviceId !== undefined && assetId.deviceId === asset.id?.deviceId) ||
        (assetId.smartplugId !== undefined && assetId.smartplugId === asset.id?.smartplugId)
      );
    });
  };

  const activeAssets = [
    ...props.activeAssets
      .sort((a, b) => (a.label ?? 'NO LABEL').localeCompare(b.label ?? 'NO LABEL'))
      .filter(asset => {
        if (asset.id?.localised) return false;

        if (asset.prefix === jsUcFirst(EntityType.Microfence)) {
          return !!props.availableMicrofences
            .find(m => JSON.stringify(m.data.assetId) === JSON.stringify(asset.id))
            ?.feature.get(ACTIVE_MICROFENCE);
        }
        return true;
      }),
  ];

  const isActive = useCallback(() => true, []);
  const assetLabel = useCallback((a: SelectedAsset) => a.label, []);
  const assetId = useCallback(
    (a: SelectedAsset) => a.id?.beaconId || a.id?.deviceId || a.id?.gpsTrackerId,
    [],
  );

  const onAssetClicked = (asset: SelectedAsset) => {
    if (asset.prefix === TRACKED_DEVICE) {
      if (asset.id?.deviceId) {
        props.setSelectedAsset(asset);
        props.setSelectedDevice({
          id: asset.id?.deviceId as string,
          label:
            asset.label ??
            props.availableDevices.find(d => d.id === asset.id?.deviceId)?.label ??
            '',
          type: AssetEntity.Device,
        });
      } else if (asset.id?.gpsTrackerId) {
        props.setSelectedAsset(asset);
        props.setSelectedGPSTracker({
          id: asset.id?.gpsTrackerId as string,
          label:
            asset.label ??
            props.availableGpsTrackers.find(d => d.id === asset.id?.gpsTrackerId)?.label ??
            '',
          type: AssetEntity.GPSTracker,
        });
      } else if (asset.id?.beaconId) {
        props.setSelectedAsset(asset);
        const foundTool = findKnownTool(asset.id, knownTools);
        if (foundTool) {
          props.setSelectedTool(foundTool as PortableAssetTool);
        } else {
          props.setSelectedBeacon({
            id: asset.id.beaconId as string,
            label:
              asset.label ??
              props.availableBeacons.find(b => b.id === asset.id?.beaconId)?.label ??
              '',
            type: AssetEntity.Beacon,
          });
        }
      }
    } else if (asset.prefix === jsUcFirst(EntityType.Microfence)) {
      props.setSelectedAsset(asset);
      const activeMicrofence = findAvailableMicrofence(asset);
      if (activeMicrofence) {
        activeMicrofence.feature.set('selected', true);
        const coords = activeMicrofence.feature.get('point')?.coordinates;
        const extent = transformExtent([...coords, ...coords], 'EPSG:4326', 'EPSG:3857');
        props.setSelectedMicrofence(activeMicrofence.feature.getProperties());
        if (coords) {
          props.setExtent(extent);
        }
      }
    }
  };

  const getPrefix = useCallback(
    (asset: SelectedAsset) =>
      asset.prefix !== jsUcFirst(EntityType.Microfence) ? (
        <Typography fontSize="small">{AssetPrefixText(asset, knownTools)}</Typography>
      ) : (
        <Typography fontSize="small">[MICROFENCE]</Typography>
      ),
    [knownTools],
  );

  return (
    <SidebarAssetList
      assets={activeAssets}
      assetIsActive={isActive}
      assetPrefix={getPrefix}
      assetLabel={assetLabel}
      assetId={assetId}
      onClick={onAssetClicked}
    />
  );
};
