import { NearMe } from '@mui/icons-material';
import { Button, Grid, IconButton, Paper, Tooltip } from '@mui/material';
import { WHITE } from '../../../../../Style/GeoMobyBaseTheme';
import { EntityType, SearchTypeIDs, SearchTypeValue } from '../../../../../util/enums';
import { AssetActivityStatus, displayAssetHeading, AssetIdOrUnknown } from '../../../Helpers';
import { Asset } from '../../../types';
import { SearchListProps } from '../../Props';
import { SidebarSelectedAsset } from '../SidebarSelectedAsset';

export const SelectedBeacon = (props: SearchListProps & { selectedBeacon: Asset }) => {
  return (
    <SidebarSelectedAsset
      asset={props.selectedBeacon}
      entityType={EntityType.Beacon}
      category={SearchTypeIDs.Beacons}
      onCategoryClicked={() => {
        props.setSearchType({ id: SearchTypeIDs.Beacons, value: SearchTypeValue.Beacons });
        props.setSelectedBeacon(undefined);
        props.setSelectedAsset(undefined);
        props.setRefreshSearch(true);
      }}
      canNavigate={!!props.userExtent}
      onNavigateClicked={() => props.setNavigateTo(props.selectedBeacon.id)}
      isActive={props.selectedAsset?.id?.beaconId === props.selectedBeacon.id}
      activeSinceIso8601={props.selectedAsset?.iso8601}
      getNowIso8601={props.getNowIso8601}
    />
  );
};
