import { Button, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { SendEmailInviteProps } from './Props';

export const SendEmailInvite = (props: SendEmailInviteProps) => {
  return (
    <Stack p={2} direction="column" spacing={2}>
      <Typography variant="h6">Send email invite</Typography>
      <Stack direction="row" spacing={2}>
        <TextField label="Email" onChange={e => props.setEmail(e.target.value)} />
        <TextField
          label="Client ID"
          onChange={e => props.setEmailClientId(e.target.value)}
          color={props.clientInputColour(props.emailClientId)}
        />
        <TextField
          label="Project ID"
          onChange={e => props.setEmailProjectId(e.target.value)}
          color={props.projectInputColour(props.emailClientId, props.emailProjectId)}
        />
      </Stack>
      <Button
        onClick={props.createUser}
        disabled={!props.email || !props.emailClientId || !props.emailProjectId}
      >
        Send email invite
      </Button>
      {props.emailInviteData && (
        <Typography variant="caption" color="greenyellow">
          Sent!
        </Typography>
      )}
      {props.emailInviteError && (
        <Typography variant="caption" color="red">
          Ruh roh!
        </Typography>
      )}
    </Stack>
  );
};
