import { Radar, Search } from '@mui/icons-material';
import { DateRangePicker, LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';
import { subDays } from 'date-fns';
import { useAtomValue, useSetAtom } from 'jotai';
import { transform } from 'ol/proj';
import { Feature } from 'ol';
import olMap from 'ol/Map';
import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { CenteredProgress, Header, Item, Sidebar } from '../../Common/Sidebar';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { MAP_API_KEYS } from '../../store/map';
import { AUTHN_URL, PERSISTOR_URL, TRIGGERS_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { SidebarAndMap } from '../Map/SidebarAndMap/SidebarAndMap';
import { HeatMap } from './HeatMap';
import { getVectorLayers } from '../../API/layers';
import { containsExtent, Extent } from 'ol/extent';
import VectorSource from 'ol/source/Vector';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import Popup from 'ol-ext/overlay/Popup';
import { GeofenceType } from '../Events/types';
import { GeofenceTypes } from '../Events/values';
import { Bounds, Device, GeofenceFilter, MapSourceType, NameId } from '../Map/types';
import { GridRowData } from '@material-ui/data-grid';
import { SaveResult, SAVE_NOTIFICATION } from '../../store/notifications';
import InputContainer from '../Global/InputContainer';
import {
  ALL,
  ALL_GEOFENCE_TYPES,
  ALL_GROUPS,
  initialExtentInDegrees,
  initialLatitude,
  initialLongitude,
} from '../../util/constants';
import {
  FenceZone,
  GeofenceEntityTypeId,
  GeofenceEntityTypeValue,
  MapType,
} from '../../util/enums';
import { getZoneIcon } from '../Map/Helpers';
import { BACKGROUND_OFFSET, PRIMARY } from '../../Style/GeoMobyBaseTheme';
import QuickSearch from '../../Common/QuickSearch';
import { truncate } from '../../util/stringUtils';
import { createMap, createMapDefaults } from '../Map/InitMap';
import { eventPopUpStyle } from '../Map/Styles/MiscStyles';

export type FenceEvent = {
  entered: {
    timestamp?: string;
    coordinates?: { latitude: number; longitude: number };
  };
  exited: {
    timestamp?: string;
    coordinates?: { latitude: number; longitude: number };
  };
  fenceId: {
    id: string;
    label?: string;
    type: string;
  };
  ids: {
    id: { deviceId: string | undefined };
    label?: string;
  };
  layerIds: {
    id: string;
    label: string;
  };
  parentFenceId?: string;
  zone: keyof typeof FenceZone;
};

export type SimplifiedFenceEvent = {
  latitude: number | undefined;
  longitude: number | undefined;
  deviceId: string;
  layerId: string;
  fenceId: {
    id: string;
    label: string;
    type: string;
  };
  timestamp?: string;
};

const useMap = ({ source, id }: { source: MapSourceType; id: MapType }): { map?: olMap } => {
  const mapApiKeys = useAtomValue(MAP_API_KEYS);
  const [{ map, setSource }, setState] = useState<{
    map?: olMap;
    setSource?: (source: MapSourceType) => void;
  }>({});

  useEffect(
    () => {
      const map = createMap(
        MapType.HEAT_MAP,
        createMapDefaults({
          sourceType: source,
          edit: false,
          specifiedCoordinates: [initialLongitude, initialLatitude], // TODO: LTP-971: Add user starting coordinates to heatmap
          mapApiKeys,
        }),
      );
      setState(map);
      return () => map.map.dispose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id],
  );

  useEffect(() => {
    if (setSource) setSource(source);
  }, [source, setSource]);

  return { map };
};

export const EventHeatMap = () => {
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authnUrl = useAtomValue(AUTHN_URL);
  const persistorUrl = useAtomValue(PERSISTOR_URL);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);

  const ALL = 'ALL';

  const currentlyDisplayedTripwiresRef = useRef<Feature<Geometry>[]>([]);
  const knownExtents = useRef<Extent[]>([]);
  const init = useRef<boolean>();
  const pageRef = useRef<number>(1);
  const popUpHoverRef = useRef<Popup>(new Popup());

  const [availableDevices, setAvailableDevices] = useState<{ id: string; label: string }[]>([]);
  const [availableGeofences, setAvailableGeofences] = useState<GridRowData[]>([]);
  const [availableLayers, setAvailableLayers] = useState<NameId[]>([]);
  const [bounds, setBounds] = useState<Bounds>({
    latitude: initialLatitude,
    longitude: initialLongitude,
    extentInDegrees: initialExtentInDegrees,
  });
  const [currentCenter, setCurrentCenter] = useState<number[] | undefined>();
  const [data, setData] = useState<SimplifiedFenceEvent[] | undefined>(undefined);
  const [fencesCount, setFencesCount] = useState<number>(0);
  const [focusGeofenceList, setFocusGeofenceList] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>();
  const [loadingDevices, setLoadingDevices] = useState<boolean>();
  const [loadingFences, setLoadingFences] = useState<boolean>();
  const [loadingMap, setLoadingMap] = useState<boolean>(true);
  const [searchFenceName, setSearchFenceName] = useState<string | undefined>();
  const [selectedDevice, setSelectedDevice] = useState<{ id: string; label: string } | undefined>();
  const [selectedLayer, setSelectedLayer] = useState<NameId | undefined>();
  const [selectedGeofence, setSelectedGeofence] = useState<GridRowData | undefined>();
  const [selectedFenceType, setSelectedFenceType] = useState<
    GeofenceType | { id: typeof ALL; value: typeof ALL_GEOFENCE_TYPES }
  >({ id: ALL, value: ALL_GEOFENCE_TYPES });
  const [range, setRange] = useState<[Date | null, Date | null]>([
    subDays(new Date(), 15),
    new Date(),
  ]);
  const [search, setSearch] = useState<boolean>();
  const [source, setSource] = useState<MapSourceType>('Terrain & Roads');
  const { map } = useMap({ id: MapType.HEAT_MAP, source });

  const debouncedOnMapMoved = useRef(
    debounce(
      (olmap: olMap) => {
        const view = olmap?.getView();
        if (!view) return;
        const viewCenter = view.getCenter();
        if (!viewCenter) return;
        const center = transform(viewCenter, view.getProjection(), 'EPSG:4326');
        setBounds({
          latitude: center[1],
          longitude: center[0],
          extentInDegrees: initialExtentInDegrees,
        });
      },
      1000,
      { leading: true },
    ),
  ).current;

  const debouncedOnSearchFence = useRef(
    debounce(
      (filter: GeofenceFilter) => {
        pageRef.current = 1;
        paginateGeofences(filter);
      },
      1000,
      { leading: true },
    ),
  ).current;

  const initialise = useCallback(
    async (m: olMap) => {
      const [layers] = await Promise.all([
        getVectorLayers(triggersUrl, authedConfig, { cid, pid }, bounds),
      ]);

      setAvailableLayers(
        layers
          ?.map(({ id, name }) => ({ id, name }))
          ?.sort((a, b) => a.name.localeCompare(b.name)) ?? [],
      );

      layers?.forEach(layer => {
        layer.source.setSource(layer.source.getSource());
        m.addLayer(layer.source);
      });
    },
    [cid, pid, authedConfig, bounds, triggersUrl],
  );

  const getDevices = useCallback(async () => {
    setAvailableDevices([]);
    setLoadingDevices(true);

    // LTP-1167 To update to an actual paginated list.
    try {
      const { devices, count } = (
        await axios.get<{ devices: Device[]; count: number }>(
          `${authnUrl}/open/device/paginate/${cid}/${pid}/1?perPage=500`,
          authedConfig,
        )
      ).data;

      setAvailableDevices(
        devices.map(({ deviceId, label }) => {
          return {
            id: deviceId,
            label: label,
          };
        }),
      );
    } catch (error: unknown) {
      console.error(error);
    } finally {
      setLoadingDevices(false);
    }
  }, [authedConfig, authnUrl, cid, pid]);

  const paginateGeofences = useCallback(
    async (filter: GeofenceFilter | undefined) => {
      const filterAllLayers = filter?.layerId === ALL;
      if (filterAllLayers) {
        filter.layerId = undefined;
      }
      setFencesCount(0);
      if (pageRef.current === 1) {
        setLoadingFences(true);
      }

      let filters = `?perPage=50`;
      Object.entries(filter ?? {}).map(([key, value]) => {
        if (key !== undefined && value !== undefined) {
          filters = filters.concat(
            `&${key}=${
              typeof value === 'object'
                ? Array.isArray(value)
                  ? String(value)
                  : value.id
                : value.toString()
            }`,
          );
        }
      });

      const { geofences, count } = (
        await axios.get<{ geofences: GridRowData[]; count: number }>(
          `${triggersUrl}/${cid}/${pid}/geofences/paginate/${pageRef.current}${filters}`,
          authedConfig,
        )
      ).data;

      if (pageRef.current === 1) {
        setAvailableGeofences(geofences);
      } else {
        const nextPage = [...availableGeofences, ...geofences];
        setAvailableGeofences(nextPage);
      }
      setFencesCount(count);
      setLoadingFences(false);
    },
    [cid, pid, triggersUrl, authedConfig, availableGeofences],
  );

  const setMapMoveHandler = useCallback(
    (m: olMap) => {
      popUpHoverRef.current?.getElement()?.childNodes[0].remove();
      m.addOverlay(popUpHoverRef.current);

      m.on('moveend', () => {
        setCurrentCenter(m.getView().getCenter());
        debouncedOnMapMoved(m);
      });
      m.on('pointermove', e => {
        const points = m
          .getFeaturesAtPixel(e.pixel)
          ?.filter(point => point.get('longitude') && point.get('latitude'));
        if (points.length > 0) {
          // Just display one for now. Not sure if we want multiple in one popup later.
          const event = points[0].getProperties();
          const coordinates = [event.longitude, event.latitude];
          const formattedFenceName = truncate(event.fenceId.label?.replace(/(.{50})/g, '$1\n'), {
            length: 1000,
            omission: '...',
          });
          const formattedDeviceId = truncate(event.deviceId?.replace(/(.{50})/g, '$1\n'), {
            length: 1000,
            omission: '...',
          });
          popUpHoverRef.current.show(
            transform(coordinates, 'EPSG:4326', 'EPSG:3857'),
            eventPopUpStyle({
              latitude: event.latitude,
              longitude: event.longitude,
              fenceName: formattedFenceName,
              deviceId: formattedDeviceId,
              timestamp: new Date(event.timestamp),
            }),
          );
          return;
        }
        popUpHoverRef.current.hide();
      });
    },
    [debouncedOnMapMoved],
  );

  const handleSubmit = useCallback(async () => {
    if (range.length < 2) return;
    try {
      setData(undefined);
      setLoadingData(true);
      const response = (
        await axios.get<FenceEvent[]>(
          `${persistorUrl}/${cid}/${pid}/analytics/geofence-events/collated?fromIso8601=${new Date(
            range[0] ?? '',
          )?.toISOString()}&toIso8601=${new Date(
            new Date(range[1] ?? '')?.toISOString(),
          )?.toISOString()}`,
          authedConfig,
        )
      ).data;

      const enteredBreachZones = response
        .filter(evt => evt.zone === FenceZone.breach)
        .map(breach => {
          return {
            latitude: breach.entered?.coordinates?.latitude,
            longitude: breach.entered?.coordinates?.longitude,
            deviceId: breach.ids.label ?? 'UNKNOWN DEVICE',
            layerId: breach.layerIds.id,
            fenceId: {
              id: breach.fenceId.id,
              label: breach.fenceId.label ?? 'UNKNOWN GEOFENCE',
              type: breach.fenceId.type as GeofenceEntityTypeId,
            },
            timestamp: breach.entered.timestamp,
          };
        });
      const exitedClearedZones = response
        .filter(evt => evt.zone === FenceZone.cleared && evt.exited.coordinates)
        .map(cleared => {
          return {
            latitude: cleared.exited?.coordinates?.latitude,
            longitude: cleared.exited?.coordinates?.longitude,
            deviceId: cleared.ids.label ?? 'UNKNOWN DEVICE',
            layerId: cleared.layerIds.id,
            fenceId: {
              id: cleared.fenceId.id,
              label: cleared.fenceId.label ?? 'UNKNOWN GEOFENCE',
              type: cleared.fenceId.type as GeofenceEntityTypeId,
            },
            timestamp: cleared.exited.timestamp,
          };
        });

      let filteredData = [...enteredBreachZones, ...exitedClearedZones];
      if (selectedDevice && selectedDevice.id !== 'All Devices') {
        filteredData = filteredData.filter(evt => evt.deviceId === selectedDevice.id);
      }
      if (selectedLayer && selectedLayer?.id !== ALL) {
        filteredData = filteredData.filter(evt => evt.layerId === selectedLayer?.id);
      }
      if (selectedFenceType && selectedFenceType?.id !== ALL) {
        filteredData = filteredData.filter(evt => evt.fenceId.type === selectedFenceType?.id);
      }
      if (selectedGeofence) {
        filteredData = filteredData.filter(evt => evt.fenceId.id === selectedGeofence?.id);
      }

      setData(filteredData);
      setSaveNotification({
        id: filteredData.length === 0 ? SaveResult.SUCCESS : SaveResult.FAIL,
        action: '',
        message: `${filteredData.length} ${filteredData.length === 1 ? 'Breach' : 'Breaches'}`,
      });
    } catch (error: unknown) {
      console.error(error);
    } finally {
      setLoadingData(false);
    }
  }, [
    range,
    cid,
    pid,
    persistorUrl,
    authedConfig,
    selectedDevice,
    selectedLayer,
    selectedFenceType,
    selectedGeofence,
    setSaveNotification,
  ]);

  useEffect(() => {
    if (!map || init.current) return;
    init.current = true;
    setMapMoveHandler(map);
    initialise(map);
    getDevices();
    const fenceType = selectedFenceType?.id === ALL ? undefined : selectedFenceType?.id;
    paginateGeofences({
      layerId: selectedLayer?.id,
      fenceType: fenceType ? [fenceType] : undefined,
      fenceName: searchFenceName,
    } as GeofenceFilter);
  }, [
    map,
    setMapMoveHandler,
    initialise,
    getDevices,
    paginateGeofences,
    selectedLayer,
    selectedFenceType,
    searchFenceName,
  ]);

  useEffect(() => {
    const loadMoreFences = async () => {
      setLoadingMap(true);
      const { latitude, longitude, extentInDegrees } = bounds;
      const [xMax, yMax] = transform(
        [longitude + extentInDegrees, latitude + extentInDegrees / 2],
        'EPSG:4326',
        'EPSG:3857',
      );
      const [xMin, yMin] = transform(
        [longitude - extentInDegrees, latitude - extentInDegrees / 2],
        'EPSG:4326',
        'EPSG:3857',
      );
      const extent: [number, number, number, number] = [xMax, yMax, xMin, yMin];
      // already got features for this extent
      if (knownExtents.current.some(ext => containsExtent(ext, extent))) {
        setLoadingMap(false);
        return;
      }

      const layers = await getVectorLayers(triggersUrl, authedConfig, { cid, pid }, bounds);
      setLoadingMap(false);
      layers?.forEach(layer => {
        const existingLayer = getLayerFromMap(layer.name);
        const newFeatures = layer.source.getSource().getFeatures();
        const existingSource = existingLayer?.getSource();
        const existingFeatures = existingSource?.getFeatures() || [];
        const keepIds = Object.fromEntries([
          ...existingFeatures.map((f: Feature<Geometry>) => [`${f.get('id')}`, true]),
        ]);
        const keepFeatures = newFeatures.filter(f => !keepIds[f.get('id')]);
        currentlyDisplayedTripwiresRef.current = Array.from(
          new Set([
            ...currentlyDisplayedTripwiresRef.current,
            ...existingFeatures,
            ...keepFeatures,
          ]),
        ).filter(f => f.get('points')?.type === 'LineString' || f.get('type') === 'LineString');
        existingSource?.addFeatures(keepFeatures);
      });
      knownExtents.current = [...knownExtents.current, extent];
    };
    loadMoreFences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds]);

  const getLayerFromMap = (name: string) => {
    const layer = map
      ?.getAllLayers()
      .find(
        layer =>
          layer instanceof VectorLayer &&
          (layer.getSource().get('name') ?? layer.get('name')) === name,
      );
    if (!layer) {
      console.error('Map layer not found from name provided', name);
      return;
    }

    return layer as VectorLayer<VectorSource<Geometry>>;
  };

  const setVisibility = (layerId: string) => {
    map?.getAllLayers().forEach(lyr => {
      if (lyr instanceof VectorLayer && lyr.getSource().get('id') !== undefined) {
        if (lyr.getSource().get('id') !== layerId && layerId !== ALL) {
          lyr.setVisible(false);
        } else {
          lyr.setVisible(true);
        }
        // If we want to unset visibility for fences and fenceTypes in the future too.
        /*         else {
          if (fenceId === ALL) {
            lyr.setVisible(true);
            lyr.getSource().getFeatures().forEach((fence: Feature<Geometry>) => {
              fence.set('visible', true);
            });
          } else {
            lyr.getSource().getFeatures().forEach((fence: Feature<Geometry>) => {
              if (fence.get('id') === fenceId) {
                fence.set('visible', true);
              } else {
                fence.set('visible', false);
              }
            });
          }
        }*/
      }
    });
  };

  return (
    <SidebarAndMap
      sidebar={
        <Sidebar>
          <Item>
            <Header icon={<Radar />}>Event Heat Map</Header>

            {/* Select Dates */}
            <DateRangePicker
              value={range}
              onChange={value => setRange(value)}
              disableFuture
              renderInput={(startProps, endProps) => (
                <Stack direction="row" spacing={2}>
                  <TextField {...startProps} label="Start date" />
                  <TextField {...endProps} label="End date" />
                </Stack>
              )}
            />

            {/* Select Device */}
            <FormControl
              fullWidth
              style={{
                marginTop: 30,
                marginBottom: 20,
                alignSelf: 'center',
              }}
            >
              <InputLabel id="device-id-option">Device ID</InputLabel>
              <Select
                fullWidth
                labelId="device-id-action"
                id="device-id-dropdown"
                value={selectedDevice?.label ?? 'All Devices'}
                label="Device ID"
                disabled={loadingDevices}
                onChange={e => {
                  setSelectedDevice(availableDevices?.find(d => d.label === e.target.value));
                }}
              >
                {[{ id: 'All Devices', label: 'All Devices' }, ...availableDevices].map(device => (
                  <MenuItem key={device.id} value={device.label}>
                    <Tooltip title={device.id}>
                      <Typography
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'calc(100% - 50px)',
                        }}
                      >
                        {device.label}
                      </Typography>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Select Group */}
            <FormControl
              fullWidth
              style={{
                marginBottom: 20,
                alignSelf: 'center',
              }}
            >
              <InputLabel id="geofence-group-option">Geofence Group</InputLabel>
              <Select
                fullWidth
                labelId="geofence-group-action"
                id="geofence-group-dropdown"
                value={selectedLayer?.name ?? ALL_GROUPS}
                label="Geofence Group"
                disabled={loadingFences}
                onChange={e => {
                  const foundLayer = [{ name: ALL_GROUPS, id: ALL }, ...availableLayers].find(
                    lyr => lyr.name === e.target.value,
                  );
                  if (foundLayer) {
                    setSelectedLayer(foundLayer);
                    if (selectedGeofence?.layerId !== foundLayer.id) {
                      setSelectedGeofence(undefined);
                    }
                    pageRef.current = 1;
                    const fenceType =
                      selectedFenceType?.id === ALL
                        ? undefined
                        : selectedFenceType?.id?.toUpperCase();
                    paginateGeofences({
                      layerId: foundLayer?.id,
                      fenceType: fenceType ? [fenceType] : undefined,
                      fenceName: searchFenceName,
                    } as GeofenceFilter);
                    setVisibility(foundLayer?.id ?? ALL);
                  }
                }}
              >
                {[{ name: ALL_GROUPS, id: ALL }, ...availableLayers].map(layer => (
                  <MenuItem key={layer.id} value={layer.name}>
                    <Tooltip title={layer.name}>
                      <Typography
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'calc(100% - 50px)',
                        }}
                      >
                        {layer.name}
                      </Typography>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Select Geofence Type */}
            <FormControl
              fullWidth
              style={{
                marginBottom: 20,
                alignSelf: 'center',
              }}
            >
              <InputLabel id="geofence-type-option">Geofence Type</InputLabel>
              <Select
                fullWidth
                labelId="geofence-type-action"
                id="geofence-type-dropdown"
                value={selectedFenceType?.value ?? ''}
                label="Geofence Type"
                disabled={loadingFences}
                onChange={e => {
                  const foundGeofenceType = GeofenceTypes.find(
                    l => l.value === (e.target.value as GeofenceEntityTypeValue),
                  );
                  setSelectedFenceType(foundGeofenceType ?? { id: ALL, value: ALL_GEOFENCE_TYPES });
                  pageRef.current = 1;
                  if (
                    foundGeofenceType &&
                    selectedGeofence?.type.toLowerCase() !== foundGeofenceType.id.toLowerCase()
                  ) {
                    setSelectedGeofence(undefined);
                  }
                  paginateGeofences({
                    layerId: selectedLayer?.id,
                    fenceType: foundGeofenceType ? [foundGeofenceType.id] : undefined,
                    fenceName: searchFenceName,
                  } as GeofenceFilter);
                }}
              >
                {[{ id: ALL, value: ALL_GEOFENCE_TYPES }, ...GeofenceTypes].map(geofenceType => (
                  <MenuItem key={geofenceType.id} value={geofenceType.value}>
                    <Tooltip title={geofenceType.value}>
                      <Typography
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'calc(100% - 50px)',
                        }}
                      >
                        {geofenceType.value}
                      </Typography>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Select Geofence */}
            <Grid
              container
              direction="column"
              style={{
                display: 'grid',
                gridTemplateColumns: '50% 50%',
              }}
            >
              <Grid
                item
                style={{
                  width: 'fit-content',
                }}
              >
                <InputLabel
                  id="geofence-name-option"
                  style={{
                    color: focusGeofenceList || selectedGeofence ? PRIMARY : 'revert',
                  }}
                >
                  Geofence Name
                </InputLabel>
              </Grid>

              <Grid
                item
                style={{
                  width: 'fit-content',
                  justifySelf: 'end',
                }}
              >
                <Typography>{`${fencesCount} ${
                  Number(fencesCount) === 1 ? 'result' : 'results'
                }`}</Typography>
              </Grid>
            </Grid>

            {/* Fence Search */}
            {QuickSearch({
              showQuickSearch: true,
              searchString: searchFenceName,
              onChange: (searchName: string) => {
                setSearchFenceName(searchName);
                setAvailableGeofences([]);
                debouncedOnSearchFence({
                  layerId: selectedLayer?.id ?? ALL,
                  fenceType: selectedFenceType?.id === ALL ? undefined : [selectedFenceType?.id],
                  searchName,
                });
              },
            })}

            <Grid
              container
              direction={'row'}
              style={{
                marginTop: '10px',
                height: 'fit-content',
                maxHeight: '620px',
                overflowY: 'auto',
                border: focusGeofenceList || selectedGeofence ? 'inset #4CB8C4' : 'inset #23272D',
                minHeight: fencesCount === 0 ? '200px' : 'auto',
              }}
              onScroll={e => {
                const target = e.target as HTMLTextAreaElement;
                if (
                  target.scrollTop % target.offsetTop < 20 &&
                  !loadingFences &&
                  fencesCount > availableGeofences.length
                ) {
                  const fenceType =
                    selectedFenceType?.id === ALL ? undefined : selectedFenceType?.id;
                  pageRef.current += 1;
                  paginateGeofences({
                    layerId: selectedLayer?.id,
                    fenceType: fenceType ? [fenceType] : undefined,
                    fenceName: searchFenceName,
                  } as GeofenceFilter);
                }
              }}
            >
              {loadingFences && (
                <Grid
                  container
                  justifyContent={'center'}
                  style={{
                    margin: '50px',
                  }}
                >
                  <CenteredProgress />
                </Grid>
              )}

              {/*Geofences */}
              {!loadingFences &&
                availableGeofences.map((fence, index) => {
                  return (
                    // Accordion has always been glitchy with minHeight when there are few contents.
                    <Accordion
                      id={String(index)}
                      key={String(index)}
                      expanded={false}
                      style={{
                        width: '98%',
                        marginBottom:
                          fencesCount < 5 && fencesCount > 0 && index === fencesCount - 1
                            ? `${(5 - fencesCount) * 50}px`
                            : 'auto',
                      }}
                    >
                      <AccordionSummary
                        onClick={() => {
                          if (!setSelectedGeofence || fence?.id !== selectedGeofence?.id) {
                            setSelectedGeofence(fence);
                            setSelectedFenceType({
                              id: fence.type,
                              value:
                                GeofenceTypes.find(type => type.id === fence.type)?.value ??
                                ALL_GEOFENCE_TYPES,
                            });
                            const foundLayer = availableLayers.find(
                              lyr => lyr.id === fence.layerId,
                            );
                            if ((!selectedLayer || selectedLayer?.id === ALL) && foundLayer) {
                              setSelectedLayer({
                                id: fence.layerId,
                                name: foundLayer.name,
                              });
                            }
                            setSelectedGeofence(fence);
                            setVisibility(foundLayer?.id ?? ALL);
                          } else {
                            setSelectedGeofence(undefined);
                            setVisibility(selectedLayer?.id ?? ALL);
                          }
                        }}
                        onFocus={() => {
                          setFocusGeofenceList(true);
                        }}
                        onBlur={() => {
                          setFocusGeofenceList(false);
                        }}
                        sx={{
                          background:
                            fence.id === selectedGeofence?.id ? '#1E3748' : BACKGROUND_OFFSET,
                          '& .MuiAccordionSummary-content': {
                            width: '90%',
                          },
                        }}
                      >
                        {getZoneIcon(fence.zone)}
                        <Tooltip
                          title={`${fence.name}${
                            !fence.zone || fence.zone === FenceZone.none
                              ? ''
                              : ' (' + fence.zone.toUpperCase() + ')'
                          }`}
                        >
                          <Typography
                            style={{
                              width: '100%',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {fence.name}
                          </Typography>
                        </Tooltip>
                      </AccordionSummary>
                    </Accordion>
                  );
                })}
            </Grid>

            {/* Search and Reset */}
            <Grid
              container
              style={{
                display: 'grid',
                gap: '4%',
                gridTemplateColumns: '48% 48%',
              }}
            >
              <Button
                variant="outlined"
                size="large"
                disabled={loadingData}
                onClick={() => {
                  pageRef.current = 1;
                  setRange([subDays(new Date(), 15), new Date()]);
                  setData(undefined);
                  setSelectedDevice(undefined);
                  setSelectedLayer(undefined);
                  setSelectedGeofence(undefined);
                  setSelectedFenceType({ id: ALL, value: ALL_GEOFENCE_TYPES });
                  setSearchFenceName(undefined);
                  paginateGeofences(undefined);
                  setVisibility(ALL);
                }}
              >
                Reset
              </Button>
              <LoadingButton
                color="secondary"
                variant="contained"
                onClick={() => {
                  handleSubmit();
                }}
                loading={loadingData}
              >
                Search
              </LoadingButton>
            </Grid>
          </Item>
        </Sidebar>
      }
      map={
        <HeatMap
          map={map}
          data={data}
          source={source}
          setSource={setSource}
          loadingMap={loadingMap}
        />
      }
    />
  );
};
