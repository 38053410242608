import { GridRowData } from '@material-ui/data-grid';
import { Delete, Edit, MoreVert } from '@mui/icons-material';
import { Box, Button, Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { BACKGROUND_OFFSET } from '../../../Style/GeoMobyBaseTheme';
import {
  EBTNotifierTypeId,
  EBTNotifierTypeValue,
  EBTListenerTypeId,
  EBTTriggerTypeOptionsId,
  EBTTriggerTypeOptionsValue,
  FenceZone,
  GeofenceEntityTypeId,
  Method,
  EBTTriggerTypeId,
} from '../../../util/enums';
import { useMobile } from '../../../util/useMobile';
import { getEventTileIcon, getEventTitle, getListeners, getTypeTitle } from '../Helpers';
import { EBTProps, EmailOrWebhookListProps, ListenersProps } from '../Props';
import { EBT } from '../types';
import { TriggerTypes } from '../values';

const WebhookList = (props: EBTProps & EmailOrWebhookListProps) => {
  const isMobile = useMobile();
  return (
    <>
      {props.availableEBTs
        .filter(e => e.notifierType === EBTNotifierTypeId.Webhook)
        ?.map(webhook => (
          <Box
            p={2}
            key={webhook.id}
            style={{
              width: '100%',
              height: '200px',
              marginBottom: '25px',
            }}
          >
            <Paper
              elevation={4}
              style={{
                height: 'auto',
                overflow: 'hidden',
                background:
                  webhook.id === props.eventIds?.webhookId ? '#1E3748' : BACKGROUND_OFFSET,
              }}
            >
              <Stack
                p={4}
                direction="column"
                spacing={1}
                style={{
                  overflowX: 'hidden',
                  paddingRight: '50px',
                  paddingBottom: isMobile ? '4px' : '20px',
                }}
              >
                <Grid
                  container
                  direction="column"
                  style={{
                    display: 'grid',
                    gap: '2%',
                    gridTemplateColumns: isMobile ? '50% 60%' : '9% 31% 53% 1%',
                    width: '100%',
                  }}
                >
                  {!isMobile && (
                    <Grid item>
                      {getEventTileIcon(
                        `${getTypeTitle(
                          webhook.listenerType,
                          props.boundariesById[webhook.listenerId]?.type,
                          !!props.geofencesById[webhook.listenerId],
                          !!props.layersById[webhook.listenerId],
                          !!props.microfencesById[webhook.listenerId],
                        ).toUpperCase()}_
                        ${getEventTitle(
                          webhook,
                          props.boundariesById[webhook.listenerId]?.higherIsEnter,
                          props.geofencesById[webhook.listenerId]?.type,
                        ).toUpperCase()}`,
                        !!isMobile,
                      )}
                    </Grid>
                  )}

                  <Grid
                    item
                    style={{
                      overflowX: 'auto',
                      maxWidth: isMobile ? '90%' : 'auto',
                      height: isMobile ? '90px' : '160px',
                    }}
                  >
                    <Box>
                      <Tooltip title={webhook.method}>
                        <Typography variant="h6">{webhook.method}</Typography>
                      </Tooltip>
                    </Box>
                    <Box>
                      <Tooltip title={webhook.url}>
                        <Typography>{webhook.url}</Typography>
                      </Tooltip>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    style={{
                      overflowY: 'auto',
                      height: '160px',
                    }}
                  >
                    {getListeners({
                      ...props,
                      ebt: webhook,
                      boundariesById: props.boundariesById,
                      geofencesById: props.geofencesById,
                      layersById: props.layersById,
                      microfencesById: props.microfencesById,
                    } as unknown as ListenersProps)}
                  </Grid>

                  {!isMobile && (
                    <Grid
                      item
                      container
                      direction="row"
                      spacing={2}
                      style={{
                        height: '100px',
                        marginTop: 'auto',
                      }}
                    >
                      <Grid item>
                        <Tooltip title={props.isLoading ? '' : 'Edit'}>
                          <Button
                            size="small"
                            disabled={props.isLoading}
                            onClick={() => {
                              props.editEBT(webhook, EBTNotifierTypeId.Webhook);
                            }}
                          >
                            <Edit />
                          </Button>
                        </Tooltip>
                      </Grid>

                      <Grid item>
                        <Tooltip title={props.isLoading ? '' : 'Delete'}>
                          <Button
                            size="small"
                            disabled={props.isLoading}
                            onClick={() => {
                              props.setDeleting({
                                id: webhook?.id,
                                type: EBTNotifierTypeId.Webhook,
                              });
                            }}
                          >
                            <Delete />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                {isMobile && (
                  <Grid
                    item
                    container
                    direction="column"
                    style={{
                      height: '40px',
                      display: 'grid',
                      gridTemplateColumns: '68% 13% 13% 13%',
                      width: '100%',
                    }}
                  >
                    <Grid item>
                      {getEventTileIcon(
                        `${getTypeTitle(
                          webhook.listenerType,
                          props.boundariesById[webhook.listenerId]?.type,
                          !!props.geofencesById[webhook.listenerId],
                          !!props.layersById[webhook.listenerId],
                          !!props.microfencesById[webhook.listenerId],
                        ).toUpperCase()}_
                        ${getEventTitle(
                          webhook,
                          props.boundariesById[webhook.listenerId]?.higherIsEnter,
                          props.geofencesById[webhook.listenerId]?.type,
                        ).toUpperCase()}`,
                        !!isMobile,
                      )}
                    </Grid>

                    <Grid item>
                      <Tooltip title={props.isLoading ? '' : 'Edit'}>
                        <Button
                          size="small"
                          disabled={props.isLoading}
                          onClick={() => {
                            props.editEBT(webhook, EBTNotifierTypeId.Webhook);
                          }}
                        >
                          <Edit />
                        </Button>
                      </Tooltip>
                    </Grid>

                    <Grid item>
                      <Tooltip title={props.isLoading ? '' : 'Delete'}>
                        <Button
                          size="small"
                          disabled={props.isLoading}
                          onClick={() => {
                            props.setDeleting({ id: webhook?.id, type: EBTNotifierTypeId.Webhook });
                          }}
                        >
                          <Delete />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
              </Stack>
            </Paper>
          </Box>
        ))}
    </>
  );
};
export default WebhookList;
