import { Asset, LastKnownLocation, PortableAssetTool, SelectedAsset } from '../../types';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { GridRowData } from '@material-ui/data-grid';
import { StrongFeatureHolder } from '../../../../hooks/geomoby/useLiveMapLoader';
import { Point } from 'ol/geom';
import { AssetState, MicrofenceData } from '../../Messages';
import { LocationDisplayType, LocationSearchData } from '../../Toolbar/LocationSearch';
import { BeaconLatestLocation } from './BeaconLatestLocation';
import { AssetLastLocation } from './AssetLastLocation';
import { findActiveAsset, getMostRecentStreamedLocation, getSensedBeacon } from '../../Helpers';
import { Extent } from 'ol/extent';

export const AssetLatestLocation = ({
  assetStates,
  microfences,
  activeAssets,
  range,
  setRange,
  now,
  selectedDevice,
  setSelectedDevice,
  selectedGPSTracker,
  setSelectedGPSTracker,
  selectedTool,
  setSelectedTool,
  selectedBeacon,
  setSelectedBeacon,
  setSelectedMicrofence,
  selectedAsset,
  setSelectedAsset,
  userExtent,
  setUserExtent,
}: {
  assetStates: AssetState[];
  microfences: StrongFeatureHolder<Point, MicrofenceData>[];
  activeAssets: SelectedAsset[];
  range: [Date | null, Date | null];
  setRange: Dispatch<SetStateAction<[Date | null, Date | null]>>;
  now: () => Date;
  selectedDevice: Asset | undefined;
  setSelectedDevice: Dispatch<Asset | undefined>;
  selectedGPSTracker: Asset | undefined;
  setSelectedGPSTracker: Dispatch<Asset | undefined>;
  setSelectedMicrofence: Dispatch<GridRowData | undefined>;
  selectedTool: PortableAssetTool | undefined;
  setSelectedTool: Dispatch<PortableAssetTool | undefined>;
  selectedBeacon: Asset | undefined;
  setSelectedBeacon: Dispatch<Asset | undefined>;
  selectedAsset: SelectedAsset | undefined;
  setSelectedAsset: Dispatch<SelectedAsset | undefined>;
  userExtent: Extent | undefined;
  setUserExtent: (extent: Extent | undefined) => void;
}) => {
  const asset =
    selectedBeacon ?? selectedDevice ?? selectedGPSTracker ?? (selectedTool as Asset | undefined);

  useEffect(() => {
    if (!asset?.id) return;

    const foundActiveAsset = findActiveAsset(asset.id, activeAssets);
    if (
      foundActiveAsset &&
      (!selectedAsset || JSON.stringify(selectedAsset?.id) !== JSON.stringify(foundActiveAsset.id))
    ) {
      setSelectedAsset(foundActiveAsset);
    }
  }, [asset?.id, selectedAsset, setSelectedAsset, activeAssets]);

  const isActiveBeacon = selectedAsset && (selectedBeacon || selectedTool);
  const isInactiveBeaconOrSomeOtherAsset =
    (!selectedAsset && (selectedBeacon || selectedTool)) || selectedDevice || selectedGPSTracker;

  return (
    <>
      {isActiveBeacon && (
        <BeaconLatestLocation
          sensedBeacon={getSensedBeacon(selectedAsset, assetStates)}
          microfences={microfences}
          activeAssets={activeAssets}
          setSelectedDevice={setSelectedDevice}
          setSelectedGPSTracker={setSelectedGPSTracker}
          setSelectedMicrofence={setSelectedMicrofence}
          setSelectedTool={setSelectedTool}
          setSelectedBeacon={setSelectedBeacon}
          setSelectedAsset={setSelectedAsset}
          userExtent={userExtent}
          setUserExtent={setUserExtent}
          now={now}
        ></BeaconLatestLocation>
      )}

      {isInactiveBeaconOrSomeOtherAsset && (
        <AssetLastLocation
          asset={selectedBeacon ?? selectedDevice ?? selectedGPSTracker ?? (selectedTool as Asset)}
          getMostRecentStreamedLocation={() =>
            getMostRecentStreamedLocation(
              selectedBeacon ?? selectedDevice ?? selectedGPSTracker ?? (selectedTool as Asset),
              assetStates,
            )
          }
          activeAssets={activeAssets}
          range={range}
          setRange={setRange}
          now={now}
          setSelectedTool={setSelectedTool}
          setSelectedBeacon={setSelectedBeacon}
          setSelectedAsset={setSelectedAsset}
          setSelectedDevice={setSelectedDevice}
          setSelectedGPSTracker={setSelectedGPSTracker}
          setSelectedMicrofence={setSelectedMicrofence}
          microfences={microfences}
          setUserExtent={setUserExtent}
        ></AssetLastLocation>
      )}
    </>
  );
};
