/* eslint-disable @typescript-eslint/no-explicit-any */

import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import {
  AssetState,
  BatteryReading,
  CMContainer,
  DeviceLocation,
  GeofenceEvent,
  GlobalProjectId,
  LocalBeacons,
  MessageType,
  SensedAssetsReport,
  SensedTemp,
  SensedTriggeredEvent,
  TempRangeEvent,
  WelfareCheckResponse,
} from '../../Components/Map/Messages';
import {
  LIVE_LATEST_EVENTS,
  LIVE_NEW_MESSAGES,
  LIVE_NEW_NOTIFICATIONS,
} from '../../store/liveEvents';
import { isDefined } from '../../util/isDefined';
import {
  AssetUpdateType,
  LiveMapUpdate,
  updateBattery,
  updateFenceEvent,
  updateFullState,
  updateLocation,
  updateNotification,
  updateSensedAssets,
  updateSensedTemp,
  updateSensedTriggered,
  updateTempRangeEvent,
} from './LiveMapActions';
import { LIVE_NOTIFICATIONS, RawNotification, makeNotificationId } from '../../store/notifications';
import { uniqBy } from 'lodash';
import { TRACKING_BOUNDS } from '../../util/constants';
import {
  isLocalisedAssetLabel,
  localiseAssetLabel,
} from '../../Components/Map/LiveAndReplay/EventUpdates';
import { FenceEvent } from '../../util/enums';
import { stringifyIdRecord } from '../../util/stringUtils';
import { arrayWithReplacementAtIndex } from '../../util/arrayUtils';

function emptyAssetState(label: string, id: Record<string, string | undefined>): AssetState {
  return {
    id,
    label,
    lastLocation: undefined,
    lastFenceEvent: undefined,
    lastSensed: undefined,
    lastLocalBeacons: undefined,
    lastTemp: undefined,
    lastTempRangeEvent: undefined,
    lastWelfareCheckResponse: undefined,
    recentSensedTriggered: undefined,
    lastBatteryReading: undefined,
  };
}

export interface LiveMapState {
  assets: { label: string; assetState: AssetState }[];
  selectedAsset: string | undefined;
  notifications: RawNotification[];
}

export const reduceIncomingMessagesToMapState = (
  liveMapState: LiveMapState,
  liveMapUpdate: LiveMapUpdate,
): LiveMapState => {
  if (liveMapUpdate.type === AssetUpdateType.FullStateUpdate) {
    return liveMapUpdate.payload;
  }
  const assetLabel =
    liveMapUpdate.payload.label === undefined && liveMapUpdate.type === AssetUpdateType.LocalBeacons
      ? localiseAssetLabel(liveMapUpdate.payload.deviceLocation.label)
      : liveMapUpdate.payload.label;
  if (!assetLabel) {
    return liveMapState;
  }
  const assetId = liveMapUpdate.payload.id;
  const isLocalised = isLocalisedAssetLabel(assetLabel);

  const indexOfAssetToUpdate = liveMapState.assets.findIndex(asset => {
    const idMatches = stringifyIdRecord(asset.assetState.id) === stringifyIdRecord(assetId);
    const localisationMatches = isLocalisedAssetLabel(asset.label) === isLocalised;
    return idMatches && localisationMatches;
  });
  const assetToBeUpdated =
    indexOfAssetToUpdate >= 0
      ? liveMapState.assets[indexOfAssetToUpdate].assetState
      : emptyAssetState(assetLabel, assetId);
  switch (liveMapUpdate.type) {
    case AssetUpdateType.Battery: {
      const shouldIgnoreBatteryMesage = liveMapUpdate.payload.percent < 0;
      if (shouldIgnoreBatteryMesage) {
        return liveMapState;
      }
      return {
        ...liveMapState,
        assets: arrayWithReplacementAtIndex(
          indexOfAssetToUpdate,
          {
            label: assetLabel,
            assetState: {
              ...assetToBeUpdated,
              id: liveMapUpdate.payload.id,
              lastBatteryReading: liveMapUpdate.payload,
            },
          },
          liveMapState.assets,
        ),
      };
    }
    case AssetUpdateType.Location: {
      return {
        ...liveMapState,
        assets: arrayWithReplacementAtIndex(
          indexOfAssetToUpdate,
          {
            label: assetLabel,
            assetState: {
              ...assetToBeUpdated,
              source: liveMapUpdate.payload.source,
              lastLocation: liveMapUpdate.payload,
            },
          },
          liveMapState.assets,
        ),
      };
    }
    case AssetUpdateType.GeofenceEvent: {
      return {
        ...liveMapState,
        assets: arrayWithReplacementAtIndex(
          indexOfAssetToUpdate,
          {
            label: assetLabel,
            assetState: {
              ...assetToBeUpdated,
              lastFenceEvent: liveMapUpdate.payload,
            },
          },
          liveMapState.assets,
        ),
      };
    }
    case AssetUpdateType.SensedAssets: {
      return {
        ...liveMapState,
        assets: arrayWithReplacementAtIndex(
          indexOfAssetToUpdate,
          {
            label: assetLabel,
            assetState: {
              ...assetToBeUpdated,
              lastSensed: liveMapUpdate.payload,
            },
          },
          liveMapState.assets,
        ),
      };
    }
    case AssetUpdateType.SensedTriggered: {
      const previousSensedTriggered = assetToBeUpdated.recentSensedTriggered ?? [];
      return {
        ...liveMapState,
        assets: arrayWithReplacementAtIndex(
          indexOfAssetToUpdate,
          {
            label: assetLabel,
            assetState: {
              ...assetToBeUpdated,
              recentSensedTriggered: [
                liveMapUpdate.payload,
                ...previousSensedTriggered.filter(
                  st =>
                    JSON.stringify(st.sensedId) !== JSON.stringify(liveMapUpdate.payload.sensedId),
                ),
              ].sort(
                ({ timestamp: a }, { timestamp: b }) =>
                  new Date(b).getTime() - new Date(a).getTime(),
              ),
            },
          },
          liveMapState.assets,
        ),
      };
    }
    case AssetUpdateType.LocalBeacons: {
      return {
        ...liveMapState,
        assets: arrayWithReplacementAtIndex(
          indexOfAssetToUpdate,
          {
            label: assetLabel,
            assetState: {
              ...assetToBeUpdated,
              lastLocalBeacons: liveMapUpdate.payload,
            },
          },
          liveMapState.assets,
        ),
      };
    }
    case AssetUpdateType.SensedTemp: {
      return {
        ...liveMapState,
        assets: arrayWithReplacementAtIndex(
          indexOfAssetToUpdate,
          {
            label: assetLabel,
            assetState: {
              ...assetToBeUpdated,
              lastTemp: liveMapUpdate.payload,
            },
          },
          liveMapState.assets,
        ),
      };
    }
    case AssetUpdateType.TempRangeEvent: {
      return {
        ...liveMapState,
        assets: arrayWithReplacementAtIndex(
          indexOfAssetToUpdate,
          {
            label: assetLabel,
            assetState: {
              ...assetToBeUpdated,
              lastTempRangeEvent: liveMapUpdate.payload,
            },
          },
          liveMapState.assets,
        ),
      };
    }
    case AssetUpdateType.WelfareCheckResponseUpdate: {
      return {
        ...liveMapState,
        assets: arrayWithReplacementAtIndex(
          indexOfAssetToUpdate,
          {
            label: assetLabel,
            assetState: {
              ...assetToBeUpdated,
              id: liveMapUpdate.payload.id,
              lastWelfareCheckResponse: liveMapUpdate.payload,
            },
          },
          liveMapState.assets,
        ),
      };
    }
    case AssetUpdateType.NotificationUpdate: {
      const nextNotification: RawNotification = {
        ...liveMapUpdate.payload,
        datetime: new Date(liveMapUpdate.payload.timestamp),
      };
      return {
        ...liveMapState,
        notifications: uniqBy([...liveMapState.notifications, nextNotification], 'notificationId'),
      };
    }
  }
};

export function toMapUpdateOrUndefined(
  o: CMContainer,
): LiveMapUpdate | LiveMapUpdate[] | undefined {
  // TODO: Remove this 'any'. Seems odd that we're passing in CMContainer and then imediately treading it as an unknown type.
  // Also, the name CMContainer is not obvious what it does.
  //  https://geomoby.atlassian.net/browse/LTP-1192
  const m = (o as any).data;
  switch (o.type) {
    case MessageType.Battery:
      return updateBattery({
        id: m.ids.id,
        label: m.ids.label,
        iso8601: m.iso8601,
        percent: m.percent,
        volts: m.volts,
      });
    case MessageType.Coordinates:
      return updateLocation({
        id: m.ids.id,
        label: m.ids.label,
        beaconId: m.ids.id.beaconId,
        source: m.sourceIds,
        timestamp: m.iso8601,
        lon: m.coordinates.longitude,
        lat: m.coordinates.latitude,
        radius: m.coordinates.errorRadiusMetres,
      });
    case MessageType.CoordinatesDwell:
      return updateFenceEvent({
        id: m.ids.id,
        label: m.ids.label,
        timestamp: m.iso8601,
        fenceId: m.fenceIds.id,
        fenceName: m.fenceIds.label,
        type: m.fenceIds.type,
        layerId: m.layerIds.id,
        x: m.coordinates.longitude,
        y: m.coordinates.latitude,
        event: FenceEvent.Dwell,
        dwellSeconds: m.dwellSeconds,
      });
    case MessageType.CoordinatesTriggered:
      if (m.layerIds.label === TRACKING_BOUNDS && !m.entered) {
        return [
          updateFenceEvent({
            id: m.ids.id,
            label: m.ids.label,
            timestamp: m.iso8601,
            fenceId: m.fenceIds.id,
            fenceName: m.fenceIds.label,
            type: m.fenceIds.type,
            layerId: 'n/a',
            x: m.coordinates.longitude,
            y: m.coordinates.latitude,
            event: m.entered ? FenceEvent.Entered : FenceEvent.Exited,
          }),
          updateNotification({
            id: m.ids.id,
            notificationId: makeNotificationId({
              type: 'CoordinatesTriggered',
              iso8601: m.iso8601,
              primaryId: JSON.stringify(m.ids.id),
            }),
            label: m.ids.label,
            text: `Asset ${m.ids.label ?? m.ids.id?.beaconId} exited a tracking bound`,
            timestamp: m.iso8601,
          }),
        ];
      }

      return updateFenceEvent({
        id: m.ids.id,
        label: m.ids.label,
        timestamp: m.iso8601,
        fenceId: m.fenceIds.id,
        fenceName: m.fenceIds.label,
        type: m.fenceIds.type,
        layerId: 'n/a',
        x: m.coordinates.longitude,
        y: m.coordinates.latitude,
        event: m.entered ? FenceEvent.Entered : FenceEvent.Exited,
      });
    case MessageType.Sensed:
      return updateSensedAssets({
        id: m.ids.id,
        label: m.ids.label,
        timestamp: m.iso8601,
        assets: (m.sensed ?? []).map((sensed: any) => ({
          label: sensed.ids.label,
          beaconId: sensed.ids.id.beaconId,
          rssi: sensed.rssi,
          txpower: sensed.txPower,
          distance: sensed.distanceMetres,
          battery_level: NaN,
        })),
      });
    case MessageType.SensedTriggered:
      return [
        updateSensedTriggered({
          id: m.ids.id,
          label: m.ids.label,
          sensedId: m.sensedIds.id,
          sensedLabel: m.sensedIds.label,
          timestamp: m.iso8601,
          entered: m.entered,
        }),
        updateNotification({
          id: m.ids.id,
          notificationId: makeNotificationId({
            type: 'SensedTriggered',
            iso8601: m.iso8601,
            primaryId: JSON.stringify(m.ids.id),
            otherIds: [JSON.stringify(m.sensedIds.id)],
          }),
          label: m.ids.label,
          text: `Asset  ${m.sensedIds.label ?? m.sensedIds.id?.beaconId} ${
            m.entered ? 'entered' : 'exited'
          } MicroFence ${m.ids.label}`,
          timestamp: m.iso8601,
          interaction: {
            microfenceId: m.ids.id,
            microfenceName: m.ids.label,
            type: 'find-microfence',
          },
        }),
      ];
    case MessageType.Temperature:
      return updateSensedTemp({
        id: m.ids.id,
        label: m.ids.label,
        timestamp: m.iso8601,
        temp: m.celsius,
      });
    case MessageType.SensorTriggeredBoundary:
      if (m.boundaryIds.type === 'TEMPERATURE_CELSIUS')
        return updateTempRangeEvent({
          id: m.ids.id,
          temp: m.value,
          event: m.entered ? FenceEvent.Entered : FenceEvent.Exited,
          limit: NaN,
          label: m.ids.label,
          timestamp: m.iso8601,
          tempRangeId: m.boundaryIds.id,
          tempRangeLabel: m.boundaryIds.label,
        });
      else return;
    case MessageType.Spo2:
      return;
    default:
      console.warn('Unknown message from websocket', o);
  }
}

export interface AuthPacket {
  gpid: GlobalProjectId;
  login: string;
  key: string;
}

export interface LiveStreamData {
  state: LiveMapState;
  lastUpdates: LiveMapUpdate[] | undefined;
  startDate: Date;
}

const INITIAL_STATE: LiveMapState = {
  assets: [],
  selectedAsset: undefined,
  notifications: [],
};

export function useGeomobyLiveStream({
  useFor,
}: {
  useFor: 'notifications' | 'livemap';
}): LiveStreamData {
  const startDate = useMemo(() => new Date(), []);
  const [state, dispatchState] = useReducer(reduceIncomingMessagesToMapState, INITIAL_STATE);
  const [lastUpdates, setLastUpdates] = useState<LiveMapUpdate[] | undefined>();
  const [queue, setQueue] = useAtom(
    useFor === 'notifications' ? LIVE_NEW_NOTIFICATIONS : LIVE_NEW_MESSAGES,
  );
  const lastEvents = useAtomValue(LIVE_LATEST_EVENTS);
  const setNotifications = useSetAtom(LIVE_NOTIFICATIONS);

  const isWantedUpdate = useCallback(
    (liveMapUpdate: LiveMapUpdate) =>
      useFor === 'notifications'
        ? liveMapUpdate.type === AssetUpdateType.NotificationUpdate
        : liveMapUpdate.type !== AssetUpdateType.NotificationUpdate,
    [useFor],
  );

  useEffect(() => {
    if (lastEvents) {
      const messages = lastEvents.flatMap(e => toMapUpdateOrUndefined(e)).filter(isDefined);
      const actualInitialState = messages.reduce<LiveMapState>(
        (liveMapState, liveMapUpdate) =>
          isWantedUpdate(liveMapUpdate)
            ? reduceIncomingMessagesToMapState(liveMapState, liveMapUpdate)
            : liveMapState,
        INITIAL_STATE,
      );
      const updateEvent = updateFullState(actualInitialState);
      dispatchState(updateEvent);
      setLastUpdates([updateEvent]);
    }
  }, [lastEvents, isWantedUpdate]);

  useEffect(() => {
    let messages: CMContainer[] = [];
    if (queue.length > 0) {
      setQueue(queue => {
        messages = queue;
        return [];
      });
    }

    const updates = messages
      .flatMap(message => toMapUpdateOrUndefined(message))
      .filter((update): update is LiveMapUpdate => !!update && isWantedUpdate(update));

    updates.forEach(update => {
      dispatchState(update);
    });
    setLastUpdates(updates);
  }, [queue, setQueue, isWantedUpdate]);

  useEffect(() => {
    if (useFor === 'notifications') {
      setNotifications(notifications =>
        uniqBy([...notifications, ...state.notifications], 'notificationId'),
      );
    }
  }, [useFor, state.notifications, setNotifications]);

  return {
    state,
    lastUpdates,
    startDate,
  };
}
