import { Button, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { CreateUsersProps } from './Props';

export const CreateNewProject = (props: CreateUsersProps) => {
  return (
    <Stack p={2} direction="column" spacing={2}>
      <Stack direction="row" spacing={2}>
        <TextField label="Client ID" onChange={e => props.setNewClientId(e.target.value)} />
        <TextField label="Project ID" onChange={e => props.setNewProjectId(e.target.value)} />
      </Stack>
      <Button onClick={props.createProject} disabled={!props.newClientId || !props.newProjectId}>
        Create project
      </Button>
      {props.createProjectData && props.createProjectData.length === 1 && (
        <Typography variant="caption">Project already exists</Typography>
      )}
      {props.createProjectData && props.createProjectData.length > 1 && (
        <Typography variant="caption" color="greenyellow">
          Project created!
        </Typography>
      )}
      {props.createProjectError && (
        <Typography variant="caption" color="red">
          Error creating project
        </Typography>
      )}
    </Stack>
  );
};
