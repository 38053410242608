export enum ProjectConfigCategories {
  Properties = 'Properties',
}

export enum PropertiesColumns {
  Selected = 'Selected',
  Label = 'Label',
  Message = 'Message',
}

export enum PropertyType {
  List = 'List',
  Number = 'Number',
  String = 'String',
}
