import { Category, NearMe } from '@mui/icons-material';
import { Button, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { PRIMARY, SECONDARY, truncationStyle, WHITE } from '../../../../Style/GeoMobyBaseTheme';
import { EntityType, SearchTypeIDs } from '../../../../util/enums';
import { AssetActivityStatus, AssetIdOrUnknown } from '../../Helpers';

export const SidebarSelectedAsset = <T extends { label?: string; id?: string }>({
  asset,
  entityType,
  category,
  onCategoryClicked,
  canNavigate,
  onNavigateClicked,
  isActive,
  activeSinceIso8601,
  getNowIso8601,
  omitIds,
  extraRows,
}: {
  asset: T;
  entityType: EntityType;
  category: SearchTypeIDs;
  onCategoryClicked: () => void;
  canNavigate: boolean;
  onNavigateClicked?: () => void;
  isActive: boolean;
  activeSinceIso8601?: string;
  getNowIso8601: () => string;
  omitIds?: boolean;
  extraRows?: [label: string, val: string][];
}) => (
  <Paper
    variant="outlined"
    style={{
      padding: '10px',
      height: 'fit-content',
    }}
  >
    <Grid
      container
      direction="row"
      style={{
        width: '100%',
        justifyContent: 'right',
        marginTop: '-6px',
      }}
    >
      <Tooltip title={'NAVIGATE TO'}>
        <IconButton color="primary" disabled={!canNavigate} onClick={onNavigateClicked}>
          <span>
            <NearMe />
          </span>
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid
      container
      direction="row"
      spacing={1}
      marginLeft={0}
      justifyContent="left"
      alignItems="center"
    >
      <Tooltip title={asset.label || asset.id} style={truncationStyle}>
        <Typography variant="h5">
          {(asset.label || asset.id) && (
            <span style={truncationStyle}>{asset.label || asset.id}</span>
          )}
          {!(asset.label || asset.id) && (
            <span
              style={{
                color: SECONDARY,
              }}
            >
              {`${entityType} IS UNKNOWN`}
            </span>
          )}
        </Typography>
      </Tooltip>
    </Grid>
    <Grid
      container
      direction="column"
      style={{
        marginBottom: '10px',
        width: 'fit-content',
      }}
    >
      <Button
        style={{
          marginLeft: '-7px',
          color: WHITE,
        }}
        onClick={onCategoryClicked}
      >
        {category}
      </Button>
    </Grid>

    <Grid container direction={'row'} justifyContent={'center'}>
      {!omitIds && AssetIdOrUnknown(asset.id, entityType)}
      {extraRows &&
        extraRows.map(([label, val]) => (
          <Grid item container direction={'row'} xs={12} fontSize="90%" key={label}>
            <Tooltip title={label}>
              <Typography style={{ color: PRIMARY, paddingRight: '5px' }}>{label}:</Typography>
            </Tooltip>
            <Tooltip title={val}>
              <Typography>{val}</Typography>
            </Tooltip>
          </Grid>
        ))}
      <AssetActivityStatus
        isActive={isActive}
        sinceIso8601={activeSinceIso8601}
        nowIso8601={getNowIso8601()}
      />
    </Grid>
  </Paper>
);
