import { GridRowData } from '@material-ui/data-grid';
import { FenceGeometryType, GeoJsonType, MicrofenceEntity } from '../../util/enums';

export const microfenceTypeOfEntity = (entity: GridRowData): MicrofenceEntity | undefined => {
  const type = (entity?.type ?? entity?.points?.type)?.toLowerCase();
  if (!type) return;
  if (type === MicrofenceEntity.Beacon) {
    return MicrofenceEntity.Beacon;
  } else if (type === MicrofenceEntity.Device) {
    return MicrofenceEntity.Device;
  } else if (type === MicrofenceEntity.Gateway) {
    return MicrofenceEntity.Gateway;
  } else if (type === MicrofenceEntity.Smartplug) {
    return MicrofenceEntity.Smartplug;
  }
  return;
};

export const geometryTypeOfEntity = (entity: GridRowData): FenceGeometryType | undefined => {
  const type = (entity?.type ?? entity?.points?.type)?.toLowerCase();
  if (microfenceTypeOfEntity(entity)) {
    return FenceGeometryType.Microfence;
  } else if (type === FenceGeometryType.Multipolygon) {
    return FenceGeometryType.Multipolygon;
  } else if (type === FenceGeometryType.Polygon) {
    return FenceGeometryType.Polygon;
  } else if (type?.startsWith(FenceGeometryType.Line)) {
    return FenceGeometryType.Line;
  }
  return;
};

// Microfence is not actually a valid geoJson. But we have it here, basically to have the inverse of the function above.
export const geojsonTypeOfEntity = (entity: GridRowData): GeoJsonType | undefined => {
  const type = (entity?.type ?? entity?.points?.type)?.toLowerCase();
  if (!type) return;
  if (microfenceTypeOfEntity(entity)) {
    return GeoJsonType.Microfence;
  }
  if (type === FenceGeometryType.Multipolygon) {
    return GeoJsonType.Multipolygon;
  } else if (type === FenceGeometryType.Polygon) {
    return GeoJsonType.Polygon;
  } else if (type?.startsWith(FenceGeometryType.Line)) {
    return GeoJsonType.Linestring;
  }
  return;
};
