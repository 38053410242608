import {
  CheckCircle,
  Login,
  Logout,
  Thermostat,
  Radar,
  Sensors,
  SensorsOff,
  WifiTetheringError,
  HistoryToggleOff,
  BatteryStd,
  BatteryFull,
  Battery80,
  Battery60,
  Battery50,
  Battery30,
  Battery20,
  BatteryAlert,
} from '@mui/icons-material';
import TrackChanges from '@mui/icons-material/TrackChanges';
import { Stack, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { PaperBox, SubHeader } from '../../Common/Sidebar';
import TemperatureRangeIcon from '../../Style/icons/TemperatureRangeIcon';
import {
  AssetState,
  BatteryReading,
  DeviceLocation,
  GeofenceEvent,
  LocalBeacons,
  ReportBeacon,
  SensedAsset,
  SensedAssetsReport,
  SensedTemp,
  SensedTriggeredEvent,
  TempRangeEvent,
  WelfareCheckResponse,
} from './Messages';
import { rest } from 'lodash';
import { SECONDARY, truncationStyle } from '../../Style/GeoMobyBaseTheme';
import { PortableAssetTool } from './types';

const RenderWelfareCheckResponse = (
  lastWelfareCheckResponse: WelfareCheckResponse | undefined,
  now: () => string,
) => (
  <>
    {lastWelfareCheckResponse && (
      <PaperBox>
        <SubHeader icon={<CheckCircle />} from={now()} to={lastWelfareCheckResponse.timestamp}>
          Welfare Check
        </SubHeader>
        <div style={{ marginLeft: '1rem' }}>
          <Tooltip title={lastWelfareCheckResponse.ok ? 'OK!' : 'Not OK'}>
            <pre style={truncationStyle}>
              Response: {lastWelfareCheckResponse.ok ? 'OK!' : 'Not OK'}
            </pre>
          </Tooltip>
          <Tooltip title={new Date(lastWelfareCheckResponse.timestamp).toLocaleString()}>
            <pre style={truncationStyle}>
              at: {new Date(lastWelfareCheckResponse.timestamp).toLocaleString()}
            </pre>
          </Tooltip>
        </div>
      </PaperBox>
    )}
  </>
);

const BatteryIcon = ({ percentage }: { percentage: number | undefined }) =>
  percentage === undefined || percentage < 0 ? (
    <></>
  ) : percentage > 90 ? (
    <BatteryFull />
  ) : percentage > 70 ? (
    <Battery80 />
  ) : percentage > 50 ? (
    <Battery60 />
  ) : percentage > 40 ? (
    <Battery50 />
  ) : percentage > 20 ? (
    <Battery30 />
  ) : percentage > 10 ? (
    <Box component="div" color="warning.light">
      <Battery20 />
    </Box>
  ) : (
    <Box component="div" color="error.light">
      <BatteryAlert color="error" />
    </Box>
  );

const RenderBatteryLevel = (battery: BatteryReading | undefined, now: () => string) => (
  <>
    {battery && (
      <PaperBox>
        <SubHeader
          icon={<BatteryIcon percentage={battery.percent} />}
          from={now()}
          to={battery.iso8601}
        >
          Battery
        </SubHeader>
        <div style={{ marginLeft: '1rem' }}>
          <pre style={truncationStyle}>
            {battery.percent.toFixed(0)}%{battery.volts && <> ({battery.volts.toFixed(2)}V)</>}
          </pre>
          <pre style={truncationStyle}>at: {new Date(battery.iso8601).toLocaleString()}</pre>
        </div>
      </PaperBox>
    )}
  </>
);

const RenderSensedTemp = (sensedTemp: SensedTemp | undefined, now: () => string) => (
  <>
    {sensedTemp && (
      <PaperBox>
        <SubHeader icon={<Thermostat />} from={now()} to={sensedTemp.timestamp}>
          Temperature
        </SubHeader>
        <div style={{ marginLeft: '1rem' }}>
          <Tooltip title={sensedTemp.temp.toString()}>
            <pre style={truncationStyle}>temp: {sensedTemp.temp}</pre>
          </Tooltip>
          <Tooltip title={new Date(sensedTemp.timestamp).toLocaleString()}>
            <pre style={truncationStyle}>at: {new Date(sensedTemp.timestamp).toLocaleString()}</pre>
          </Tooltip>
        </div>
      </PaperBox>
    )}
  </>
);

const RenderTempRangeEvent = (tempRangeEvent: TempRangeEvent | undefined, now: () => string) => {
  const entered = tempRangeEvent?.event?.toLowerCase() === 'entered';
  return (
    <>
      {tempRangeEvent && (
        <PaperBox>
          <SubHeader
            icon={<TemperatureRangeIcon style={{}} color="inhert" />}
            from={now()}
            to={tempRangeEvent.timestamp}
          >
            {entered ? 'Entered' : 'Exited'} Temperature Event
          </SubHeader>
          <div style={{ marginLeft: '1rem' }}>
            <Tooltip title={tempRangeEvent.tempRangeLabel}>
              <pre style={truncationStyle}>name: {tempRangeEvent.tempRangeLabel}</pre>
            </Tooltip>
            <Tooltip title={new Date(tempRangeEvent.timestamp).toLocaleString()}>
              <pre style={truncationStyle}>
                at: {new Date(tempRangeEvent.timestamp).toLocaleString()}
              </pre>
            </Tooltip>
          </div>
        </PaperBox>
      )}
    </>
  );
};

const RenderGeofenceEvent = (
  geofenceEvent: GeofenceEvent | undefined,
  now: () => string,
  multiEvent?: boolean,
) => {
  const entered = geofenceEvent?.event?.toLowerCase() === 'entered';
  const dwell = geofenceEvent?.event?.toLowerCase() === 'dwell';
  return (
    <>
      {geofenceEvent && (
        <PaperBox>
          <SubHeader
            icon={dwell ? <HistoryToggleOff /> : entered ? <Login /> : <Logout />}
            from={now()}
            to={geofenceEvent.timestamp}
          >
            {dwell
              ? 'Dwell '
              : (geofenceEvent.type === 'polygon' || geofenceEvent.type === 'multipolygon') &&
                (entered ? 'Entered ' : 'Exited ')}
            {!dwell &&
              geofenceEvent.type === 'line' &&
              (entered ? 'Left to Right ' : 'Right to Left ')}
            Geofence
          </SubHeader>
          <div style={{ marginLeft: '1rem', width: '85%' }}>
            {multiEvent && (
              <Tooltip title={geofenceEvent.label}>
                <pre style={truncationStyle}>Asset ID: {geofenceEvent.label},</pre>
              </Tooltip>
            )}
            {!multiEvent && (
              <Tooltip title={geofenceEvent.fenceName}>
                <pre style={truncationStyle}>fence name: {geofenceEvent.fenceName},</pre>
              </Tooltip>
            )}
            {dwell && (
              <Tooltip title={geofenceEvent.dwellSeconds}>
                <pre style={truncationStyle}>dwell time: {geofenceEvent.dwellSeconds} seconds,</pre>
              </Tooltip>
            )}
            <Tooltip title={geofenceEvent.y.toFixed(5).toString()}>
              <pre style={truncationStyle}>lat: {geofenceEvent.y.toFixed(5)},</pre>
            </Tooltip>
            <Tooltip title={geofenceEvent.x.toFixed(5).toString()}>
              <pre style={truncationStyle}>lon: {geofenceEvent.x.toFixed(5)}</pre>
            </Tooltip>
            <Tooltip title={new Date(geofenceEvent.timestamp).toLocaleString()}>
              <pre style={truncationStyle}>
                at: {new Date(geofenceEvent.timestamp).toLocaleString()}
              </pre>
            </Tooltip>
          </div>
        </PaperBox>
      )}
    </>
  );
};

const renderSensedTriggered = (
  sensedTriggeredEvent: SensedTriggeredEvent | undefined,
  key: number,
) => (
  <div key={key}>
    {sensedTriggeredEvent && (
      <Stack direction="row">
        <Box color="primary.main" pr={1} display="flex" flexWrap="wrap" alignContent="center">
          {sensedTriggeredEvent.entered ? <Sensors /> : <SensorsOff />}
        </Box>
        <div style={{ width: '85%' }}>
          <Tooltip
            title={`${sensedTriggeredEvent.sensedLabel} ${
              sensedTriggeredEvent.entered ? 'entered' : 'exited'
            }`}
          >
            <pre style={truncationStyle}>
              {sensedTriggeredEvent.sensedLabel}{' '}
              {sensedTriggeredEvent.entered ? 'entered' : 'exited'}
            </pre>
          </Tooltip>
          <Tooltip title={new Date(sensedTriggeredEvent.timestamp).toLocaleString()}>
            <pre style={truncationStyle}>
              at: {new Date(sensedTriggeredEvent.timestamp).toLocaleString()}
            </pre>
          </Tooltip>
        </div>
      </Stack>
    )}
  </div>
);

const RenderMicrofenceEvents = (
  sensedTriggeredEvent: SensedTriggeredEvent[] | undefined,
  now: () => string,
) => (
  <>
    {sensedTriggeredEvent && sensedTriggeredEvent.length > 0 && (
      <PaperBox>
        <SubHeader
          icon={<WifiTetheringError />}
          from={now()}
          to={sensedTriggeredEvent[0].timestamp}
        >
          Entered/Exited
        </SubHeader>
        <Stack direction="column" divider={<Divider color="gray" />} spacing={1}>
          {sensedTriggeredEvent.map((event, index) => renderSensedTriggered(event, index))}
        </Stack>
      </PaperBox>
    )}
  </>
);

const renderSensedAsset = (
  sensedAsset: SensedAsset | undefined,
  key: number,
  knownTools: PortableAssetTool[],
) => (
  <div key={key}>
    {sensedAsset && (
      <div>
        <Tooltip title={sensedAsset.label ?? 'NO LABEL'}>
          <pre style={truncationStyle}>
            {sensedAsset.beaconId
              ? knownTools.find(t => t.id === sensedAsset.beaconId)
                ? 'tool'
                : 'beacon'
              : 'asset'}
            : {sensedAsset.label}
            {!sensedAsset.label && (
              <span
                style={{
                  color: SECONDARY,
                }}
              >
                NO LABEL
              </span>
            )}
          </pre>
        </Tooltip>

        {sensedAsset.beaconId && (
          <Tooltip title={sensedAsset.beaconId}>
            <pre style={truncationStyle}>ID: {sensedAsset.beaconId}</pre>
          </Tooltip>
        )}
        <Tooltip title={sensedAsset.rssi.toString()}>
          <pre style={truncationStyle}>rssi: {sensedAsset.rssi}</pre>
        </Tooltip>
        {sensedAsset.distance !== undefined && (
          <Tooltip title={sensedAsset.distance.toString()}>
            <pre style={truncationStyle}>distance: {sensedAsset.distance}</pre>
          </Tooltip>
        )}
        {Number.isFinite(sensedAsset.battery_level) &&
          sensedAsset.battery_level !== undefined &&
          sensedAsset.battery_level >= 0 && (
            <Tooltip title={sensedAsset.battery_level ? sensedAsset.battery_level.toString() : ''}>
              <pre style={truncationStyle}>battery_level: {sensedAsset.battery_level}</pre>
            </Tooltip>
          )}
      </div>
    )}
  </div>
);

const renderNearbySensedAssets = (
  assets: SensedAsset[],
  timestamp: string,
  knownTools: PortableAssetTool[],
  title: string,
  now: () => string,
) => (
  <div key={title}>
    <PaperBox>
      <SubHeader icon={<TrackChanges />} from={now()} to={timestamp}>
        {title}
      </SubHeader>
      <div style={{ marginLeft: '1rem' }}>
        <Stack direction="column" divider={<Divider color="gray" />} spacing={1}>
          {assets?.map((asset, index) => renderSensedAsset(asset, index, knownTools))}
        </Stack>
      </div>
    </PaperBox>
  </div>
);

const RenderSensedBeacons = (
  sensed: SensedAssetsReport | undefined,
  knownTools: PortableAssetTool[],
  now: () => string,
) => (
  <>
    {sensed &&
      sensed.assets.length > 0 &&
      renderNearbySensedAssets(sensed.assets, sensed.timestamp, knownTools, 'BLE Tags', now)}
  </>
);

const RenderLocalBeacons = (
  beacons: LocalBeacons | undefined,
  knownTools: PortableAssetTool[],
  now: () => string,
) => (
  <>
    {beacons && beacons.nearbyBeacons.length > 0 && (
      <>
        {renderNearbySensedAssets(
          beacons.nearbyBeacons.map(({ label, uuid, major, minor, beacon_id, ...rest }) => ({
            ...rest,
            uuid,
            major,
            minor,
            label,
            beaconId: beacon_id,
            id: { uuid, major: major.toString(), minor: minor.toString() },
          })),
          beacons.deviceLocation.timestamp,
          knownTools,
          'Nearby Beacons',
          now,
        )}
      </>
    )}
  </>
);

export const RenderAssetState = (
  assetState: AssetState,
  knownTools: PortableAssetTool[],
  multiEvent: boolean,
  key: number,
  now: () => string,
) => {
  if (!assetState) return <div key={key}></div>;
  const {
    label,
    lastWelfareCheckResponse,
    lastBatteryReading,
    lastTemp,
    lastTempRangeEvent,
    lastFenceEvent,
    recentSensedTriggered,
    lastSensed,
    lastLocalBeacons,
  } = assetState;
  if (multiEvent) {
    return (
      <Stack key={label} direction="column" spacing={1}>
        {RenderGeofenceEvent(lastFenceEvent, now, true)}
      </Stack>
    );
  }

  return (
    <Stack key={label} direction="column" spacing={1}>
      {RenderWelfareCheckResponse(lastWelfareCheckResponse, now)}
      {RenderBatteryLevel(lastBatteryReading, now)}
      {RenderSensedTemp(lastTemp, now)}
      {RenderTempRangeEvent(lastTempRangeEvent, now)}
      {RenderGeofenceEvent(lastFenceEvent, now)}
      {RenderMicrofenceEvents(recentSensedTriggered, now)}
      {RenderSensedBeacons(lastSensed, knownTools, now)}
      {RenderLocalBeacons(lastLocalBeacons, knownTools, now)}
    </Stack>
  );
};
