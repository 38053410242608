import { createTheme, CSSInterpolation, PaletteOptions } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';

export const PRIMARY = '#4CB8C4';
export const SECONDARY = '#924439';

export const BACKGROUND = '#23272D';
export const BACKGROUND_OFFSET = '#2D3748';

export const WHITE = '#fff';
export const WHITE_OFFSET = '#ddd';
export const CLEARED_ZONE = '#B642F5';

export const RED = '#ED3153';
export const PURPLE = '#883DE8';
export const ACTIVE_GREEN = '#33d431';

export const lineLimitStyle: React.CSSProperties | undefined = {
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflowWrap: 'break-word',
};

export const truncationStyle: React.CSSProperties | undefined = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const FONT_FAMILY_VALUE = `Open Sans, Montserrat, Arial, sans-serif`;

export const GeoMobyBase = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: PRIMARY },
    secondary: { main: SECONDARY },
    background: { default: BACKGROUND, paper: BACKGROUND_OFFSET },
    text: { primary: WHITE, secondary: WHITE_OFFSET },
  },
  typography: {
    fontFamily: `"Open Sans", "Montserrat", "Arial", sans-serif`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
});

export const GeoMobyBaseTheme = createTheme({
  ...GeoMobyBase,
  components: {
    MuiPaper: {
      styleOverrides: {
        root: { backgroundImage: 'none' },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: { backgroundColor: GeoMobyBase.palette.primary.dark },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          height: '100%',
          ['MuiTabs-flexContainer']: {
            height: '100%',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        pre: {
          margin: 0,
        },
        '::-webkit-scrollbar': {
          width: '20px',
          height: '20px',
        },
        '::-webkit-scrollbar-track': {
          background: '#101214',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#2a2d31',
        },
        '::-webkit-scrollbar-corner': {
          background: '#101214',
        },
      },
    },
  },
});

export default GeoMobyBaseTheme;
