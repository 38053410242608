import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import { StrongFeatureHolder } from '../../../../../hooks/geomoby/useLiveMapLoader';
import { ACCESS_JWT_TOKEN, AUTHED_REQUEST_CONFIG } from '../../../../../store/auth';
import { AUTHN_URL, METADATA_URL, PERSISTOR_URL, TRIGGERS_URL } from '../../../../../store/url';
import { CID, PID } from '../../../../../store/user';
import {
  AssetFilter,
  GeofenceFilter,
  LayerFilter,
  MicrofenceFilter,
  MicrofenceType,
  Asset,
  PortableAssetTool,
  SearchType,
  ToolFilter,
  ToolType,
  CreatorFilter,
  Creator,
  GeomobyOverride,
  Device,
} from '../../../types';
import { SearchTypes } from '../../../values';
import Polygon from 'ol/geom/Polygon';
import { MicrofenceData } from '../../../Messages';
import { LineString, MultiPolygon, Point } from 'ol/geom';
import { GridRowData } from '@material-ui/data-grid';
import { CenteredProgress } from '../../../../../Common/Sidebar';
import { Clear, FilterList, LocationOn } from '@mui/icons-material';
import { Extent } from 'ol/extent';
import { transformExtent } from 'ol/proj';
import { Feature } from 'ol';
import {
  AssetLocation,
  LocationDisplayType,
  LocationSearch,
  LocationSearchData,
} from '../../../Toolbar/LocationSearch';
import { SHOW_TOOL_FINDER } from '../../../../../store/map';
import { useMobile } from '../../../../../util/useMobile';
import {
  AssetEntity,
  EntityType,
  MicrofenceEntity,
  SearchTypeIDs,
  SearchTypeValue,
} from '../../../../../util/enums';
import {
  ACTIVE_MICROFENCE,
  ALL_GROUPS,
  ALL_LAYERS,
  MICROFENCE_LAYER_ID,
  MICROFENCE_LAYER_LABEL,
} from '../../../../../util/constants';
import { selectedElement } from '../../../Helpers';
import { SearchListProps } from '../../Props';
import { GeofenceList } from '../../../../Geofence/GeofenceList';
import { MicrofenceList } from '../../../../Microfence/MicrofenceList';
import { ActiveList } from '../../Sidebar/Active/ActiveList';
import { BeaconList } from '../../Sidebar/Beacon/BeaconsList';
import { DeviceList } from '../../Sidebar/Device/DeviceList';
import { ToolList } from '../../Sidebar/Tool/ToolList';
import { TrackerList } from '../../Sidebar/Tracker/TrackerList';
import { SelectedGeofence } from '../../Sidebar/Geofence/SelectedGeofence';
import { SelectedMicrofence } from '../../Sidebar/Microfence/SelectedMicrofence';
import { SelectedBeacon } from '../Beacon/SelectedBeacon';
import { SelectedDevice } from '../Device/SelectedDevice';
import { SelectedTool } from '../Tool/SelectedTool';
import { SelectedTracker } from '../Tracker/SelectedTracker';
import { SidebarLayers } from '../Layer/Layers';
import { truncationStyle, WHITE } from '../../../../../Style/GeoMobyBaseTheme';
import { FilterComponent } from './FilterComponent';
import { jsUcFirst } from '../../../../Global/StringFormatterFunctions';
import API from '../../../../../API/api';
import { KNOWN_TOOLS } from '../../../../../store/tools';

export const SearchList = (props: SearchListProps) => {
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const authnUrl = useAtomValue(AUTHN_URL);
  const metadataUrl = useAtomValue(METADATA_URL);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const showToolFinder = useAtomValue(SHOW_TOOL_FINDER);
  const knownTools = useAtomValue(KNOWN_TOOLS);
  const accessJwtToken = useAtomValue(ACCESS_JWT_TOKEN);
  const Authorization = `Bearer ${accessJwtToken}`;

  const [availableBeacons, setAvailableBeacons] = useState<Asset[]>([]);
  const [availableDevices, setAvailableDevices] = useState<Asset[]>([]);
  const [availableGeofences, setAvailableGeofences] = useState<GridRowData[]>([]);
  const [availableGpsTrackers, setAvailableGpsTrackers] = useState<Asset[]>([]);
  const [availableMicrofences, setAvailableMicrofences] = useState<
    StrongFeatureHolder<Point, MicrofenceData>[]
  >(props.microfences);
  const [availableTools, setAvailableTools] = useState<PortableAssetTool[]>([]);
  const [count, setCount] = useState<number>(0);
  const [displayCount, setDisplayCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startScrolling, setStartScrolling] = useState<boolean>(false);

  const isMobile = useMobile();
  const isPaginatingRef = useRef<boolean>(false);
  const pageRef = useRef<number>(1);

  const getGeofence = useCallback(
    async (fence: GridRowData) => {
      props.setSelectedMicrofence(undefined);
      pageRef.current;
      const geofence = (
        await axios.get<{
          id: string;
          name: string;
          points: { coordinates: Extent };
          geomobyProperties: Record<string, string>;
          geomobyOverrides: GeomobyOverride[];
        }>(
          `${triggersUrl}/${cid}/${pid}/geofences/${fence.layerId}/${fence.type}/${fence.id}`,
          authedConfig,
        )
      ).data;

      if (!geofence) return;
      const newFence =
        fence.type === 'polygon'
          ? new Polygon(geofence.points.coordinates)
          : fence.type === 'multipolygon'
          ? new MultiPolygon(geofence.points.coordinates)
          : new LineString(geofence.points.coordinates);

      const extent = transformExtent(newFence.getExtent(), 'EPSG:4326', 'EPSG:3857');
      props.setExtent(extent);
    },
    [cid, pid, triggersUrl, authedConfig, props],
  );

  const getToolTypes = useCallback(async () => {
    props.setToolTypes(
      (
        await axios.get<ToolType[]>(
          `${metadataUrl}/${cid}/${pid}/portableasset/tool/all`,
          authedConfig,
        )
      ).data,
    );
  }, [cid, pid, metadataUrl, authedConfig, props]);

  const paginateGPSTrackers = useCallback(
    async (filter?: AssetFilter) => {
      setCount(0);
      if (pageRef.current === 1 && !startScrolling) {
        setIsLoading(true);
        setDisplayCount(0);
      }

      const {
        results,
        info: { count },
      } = await API.metadata.getGpsTrackers({
        clientId: cid,
        projectId: pid,
        Authorization,
        query: {
          page: pageRef.current,
          perPage: 200,
          serial: filter?.searchId,
          label: filter?.searchLabel,
          order: filter?.order?.id,
          orderBy: 'label' as const,
        },
      });

      setAvailableGpsTrackers(
        results.map(tracker => ({
          id: tracker.serial,
          label: tracker.label,
          type: AssetEntity.GPSTracker,
        })),
      );

      setCount(count);
      setDisplayCount(count);
      setIsLoading(false);
    },
    [cid, pid, Authorization, startScrolling],
  );

  const paginateDevices = useCallback(
    async (filter?: AssetFilter) => {
      setCount(0);
      if (pageRef.current === 1 && !startScrolling) {
        setIsLoading(true);
        setDisplayCount(0);
      }

      let filters = `?perPage=200`;
      Object.entries(filter ?? {}).map(([key, value]) => {
        if (key !== undefined && value !== undefined) {
          filters = filters.concat(
            `&${key}=${
              typeof value === 'object'
                ? key === 'order'
                  ? value.id.toLowerCase()
                  : value.id
                : value.toString()
            }`,
          );
        }
      });

      const { devices, count } = (
        await axios.get<{ devices: Device[]; count: number }>(
          `${authnUrl}/open/device/paginate/${cid}/${pid}/${pageRef.current}${filters}`,
          authedConfig,
        )
      ).data;

      setAvailableDevices(
        devices.map(({ deviceId, label }) => {
          return {
            id: deviceId,
            label: label,
            type: AssetEntity.Device,
          } as Asset;
        }),
      );
      setCount(count);
      setDisplayCount(count);
      setIsLoading(false);
    },
    [cid, pid, authnUrl, authedConfig, startScrolling],
  );

  const paginateBeacons = useCallback(
    async (filter?: AssetFilter) => {
      setCount(0);
      if (pageRef.current === 1 && !startScrolling) {
        setIsLoading(true);
        setDisplayCount(0);
      }

      let filters = `?type=beacon&perPage=50`;
      Object.entries(filter ?? {}).map(([key, value]) => {
        if (key !== undefined && value !== undefined) {
          filters = filters.concat(
            `&${key}=${typeof value === 'object' ? value.id : value.toString()}`,
          );
        }
      });
      const { portableAssets, count } = (
        await axios.get<{ portableAssets: Asset[]; count: number }>(
          `${metadataUrl}/${cid}/${pid}/portableAsset/paginate/${pageRef.current}${filters}`,
          authedConfig,
        )
      ).data;
      setAvailableBeacons(portableAssets);
      setCount(count);
      setDisplayCount(count);
      setIsLoading(false);
    },
    [cid, pid, metadataUrl, authedConfig, startScrolling],
  );

  const paginateGeofences = useCallback(
    async (filter?: GeofenceFilter) => {
      if (props.layers.length === 0) {
        setAvailableGeofences([]);
        setCount(0);
        setDisplayCount(0);
        return;
      }

      const filterAllLayers = filter?.layerId === ALL_LAYERS;
      if (filterAllLayers) {
        filter.layerId = undefined;
      }
      setCount(0);
      if (pageRef.current === 1 && !startScrolling) {
        setIsLoading(true);
        setDisplayCount(0);
      }

      let filters = `?perPage=200`;
      Object.entries(filter ?? {}).map(([key, value]) => {
        if (key !== undefined && value !== undefined) {
          filters = filters.concat(
            `&${key}=${
              typeof value === 'object'
                ? Array.isArray(value)
                  ? String(value)
                  : value.id
                : value.toString()
            }`,
          );
        }
      });

      const { geofences, count } = (
        await axios.get<{ geofences: GridRowData[]; count: number }>(
          `${triggersUrl}/${cid}/${pid}/geofences/paginate/${pageRef.current}${filters}`,
          authedConfig,
        )
      ).data;

      setAvailableGeofences(geofences);
      setCount(count);
      setDisplayCount(count);
      setIsLoading(false);
    },
    [cid, pid, triggersUrl, authedConfig, props, startScrolling],
  );

  const paginateTools = useCallback(
    async (filter?: ToolFilter) => {
      setCount(0);
      if (pageRef.current === 1 && !startScrolling) {
        setIsLoading(true);
        setDisplayCount(0);
      }

      let filters = `?perPage=200`;
      Object.entries(filter ?? {}).map(([key, value]) => {
        if (key !== undefined && value !== undefined) {
          filters = filters.concat(
            `&${key}=${typeof value === 'object' ? value.id : value.toString()}`,
          );
        }
      });

      const { tools, count } = (
        await axios.get<{ tools: PortableAssetTool[]; count: number }>(
          `${metadataUrl}/${cid}/${pid}/portableasset/tool/paginate/${pageRef.current}${filters}`,
          authedConfig,
        )
      ).data;

      setAvailableTools(tools);
      setCount(count);
      setDisplayCount(count);
      setIsLoading(false);
    },
    [cid, pid, metadataUrl, authedConfig, startScrolling],
  );

  const refreshFilter = useCallback(() => {
    setAvailableGeofences([]);
    setAvailableMicrofences(props.microfences);
    setCount(0);
    setDisplayCount(0);
    props.setAssetFilter(undefined);
    props.setGeofenceFilter(undefined);
    props.setToolFilter(undefined);
    props.setMicrofenceFilter(undefined);
    props.setSelectedAsset(undefined);
    pageRef.current = 1;

    if (props.searchType?.id === SearchTypeIDs.Geofences) {
      paginateGeofences({ layerId: props.layerFilter?.layer?.id } as GeofenceFilter);
    }
    if (props.searchType?.id === SearchTypeIDs.Beacons) {
      paginateBeacons();
    }
    if (props.searchType?.id === SearchTypeIDs.GPSTrackers) {
      paginateGPSTrackers();
    }
    if (props.searchType?.id === SearchTypeIDs.Devices) {
      paginateDevices();
    }
    if (props.searchType?.id === SearchTypeIDs.Tools) {
      paginateTools();
      getToolTypes();
    }
  }, [
    props,
    paginateGeofences,
    paginateBeacons,
    paginateDevices,
    paginateGPSTrackers,
    paginateTools,
    getToolTypes,
  ]);

  const clearAll = () => {
    setAvailableGeofences([]);
    setAvailableMicrofences(props.microfences);
    setAvailableBeacons([]);
    setAvailableDevices([]);
    setAvailableGpsTrackers([]);
    setCount(0);
    setDisplayCount(0);
    props.setGeofenceFilter(undefined);
    props.setMicrofenceFilter(undefined);
    props.setLayerFilter(undefined);
    pageRef.current = 1;
    props.setSearchType(undefined);
    props.setSelectedAsset(undefined);
    isPaginatingRef.current = false;
    setStartScrolling(false);
  };

  const goBack = () => {
    props.setSelectedBeacon(undefined);
    props.setSelectedDevice(undefined);
    props.setSelectedGPSTracker(undefined);
    props.setSelectedGeofence(undefined);
    props.setSelectedMicrofence(undefined);
    props.setSelectedTool(undefined);
    props.setSelectedAsset(undefined);
    props.setDeselectFence(true);
    props.setUserExtent(undefined);
  };

  const paginateList = async () => {
    switch (props.searchType?.id) {
      case SearchTypeIDs.Geofences:
        await paginateGeofences({
          ...props.geofenceFilter,
          layerId: props.layerFilter?.layer?.id,
        } as GeofenceFilter);
        break;
      case SearchTypeIDs.Beacons:
        paginateBeacons({
          ...props.assetFilter,
        } as AssetFilter);
        break;
      case SearchTypeIDs.GPSTrackers:
        paginateGPSTrackers({
          ...props.assetFilter,
        });
        break;
      case SearchTypeIDs.Devices:
        paginateDevices({
          ...props.assetFilter,
        });
        break;
      case SearchTypeIDs.Tools:
        paginateTools({
          ...props.toolFilter,
        } as ToolFilter);
    }
  };

  const onClickMicrofence = (microfence: GridRowData) => {
    const coords = microfence.point?.coordinates;
    const extent = transformExtent([...coords, ...coords], 'EPSG:4326', 'EPSG:3857');
    microfence.selected = true;
    props.setSelectedFromMap(false);
    props.setSelectedMicrofence(microfence);
    if (extent) {
      props.setExtent(extent);
    }
    const activeMicrofence = props.activeAssets.find(
      active =>
        (active.prefix === jsUcFirst(EntityType.Microfence) &&
          active.id?.gatewayId !== undefined &&
          active.id?.gatewayId === microfence.assetId?.gatewayId) ||
        (active.id?.uuid !== undefined &&
          active.id?.major !== undefined &&
          active.id?.minor !== undefined &&
          active.id?.uuid === microfence.assetId?.uuid &&
          active.id?.major === microfence.assetId?.major &&
          active.id?.minor === microfence.assetId?.minor) ||
        (active.id?.deviceId !== undefined && active.id?.deviceId === microfence.assetId?.deviceId),
    );
    if (!activeMicrofence) return;
    props.setSelectedAsset(activeMicrofence);
  };

  useEffect(() => {
    if (!props.refreshSearch) return;

    if (props.searchType?.id === SearchTypeIDs.Geofences) {
      pageRef.current = 1;
      paginateGeofences({
        ...props.geofenceFilter,
        layerId: props.layerFilter?.layer?.id,
      } as GeofenceFilter);
    }

    // TODO: This will have to do for microfence searching for now. At least until we have an end-point that does it - LTP-478.
    if (props.searchType?.id === SearchTypeIDs.Microfences) {
      let updatedMicrofences = props.microfences;
      Object.entries(props.microfenceFilter ?? {}).map(([key, value]) => {
        if (
          !(
            key === 'orderBy' ||
            key === 'order' ||
            key === 'microfenceProperties' ||
            typeof value === 'string'
          )
        ) {
          updatedMicrofences = updatedMicrofences.filter(
            microfence => microfence.feature.get(key) === (value as MicrofenceType).id,
          );
        }
        if (key === 'searchName') {
          updatedMicrofences = updatedMicrofences.filter(microfence =>
            microfence.feature.get('name')?.includes(value),
          );
        }
        if (key === 'searchId') {
          updatedMicrofences = updatedMicrofences.filter(microfence => {
            return (
              (microfence.feature.get('type') === MicrofenceEntity.Beacon &&
                microfence.feature.get('assetId').uuid?.includes(value)) ||
              (microfence.feature.get('type') === MicrofenceEntity.Gateway &&
                microfence.feature.get('assetId').gatewayId?.includes(value)) ||
              (microfence.feature.get('type') === MicrofenceEntity.Device &&
                microfence.feature.get('assetId').deviceId?.includes(value)) ||
              (microfence.feature.get('type') === MicrofenceEntity.Smartplug &&
                microfence.feature.get('assetId').smartplugId?.includes(value))
            );
          });
        }
        if (key === 'microfenceProperties') {
          updatedMicrofences = updatedMicrofences.filter(microfence => {
            const found = Object.entries(microfence.feature.get('geomobyProperties')).find(
              ([k, v]) => {
                if (props.microfenceFilter?.microfenceProperties?.operator?.id === 'EQUAL_TO') {
                  return (
                    k === props.microfenceFilter?.microfenceProperties?.type?.value &&
                    Number(v) === props.microfenceFilter?.microfenceProperties?.value
                  );
                } else if (
                  props.microfenceFilter?.microfenceProperties?.operator?.id === 'GREATER_THAN'
                ) {
                  return (
                    k === props.microfenceFilter?.microfenceProperties?.type?.value &&
                    Number(v) > props.microfenceFilter?.microfenceProperties?.value
                  );
                } else {
                  return (
                    k === props.microfenceFilter?.microfenceProperties?.type?.value &&
                    Number(v) < props.microfenceFilter?.microfenceProperties?.value
                  );
                }
              },
            );
            if (!found) return;
            return microfence;
          });
        }
      });

      updatedMicrofences =
        props.microfenceFilter?.order?.id === 'DESC'
          ? updatedMicrofences.sort((a, b) =>
              b.feature
                .get(String(props.microfenceFilter?.orderBy?.id ?? 'name'))
                .localeCompare(
                  a.feature.get(String(props.microfenceFilter?.orderBy?.id ?? 'name')),
                ),
            )
          : updatedMicrofences.sort((a, b) =>
              a.feature
                .get(String(props.microfenceFilter?.orderBy?.id ?? 'name'))
                .localeCompare(
                  b.feature.get(String(props.microfenceFilter?.orderBy?.id ?? 'name')),
                ),
            );

      setAvailableMicrofences(updatedMicrofences);
    }

    if (props.searchType?.id === SearchTypeIDs.Beacons) {
      pageRef.current = 1;
      paginateBeacons({
        ...props.assetFilter,
      });
    }
    if (props.searchType?.id === SearchTypeIDs.GPSTrackers) {
      pageRef.current = 1;
      paginateGPSTrackers({
        ...props.assetFilter,
      });
    }
    if (props.searchType?.id === SearchTypeIDs.Devices) {
      pageRef.current = 1;
      paginateDevices({
        ...props.assetFilter,
      });
    }

    if (props.searchType?.id === SearchTypeIDs.Tools) {
      pageRef.current = 1;
      paginateTools(props.toolFilter);
      getToolTypes();
    }

    isPaginatingRef.current = false;
    setStartScrolling(false);
    props.setRefreshSearch(false);
    props.setShowFilter(false);
  }, [
    props,
    paginateGeofences,
    paginateBeacons,
    paginateDevices,
    paginateGPSTrackers,
    paginateTools,
    getToolTypes,
    refreshFilter,
  ]);

  useEffect(() => {
    if (props.clearFilter) {
      refreshFilter();
      props.setClearFilter(false);
    }
  }, [props, refreshFilter]);

  const hasSelectedElement = selectedElement(props);

  const showActiveList =
    !hasSelectedElement && !isLoading && props.searchType?.id === SearchTypeIDs.ActiveAssets;
  const showBeaconList =
    !hasSelectedElement && !isLoading && props.searchType?.id === SearchTypeIDs.Beacons;
  const showDevicesList =
    !hasSelectedElement && !isLoading && props.searchType?.id === SearchTypeIDs.Devices;
  const showGpsTrackerList =
    !hasSelectedElement && !isLoading && props.searchType?.id === SearchTypeIDs.GPSTrackers;
  const showToolsList =
    !hasSelectedElement && !isLoading && props.searchType?.id === SearchTypeIDs.Tools;
  const { selectedBeacon, selectedDevice, selectedGPSTracker, selectedTool, searchType } = props;

  return (
    <>
      <Grid
        style={{
          contentVisibility: props.showFilter ? 'hidden' : 'visible',
        }}
      >
        {hasSelectedElement && (
          <Button
            style={{
              marginLeft: '-7px',
            }}
            onClick={() => goBack()}
          >
            Back
          </Button>
        )}

        {/* Search type  */}
        {!hasSelectedElement && (
          <FormControl
            fullWidth
            style={{
              width: '98%',
              marginBottom: '20px',
            }}
          >
            <InputLabel id="search-option">Search For</InputLabel>
            <Select
              fullWidth
              labelId="search-option"
              id="search-option"
              value={props.searchType?.value ?? ''}
              label="Search For"
              onChange={e => {
                const search = SearchTypes.find(
                  l => l.value === (e.target.value as SearchTypeValue),
                );
                clearAll();
                props.setSearchType(search);

                switch (search?.id) {
                  case SearchTypeIDs.Beacons:
                    paginateBeacons();
                    break;
                  case SearchTypeIDs.Devices:
                    paginateDevices();
                    break;
                  case SearchTypeIDs.Geofences:
                    props.setLayerFilter({
                      ...props.layerFilter,
                      layer: { name: ALL_GROUPS, id: ALL_LAYERS },
                    });
                    pageRef.current = 1;
                    paginateGeofences({
                      ...props.geofenceFilter,
                      layerId: ALL_LAYERS,
                    } as GeofenceFilter);
                    break;
                  case SearchTypeIDs.GPSTrackers:
                    paginateGPSTrackers();
                    break;
                  case SearchTypeIDs.Microfences:
                    props.setLayerFilter({
                      ...props.layerFilter,
                      layer: { name: MICROFENCE_LAYER_LABEL, id: MICROFENCE_LAYER_ID },
                    });
                    break;
                  case SearchTypeIDs.Tools:
                    props.setToolFilter(undefined);
                    paginateTools();
                    getToolTypes();
                    break;
                }
              }}
            >
              {SearchTypes.map(search => (
                <MenuItem
                  key={search.id}
                  value={search.value}
                  disabled={
                    search.disabled || (!showToolFinder && search.id === SearchTypeIDs.Tools)
                  }
                >
                  <Tooltip title={search.value}>
                    <Typography
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 'calc(100% - 30px)',
                      }}
                    >
                      {search.value}
                    </Typography>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* Layers  */}
        {SidebarLayers({
          ...props,
          isLoading,
          availableGeofences,
          pageRef,
          paginateGeofences,
        })}

        {/* Filter button and count */}
        {props.searchType &&
          props.searchType?.id !== SearchTypeIDs.Locations &&
          props.searchType?.id !== SearchTypeIDs.ActiveAssets &&
          !hasSelectedElement &&
          !isLoading && (
            <Grid
              container
              direction="column"
              style={{
                display: 'grid',
                gridTemplateColumns: '50% 50%',
              }}
            >
              <Grid item>
                <Button onClick={() => props.setShowFilter(true)}>
                  <span style={{ fontSize: '10px' }}>Filter</span>
                  <FilterList />
                </Button>
                <Button onClick={() => props.setClearFilter(true)}>
                  <span style={{ fontSize: '10px' }}>Clear</span>
                  <Clear />
                </Button>
              </Grid>

              <Grid
                item
                style={{
                  margin: '8px 10px 0px 0px',
                  justifySelf: 'end',
                }}
              >
                {(props.searchType?.id === SearchTypeIDs.Geofences ||
                  props.searchType?.id === SearchTypeIDs.Beacons ||
                  props.searchType?.id === SearchTypeIDs.Devices ||
                  props.searchType?.id === SearchTypeIDs.GPSTrackers ||
                  props.searchType?.id === SearchTypeIDs.Tools) && (
                  <Typography>{`${displayCount} ${
                    Number(displayCount) === 1 ? 'result' : 'results'
                  }`}</Typography>
                )}
                {props.searchType?.id === SearchTypeIDs.Microfences && (
                  <Typography>{`${availableMicrofences.length} ${
                    Number(availableMicrofences.length) === 1 ? 'result' : 'results'
                  }`}</Typography>
                )}
              </Grid>
            </Grid>
          )}

        {/* Geofences, Microfences, Assets */}
        <Grid
          container
          direction={'row'}
          style={{
            marginTop: '10px',
            height: 'fit-content',
            maxHeight: '1000px',
            overflowY: 'auto',
          }}
          onScroll={async e => {
            const target = e.target as HTMLTextAreaElement;
            if (target.scrollTop > 8000) {
              if (isPaginatingRef.current) {
                target.scrollTop = 7800;
                return;
              }
              pageRef.current += 1;
              setStartScrolling(true);
              isPaginatingRef.current = true;
              await paginateList();
              isPaginatingRef.current = false;
              target.scrollTop = 200;
            }
            if (startScrolling && target.scrollTop < 100) {
              if (isPaginatingRef.current) {
                target.scrollTop = 300;
                return;
              }
              if (pageRef.current > 1) {
                pageRef.current -= 1;
              } else {
                setStartScrolling(false);
                return;
              }
              isPaginatingRef.current = true;
              await paginateList();
              isPaginatingRef.current = false;
              target.scrollTop = 7800;
            }
          }}
        >
          {isLoading && (
            <Grid
              container
              justifyContent={'center'}
              style={{
                marginTop: '100px',
              }}
            >
              <CenteredProgress />
            </Grid>
          )}

          {GeofenceList({
            showList:
              !hasSelectedElement && !isLoading && props.searchType?.id === SearchTypeIDs.Geofences,
            availableGeofences: availableGeofences,
            onClick: (fence: GridRowData) => {
              fence.selected = true;
              props.setSelectedFromMap(false);
              props.setSelectedGeofence(fence);
              getGeofence(fence);
            },
          })}

          {MicrofenceList({
            showList:
              !selectedElement(props) &&
              !isLoading &&
              props.searchType?.id === SearchTypeIDs.Microfences,
            availableMicrofences: availableMicrofences.map(m => {
              return {
                ...m.data,
                isActive: !!m.feature.get(ACTIVE_MICROFENCE),
              };
            }),
            onClick: onClickMicrofence,
          })}

          {ActiveList({
            ...props,
            activeAssets: showActiveList ? props.activeAssets : [],
            availableMicrofences,
            availableBeacons,
            availableDevices,
            availableGpsTrackers,
            availableTools,
          })}
          {BeaconList({
            ...props,
            knownTools,
            availableBeacons: showBeaconList ? availableBeacons : [],
          })}
          {DeviceList({ ...props, availableDevices: showDevicesList ? availableDevices : [] })}
          {TrackerList({
            ...props,
            availableGpsTrackers: showGpsTrackerList ? availableGpsTrackers : [],
            onChosen: (tracker, activeTracker) => {
              props.setSelectedGPSTracker(tracker);
              if (activeTracker) {
                props.setSelectedAsset(activeTracker);
              }
            },
          })}
          {ToolList({ ...props, availableTools: showToolsList ? availableTools : [] })}
        </Grid>

        {/* Selected Geofence */}
        {SelectedGeofence({ ...props, paginateGeofences, pageRef })}

        {/* Selected Microfence */}
        {SelectedMicrofence({ ...props, availableMicrofences })}

        {/* Selected Beacon */}
        {selectedBeacon && SelectedBeacon({ ...props, selectedBeacon: selectedBeacon })}

        {/* Selected Device */}
        {selectedDevice && SelectedDevice({ ...props, selectedDevice })}

        {/* Selected Tracker */}
        {selectedGPSTracker && SelectedTracker({ ...props, selectedGPSTracker })}

        {/* Selected Tool */}
        {selectedTool && SelectedTool({ ...props, selectedTool })}
      </Grid>

      {/* Location */}
      {props.searchType?.id === SearchTypeIDs.Locations && !hasSelectedElement && props.layers && (
        <>
          <div
            style={{
              width: '100px',
              alignSelf: 'start',
              margin: '0px 0px -20px 0px',
            }}
          >
            <Tooltip title={'Search'}>
              <Typography variant="h5">Search</Typography>
            </Tooltip>
          </div>
          <LocationSearch
            isGeofenceEditor={false}
            setLocationSearchData={props.setLocationSearchData}
            currentCenter={props.currentCenter}
            setLocationDisplay={props.setLocationDisplay}
          ></LocationSearch>

          {props.locationDisplay && (
            <Paper
              variant="outlined"
              style={{
                padding: '10px',
                height: 'fit-content',
              }}
            >
              <Grid
                container
                direction="row"
                sx={{
                  '& .MuiTypography-root': {
                    marginLeft: '0px',
                    width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                    ...truncationStyle,
                  },
                  '& .MuiStack-root': {
                    width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                    justifyContent: 'start',
                  },
                }}
              >
                <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                  <Box color="primary.main">
                    <LocationOn />
                  </Box>
                  <Tooltip title={props.locationDisplay.label}>
                    <Typography variant="h5">{props.locationDisplay.label}</Typography>
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid
                style={{
                  marginLeft: '32px',
                }}
              >
                <Tooltip title={props.locationDisplay.coordinates}>
                  <Typography
                    style={{
                      marginLeft: '-7px',
                      color: WHITE,
                    }}
                  >
                    {`${props.locationDisplay.coordinates[0]}, ${props.locationDisplay.coordinates[1]}`}
                  </Typography>
                </Tooltip>
              </Grid>
            </Paper>
          )}
        </>
      )}

      {/* Filter geofences, microfences, assets */}
      {FilterComponent({ ...props, searchType, knownTools })}
    </>
  );
};
